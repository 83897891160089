import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from "react-query";
import { ApiStatus } from "../constants";

export const getTagList = async body => {
  let response = await axiosInstance.post(`${baseUrl}/tag/list`, body);
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError);
  }
  return response.data;
};
export function useToGetTagList(params, options = {}) {
  return useQuery(["GET_Tag_LIST"], () => getTagList(params), options);
}

// All Tag List
export const allTagList = async body => {
  let response = await axiosInstance.post(`${baseUrl}/tag/fullList`, body);
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError);
  }
  return response.data;
};

export function useToGetAllTagList(params, options = {}) {
  return useQuery(["GET_AllTag_LIST"], () => allTagList(params), options);
}

// Add Tag
export const addTag = async params => {
  const response = await axiosInstance.post(`${baseUrl}/tag`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

//  Get Tag Details
export const getTagDetails = async params => {
  const response = await axiosInstance.get(`${baseUrl}/tag/${params}`);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// Update Tag
export const updateTag = async params => {
  const response = await axiosInstance.put(`${baseUrl}/tag`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// Delete Tag
export const deleteTag = async params => {
  const response = await axiosInstance.post(
    `${baseUrl}/tag/toggleDelete`,
    params
  );
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// //Task Export
// export const ExportTasktList = async params => {
//   const response = await axiosInstance.post(`${baseUrl}/task/export`, params);
//   if (response.data.code !== 200) {
//     throw new Error(response.data.message);
//   }
//   return response.data;
// };

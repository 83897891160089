import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";


// shift List
export const getShiftList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/shift/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetShiftList(params, options = {}) {
    return useQuery(['GET_SHIFT_LIST',], () => getShiftList(params), options)
}

// All Shift List
export const getAllShiftList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/shift/fullList`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}

// shift add 
export const shiftAdd = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/shift`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

//  Get shift Details
export const getShiftDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/shift/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update shift
export const updateShift = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/shift`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}
export const breakRemove = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/shift/removeBreak`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}
// Delete shift
export const deleteShift = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/shift/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
//Shift Export
export const ExportShitList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/shift/export`, params);
    if (response.data.code !== 200) {
      throw new Error(response.data.message);
    }
    return response.data;
  };
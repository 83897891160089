import React from 'react'
import Table from '../../../components/Table/Table'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import useSkillList from './Hooks/useSkillList'
import useSkillAddUpdate from './Hooks/useSkillAddUpdate'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'
import { Pagination } from '../../../components/pagination/Pagination'
import SearchAndStatusFilter from '../../../components/SearchAndStatusFilter'
import Modal from '../../../components/Modal'
// import TagForm from "./TagForm";
import CreateSkill from './CreateSkill'

const Skills = () => {
  const { data, methods } = useSkillList()
  const { data: FormData, methods: FormMethods } = useSkillAddUpdate()

  const { skillFormModel } = FormData
  const { setSkillFormModel, setSkillInputs } = FormMethods
  const { skillList, search, statusType, totalCount, controller, isLoading, deleteModal } = data
  const { handleSearchChange, setStatusType, resetFilter, applayFilter, handlePagination, setDeleteModal, handleDelete } = methods

  const TableHead = [
    { label: '#', id: '' },
    { label: 'Skill Name', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} />
            </div>
            <div className="d-flex align-items-end">
              <Button className="dropDownButtons11 ms-4" onClick={() => setSkillFormModel((prev) => ({ ...prev, open: true }))} startIcon={<AddIcon />}>
                Add New
              </Button>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus pt-2">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} />
                    <tbody>
                      {skillList?.map((item, index) => {
                        return (
                          <tr
                            className="text-start rounded-row "
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td className="table-link">{controller.rowsPerPage * (controller.page - 1) + index + 1} </td>
                            <td className="">{item.skillName || '--'}</td>
                            <td>
                              <div className="dropdown p-0 align-items-center justify-content-center">
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <li style={{ cursor: 'pointer' }} onClick={() => setSkillFormModel({ data: item._id, open: true })} className="dropdown-item">
                                    Edit
                                  </li>
                                  <li className="dropdown-item" onClick={() => setDeleteModal({ data: item._id, open: true })}>
                                    Delete
                                  </li>
                                  {/* <a className="dropdown-item">
                                      View History
                                    </a> */}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!skillList?.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Modal */}
      <Modal
        size="sm"
        show={skillFormModel?.open}
        onHide={() => {
          setSkillFormModel({ data: '', open: false })
          setSkillInputs({ skillName: '' })
        }}
        headerContent={<h5>Skills</h5>}
        bodyContent={<CreateSkill data={FormData} methods={FormMethods} />}
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default Skills

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useToGetPriject, deleteProject, ExportProjecttList } from '../../../repository/projectRepo'
import { toast } from 'react-toastify'
import useReduxStore from '../../../hooks/useReduxStore'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../Slices/FiltersSlice'

const useProjectList = () => {
  const { selector: user } = useReduxStore('auth')
  const { selector: ProjectsFilter } = useReduxStore('filters')
  const dispatch = useDispatch()
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  const [filterAction, setFilterAction] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [search, setSearchText] = useState(ProjectsFilter?.Projects?.filters?.search || '')
  const [statusType, setStatusType] = useState(ProjectsFilter?.Projects?.filters?.statusType || '')
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const cacheTime = { cacheTime: 10000 }

  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    search: search,
  }
  if (typeof statusType == 'boolean') payload.isDeleted = statusType
  else {
    statusType === 'false' && (payload.isDeleted = false)
    statusType === 'true' && (payload.isDeleted = true)
  }

  useEffect(() => {
    // Dispatch the addFilter action with the current 'Projects' page and the filter
    dispatch(addFilter({
      page: 'Projects', filter: {
        search: search,
        statusType: statusType,
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, statusType]);
  // Fetch api
  const {
    data: projectsList,
    error: projectError,
    isFetching: projectIsFetching,
    refetch,
  } = useToGetPriject(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetch()
  }, [controller, filterAction])

  // Error Toast Message
  useEffect(() => {
    if (projectError) {
      // toast.error(projectError?.message);
    }
  }, [projectError])

  // Export Projectt Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportProjecttList(payload)
      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setSearchText('')
    setStatusType()
  }
  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetch()
    });
  }
  // Handle Search
  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    // Perform any other actions you need here
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteProject({
        _id: deleteModal?.data._id,
        isDeleted: !deleteModal.data.isDeleted,
      })
      toast.success(response.message)
      setDeleteModal({ data: '', open: false })
      refetch()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      controller,
      isLoading: spinner || projectIsFetching,
      totalCount: projectError?.message === "No record found" ? 0 : projectsList?.totalCount || 0,
      projectList: projectError?.message === "No record found" ? [] : projectsList?.data || [],
      search,
      statusType,
      deleteModal,
      ProjectPermission: user
    },
    methods: {
      handlePagination,
      resetFilter,
      applayFilter,
      handleSearchChange,
      setStatusType,
      setDeleteModal,
      handleDelete,
      Export
    },
  }
}

export default useProjectList

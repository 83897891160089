/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Box from '@mui/material/Box'
import Button from 'react-bootstrap/Button'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useNavigate } from 'react-router-dom'
// import Emily from '../../assets/images/cap.png'
// import NavDropdown from 'react-bootstrap/NavDropdown'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import CallIcon from '@mui/icons-material/Call'
import AddIcon from '@mui/icons-material/Add'
// import TextField from '@mui/material/TextField'
// import PropTypes from 'prop-types'
// import Collapse from '@mui/material/Collapse'
// import IconButton from '@mui/material/IconButton'
// import { Table, TableBody } from '@mui/material'
// import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
// import TableHead from '@mui/material/TableHead'
// import TableRow from '@mui/material/TableRow'
// import Typography from '@mui/material/Typography'
// import Paper from '@mui/material/Paper'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
// import Checkbox from '@mui/material/Checkbox'
import { MenuItem } from '@mui/material'
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'
import NewTable from '../../components/Table/Table'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import { CKEditorCustomToolbar } from '../../constants'

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
// const checkedIcon = <CheckBoxIcon fontSize="small" />

const Contacts = () => {
  const [value, setValue] = React.useState('2')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const Navigate = useNavigate()

  const TableHeadData = [
    { label: 'Tags', id: '' },
    { label: 'Status', id: '' },
    { label: 'Agent', id: '' },
    { label: 'Resolved', id: '' },
    { label: 'Resolve/Closed', id: '' },
  ]
  const TableRows = [
    {
      tags: 'kyu #',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolved: 'a day ago',
      resolve: 'on time',
    },
    {
      tags: 'Email address change #',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolved: 'two day ago',
      resolve: 'late',
    },
    {
      tags: 'Project 3',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolved: 'a day ago',
      resolve: 'on time',
    },
  ]
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className="">
        <div className="contact">
          <div className="container bg">
            <div className="row">
              <div className="col-lg-12 pt-4">
                <div className="d-flex justify-content-end">
                  <div className="d-flex">
                    {/* <div className="">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Add Tags"
                        // multiline
                        maxRows={4}
                        variant="outlined"
                        className="outlinedtextfield"
                        defaultValue=""
                        size="small"
                      />
                    </div> */}
                    <div>
                      <Button variant="primary3" className="px-2" onClick={() => Navigate(`/tickets/new-ticket-form`)}>
                        <AddIcon /> New Ticket
                      </Button>
                    </div>
                    <div className="pe-4">
                      {/* <Button variant="primary3" className="px-2">
                 <MoreVertIcon/> 
                </Button> */}
                      <div className="ms-4 dotsbtn">
                        <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                          <MoreVertIcon />
                        </a>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={handleClose}>Edit</MenuItem>
                          {/* <MenuItem onClick={handleClose}>
                            Delete
                          </MenuItem> */}
                          <MenuItem onClick={handleClose}>Merge</MenuItem>
                          {/* <MenuItem onClick={handleClose}>
                            Convert to Agent
                          </MenuItem> */}
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pt-4 mt-2 ms-4">
                  <div className="row my-3">
                    <div className="col-lg-6">
                      <p className="">
                        <span className="fw-bold">Name :</span> <span className="text-muted">Sarah</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Emails :</span> <span className="text-muted">emily.garcia@freshdesk.com</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Address :</span> <span className="text-muted">86-90 Paul Street, London, EC2A 4NE</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Language :</span> <span className="text-muted">English</span>
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p className="">
                        <span className="fw-bold">Time Zone :</span> <span className="text-muted">Casablanca</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Phone :</span> <span className="text-muted">+448081698824</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Type :</span> <span className="text-muted">Phone</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container bg my-4">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {/* <Tab label="TIMELINE" value="1" /> */}
                    <Tab label="TICKETS" value="2" />
                    <Tab label="NOTES" value="3" />
                    {/* <Tab label="FORUMS" value="4" /> */}
                  </TabList>
                </Box>
                {/* <TabPanel value="1">

                  <TableContainer component={Paper} className="shadow">
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow className="tbl-head">
                          <TableCell />
                          <TableCell><b>Ticket Id</b></TableCell>
                          <TableCell align="left"><b>Date</b></TableCell>
                          <TableCell align="left"><b>Time</b></TableCell>
                          <TableCell align="left"><b>Channel</b></TableCell>
                          <TableCell align="left"><b>Group</b></TableCell>
                          <TableCell align="left"><b>Agent</b></TableCell>
                          <TableCell align="left"><b>Status</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <Row key={row.name} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel> */}
                <TabPanel value="2">
                  <div className="">
                    <div className="mt-2">
                      <table className="table">
                        <NewTable TableHeadData={TableHeadData} />
                        <tbody>
                          {TableRows?.map((item) => {
                            return (
                              <tr
                                className="text-start rounded-row"
                                style={{
                                  borderRadius: '50px',
                                }}
                              >
                                <td className="table-link" onClick={() => Navigate(`/contacts/1234`)}>
                                  {item.tags}
                                </td>
                                <td>{item.status}</td>
                                <td className="" onClick={() => Navigate(`/tickets/email`)}>
                                  {item.agent}
                                </td>
                                <td>{item.resolved}</td>
                                <td>{item.resolve}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className="col-lg-12">
                    <div className="App">
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: CKEditorCustomToolbar,
                        }}
                        data=""
                        onReady={(editor) => {
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          // const data = editor.getData()
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex justify-content-end pb-2 py-5">
                      <Button variant="primary3" className="px-4">
                        Cancel
                      </Button>
                      <Button variant="primary2" className="px-4 mx-3">
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </TabPanel>
                {/* <TabPanel value="4">FORUMS</TabPanel> */}
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacts

// function createData(ticket, date, time, channel, group, agent, status) {
//   return {
//     ticket,
//     date,
//     time,
//     channel,
//     group,
//     agent,
//     status,
//     history: [
//       {
//         tags: 'kyu #4',
//         created: 'Sachin',
//         priority: 'High',
//         response: 3,
//         file: 'image',
//       },
//       {
//         tags: 'Payment #5',
//         created: 'Rana',
//         priority: 'Urgent',
//         response: 1,
//         file: 'image',
//       },
//     ],
//   }
// }

// const rows = [createData('ALH452635', '16/12/2022', '07:07 AM', 'Email', 'Escalations', 'Rana', 'Open'), createData('BHE258679', '10/06/2023', '09:12 AM', 'Phone', 'Escalations', 'Rana', 'Closed')]

// function Row(props) {
//   const { row } = props
//   const [open, setOpen] = React.useState(false)
//   const Navigate = useNavigate()
//   return (
//     <React.Fragment>
//       <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
//         <TableCell>
//           <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </IconButton>
//         </TableCell>
//         <TableCell component="th" scope="row" className="table-link" onClick={() => Navigate(`/tickets/email`)}>
//           {row.ticket}
//         </TableCell>
//         <TableCell align="left">{row.date}</TableCell>
//         <TableCell align="left">{row.time}</TableCell>
//         <TableCell align="left">{row.channel}</TableCell>
//         <TableCell align="left">{row.group}</TableCell>
//         <TableCell align="left">{row.agent}</TableCell>
//         <TableCell align="left">{row.status}</TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box sx={{ margin: 1 }}>
//               <Table size="small" aria-label="purchases">
//                 <TableHead>
//                   <TableRow className="tbl-head">
//                     <TableCell align="left">
//                       <b>Tags</b>
//                     </TableCell>
//                     <TableCell>
//                       <b>Created</b>
//                     </TableCell>
//                     <TableCell>
//                       <b>Priority</b>
//                     </TableCell>
//                     <TableCell align="left">
//                       <b>Response</b>
//                     </TableCell>
//                     <TableCell align="left">
//                       <b>File</b>
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {row.history.map((historyRow) => (
//                     <TableRow key={historyRow.created} className="tbl-row">
//                       <TableCell align="left" className="table-link">
//                         {historyRow.tags}
//                       </TableCell>
//                       <TableCell component="th" scope="row">
//                         {historyRow.created}
//                       </TableCell>
//                       <TableCell>{historyRow.priority}</TableCell>
//                       <TableCell align="left">{historyRow.response}</TableCell>
//                       <TableCell align="left">{historyRow.file}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </React.Fragment>
//   )
// }

import React from "react";
import { Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];


const TicketUpdatesForms = () => {
  return (
    <>
    <div className="contact">
      <div className="container bg">
        <div className="row">
          <div className="row my-3">
            <div className="col-lg-4 mt-4">
              <TextField
                id="standard-multiline-flexible"
                label="Rule name"
                // multiline
                className="w-75"
                maxRows={4}
                variant="standard"
                defaultValue=""
                placeholder="Enter Rule name"
              />
            </div>
          </div>
          <div className="pt-3 mb-4">
            <h5>When an action performed by...</h5>
            <div className="">
              <FormControlLabel
                value="best"
                control={<Radio />}
                label="Agent"
              />
            </div>
            <div className="">
              <FormControlLabel
                value="worst"
                control={<Radio />}
                label="Requester"
              />
            </div>
            <div className="">
              <FormControlLabel
                value="worst"
                control={<Radio />}
                label="Agent or requester"
              />
            </div>
            <div className="">
              <FormControlLabel
                value="worst"
                control={<Radio />}
                label="System"
              />
            </div>
            
          </div>

          <h5 className="m-0 ">Involves any of these events</h5>
          <div style={{ backgroundColor: "#f9f9f9" }} className="mt-3">
            <div className="row">
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="col-lg-11 mb-4 d-flex justify-content-between">
              <div className="d-flex py-2">
              </div>
              <div>
                <Button className="px-2 add-cc-btn mt-3">+</Button>
              </div>
            </div>
          </div>



          <div className="pt-3 mt-4">
            <h5>When an action performed by...</h5>
            <div className="">
              <FormControlLabel
                value="best"
                control={<Radio />}
                label="Match ANY of the below"
              />
            </div>
            <div className="">
              <FormControlLabel
                value="worst"
                control={<Radio />}
                label="Match ALL of the below"
              />
            </div>
            
          </div>
          <div style={{ backgroundColor: "#f9f9f9" }} className="mt-1">
            <div className="row">
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="col-lg-11 mt-4">
              <TextField
                id="standard-select-currency"
                select
                label=""
                defaultValue=""
                className="w-100"
                variant="standard"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-lg-11 mb-4 d-flex justify-content-between">
              <div className="d-flex py-2">
              </div>
              <div>
                <Button className="px-2 add-cc-btn mt-3">+</Button>
              </div>
            </div>
          </div>






          <div className="text-center mb-4">
            <Button className="px-4 add-cc-btn mt-3">+ Add new filter </Button>
          </div>



          <h5>Perform these actions</h5>
          <div style={{ backgroundColor: "#f9f9f9" }} className="mt-3">
            <div className="row">
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-75"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
            </div>
            {/* <div className="col-lg-11 mt-4">
              <TextField
                id="standard-select-currency"
                select
                label=""
                defaultValue=""
                className="w-100"
                variant="standard"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div> */}
            <div className="col-lg-11 mb-4 d-flex justify-content-between">
              <div className="d-flex py-2">
                {/* <Switch {...label} /> <p className="mt-1">Match case</p>{" "} */}
              </div>
              <div>
                <Button className="px-2 add-cc-btn mt-3">+</Button>
              </div>
            </div>
          </div>






          <div className="col-lg-11 mb-5">
            <div className="row">
              <div className="d-flex justify-content-between">
                <div className="mt-4">
                </div>
                <div className="row">
                  <div className="col-lg-4 mt-4 text-center">
                    <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                  </div>
                  <div className="col-lg-8 mt-4 text-center">
                    <Button className="px-4 add-cc-btn mt-3">
                      Preview and Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default TicketUpdatesForms

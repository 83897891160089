import React from 'react'
import { Form, Button } from 'react-bootstrap';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";

const EmailList = () => {
    const navigate = useNavigate();

    return (
        <div className="row my-4">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div>
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className="me-2 w-100"
                            aria-label="Search"
                        />
                    </div>
                    <div>
                        <Button variant="primary4" className="px-4 ms-3">
                            <SearchIcon />
                        </Button>
                    </div>
                </div>
                <div className="d-flex me-5 pe-1">
                    {/* <div>
                <Button variant="primary1">
                  <ExitToAppIcon /> Export
                </Button>{" "}
              </div> */}
                    <div className="ms-2">
                        <Button className="dropDownButtons11 ms-4" onClick={() => navigate(`/setting/channel/email-form`)}>
                            <AddIcon /> Add New
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailList
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "../../components/Spinner/Spinner";
import { UserChangePassword } from "../../repository/auth";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
const ChangePassword = () => {
    const [password, setPassword] = useState("password");
    const [newPassword, setNewPassword] = useState("password");
    const [confirmNewPass, setConfirmNewPass] = useState("password");
    const navigate = useNavigate();
    const [spinner, setspinner] = useState(false);
    const [changePassData, setChangePassData] = useState({
        password: "",
        new_password: "",
        confirm_password: "",
    });
    const [validation, setValidation] = useState({});

    const ErrorMessageObj = {
        password: "Password is required",
        new_password: "New password is required",
        confirm_password: "Confirm password is required",
    };

    // Input value onChange
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setChangePassData((prevProps) => ({
            ...prevProps,
            [name]: value,
        }));
        // error message!
        if (!value?.trim()?.length) {
            validation[name] = ErrorMessageObj[name];
        } else {
            delete validation[name];
        }
        setValidation({ ...validation });
    };

    // const isValid = () => {
    //     let formValid = true;
    //     for (let item in changePassData) {
    //         if (!changePassData[item]?.trim()?.length) {
    //             let newItem =
    //                 item.replace(/_/, " ").charAt(0).toUpperCase() +
    //                 item.replace(/_/, " ").slice(1);
    //             validation[item] = newItem + " is required";
    //             formValid = false;
    //         }
    //     }
    //     setValidation({ ...validation });
    //     return formValid;
    // };


    const isValid = () => {
        let formValid = true;
        for (let item in changePassData) {
            if (!changePassData[item]?.trim()?.length) {
                let newItem =
                    item.replace(/_/, " ").charAt(0).toUpperCase() +
                    item.replace(/_/, " ").slice(1);
                validation[item] = newItem + " is required";
                formValid = false;
            }
        }

        // Check if confirm_password matches new_password
        if (changePassData.new_password !== changePassData.confirm_password) {
            validation.confirm_password = "Passwords do not match";
            formValid = false;
        } else {
            delete validation.confirm_password;
        }

        setValidation({ ...validation });
        return formValid;
    };


    // On form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        isValid() && chnagePassword();
    };

    const chnagePassword = async (value) => {
        let data = {
            oldPassword: changePassData?.password,
            newPassword: changePassData?.new_password,
        };
        setspinner(true);
        try {
            let response = await UserChangePassword(data);
            // dispatch(logout());
            toast.success(response?.message);
            setTimeout(() => {
                navigate("/")
            }, 1000)
        } catch (error) {
            toast.error(error?.message)
        }
        setspinner(false);
    };

    return (
        <>
            <section>
                <ToastContainer />
                {spinner && <Spinner />}
                <div className="container" style={{ backgroundColor: "white" }}>
                    <form onSubmit={handleSubmit}>
                        <div className="row mx-auto">
                            <div className="text-center my-5">
                                <h2>
                                    <b>Change Password</b>
                                </h2>
                            </div>
                            <div className="col-lg-3 mt-4 ps-5">
                                <label htmlFor="New Password">
                                    Password <span className="text-danger">*</span>
                                </label>
                                <div style={{ position: "relative" }}>
                                    <TextField
                                        name={"password"}
                                        onChange={handleInputChange}
                                        onBlur={handleInputChange}
                                        value={changePassData.password}
                                        type={password}
                                        variant={"standard"}
                                    />
                                    {validation.password && (
                                        <p className="text-danger">{validation.password}</p>
                                    )}

                                    <span onClick={() => password === "text" ? setPassword("password") : setPassword("text")}>
                                        {password === "password" ? (
                                            <i className="fa fa-eye-slash" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-eye" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-4 ps-5">
                                <label htmlFor="New Password">
                                    New Password <span className="text-danger">*</span>
                                </label>
                                <div style={{ position: "relative" }}>
                                    <TextField
                                        name={"new_password"}
                                        onChange={handleInputChange}
                                        onBlur={handleInputChange}
                                        value={changePassData.new_password}
                                        type={newPassword}
                                        variant={"standard"}
                                    />
                                    {validation.new_password && (
                                        <p className="text-danger">{validation.new_password}</p>
                                    )}

                                    <span onClick={() => newPassword === "text" ? setNewPassword("password") : setNewPassword("text")} >
                                        {newPassword === "password" ? (
                                            <i className="fa fa-eye-slash" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-eye" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-4 ps-5">
                                <label htmlFor="Confirm Password">
                                    Confirm Password <span className="text-danger">*</span>
                                </label>
                                <div className="position-relative">
                                    <TextField
                                        name={"confirm_password"}
                                        onChange={handleInputChange}
                                        onBlur={handleInputChange}
                                        value={changePassData.confirm_password}
                                        type={confirmNewPass}
                                        variant={"standard"}
                                    />
                                    {validation.confirm_password && (
                                        <p className="text-danger">{validation.confirm_password}</p>
                                    )}

                                    <span onClick={() => confirmNewPass === "text" ? setConfirmNewPass("password") : setConfirmNewPass("text")}>
                                        {confirmNewPass === "password" ? (
                                            <i className="fa fa-eye-slash" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-eye" style={{ position: "absolute", top: "10px", right: "0px", }} aria-hidden="true" />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="my-5 text-center">
                                <Button type="submit" variant="contained">
                                    <span style={{ fontSize: "14px" }}>Update Password</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default ChangePassword
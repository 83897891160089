import React from "react";
import { TextField, InputLabel } from "@mui/material";

const index = ({ ClassName, Lable, Name, HandleInputChange, Value, Error, max, type, readOnly, onBlur, Require, onInput, width, Disable, variant }) => {
    return (
        <div className={`${ClassName} m-3`}>
            {/* <label htmlFor={Lable}>{Lable} </label> */}
            {/* {Require == true && <span className="text-danger">*</span>} */}
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{Lable}{Require && <span className="text-danger"> *</span>}</InputLabel>

            <TextField
                // id="standard-basic"
                required={Require}
                // label={Lable}
                inputProps={{ maxLength: max, readOnly: readOnly }}
                name={Name}
                // onChange={HandleInputChange}
                onChange={(e) => {
                    if (type === "number") {
                        if (parseFloat(e.target.value) < 0) {
                            e.target.value = "0";
                        }
                    }
                    HandleInputChange(e);
                }}
                onBlur={onBlur}
                value={Value}
                type={type}
                variant={variant ? variant : "standard"}
                size="small"
                //   onInput={type == "number" ? onInput : () => { }}
                className={`w-${width}`}
                disabled={Disable}
            />
            {Error && <p className="text-danger">{Error}</p>}
        </div>
    );
};
export default (index)

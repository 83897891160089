import React from 'react'
import TextInput from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import DateInput from '../../../components/DateInput'
import { EmployeeType } from '../../../constants'
import { Switch } from '@mui/material'


const JobDetails = ({ data, methods }) => {
    const { error, teamMemberInputs, allRolesList, allShiftList, reportManagerData } = data
    const { handleInputChange, handleBlur, setTeamMemberInputs, handleDateChange, setError, } = methods
    // console.log(allShiftList,'allShiftList')
    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Designation"
                        Name="designation"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        // onBlur={handleBlur}
                        Value={teamMemberInputs?.designation}
                        width={100}
                        // Require={true}
                        Error={error?.designation}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <DateInput
                        className="w-100 form-field-design"
                        isRequired={true}
                        Label={'Date of Joining'}
                        handleDateChange={(date) => { handleDateChange(date, 'dateOfJoining'); setError((pre) => ({ ...pre, dateOfJoining: '' })) }}
                        SelectedDate={teamMemberInputs?.dateOfJoining}
                    />
                    {error?.dateOfJoining && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.dateOfJoining}</span>}
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Employee Type'}
                        width={'310px'}
                        Options={EmployeeType}
                        keyName={'title'}
                        className="form-field-design"
                        Name="empType"
                        SelectValue={teamMemberInputs?.empType}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.empType}
                        variant={'outlined'}
                        isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Shift'}
                        width={'310px'}
                        Options={allShiftList}
                        keyName={'name'}
                        className="form-field-design"
                        Name="shift"
                        SelectValue={teamMemberInputs?.shift}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.shift}
                        variant={'outlined'}
                        isRequired={true}
                    />
                </div>
                {/* <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Employee Type"
                        Name="empType"
                        variant="outlined"
                        ClassName="form-field-design"
                        Require={true}
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.empType}
                        width={100}
                        Error={error?.empType}
                    />
                </div> */}
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Roles'}
                        Options={allRolesList}
                        keyName={'roleTitle'}
                        className="form-field-design"
                        Name="role_id"
                        SelectValue={teamMemberInputs?.role_id}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.role_id}
                        variant={'outlined'}
                        isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Reporting Manager'}
                        width={'310px'}
                        Options={reportManagerData}
                        keyName={'firstName'}
                        className="form-field-design"
                        Name="reportingManager"
                        SelectValue={teamMemberInputs?.reportingManager}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.reportingManager}
                        variant={'outlined'}
                    />
                    {teamMemberInputs?.reportingManager?.length ? <p className='float-end text-danger pointer mt-1' onClick={() => setTeamMemberInputs((pre) => ({ ...pre, reportingManager: null }))}>Remove Reporting Manager</p> : null}
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Probation Days"
                        Name="probationDays"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.probationDays}
                        width={100}
                        Require={true}
                        type={'number'}
                        Error={error?.probationDays}
                    />
                </div>
                <div className="col-lg-4 mt-5">
                    <label htmlFor="" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)' }}>Earn leave caluculate</label>
                    <Switch checked={teamMemberInputs?.elCalculate} onChange={(e) => setTeamMemberInputs((pre) => ({ ...pre, elCalculate: e.target.checked }))} />
                </div>
            </div>

        </>
    )
}

export default JobDetails

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { MenuItem,} from '@mui/material'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import SearchIcon from '@mui/icons-material/Search'
import Form from 'react-bootstrap/Form'



const ReportingAnalytics = () => {
  const Navigate = useNavigate()

  const TableHead = [
    { label: 'Report Name', id: '' },
    { label: 'Created by', id: '' },
    { label: 'Created Date', id: '' },
    { label: 'Last Modified by', id: '' },
    { label: 'Last Modified Date', id: '' },
    { label: 'Action', id: '' },
  ]
  const TableRow = [
    {
      reportname: 'Time sheet Summary',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
    {
      reportname: 'Canned Responses Report',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
    {
      reportname: 'Freddy Ticket Insights - BETA',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
  ]
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div>
                <NavDropdown title="All Reports" className="dropDownButtons" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="#action3" className="">
                    All reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Private reports</NavDropdown.Item>
                  <NavDropdown.Item href="#action5">Shared reports</NavDropdown.Item>
                  <NavDropdown.Item href="#action6">Owned reports</NavDropdown.Item>
                  <NavDropdown.Item href="#action7">Curated reports</NavDropdown.Item>
                  <NavDropdown.Item href="#action8">Trash</NavDropdown.Item>
                </NavDropdown>
              </div>

              <div>
                <NavDropdown title="Sort by:" className="dropDownButtons" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="#action3" className="">
                    Name
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Created By</NavDropdown.Item>
                  <NavDropdown.Item href="#action5">Created Date</NavDropdown.Item>
                  <NavDropdown.Item href="#action6">Last Modified by</NavDropdown.Item>
                  <NavDropdown.Item href="#action7">Last modified date</NavDropdown.Item>
                  <NavDropdown.Item href="#action8">Ascending</NavDropdown.Item>
                  <NavDropdown.Item href="#action9">Descending</NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
            <div className="d-flex me-5 pe-1">
              {/* <div>
                <Button variant="primary1">
                  <ExitToAppIcon /> Export
                </Button>{" "}
              </div> */}
              <div className="d-flex">
                <div>
                  <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                </div>
                <div>
                  <Button variant="primary4" className="px-4 ms-3">
                    <SearchIcon />
                  </Button>
                </div>
                <div>
                  <Button variant="primary1" className="ms-4">
                    <ExitToAppIcon /> Export
                  </Button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {TableRow?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="text-start    rounded-row "
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td className="table-link" onClick={() => Navigate(``)}>
                              {item.reportname}{' '}
                            </td>
                            <td>{item.createdby}</td>
                            <td>{item.createddate}</td>
                            <td>{item.modifiedby}</td>
                            <td>{item.modifieddate}</td>
                            <td>
                              <div>
                                <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                  <MoreVertIcon />
                                </a>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={handleClose}>Clone Report</MenuItem>
                                </Menu>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportingAnalytics

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useToGetTagList, deleteTag } from '../../../../repository/tagsRepo';
import { toast } from "react-toastify";
import useReduxStore from '../../../../hooks/useReduxStore';
import { useDispatch } from 'react-redux';
import { addFilter } from '../../../../Slices/FiltersSlice';

const useTagList = () => {
    const { selector: TagsFilter } = useReduxStore('filters')
    const dispatch = useDispatch()
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [filterAction, setFilterAction] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [search, setSearchText] = useState(TagsFilter?.Tags?.filters?.search || "");
    const [statusType, setStatusType] = useState(TagsFilter?.Tags?.filters?.statusType || "false");
    const cacheTime = { cacheTime: 10000 };
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: [],
    });

    // Listing payLoad
    let payload = {
        count: controller.rowsPerPage,
        page: controller.page,
        isDeleted:  statusType==='true',
        search: search
    }
    // if (typeof statusType == "boolean") payload.isActive = statusType
    // else {
    //     statusType == 'false' && (payload.isActive = false)
    //     statusType == 'true' && (payload.isActive = true)
    // }
    useEffect(() => {
        // Dispatch the addFilter action with the current 'Tags' page and the filter
        dispatch(addFilter({
            page: 'Tags', filter: {
                search: search,
                statusType: statusType,
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, statusType]);

    // Fetch api
    const {
        data: tagList,
        error: tagError,
        isFetching: tagIsFetching,
        refetch,
    } = useToGetTagList(payload,
        {
            enabled: false,
            ...cacheTime,
        }
    );


    useEffect(() => {
        refetch();

    }, [controller, filterAction]);

    // Error Toast Message
    useEffect(() => {
        if (tagError) {
            // toast.error(tagError?.message);
        }
    }, [tagError]);

    const resetFilter = () => {
        setFilterAction((prev) => !prev)
        setStatusType()
        setSearchText("")
    }
    // Apply Search Filter
    const applayFilter = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            refetch()
        });
    };

    // Handle Search
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        // Perform any other actions you need here
    };
    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj);
    };
    // handle Delete
    const handleDelete = async () => {
        try {
            setSpinner(true);
            let response = await deleteTag({
                _id: deleteModal?.data,
                isDeleted: true,
            });
            toast.success(response.message);
            setDeleteModal({ data: "", open: false });
            refetch();
        } catch (error) {
            toast.error(error.message);
        }
        setSpinner(false);
    };

    return {
        data: {
            controller,
            isLoading: spinner || tagIsFetching,
            totalCount: tagError?.message === "No record found" ? 0 : tagList?.totalCount || 0,
            tagList: tagError?.message === "No record found" ? [] : tagList?.data || [],
            search,
            deleteModal,
            statusType,
        },
        methods: {
            handlePagination,
            resetFilter,
            applayFilter,
            handleSearchChange,
            setStatusType,
            handleDelete,
            setDeleteModal,
            refetch
        }
    }

}

export default useTagList
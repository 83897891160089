import React from 'react'
import Button from 'react-bootstrap/Button'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useNavigate } from 'react-router-dom'
import NewTable from '../../components/Table/Table'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditorCustomToolbar } from '../../constants'

const AllProject = () => {
  const [value, setValue] = React.useState('2')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const Navigate = useNavigate()

  const TableHeadData = [
    { label: 'Tags', id: '' },
    { label: 'Status', id: '' },
    { label: 'Agent', id: '' },
    { label: 'Resolve/Closed', id: '' },
  ]
  const TableRows = [
    {
      tags: 'kyu #',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolve: 'on time',
    },
    {
      tags: 'Email address change #',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolve: 'late',
    },
    {
      tags: 'Project 3',
      status: 'Resolved',
      agent: 'Sachin Rana',
      resolve: 'on time',
    },
  ]

  return (
    <>
      <div className="">
        <div className="contact">
          <div className="container bg">
            <div className="row">
              <div className="col-lg-12 pt-4">
                <div className="d-flex justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex">
                      {/* <div>
                                                <Form.Control
                                                    type="search"
                                                    placeholder="Search by (Id/Tag)"
                                                    className="me-2 w-100"
                                                    aria-label="Search"
                                                />
                                            </div>
                                            <div>
                                                <Button variant="primary4" className="px-4 ms-3">
                                                    <SearchIcon />
                                                </Button>
                                            </div> */}
                    </div>
                    <div className="pe-4">
                      {/* <div className="ms-4 dotsbtn">
                                                <a
                                                    id="fade-button"
                                                    aria-controls={
                                                        open ? "fade-menu" : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </a>
                                                <Menu
                                                    id="fade-menu"
                                                    MenuListProps={{
                                                        "aria-labelledby": "fade-button",
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                >
                                                    <MenuItem onClick={handleClose}>
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        Delete
                                                    </MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        Merge
                                                    </MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        Convert to Agent
                                                    </MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        Change Password
                                                    </MenuItem>
                                                </Menu>
                                            </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pt-4 mt-2 ms-4">
                  <div className="row my-3">
                    <div className="col-lg-6">
                      <p className="">
                        <span className="fw-bold">Project Name :</span> <span className="text-muted">Project 1</span>
                      </p>
                      {/* <p className=""><span className="fw-bold">Emails :</span> <span className="text-muted">emily.garcia@freshdesk.com</span></p> */}
                      <p className="">
                        <span className="fw-bold">Project ID :</span> <span className="text-muted">ABG16548526582</span>
                      </p>
                      <p className="">
                        <span className="fw-bold">Channel :</span> <span className="text-muted">Email, Phone, Chats</span>
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p className="">
                        <span className="fw-bold">Notes :</span> <span className="text-muted">All Channel Sports</span>
                      </p>
                      {/* <p className=""><span className="fw-bold">Work Phone :</span> <span className="text-muted">+448081698824</span></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container bg my-4">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {/* <Tab label="TIMELINE" value="1" /> */}
                    <Tab label="TICKETS" value="2" />
                    <Tab label="NOTES" value="3" />
                  </TabList>
                </Box>
                {/* <TabPanel value="1">

                                    <TableContainer component={Paper} className="shadow">
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow className="tbl-head">
                                                    <TableCell />
                                                    <TableCell><b>Ticket Id</b></TableCell>
                                                    <TableCell align="left"><b>Date</b></TableCell>
                                                    <TableCell align="left"><b>Time</b></TableCell>
                                                    <TableCell align="left"><b>Channel</b></TableCell>
                                                    <TableCell align="left"><b>Group</b></TableCell>
                                                    <TableCell align="left"><b>Agent</b></TableCell>
                                                    <TableCell align="left"><b>Status</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <Row key={row.name} row={row} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel> */}
                <TabPanel value="2">
                  <div className="">
                    <div className="mt-2">
                      <table className="table">
                        <NewTable TableHeadData={TableHeadData} />
                        <tbody>
                          {TableRows?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className="text-start rounded-row "
                                style={{
                                  borderRadius: '50px',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <td className="table-link" onClick={() => Navigate(`/contacts/1234`)}>
                                  {item.tags}
                                </td>
                                <td>{item.status}</td>
                                <td className="" onClick={() => Navigate(`/tickets/email`)}>
                                  {item.agent}
                                </td>
                                <td>{item.resolve}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className="col-lg-12">
                    <div className="App">
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: CKEditorCustomToolbar,
                        }}
                        data=""
                        onReady={(editor) => {
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          // const data = editor.getData()
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex justify-content-end pb-2 py-5">
                      <Button variant="primary3" className="px-4">
                        Cancel
                      </Button>
                      <Button variant="primary2" className="px-4 mx-3">
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllProject


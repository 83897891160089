/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useToGetTicketList } from '../../../repository/ticketRepo';
import { useParams } from 'react-router-dom';
import { getUserProfile } from '../../../repository/auth';
import { MergeReportingUser, userFullList } from '../../../repository/teamManagementRepo';
import { toast } from 'react-toastify';
const useReportidByUser = () => {
    const { id } = useParams()

    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [allReportingUserList, setAllReportingUserList] = useState([]);
    const [searchTickets, setSearchTickets] = useState("");
    const [slotId, setslotID] = useState(null)
    const [selectedMergeUser, setSelectedMergeUser] = useState([]);

    const [mergeModel, setMergeModel] = useState({
        open: false,
        ticketData: "",
    });
    const cacheTime = { cacheTime: 10000 };
    const [spinner, setSpinner] = useState(false);
    const [reportedUserData, setReportedUserData] = useState([])
    useEffect(() => {
        userDetails()
    }, [])

    useEffect(() => {
        getAllReportingUserList()
    }, [])

    const userDetails = async () => {
        try {
            const response = await getUserProfile(id)
            setReportedUserData(response.data)
        } catch (error) {

        }
    }


    // Get All Reporting User  List
    const getAllReportingUserList = async () => {
        let payload = {
            reporting_user: true,
            // role_id: "64e5871184ff5978bc1f8b92"
        };
        try {
            setSpinner(true);
            let response = await userFullList(payload);
            setAllReportingUserList(response.data);
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false);
    };

    // Listing payLoad
    let payload = {
        reportedBy_id: id,
        count: controller.rowsPerPage,
        page: controller.page,
    };
    // if (typeof statusType == "boolean") payload.isActive = statusType;

    // Fetch api
    const {
        data: ticketList,
        error: ticketError,
        isFetching: ticketIsFetching,
        refetch: refetchTicket,
    } = useToGetTicketList(payload, {
        enabled: false,
        ...cacheTime,
    });


    useEffect(() => {
        refetchTicket();
    }, [controller]);

    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj);
    };

    // User Merge
    const UserMerge = async () => {
        let payload = {
            user_id: id,
            mergeTo_id: selectedMergeUser,
        };
        try {
            let response = await MergeReportingUser(payload);
            toast.success(response?.message)
            setMergeModel((prev) => ({ ...prev, open: false }));
        } catch (error) {
            toast.error(error.message);
        }
    };

    // handle Reporting User
    const handleCheckSlots = (isCheckedValue, item, index) => {
        if (isCheckedValue) {
            setSelectedMergeUser(item._id);
            setslotID(index)
        } else {
            setSelectedMergeUser(null);
            setslotID(null)
        }
    };

    return ({

        data: {
            controller,
            isLoading: spinner || ticketIsFetching,
            totalCount: ticketError?.message === "No record found" ? 0 : ticketList?.totalCount || 0,
            ticketList: ticketError?.message === "No record found" ? [] : ticketList?.data || [],
            reportedUserData,
            mergeModel,
            allReportingUserList: allReportingUserList.filter(item => item._id !== id),
            searchTickets,
            slotId,
            selectedMergeUser
        },
        methods: {
            // handleInputChange,
            handlePagination,
            // resetFilter,
            // applayFilter,
            // setTicketModal,
            // status,
            // handleDelete,
            // setDeleteModal,
            setController,
            setMergeModel,
            handleCheckSlots,
            setSearchTickets,
            UserMerge,
            refetchTicket,
            setSpinner,
        },
    }

    )
}

export default useReportidByUser
import React from 'react'
import { Form, Button } from 'react-bootstrap'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Table from '../../../components/Table/Table'

const PhoneList = () => {
  const navigate = useNavigate()
  const TableHeadd = [
    { label: 'Name', id: '' },
    { label: 'Email', id: '' },
    { label: 'Group', id: '' },
    { label: 'Support', id: '' },
    { label: 'Action', id: '' },
  ]
  const TableRoww = [
    {
      name: 'Sachin',
      email: ' sachin@gmail.com',
      group: 'Billing',
      support: 'Phone',
    },
  ]
  return (
    <>
      <div className="row my-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div>
              <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
            </div>
            <div>
              <Button variant="primary4" className="px-4 ms-3">
                <SearchIcon />
              </Button>
            </div>
          </div>
          <div className="d-flex me-5 pe-1">
            {/* <div>
                <Button variant="primary1">
                  <ExitToAppIcon /> Export
                </Button>{" "}
              </div> */}
            <div className="ms-2">
              <Button className="dropDownButtons11 ms-4" onClick={() => navigate(`/setting/channel/phone-form`)}>
                <AddIcon /> Add New
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-lg-12 ticet">
          <div className="bg shadow raduus pt-2">
            <div className="">
              <div className="">
                <table className="table">
                  <Table TableHeadData={TableHeadd} />
                  <tbody>
                    {TableRoww?.map((item) => {
                      return (
                        <tr
                          className="text-start rounded-row "
                          style={{
                            borderRadius: '50px',
                            backgroundColor: '#fff',
                          }}
                        >
                          <td className="">{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.group}</td>
                          <td>{item.support}</td>
                          <td>
                            <div className="dropdown p-0  align-items-center justify-content-center">
                              <i className="fa-solid fa-ellipsis-vertical px-3" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}></i>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li style={{ cursor: 'pointer' }} onClick={() => navigate(`/setting/channel/phone-form`, { state: { teamId: item._id } })} className="dropdown-item">
                                  Edit{' '}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PhoneList

import React, { useState } from 'react'
import '../credentials/credentials.css'
import TextField from '../../components/TextField'
import Button from 'react-bootstrap/Button'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
// import Google from "../../assets/images/google.png"
// import Apple from "../../assets/images/apple.png"
// import Facebook from "../../assets/images/facebook.png"
import { Spinner } from '../../components/Spinner/Spinner'
import { loginUser } from '../../repository/auth'
import { requiredMessage } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { add } from '../../Slices/AuthSlice'
import useDisableBrowserBackButton from '../../hooks/useDisableBrowserBackButton'
const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authError = JSON.parse(sessionStorage.getItem("authError"));
  const [spinner, setSpinner] = useState(false)
  const [loginInputs, setLoginInputs] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState({})
  // const debounceDelay = 500 // Milliseconds
  useDisableBrowserBackButton()

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setLoginInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    email: requiredMessage('email'),
    password: requiredMessage('password'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!loginInputs.email) {
      errorObj['email'] = errorMessage['email']
    }
    if (!loginInputs.password?.trim()?.length) {
      errorObj['password'] = errorMessage['password']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle submit data..!
  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        email: loginInputs.email,
        password: loginInputs.password,
      }
      let response = await loginUser(payload)
      sessionStorage.removeItem("authError")
      dispatch(add(response?.data))
      navigate('/')
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="pt-5">
        <div className="container mt-5 ">
          <div className="contact" style={{ height: '80vh' }}>
            <div className="col-lg-5 mx-auto bg2 shadow2 p-4">
              <div className="text-center">
              {authError && <h3 className='text-danger mb-4'><b>Your login session has timed out. Please log in again to continue.</b></h3>}
                <h4>
                  <b>Welcome!</b>
                </h4>
                <p className="login-font-waight">Enter your details to log into your account</p>
              </div>
              <div className="mt-4 row">
                <form action="" onSubmit={handleSubmit}>


                  <div className="col-12 mt-4">
                    <TextField
                      Lable="Enter Email"
                      Name="email"
                      variant="standard"
                      ClassName="form-field-design"
                      Require={true}
                      HandleInputChange={handleInputChange}
                      onBlur={handleBlur}
                      Value={loginInputs.email}
                      width={100}
                      Error={error.email}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <TextField
                      Lable="Password"
                      Name="password"
                      variant="standard"
                      ClassName="form-field-design"
                      HandleInputChange={handleInputChange}
                      onBlur={handleBlur}
                      Value={loginInputs.password}
                      Require={true}
                      width={100}
                      Error={error.password}
                      type={'password'}
                    />
                  </div>

                  <div className="text-end mt-5">
                    <span className="table-link loginfont" onClick={() => navigate('/forgotpassword')}>
                      Forgot your Password ?
                    </span>
                  </div>
                  <div className="mt-2">
                    <Button variant="primary1" className="py-2 w-100" type='submit'>
                      Log In
                    </Button>
                  </div>
                </form>
              </div>
              {/* <div className="text-center my-4">
              <p className="fw-1 text-muted">---- Or, Sign in With ----</p>
              </div>
              <div className="">
                  <div className="text-center">
                    <p className="loginfont google"><img src={Google} className="login-icon" alt="" /> &nbsp; Continue With Google</p>   
                  </div>
              </div>
              <div className="my-3">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Apple} className="login-icon" alt="" /> &nbsp; &nbsp; &nbsp; Continue With Apple</p>   
                  </div>
              </div>
              <div className="">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Facebook} className="login-icon" alt="" /> Continue With Facebook</p>   
                  </div>
              </div>
              <p className="loginfont text-center mt-4 pt-2">Don't have an account? <span className="table-link">Register Now</span></p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login

import React, { useRef, useState } from 'react'
import useUploadAttendance from './Hooks/useUploadAttendance'
import DateInput from '../../components/DateInput'
import { Button, Fab } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import ClearIcon from '@mui/icons-material/Clear'

const UploadAttendance = () => {

  const { data, methods } = useUploadAttendance()
  const { uploadAttendanceInput, isLoading, selectedFiles, error } = data
  const { setuploadAttendanceInput, handleTimeLogFileChange, AttendanceUpload, handleLogRemoveFile, fileType, setError } = methods

  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0]; 
  
      if (file.type === 'application/vnd.ms-excel' || file.name.endsWith('.xlsx')) {
        handleTimeLogFileChange(files);
      } else {
        setError({ selectedFiles: 'Please select a valid XLS file.' });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="contact">
        <div className="container bg  p-5">
          {/* <div className="col-lg-8 mx-auto"></div> */}
          <div className='row'>
            <div className="col-lg-4 mb-4">
              <DateInput
                size="small" // You can customize the size here
                variant="outlined" // You can customize the variant here
                className="w-100 form-field-design" // You can customize the width here
                // isRequired={true}
                Label={'Date'}
                SelectedDate={uploadAttendanceInput?.startDate || null}
                handleDateChange={(date) => {
                  setuploadAttendanceInput((pre) => ({ ...pre, startDate: date }))
                  setError(prev => ({ ...prev, startDate: "" }))
                }}
              />
              {error?.startDate && <span className='text-danger'>{error?.startDate}</span>}
            </div>

            {!selectedFiles.length ?
              //  <div className='col-6'>
              //     <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
              //       <h5 className="">Attachments</h5>
              //       <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
              //       <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
              //     </div>
              //   </div>
              <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: `2px dashed ${isDragging ? 'blue' : '#ccc'}`,
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <p>{isDragging ? 'Release to drop' : 'Drag and drop a file here, or click to select a file.'}</p>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleTimeLogFileChange(e.target.files)}
                  style={{ display: 'none' }}
                  accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <Button type="button" onClick={() => fileInputRef.current.click()}>
                  Select File
                </Button>
                {error?.selectedFiles && <div className='text-danger'>{error?.selectedFiles}</div>}
              </div>

              // <div className="col-9 bodrerer" style={{ border: `${!selectedFiles.length ? "2px dashed #aaaaaa" : "none"}` }}>
              //   <label htmlFor="inputTag">
              //     <AiOutlineCloudUpload className="fs-4" />
              //     <input id="inputTag" type="file" name="file" onChange={handleTimeLogFileChange} />
              //     <br />
              //     Drag & Drop to upload
              //     <br />
              //   </label>
              //   {error?.selectedFiles && <div className='text-danger'>{error?.selectedFiles}</div>}
              // </div>
              :
              <div className='d-flex justify-content-center p-5'  style={{
                border: `2px dashed ${isDragging ? 'blue' : '#ccc'}`,
              }}>
                {selectedFiles.map((file, fileIndex) => (
                 <div  key={fileIndex}>
                 <div className='d-flex justify-content-center'>
                   <span style={{ width: '80px', height: '80px' }} className="position-relative d-block">
                   {fileType(file, fileIndex, "TIMELOG")}
                    <Fab
                      className="m-2"
                      sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                      onClick={() => {handleLogRemoveFile(fileIndex)
                        setError(prev => ({ ...prev, selectedFiles: "" }))
                      }}
                    >
                      <ClearIcon sx={{ fontSize: '15px' }} />
                    </Fab>
                   </span>
                  </div>
                    <div >{file.split("_").pop()}</div>
                 </div>
                ))}
              </div>}
            <div className='d-flex justify-content-end mt-4'>
              <Button variant="contained" onClick={AttendanceUpload}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadAttendance
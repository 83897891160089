import React from 'react'
import { Button, InputLabel, Autocomplete, TextField, FormControlLabel, FormGroup } from '@mui/material'
import useTamManagementAddUpdate from './Hooks/useTamManagementAddUpdate'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import TextInput from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import BasicDetails from './Details/basicDetails'
import DateInput from '../../components/DateInput'
import JobDetails from './Details/jobDetails'
import PersonalDetails from './Details/personalDetails'
import OtherDetails from './Details/otherDetails'
import SalaryDetails from './Details/salaryDetails'

const steps = ['Basic Information', 'Job Details', 'Personal Details', 'Other Details', 'Permissions'];
// const steps = ['Basic Information', 'Job Details', 'Personal Details', 'Other Details', 'Finance Details', 'Permissions'];
const NewAgentform = () => {

  const userAlreadyFoundArr = [
    { value: "CREATE", title: "Create New Account", isChecked: false },
    { value: "RECOVER", title: "Recover Old Account", isChecked: false }
  ]

  const { data, methods } = useTamManagementAddUpdate()
  const { spinner, error, teamMemberInputs, state, allRolesList, allTeamList, allSkillList, allShiftList, timeZonelist, userAlreadyFound, slotId, TeamPermission, permissions,
    activeStep, skipped
  } = data

  const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, navigate, setTeamMemberInputs, handleCheckSlots, handlePermissionChange, setActiveStep,
    setSkipped, isStepOptional, isStepSkipped, handleNext, handleBack } = methods

  const { add, edit, } = TeamPermission?.userInfo?.permission?.team

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  // console.log(allShiftList,'allShiftList')
  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row mb-5">
              <div className="text-center pt-3">
                <h4>{state?.teamMemberId ? 'Edit Team Member' : 'Add Team Member'}</h4>
              </div>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {
  
                  <React.Fragment>
                    {
                      activeStep === 0 &&
                      <BasicDetails data={data} methods={methods} />
                    }
                    {
                      activeStep === 1 &&
                      <JobDetails data={data} methods={methods} />
                    }
                    {
                      activeStep === 2 &&
                      <PersonalDetails data={data} methods={methods} />
                    }
                    {
                      activeStep === 3 &&
                      <OtherDetails data={data} methods={methods} />
                    }
                    {/* {
                      activeStep === 4 &&
                      // 'Finance Details'
                      <SalaryDetails data={data} methods={methods} />
                    } */}
                    {
                      activeStep === 4 &&
                      <div className="col-lg-12 row mt-4">
                        {Object.keys(permissions)?.map((category) => {
                          return (
                            <span className={'col-3 mt-2'} key={category}>
                              <h5>{category === 'rolesAndPermission' ? 'Roles And Permission' : capitalizeFirstLetter(category)}</h5>
                              <FormGroup>
                                {Object.keys(permissions[category]).map((key) => (
                                  <>
                                    <FormControlLabel
                                      key={key}
                                      control={<Checkbox checked={permissions[category][key].value} onChange={() => handlePermissionChange(category, key)} />}
                                      label={permissions[category][key].title}
                                    />
                                  </>
                                ))}
                              </FormGroup>
                            </span>
                          )
                        }
                        )}
                      </div>
                    }

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className='mb-3'
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {
                        activeStep === steps.length - 1 ?
                          <Button onClick={() => { state?.teamMemberId ? handleupdateSubmit(activeStep) : handleAddSubmit(activeStep) }} className='mb-3'>{state?.teamMemberId ? 'Update' : 'Create'}</Button>
                          :
                          <Button onClick={() => { state?.teamMemberId ? handleupdateSubmit(activeStep) : handleAddSubmit(activeStep) }} className='mb-3'>Next</Button>
                      }
                    </Box>
                  </React.Fragment>

                }
              </Box>
              {/* <div className="row mt-4">
              <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="EMP ID"
                    Name="empId"
                    variant="outlined"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.empId}
                    width={100}
                    Error={error.empId}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="Biometric ID"
                    Name="biometricId"
                    variant="outlined"
                    ClassName="form-field-design"
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.biometricId}
                    width={100}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="First Name"
                    Name="firstName"
                    variant="outlined"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.firstName}
                    width={100}
                    Error={error.firstName}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="Last Name"
                    Name="lastName"
                    variant="outlined"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.lastName}
                    width={100}
                    Error={error.lastName}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="Email"
                    Name="email"
                    variant="outlined"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.email}
                    width={100}
                    Error={error.email}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <TextInput
                    Lable="Designation"
                    Name="designation"
                    variant="outlined"
                    ClassName="form-field-design"
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={teamMemberInputs.designation}
                    width={100}
                    Error={error.designation}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Roles'}
                    Options={allRolesList}
                    keyName={'roleTitle'}
                    className="form-field-design"
                    Name="role_id"
                    SelectValue={teamMemberInputs.role_id}
                    HandleChange={handleInputChange}
                    onBlur={handleBlur}
                    Error={error.role_id}
                    variant={'outlined'}
                    isRequired={true}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Team'}
                    width={'310px'}
                    Options={allTeamList}
                    className="form-field-design"
                    keyName={'name'}
                    Name="teams"
                    SelectValue={teamMemberInputs.teams}
                    HandleChange={handleInputChange}
                    onBlur={handleBlur}
                    Error={error.teams}
                    variant={'outlined'}
                    isRequired={true}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Skills'}
                    multiple={true}
                    width={'310px'}
                    Options={allSkillList}
                    keyName={'skillName'}
                    className="form-field-design"
                    Name="skills"
                    SelectValue={teamMemberInputs.skills}
                    HandleChange={handleInputChange}
                    onBlur={handleBlur}
                    Error={error.skills}
                    variant={'outlined'}
                    isRequired={true}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Shift'}
                    width={'310px'}
                    Options={allShiftList}
                    keyName={'name'}
                    className="form-field-design"
                    Name="shift"
                    SelectValue={teamMemberInputs.shift}
                    HandleChange={handleInputChange}
                    onBlur={handleBlur}
                    Error={error.shift}
                    variant={'outlined'}
                    isRequired={true}
                  />
                </div>

                <div className="col-lg-4 mt-4">
                  <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
                    Time zone<span className="text-danger"> *</span>
                  </InputLabel>
                  <Autocomplete
                    onChange={(e, newValue) => {
                      setTeamMemberInputs((prevProps) => ({ ...prevProps, timezone: newValue }))
                    }}
                    disableClearable={true}
                    value={teamMemberInputs.timezone || null}
                    size="small"
                    options={timeZonelist}
                    getOptionLabel={(option) => `${option.text}`}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    renderOption={(props, option) => {
                      return <li {...props}>{`${option?.text}`}</li>
                    }}
                  />
                  {error.timezone && <p className="text-danger">{error.timezone}</p>}
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Reporting Manager'}
                    width={'310px'}
                    Options={reportManagerData}
                    keyName={'firstName'}
                    className="form-field-design"
                    Name="reportingManager"
                    SelectValue={teamMemberInputs.reportingManager}
                    HandleChange={handleInputChange}
                    onBlur={handleBlur}
                    Error={error.reportingManager}
                    variant={'outlined'}
                  />
                  {teamMemberInputs?.reportingManager?.length ? <p className='float-end text-danger pointer mt-1' onClick={() => setTeamMemberInputs((pre) => ({ ...pre,reportingManager: null }))}>Remove Reporting Manager</p> : null}
                </div>
                <div className="col-lg-12 row mt-4">
                {Object.keys(permissions)?.map((category) => {
                  return (
                    <span className={'col-3 mt-2'} key={category}>
                      <h5>{category === 'rolesAndPermission' ? 'Roles And Permission' : capitalizeFirstLetter(category)}</h5>
                      <FormGroup>
                        {Object.keys(permissions[category]).map((key) => (
                          <>
                            <FormControlLabel
                              key={key}
                              control={<Checkbox checked={permissions[category][key].value} onChange={() => handlePermissionChange(category, key)} />}
                              label={permissions[category][key].title}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </span>
                  )
                }
                )}
              </div>
                {userAlreadyFound && <div className="mt-4">
                  <span className='text-primary'><b>User already found with same email</b></span>
                  <p className='mt-3'>Do you wish to: <span className='text-danger'>*</span></p>
                  <div>
                    {userAlreadyFoundArr.map((item, index) => (
                      <article
                        style={{ width: "175px", height: "38px" }}
                        key={index}
                        className={`${slotId === index ? 'slot_InActive' : ' slot_Active'} article_show_status d-flex justify-content-center align-items-center`}
                        id={item?.status !== 'Available' && 'Booked_slot'}
                      >
                        <label htmlFor={item.value}>{item.title}</label>
                        <input
                          type="checkbox"
                          id="feature1"
                          defaultChecked={item?.isChecked}
                          onChange={(e) => handleCheckSlots(e.target.checked, item, index)}
                        />
                      </article>
                    ))}
                  </div>
                </div>}
              </div>

              <div className="col-lg-12 my-2 pe-4">
                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    <Button variant="outlined" onClick={() => navigate('/team-management')}>
                      Cancel
                    </Button>
                  </div>
                  {state?.teamMemberId ? (
                    <div className="m-2">
                      {edit?.value && <Button variant="contained" onClick={handleupdateSubmit}>
                        Update
                      </Button>}
                    </div>
                  ) : (
                    <div className="m-2">
                      {add?.value && <Button variant="contained" onClick={handleAddSubmit}>
                        Create
                      </Button>}
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAgentform

import React from "react";
import { Button, Input } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { ToastContainer } from 'react-toastify'
import { Spinner } from "../../../components/Spinner/Spinner";
import useSkillAddUpdate from "./Hooks/useSkillAddUpdate";
import TextField from "../../../components/TextField"
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];


const SkillForm = () => {
  const { data, methods } = useSkillAddUpdate()
  const { spinner, error, state } = data
  const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, setSkillInputs, navigate } = methods

  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="row">
            <div className="row my-3">
              <div className="col-lg-4 mt-4">
                {/* <TextField
                  id="standard-multiline-flexible"
                  label="Skill Name"
                  // multiline
                  className="w-75"
                  maxRows={4}
                  variant="standard"
                  defaultValue=""
                /> */}
                <TextField
                  Lable="Skill Name"
                  Name="skillName"
                  variant="outlined"
                  className="col-6"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={setSkillInputs.skillName}
                  width={100}
                  Error={error.skillName}
                />
              </div>
            </div>
            <div className="pt-3">
              <h5>Conditions</h5>
              <div className="">
                <FormControlLabel
                  value="best"
                  control={<Radio />}
                  label="Match ANY of the below"
                />
              </div>
              <div className="">
                <FormControlLabel
                  value="worst"
                  control={<Radio />}
                  label="Match ALL of the below"
                />
              </div>
            </div>

            <div style={{ backgroundColor: "#f9f9f9" }}>
              <div className="row">
                <div className="col-lg-4 mt-4">
                  <Input
                    id="standard-select-currency"
                    select
                    label=""
                    defaultValue=""
                    className="w-75"
                    variant="standard"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Input>
                </div>
                <div className="col-lg-4 mt-4">
                  <Input
                    id="standard-select-currency"
                    select
                    label=""
                    defaultValue=""
                    className="w-75"
                    variant="standard"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Input>
                </div>
                <div className="col-lg-4 mt-4">
                  <Input
                    id="standard-select-currency"
                    select
                    label=""
                    defaultValue=""
                    className="w-75"
                    variant="standard"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Input>
                </div>
              </div>
              <div className="col-lg-11 mt-4">
                <Input
                  id="standard-select-currency"
                  select
                  label=""
                  defaultValue=""
                  className="w-100"
                  variant="standard"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Input>
              </div>
              <div className="col-lg-11 mb-4 text-end">
                <Button className="px-2 add-cc-btn mt-3">+</Button>
              </div>
            </div>

            <div className="col-lg-11 mb-5">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className="mt-4">
                    {/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-5 mt-4 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                    </div>
                    <div className="col-lg-7 mt-4 text-center">
                      <Button className="px-4 add-cc-btn mt-3">
                        Create skill
                      </Button>
                    </div>
                  </div> */}
                  <div className=" d-flex justify-content-end ">
                    {state?.skillId ? <div className="m-2">
                      <Button variant="contained" onClick={handleupdateSubmit}>Update</Button>
                    </div>
                      :
                      <div className="m-2">
                        <Button variant="contained" onClick={handleAddSubmit}>Create</Button>
                      </div>
                    }
                    <div className="m-2">
                      <Button variant="contained" onClick={() => navigate("/team")}>Cancel</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkillForm
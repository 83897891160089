import React from "react";
import { Button } from "@mui/material";
import { Spinner } from "../../../components/Spinner/Spinner";
import TextField from "../../../components/TextField"


const CreateSkill = ({ data, methods }) => {

  const { spinner, error, skillInputs, skillFormModel } = data
  const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, setSkillInputs,  setSkillFormModel } = methods

  return (
    <>
      {spinner && <Spinner />}
      <TextField
        Lable="Skill Name"
        Name="skillName"
        variant="outlined"
        className="col-6"
        Require={true}
        HandleInputChange={handleInputChange}
        onBlur={handleBlur}
        Value={skillInputs.skillName}
        width={100}
        Error={error.skillName}
      />
      <div className="col-lg-11 mb-5">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="mt-4">
              {/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}
            </div>
            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button variant="outlined" onClick={() => { setSkillFormModel({ data: "", open: false }); setSkillInputs({ skillName: "" }) }}>Cancel</Button>
              </div>
              {skillFormModel?.data ? <div className="m-2">
                <Button variant="contained" onClick={handleupdateSubmit}>Update</Button>
              </div>
                :
                <div className="m-2">
                  <Button variant="contained" onClick={handleAddSubmit}>Create</Button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSkill;

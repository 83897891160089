import { axiosInstance, baseUrl } from './auth'
import { useQuery } from 'react-query'
import { ApiStatus } from '../constants'
import { environment } from '../env/environment'

export const uploadFileUrlTicket = `${environment?.baseUrl}/upload/tickets/`

export const getTicketList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/ticket/list`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
export function useToGetTicketList(params, options = {}) {
  return useQuery(['GET_Ticket_LIST'], () => getTicketList(params), options)
}

// All Tickets List
export const AllTicketList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/ticket/fullList`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
// Add Ticket
export const addTicket = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//  Get Ticket Details
export const getTicketDetails = async (params) => {
  const response = await axiosInstance.get(`${baseUrl}/ticket/${params}`)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Update Ticket
export const updateTicket = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/ticket`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//  Ticket Toggle Status
export const TicketStatus = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/toggleStatus`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Delete ticket
export const deleteTicket = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/toggleDelete`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//Permanent Delete
export const PermanentDeleteTicket = async params => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/permanentDelete`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// Reply Add
export const ticketReplyAdd = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/reply`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Replay update
export const updateReplay = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/reply`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Note List
export const noteList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/note/list`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
export function useToGetNoteList(params, options = {}) {
  return useQuery(['GET_Note_LIST'], () => noteList(params), options)
}

// Add Note
export const addNote = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/note`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//  Get Note Details
export const getNoteDetails = async (params) => {
  const response = await axiosInstance.get(`${baseUrl}/note/${params}`)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Update Note
export const updateNote = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/note`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Ticket Merge
export const MergeTicket = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/mergeTicket`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Ticket BulkAssign
export const bulkAssignTicket = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/bulkAssign`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//Assign Ticket To MultipleAgents
export const assignTicketToMultipleAgents = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/assignTicketToMultipleAgents`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}
// Ticket Unassign Agent
export const unassignAgent = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/unassignAgent`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Ticket Time Line History
export const timeLineHistory = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/history`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

export function useToGetTimeLineHistoryList(params, options = {}) {
  return useQuery(['GET_TIMELINE_LIST'], () => timeLineHistory(params), options)
}


//Ticket Export
export const ExportTicketList = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/ticket/export`,params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

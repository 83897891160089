/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import moment from 'moment';
import { HolidayType, timeZonelist } from '../../../../constants';
import { requiredMessage, requiredSelect } from '../../../../utils';
import { shiftAdd, getShiftDetails, updateShift, } from '../../../../repository/shiftRepo';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
const useShiftAddUpdateHook = () => {
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();
    const { id } = useParams()
    const [spinner, setSpinner] = useState(false)
    const [renderYear, setRenderYear] = useState(false)
    const [shiftForm, setShiftForm] = useState({
        shiftName: "",
        description: "",
        year: id !== "add" ? null : currentYear,
        startTiming: null,
        endTiming: null,
        graceInAction: false,
        graceIn: 0,
        graceOutAction: false,
        graceOut: 0,
        timeZone: ""
    })
    const [shiftError, setShiftError] = useState({})
    const [dayDetailModal, setDayDetailModal] = useState({
        open: false,
        data: [],
        description: ""
    })

    const [offDaysObj, setOffDayObj] = useState({
        selectedOffDays: 0,
        offDay: false
    });
    const [restDaysObj, setRestDayObj] = useState({
        selectedRestDays: 0,
        restDay: false
    });

    const [CompDaysObj, setCompDayObj] = useState({
        selectedCompDays: 0,
        compDay: false
    });

    const [pubHoliDaysObj, setPubHoliDayObj] = useState({
        selectedPubHoliDays: 0,
        pubHoliDay: false
    });
    const [calendarData, setCalendarData] = useState([])
    const [breatModal, setBreakModal] = useState({
        data: "",
        open: false
    })
    let [breakHoursInput, setBreakHoursInput] = useState([
        // {
        // duration:0
        //     startTime: null,
        //     endTime: null,
        //     reason: ""
        // }
    ])
    const [errors, setErrors] = useState([
        {
            startTime: null,
            endTime: null,
            reason: "" 
        },
    ]);
    const [breakArray, setBreakArray] = useState([])
    // console.log(errors,'errors')
    // Create Calendar data Function
    const getMonthsInYear = (year) => {
        const monthsInYear = [];
        for (let month = 0; month < 12; month++) {
            const monthStart = moment({ year, month, day: 1 });
            const daysInMonth = monthStart.daysInMonth();
            const days = [];
            for (let day = 1; day <= daysInMonth; day++) {
                const currentDate = moment({ year, month, day });
                days.push({
                    date: day,
                    dayOfWeek: currentDate.format('ddd'),
                    offDay: false,
                    compensationDay: false,
                    publicHoliday: false,
                    restDay: false,
                });
            }
            const monthName = monthStart.format('MMMM');
            monthsInYear.push({ year: year, month: month + 1, days, monthName });
        }
        return monthsInYear;
    };

    const getDetails = async () => {
        try {
            setSpinner(true)
            let response = await getShiftDetails(id)
            response?.data?.calendarData?.forEach((item) => {
                item.days.forEach((innerItem) => {
                    setOffDayObj((prev) => ({ ...prev, selectedOffDays: prev.selectedOffDays + innerItem.offDay, }))
                    setRestDayObj((prev) => ({ ...prev, selectedRestDays: prev.selectedRestDays + innerItem.restDay, }))
                    setCompDayObj((prev) => ({ ...prev, selectedCompDays: prev.selectedCompDays + innerItem.compensationDay, }))
                    setPubHoliDayObj((prev) => ({ ...prev, selectedPubHoliDays: prev.selectedPubHoliDays + innerItem.publicHoliday, }))
                }
                )
            }
            )

            let { name, description, startTime, endTime, year, graceIn, graceOut, timezone, calendarData } = response.data
            const newTimeZone = timeZonelist?.filter((item) => item.timezone === timezone)
            const updatedBreaks = response?.data?.breaks
                .filter(item => item?.isDeleted === false)
                .map(item => ({
                    duration: item?.duration,
                    // startTime: new Date(item?.startTime),
                    // endTime: new Date(item?.endTime),
                    reason: item?.reason,
                    _id: item?._id
                }));
            setBreakHoursInput(updatedBreaks?.length ? updatedBreaks : []);
            setShiftForm({
                shiftName: name,
                description: description,
                year: year,
                startTiming: new Date(startTime),
                endTiming: new Date(endTime),
                graceInAction: graceIn ? true : false,
                graceIn: graceIn,
                graceOutAction: graceOut ? true : false,
                graceOut: graceOut,
                timeZone: newTimeZone
            })
            // setCalendarData(calendarData)
            createCalendar(calendarData)
        } catch (error) {

        }
        setSpinner(false)
    }
    const createCalendar = (shiftData) => {
        shiftData.forEach(monthData => {
            const daysData = monthData.days;
            let arrLength = 0;
            switch (daysData[0]?.dayOfWeek) {
                case 'Tue':
                    arrLength = 1;
                    break;
                case 'Wed':
                    arrLength = 2;
                    break;
                case 'Thu':
                    arrLength = 3;
                    break;
                case 'Fri':
                    arrLength = 4;
                    break;
                case 'Sat':
                    arrLength = 5;
                    break;
                case 'Sun':
                    arrLength = 6;
                    break;
                default:
                    break;
            }
    
            for (let index = 0; index < arrLength; index++) {
                daysData.unshift({ isDay: true });
            }
        });
        setCalendarData(shiftData);
    };
    

    // change Calendar baseed on year
    useEffect(() => {
        if (renderYear || id === "add") {

            // setCalendarData(getMonthsInYear(shiftForm.year))
            createCalendar(getMonthsInYear(shiftForm.year))
            setOffDayObj({
                selectedOffDays: 0,
                offDay: false
            })
            setRestDayObj({
                selectedRestDays: 0,
                restDay: false
            })
            setCompDayObj({
                selectedCompDays: 0,
                compDay: false
            })
            setPubHoliDayObj({
                selectedPubHoliDays: 0,
                pubHoliDay: false
            })
        }

    }, [shiftForm.year])

    // get Details
    useEffect(() => {
        if (id !== "add") {
            // console.log("count")
            getDetails()
        }
    }, [])
    // Handle Form Input's
    const handleShiftInputChange = (event) => {
        const { value, name, checked } = event.target;

        setShiftForm((prev) => {
            const updatedShiftForm = { ...prev };
            updatedShiftForm[name] = value;

            if (name === 'graceInAction') {
                updatedShiftForm[name] = checked;
                if (checked) {
                    updatedShiftForm['graceIn'] = 10;
                } else {
                    updatedShiftForm['graceIn'] = 0;
                }
            }
            if (name === 'graceOutAction') {
                updatedShiftForm[name] = checked;
                if (checked) {
                    updatedShiftForm['graceOut'] = 10;
                } else {
                    updatedShiftForm['graceOut'] = 0;
                }
            }
            // Clear errors for the current field
            setShiftError((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name !== "graceIn") {
                    updatedErrors[name] = null;
                }
                if (name !== "graceOut") {
                    updatedErrors[name] = null;
                }
                return updatedErrors;
            });

            return updatedShiftForm;
        });
    };

    // Handle Date
    const handleDateChange = (date, field) => {
        setShiftForm((prev) => {
            const updatedShiftForm = { ...prev }
            updatedShiftForm[field] = date
            return updatedShiftForm
        })
        setShiftError((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[field] = null;
            return updatedErrors;
        });
    }

    // console.log(shiftForm, "shiftForm")

    // Error Message
    const errorMessage = {
        shiftName: requiredMessage('shift name'),
        description: requiredMessage('description'),
        year: requiredSelect('year'),
        startTiming: requiredMessage('start timing'),
        endTiming: requiredMessage('end timing'),
        timeZone: requiredSelect('time zone'),
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!shiftForm.shiftName) {
            errorObj['shiftName'] = errorMessage['shiftName']
        }
        if (!shiftForm.description) {
            errorObj['description'] = errorMessage['description']
        }
        if (!shiftForm.year) {
            errorObj['year'] = errorMessage['year']
        }
        if (!shiftForm.startTiming) {
            errorObj['startTiming'] = errorMessage['startTiming']
        }
        if (!shiftForm.endTiming) {
            errorObj['endTiming'] = errorMessage['endTiming']
        }
        if (!shiftForm.timeZone) {
            errorObj['timeZone'] = errorMessage['timeZone']
        }
        setShiftError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const handleBreakInput = (event, taskIndex) => {
        const { value, name } = event.target;

        setBreakHoursInput((prev) => {
            const updatedBreak = [...prev];
            updatedBreak[taskIndex][name] = value;

            // Clear errors for the current field
            setErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                // if (name != "ReminderAction") {
                //   updatedErrors[taskIndex][name] = null;
                // }
                return updatedErrors;
            });

            return updatedBreak;
        });
    };
    // Handle Break time
    const handleBrakeTime = (date, field, breakIndex) => {
        setBreakHoursInput((prev) => {
            const updatedTasks = [...prev]
            updatedTasks[breakIndex][field] = date
            return updatedTasks
        })


    }
    const addMultipleBreak = () => {
        if (breakHoursInput.length < 10) {
            setBreakHoursInput((prev) => [
                ...prev,
                {
                    duration: "",
                    reason: ""
                },
            ])
            // Clear errors for the newly added task
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    duration: "",
                    reason: ""
                },
            ]);
        }
    }

    // Remove Brake time
    // const removeBreak = async (breakIndex,) => {

    //     setBreakHoursInput((prev) => {
    //         const updatedBreaks = [...prev]
    //         updatedBreaks.splice(breakIndex, 1)
    //         return updatedBreaks
    //     })
    // }
    const removeBreak = async (breakIndex, breakId) => {
        setBreakHoursInput((prev) => {
            const updatedBreaks = [...prev]
            updatedBreaks.splice(breakIndex, 1)
            return updatedBreaks
        })
        setBreakArray((pre) => [...pre, breakId])

    }
    // Close modal And Empty
    const CloseFucntion = (type) => {
        if (type === 'breatModal') {
            setBreakHoursInput([
                // {
                //   startTime: null,
                //   endTime: null,
                //   reason: ""
                // },
            ])
        }
    }

    // Error Message
    const errorBreakMessage = {
        duration: requiredSelect('break duration'),
        // endTime: requiredSelect("break end"),
        reason: requiredMessage("description"),
    }
    const validateBreaks = () => {
        const errorObjArray = [];

        for (let i = 0; i < breakHoursInput.length; i++) {
            const breaks = breakHoursInput[i];
            const errorObj = {};

            if (!breaks.duration) {
                errorObj['duration'] = errorBreakMessage['duration'];
            }
            if (!breaks.reason) {
                errorObj['reason'] = errorBreakMessage['reason'];
            }
            // Add checks for other fields as needed
            errorObjArray.push(errorObj);
        }
        setErrors(errorObjArray);
        return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
    };
    // add Shift 
    const submit = async () => {
        if (!isValid()) return
        if (breakHoursInput?.length > 0) {
            if (!validateBreaks()) return
        }
        setSpinner(true)
        let payload = {
            calendarData: filteredCalendarData,
            name: shiftForm.shiftName,
            description: shiftForm.description,
            year: shiftForm.year,
            startTime: shiftForm.startTiming,
            endTime: shiftForm.endTiming,
            graceIn: shiftForm.graceIn,
            graceOut: shiftForm.graceOut,
            timezone: shiftForm.timeZone[0].timezone,
        }
        // console.log(payload,'payload')
        // return
        payload.breaks = breakHoursInput
        try {
            let response = await shiftAdd(payload)
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
            navigate('/setting/shifts')
        } catch (error) {

        }
        setSpinner(false)
    }
    // console.log(calendarData, 'calendarData')

    // Update Shift 
    const Update = async () => {
        if (!isValid()) return
        if (breakHoursInput?.length > 0) {
            if (!validateBreaks()) return
        }
        setSpinner(true)
        let payload = {
            _id: id,
            calendarData: filteredCalendarData,
            name: shiftForm.shiftName,
            description: shiftForm.description,
            year: shiftForm.year,
            startTime: shiftForm.startTiming,
            endTime: shiftForm.endTiming,
            graceIn: shiftForm.graceIn,
            graceOut: shiftForm.graceOut,
            timezone: shiftForm.timeZone[0].timezone,
            removeBreaks: breakArray
        }
        payload.breaks = breakHoursInput
        // console.log(payload,'payload')
        // return
        try {
            let response = await updateShift(payload)
            setTimeout(() => {
                toast.success(response.message)
            }, 500);
            navigate('/setting/shifts')
            setBreakArray([])
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    const handleOffDays = (type) => {
        setOffDayObj((prev) => ({ ...prev, offDay: type === HolidayType.OffDays }));
        setCompDayObj((prev) => ({ ...prev, compDay: type === HolidayType.Compensatoryoff }));
        setPubHoliDayObj((prev) => ({ ...prev, pubHoliDay: type === HolidayType.PublicHolidays }));
        setRestDayObj((prev) => ({ ...prev, restDay: type === HolidayType.RestDays }))
    };

    // Handle Checked Day's
    const handleCheckDays = (event, index, innerIndex) => {
        setCalendarData((prev) => {
            const updatedDays = [...prev];
            // set Off Day's
            if (offDaysObj.offDay) {
                if (updatedDays[index].days[innerIndex].compensationDay || updatedDays[index].days[innerIndex].publicHoliday || updatedDays[index].days[innerIndex].restDay)
                    return updatedDays
                const isChecked = event.target.checked;
                updatedDays[index].days[innerIndex] = {
                    ...updatedDays[index].days[innerIndex],
                    offDay: isChecked,
                };
                setOffDayObj((prev) => ({ ...prev, selectedOffDays: prev.selectedOffDays + (isChecked ? 1 : -1) }));
            }
            // set Rest Day's
            if (restDaysObj.restDay) {
                if (updatedDays[index].days[innerIndex].compensationDay || updatedDays[index].days[innerIndex].publicHoliday || updatedDays[index].days[innerIndex].offDay)
                    return updatedDays
                const isChecked = event.target.checked;
                updatedDays[index].days[innerIndex] = {
                    ...updatedDays[index].days[innerIndex],
                    restDay: isChecked,
                };
                setRestDayObj((prev) => ({ ...prev, selectedRestDays: prev.selectedRestDays + (isChecked ? 1 : -1) }));
            }
            // set Compensation Day 
            if (CompDaysObj.compDay) {
                if (updatedDays[index].days[innerIndex].restDay || updatedDays[index].days[innerIndex].publicHoliday || updatedDays[index].days[innerIndex].offDay)
                    return updatedDays
                const isChecked = event.target.checked;
                if (updatedDays[index].days[innerIndex].compensationDay === true) {

                    updatedDays[index].days[innerIndex] = {
                        ...updatedDays[index].days[innerIndex],
                        compensationDay: isChecked,
                        details: []
                    };
                    setCompDayObj((prev) => {
                        // console.log(prev, "pprev")
                        return ({
                            ...prev,
                            selectedCompDays: Math.max(0, prev.selectedCompDays - 1),
                        })
                    });
                }


                // updatedDays[index].days[innerIndex] = {
                //     ...updatedDays[index].days[innerIndex],
                //     compensationDay: isChecked,
                // };
                // setCompDayObj((prev) => ({ ...prev, selectedCompDays: prev.selectedCompDays + (isChecked ? 1 : -1) }));
            }
            // set Public HoliDays
            if (pubHoliDaysObj.pubHoliDay) {
                if (updatedDays[index].days[innerIndex].restDay || updatedDays[index].days[innerIndex].compensationDay || updatedDays[index].days[innerIndex].offDay)
                    return updatedDays
                const isChecked = event.target.checked;
                if (updatedDays[index].days[innerIndex].publicHoliday === true) {

                    updatedDays[index].days[innerIndex] = {
                        ...updatedDays[index].days[innerIndex],
                        publicHoliday: isChecked,
                        details: []
                    };
                    setPubHoliDayObj((prev) => {

                        // console.log(prev, "pprev")
                        return ({
                            ...prev,
                            selectedPubHoliDays: Math.max(0, prev.selectedPubHoliDays - 1),
                        })
                    });
                }
                // const isChecked = event.target.checked;
                // updatedDays[index].days[innerIndex] = {
                //     ...updatedDays[index].days[innerIndex],
                //     publicHoliday: isChecked,
                // };
                // setPubHoliDayObj((prev) => ({ ...prev, selectedPubHoliDays: prev.selectedPubHoliDays + (isChecked ? 1 : -1) }));
            }
            return updatedDays;
        });
    };

    // Handle Day Data From
    const handleDayDetails = async () => {
        // console.log("clicked")
        setCalendarData((prev) => {
            const { index, innerIndex, event, type } = dayDetailModal;
            const updatedDays = [...prev];
            // console.log(!dayDetailModal?.description, "dayDetailModal?.description")
            if (!dayDetailModal?.description) {
                toast.warn("Please enter description")
                return updatedDays
            }
            if (type === "PublicHoliDay") {
                const isChecked = event.target.checked;
                updatedDays[index].days[innerIndex] = {
                    ...updatedDays[index].days[innerIndex],
                    publicHoliday: isChecked,
                    details: [dayDetailModal?.description]
                };
                setPubHoliDayObj((prev) => ({
                    ...prev,
                    selectedPubHoliDays: Math.max(0, prev.selectedPubHoliDays + (isChecked ? 1 : -1)),
                }));
                setDayDetailModal({ data: [], open: false });
            }
            if (type === "CompensationDay") {
                const isChecked = event.target.checked;
                updatedDays[index].days[innerIndex] = {
                    ...updatedDays[index].days[innerIndex],
                    compensationDay: isChecked,
                    details: [dayDetailModal?.description]
                };
                setCompDayObj((prev) => ({
                    ...prev,
                    selectedCompDays: Math.max(0, prev.selectedCompDays + (isChecked ? 1 : -1)),
                }));
                setDayDetailModal({ data: [], open: false });
            }
            return updatedDays;
        });
    }

    // calendar Focus Function
    const focusHandle = () => {
        if (offDaysObj.offDay) {
            return "1px solid #f3963b"
        }
        if (restDaysObj.restDay) {
            return "1px solid #3fa7f3"
        }
        if (CompDaysObj.compDay) {
            return "1px solid #aa3b19"
        }
        if (pubHoliDaysObj.pubHoliDay) {
            return "1px solid #78c223"
        }
        return "1px solid #cdcdcd"
    }
    // calendar background Function
    const bgHandle = (item) => {

        if (item.compensationDay) {
            return "#aa3b19cc";
        } else if (item.restDay) {
            return "#70afdde0"
        }
        else if (item.offDay) {
            return "#f3963bdb"
        }
        else if (item.publicHoliday) {
            return "#78c223c4"
        }
        else {
            return ""
        }
    };
    // Your data
    // const data = [
    //     {
    //         "year": "2024",
    //         "month": "1",
    //         "monthName": "January",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "2",
    //         "monthName": "February",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "3",
    //         "monthName": "March",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "4",
    //         "monthName": "April",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": true,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": true,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "5",
    //         "monthName": "May",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "6",
    //         "monthName": "June",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "7",
    //         "monthName": "July",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "8",
    //         "monthName": "August",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "9",
    //         "monthName": "September",
    //         "days": [
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "10",
    //         "monthName": "October",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "11",
    //         "monthName": "November",
    //         "days": [
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "isDay": true
    //             },
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     },
    //     {
    //         "year": "2024",
    //         "month": "12",
    //         "monthName": "December",
    //         "days": [
    //             {
    //                 "date": 1,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 2,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 3,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 4,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 5,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 6,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 7,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 8,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 9,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 10,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 11,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 12,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 13,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 14,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 15,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 16,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 17,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 18,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 19,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 20,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 21,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 22,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 23,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 24,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 25,
    //                 "dayOfWeek": "Wed",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 26,
    //                 "dayOfWeek": "Thu",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 27,
    //                 "dayOfWeek": "Fri",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 28,
    //                 "dayOfWeek": "Sat",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 29,
    //                 "dayOfWeek": "Sun",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 30,
    //                 "dayOfWeek": "Mon",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             },
    //             {
    //                 "date": 31,
    //                 "dayOfWeek": "Tue",
    //                 "offDay": false,
    //                 "compensationDay": false,
    //                 "publicHoliday": false,
    //                 "restDay": false,
    //                 "details": []
    //             }
    //         ]
    //     }
    // ]

    const workingDays = calendarData.map(month => {
        let count = 0;
        month.days.forEach(day => {
            if (!(day.publicHoliday || day.restDay || day.offDay || day.compensationDay || day.isDay)) {
                count++;
            }
        });
        return { monthName: month.monthName, totalCount: count };
    });


    const monthWorking = (month) => {
        const filteredMonth = workingDays.find(item => item.monthName === month);
        if (filteredMonth) {
            return filteredMonth.totalCount;
        } else {
            return 0;
        }
    }
    const filteredCalendarData = calendarData.map(month => ({
        ...month,
        days: month.days.filter(day => !day.hasOwnProperty('isDay'))
    }));

    // console.log(filteredCalendarData,'filteredCalendarData');

    return {
        data: {
            spinner,
            shiftForm,
            shiftError,
            CompDaysObj,
            pubHoliDaysObj,
            restDaysObj,
            offDaysObj,
            calendarData,
            shiftId: id,
            breatModal,
            breakHoursInput,
            errors,
            dayDetailModal
        },
        methods: {
            setShiftForm,
            setShiftError,
            handleShiftInputChange,
            handleDateChange,
            handleCheckDays,
            focusHandle,
            bgHandle,
            handleOffDays,
            submit,
            Update,
            setBreakHoursInput,
            handleBrakeTime,
            removeBreak,
            addMultipleBreak,
            setErrors,
            handleBreakInput,
            setBreakModal,
            CloseFucntion,
            handleDayDetails,
            setDayDetailModal,
            setRenderYear,
            monthWorking
        }
    }
}

export default useShiftAddUpdateHook
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage, requiredSelect } from '../../../utils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { addTicket, updateTicket, getTicketDetails, addNote, ticketReplyAdd, deleteTicket, MergeTicket, AllTicketList, unassignAgent, useToGetNoteList, useToGetTimeLineHistoryList } from '../../../repository/ticketRepo'
import { useToGetUserFullList, userFullList } from '../../../repository/teamManagementRepo'
import { getAllTeamList } from '../../../repository/teamRepo'
import { allTagList, useToGetAllTagList } from '../../../repository/tagsRepo'
import { allProjectList, useToGetFullPriject } from '../../../repository/projectRepo'
import { DeleteFile, addTask, uploadFile } from '../../../repository/taskRepo'
import { taskTypeList, SubTaskList, TaskCategoryList, complexityList, hoursTypeList, workTypeList } from '../../../constants'
import useReduxStore from '../../../hooks/useReduxStore'

const useTicketAddUpdate = () => {
  const priorityList = [
    { title: 'Low', value: 'LOW' },
    { title: 'Medium', value: 'MEDIUM' },
    { title: 'High', value: 'HIGH' },
  ]

  const soucrsList = [
    { title: 'Email', value: 'EMAIL' },
    { title: 'Phone', value: 'PHONE' },
    { title: 'Chat', value: 'CHAT' },
    { title: 'Online', value: 'ONLINE' },
  ]
  const { id } = useParams()
  const { state } = useLocation()
  const cacheTime = { cacheTime: 10000 };

  const navigate = useNavigate()
  const { selector: user } = useReduxStore('auth')
  const [allReportingUserList, setAllReportingUserList] = useState([])
  const [allTagsList, setAllTagList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [agentList, setAgentList] = useState([])
  const [fullTicketList, setAllTicketList] = useState([])
  const [searchTickets, setSearchTickets] = useState('')
  const [selectedMergeTickets, setSelectedMergeTickets] = useState([])
  const [slotId, setslotID] = useState(null)

  const [spinner, setSpinner] = useState(false)
  const [agentFormopen, setAgentFormOpen] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState([])

  const [error, setError] = useState({})
  const [ticketsInputs, setTicketsInputs] = useState({
    ticketId: '',
    firstName: '',
    lastName: '',
    email: '',
    project_id: '',
    reportedBy: '',
    reportDate: null,
    source: '',
    priority: '',
    agent: '',
    team: '',
    tags: [],
    ticket: [],
    subject: '',
    description: '',
    notes: '',
    dueDate: null,
    status: '',
    spam: '',
    close: null,
    ticketFullDetails: {}
  })

  const [assignInputs, setAssignInputs] = useState([
    {
      taskName: "",
      project_id: "",
      priority: "",
      assignTo_id: "",
      description: "",
      tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      notes: "",
      ReminderAction: true,
      attachments: [],
      ticket_id: "",
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ])

  const [assignErrors, setAssignErrors] = useState([
    {
      taskName: null,
      project_id: null,
      priority: '',
      assignTo_id: '',
      description: '',
      // tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      // notes: '',
      ReminderAction: true,
      // attachments: [],
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ]);

  // Agent and group multiple during Add Ticket
  const [agentDetailsInputs, setAgentDetailsInputs] = useState([
    {
      group: '',
      agent: '',
    },
  ])

  // Edit Ticket State
  const [replyModel, setReplyModel] = useState({
    open: false,
    description: '',
  })
  const [noteModel, setNoteModel] = useState({
    open: false,
    description: '',
  })
  const [mergeModel, setMergeModel] = useState({
    open: false,
    ticketData: '',
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: '',
  })

  const [assignModel, setAssignModel] = useState({
    open: false,
    data: '',
  })
  const [unAssignModel, setUnAssignModel] = useState({
    open: false,
    data: '',
  })
  const [count,setCount]=useState(10)

  useEffect(() => {
    getAllReportingUserList()
    getAgentList()
    getAllTagList()
    getAllProjectList()
    getAllGroupList()
  }, [])

  useEffect(() => {
    if (id) {
      getDetails()
      getAllTicketList()
    }
  }, [])

  // Project List 
  const {
    data: projectListData,
    // error: projectError,
    isLoading: isprojectLoading,
    // refetch: refetchprojectList,
  } = useToGetFullPriject(cacheTime);
  // Tag List 
  const {
    data: tagListData,
    // error: tagError,
    isLoading: isTagLoading,
    // refetch: refetchtagList,
  } = useToGetAllTagList(cacheTime);
  // Assign TO List
  const {
    data: assignToListData,
    // error: assignToError,
    isLoading: isassignToLoading,
    // refetch: refetassignToList,
  } = useToGetUserFullList(cacheTime);

  // Get All Reporting User  List
  const getAllReportingUserList = async () => {
    let payload = {
      reporting_user: true,
      // role_id: "64e5871184ff5978bc1f8b92"
    }
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAllReportingUserList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Agent  List
  const getAgentList = async () => {
    let payload = {
      // reporting_user: true,
      // role_id: "64e5871184ff5978bc1f8b92"
    }
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Tag List
  const getAllTagList = async () => {
    try {
      setSpinner(true)
      let response = await allTagList()
      setAllTagList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Project List
  const getAllProjectList = async () => {
    try {
      setSpinner(true)
      let response = await allProjectList()
      setProjectList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Group List
  const getAllGroupList = async () => {
    try {
      setSpinner(true)
      let response = await getAllTeamList()
      setGroupList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Tickets List
  const getAllTicketList = async () => {
    try {
      setSpinner(true)
      let response = await AllTicketList()
      setAllTicketList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Time Line List
  const {
    data: timeLineListData,
    error: timeLineError,
    // isLoading: timeLineIsLoading,
    refetch: timeLineIsFetched
  } = useToGetTimeLineHistoryList({ type: "TICKET", type_id: id,page:1,count:count }, {
    ...cacheTime,
    enabled: id ? true : false,
  });
useEffect(()=>{
  if(count>10){
    timeLineIsFetched()
  }
},[count])
  const fetchMoreData = () => {setCount((prev) => prev + 10);};
  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTicketDetails(id)
      const { ticketId, source, priority, projectData, status, subject,reportedByData, description, notes, dueDate, reportDate, createdAt, replies, tags, closedOn, _id } = response?.data
      let newPriority = priorityList.filter(item => item.value === priority)
      let newTags = tagListData?.data.filter(tagItem => {
        return tags.includes(tagItem?._id);
      });
      const newAssignInputs = {
        taskName: subject,
        project_id: [projectData] || [],
        priority: newPriority,
        assignTo_id: '', // Add your logic for assignTo_id
        description: description,
        tags: newTags,
        assignStartTime: null,
        assignEndTime: null,
        minutesBeforeReminder: 15,
        notes: "",
        ReminderAction: true,
        attachments: [],
        ticket_id: _id,
      };
      setAssignInputs([newAssignInputs]);

      setTicketsInputs({
        ticketId: ticketId,
        firstName: reportedByData?.firstName,
        lastName: reportedByData?.lastName,
        email: reportedByData?.email,
        project_id: [projectData] || [],
        reportedBy: reportedByData?._id,
        reportDate: reportDate,
        source: source,
        priority: priority,
        // agent: agent,
        // team: team,
        tags: tags,
        subject: subject,
        description: description,
        notes: notes,
        dueDate: dueDate,
        status: status,
        createdAt: createdAt,
        replies: replies,
        close: closedOn || null,
        ticketFullDetails: response.data
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // handle new ticket Files
  const handleTicketFileChange = async (event) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TICKET");

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);

    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }

  // handle Remove File
  const handleTicketRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }

  const handleFileChange = async (event, taskIndex) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) return toast.warn("You can only select up to 5 files.")
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TASK");
    try {
      setSpinner(true)
      let response = await uploadFile(fd)
      toast.success(response.message)
      setAssignInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        if (updatedTasks[taskIndex].attachments.length >= 5) {
          toast.warn('You can only select up to 5 files.');
        } else {
          const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
          response?.data?.forEach((cur, index) => {
            if (newSelectedFiles.length >= 5) {
              toast.warn('You can only select up to 5 files.');
            } else {
              newSelectedFiles?.push(cur?.db_name);
            }
          });
          if (newSelectedFiles.length <= 5) {
            updatedTasks[taskIndex].attachments = newSelectedFiles;
          }
        }
        return updatedTasks;
        // const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
        // response?.data?.forEach((cur, index) => {
        //   newSelectedFiles?.push(cur?.db_name)
        // })
        // updatedTasks[taskIndex].attachments = newSelectedFiles;
        // return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  };

  // Handle Remove File
  const handleRemoveFile = async (taskIndex, fileIndex, file) => {
    let data = {
      fileName: file,
      fileType: "TASK"
    }

    try {
      setSpinner(true)
      let response = await DeleteFile(data)
      toast.success(response.message)
      setAssignInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        updatedTasks[taskIndex].attachments = updatedTasks[taskIndex].attachments.filter(
          (_, i) => i !== fileIndex
        );
        return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)

  };

  const {
    data: noteListData,
    // error: patientError,
    // isLoading: patientIsLoading,
    refetch: noteIsFetched
  } = useToGetNoteList({ type: "TICKET", ticket_id: id }, {
    ...cacheTime,
    enabled: id ? true : false,
  });

  // const {
  //   data: noteListData,
  //   error: noteError,
  //   isLoading: noteLoading,
  //   refetch: noteList,
  // } = useToGetNoteList({ type: "TICKET", ticket_id: id }, cacheTime);


  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target

    if (agentFormopen === false) {
      setTicketsInputs((prevProps) => ({
        ...prevProps,
        [name]: value,
        reportedBy: '',
      }))
    } else {
      setTicketsInputs((prevProps) => ({
        ...prevProps,
        [name]: value,
        firstName: '',
        lastName: '',
        email: '',
      }))
    }

    // value?.trim().length && setError(pre => {
    //     delete pre[name]
    //     return ({ ...pre })
    // })

    if (typeof value === 'string' && value.trim().length > 0) {
      setError((pre) => {
        if (agentFormopen === false) {
          if (name === 'reportedBy') {
            delete pre.firstName
            delete pre.lastName
            delete pre.email
          }
        } else {
          if (name === 'firstName' || name === 'lastName' || name === 'email') {
            delete pre.reportedBy
          }
        }
        delete pre[name]
        return { ...pre }
      })
    }
  }

  // const handleBlur = (event) => {
  //     const { name, value } = event.target

  //     if (typeof value === 'string' && !value.trim().length) {
  //         setError(pre => ({ ...pre, [name]: errorMessage[name] }));
  //     }
  // }

  const handleBlur = (event) => {
    const { name, value } = event.target

    if (typeof value === 'string' && !value.trim().length) {
      if (agentFormopen === false) {
        setError((pre) => {
          if (name === 'reportedBy') {
            return { ...pre, [name]: errorMessage[name] }
          }
          return { ...pre, [name]: errorMessage[name], reportedBy: '' }
        })
      } else {
        setError((pre) => {
          if (name === 'firstName' || name === 'lastName' || name === 'email') {
            return { ...pre, [name]: errorMessage[name] }
          }
          return {
            ...pre,
            [name]: errorMessage[name],
            firstName: '',
            lastName: '',
            email: '',
          }
        })
      }
    }
  }

  // -***--------------------***-
  // Error Messages
  const errorMessage = {
    // ticketId: requiredMessage('Ticket ID'),
    project_id: requiredMessage('Project'),
    reportedBy: requiredMessage('Reported By ID'),
    firstName: requiredMessage('First Name'),
    lastName: requiredMessage('Last Name'),
    email: requiredMessage('Email'),
    reportDate: requiredMessage('Report Date'),
    source: requiredSelect('Source'),
    // priority: requiredSelect("Priority"),
    // tags: requiredSelect("Tags"),
    dueDate: requiredMessage("Due Date"),
    // team: requiredSelect("Team"),
    // agent: requiredMessage("Agent"),
    subject: requiredMessage('Subject'),
    description: requiredMessage('Description'),
    // notes: requiredMessage("Notes"),
  }
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // Error handling function
  const isValid = () => {
    const errorObj = {}

    // Check individual fields

    // if (!ticketsInputs.ticketId) {
    //   errorObj['ticketId'] = errorMessage['ticketId']
    // }
    if (!ticketsInputs.project_id) {
      errorObj['project_id'] = errorMessage['project_id']
    }
    if (agentFormopen === false) {
      if (!ticketsInputs.firstName) {
        errorObj['firstName'] = errorMessage['firstName']
      }
      if (!ticketsInputs.lastName) {
        errorObj['lastName'] = errorMessage['lastName']
      }
      if (!ticketsInputs.email) {
        errorObj['email'] = errorMessage['email']
      } else if (!isValidEmail(ticketsInputs.email)) {
        errorObj['email'] = 'Invalid email format';
      }
    }
    if (!ticketsInputs.reportDate) {
      errorObj['reportDate'] = errorMessage['reportDate']
    }
    if (!ticketsInputs.source) {
      errorObj['source'] = errorMessage['source']
    }
    // if (!ticketsInputs.priority) {
    //   errorObj["priority"] = errorMessage["priority"];
    // }
    // if (!ticketsInputs.agent) {
    //   errorObj["agent"] = errorMessage["agent"];
    // }
    // if (!ticketsInputs.team) {
    //   errorObj["team"] = errorMessage["team"];
    // }
    // if (ticketsInputs.tags.length === 0) {
    //   errorObj["tags"] = errorMessage["tags"];
    // }
    if (!ticketsInputs.dueDate) {
      errorObj["dueDate"] = errorMessage["dueDate"];
    }
    if (!ticketsInputs.subject) {
      errorObj['subject'] = errorMessage['subject']
    }
    if (!ticketsInputs.description) {
      errorObj['description'] = errorMessage['description']
    }
    // if (!ticketsInputs.notes) {
    //   errorObj["notes"] = errorMessage["notes"];
    // }

    setError(errorObj)
    return Object.keys(errorObj).length === 0
  }



  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)

    const formData = new FormData()

    // formData.append('ticketId', ticketsInputs.ticketId)
    formData.append('project_id', ticketsInputs.project_id[0]?._id)
    formData.append('reportDate', ticketsInputs.reportDate)
    formData.append('source', ticketsInputs.source)
    formData.append('priority', ticketsInputs.priority)
    if (agentDetailsInputs.length > 0) {
      formData.append(
        'agentData',
        JSON.stringify(
          agentDetailsInputs.map((agentDetail) => ({
            agent_id: agentDetail.agent,
            team_id: agentDetail.group,
          }))
        )
      )
    } else {
      formData.append('agentData', JSON.stringify([]))
    }
    // ticketsInputs.agent && formData.append('agent', JSON.stringify(ticketsInputs.agent))
    // ticketsInputs.team && formData.append('team', ticketsInputs.team)
    formData.append('tags', JSON.stringify(ticketsInputs.tags.map(item => item._id)))
    formData.append('subject', ticketsInputs.subject)
    formData.append('description', ticketsInputs.description)
    formData.append('notes', ticketsInputs.notes)
    formData.append('dueDate', ticketsInputs.dueDate)
    formData.append(
      'reportedBy',
      JSON.stringify({
        id: ticketsInputs.reportedBy[0]?._id,
        firstName: ticketsInputs.firstName,
        lastName: ticketsInputs.lastName,
        email: ticketsInputs.email,
      })
    )

    selectedFiles.forEach((file) => {
      formData.append('attachments', file)
    })
    formData.append('type', 'TICKET')

    try {
      let response = await addTicket(formData)
      navigate('/tickets')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  // Handle Update submit data..!
  const handleupdateSubmit = async (event, isSpam) => {
    event.preventDefault()
    // if (!isValid()) return;
    setSpinner(true)
    try {
      const payload = {
        _id: id,
        ticketId: ticketsInputs.ticketId,
        project_id: ticketsInputs.project_id[0]?._id,
        // reportedBy: {
        //   id: ticketsInputs.reportedBy.id,
        //   firstName: ticketsInputs.reportedBy.firstName,
        //   lastName: ticketsInputs.reportedBy.lastName,
        //   email: ticketsInputs.reportedBy.email,
        // },
        reportDate: ticketsInputs.reportDate,
        source: ticketsInputs.source,
        priority: ticketsInputs.priority[0]?.value,
        agent: ticketsInputs.agent,
        team: ticketsInputs.team,
        tags: ticketsInputs.tags ? ticketsInputs.tags : [],
        subject: ticketsInputs.subject,
        description: ticketsInputs.description,
        notes: ticketsInputs.notes,
        dueDate: isSpam ? new Date() : ticketsInputs.dueDate,
        // spam: isSpam,
        closedOn: ticketsInputs.close,
        status: isSpam ? isSpam : ticketsInputs.status,
      }
      let response = await updateTicket(payload)
      navigate('/tickets')
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  //  Handle Delete Email
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteTicket({
        _id: deleteModal?.data,
        isDeleted: true,
      })
      toast.success(response.message)
      setDeleteModal({ data: '', open: false })
      // methods.refetchTicket()
      setTimeout(() => {
        navigate('/tickets')
      }, 1500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Add Reply
  const addReply = async () => {
    let payload = {
      ticket_id: id,
      reply: replyModel.description,
      attachments:selectedFiles
    }
    setSpinner(true)
    try {
       await ticketReplyAdd(payload)
      setReplyModel({ description: '', open: false })
      setSelectedFiles([])
      getDetails()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  //   Add Note
  const AddNote = async () => {
    let payload = {
      ticket_id: id,
      note: noteModel.description,
      type: "TICKET"
    }
    setSpinner(true)
    try {
      await addNote(payload)
      noteIsFetched()
      setNoteModel({ description: '', open: false })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Ticket Merge MergeTicket
  const TicketMerge = async () => {
    if (!selectedMergeTickets.length) return toast.warn("Please select one option to perform the action.")
    if (ticketsInputs?.ticketFullDetails?.isMerged) return toast.error("Already merged")
    let payload = {
      // ticket_ids: ticketsInputs.ticket,
      ticket_ids: [selectedMergeTickets],
      mergeToTicket_id: id,
    }

    try {
      let response = await MergeTicket(payload)
      toast.success(response.message)
      setMergeModel({ open: false, ticketData: '', })
      getDetails()
    } catch (error) {
      toast.error(error.message)
    }
  }
  // merge ticket
  const handleCheckSlots = (isCheckedValue, item, index) => {
    if (isCheckedValue) {
      setSelectedMergeTickets(item._id)
      setslotID(index)
    } else {
      setSelectedMergeTickets(null)
      setslotID(null)
    }
  }
  // Handel Agent details During Add ticket
  const handleAgentDetailsInput = (event, Index) => {
    const { value, name } = event.target

    setAgentDetailsInputs((prev) => {
      const updatedTasks = [...prev]
      updatedTasks[Index][name] = value
      return updatedTasks
    })
    // Check if value is a string before calling .trim()
    if (typeof value === 'string' && value.trim().length > 0) {
      // setError((prevError) => {
      //     const updatedError = { ...prevError };
      //     delete updatedError[name];
      //     return updatedError;
      // });
    }
  }

  // handel Assign multiple group's ,agent's during edit

  const handleAssignInputChange = (event, taskIndex) => {
    const { value, name, checked } = event.target;

    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs];
      updatedTasks[taskIndex][name] = value;

      if (name === 'ReminderAction') {
        updatedTasks[taskIndex][name] = checked;
        if (checked) {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 15;
        } else {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 0;
        }
      }
      // Clear errors for the current field
      setAssignErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (name !== "ReminderAction") {
          updatedErrors[taskIndex][name] = null;
        }
        return updatedErrors;
      });

      return updatedTasks;
    });
  };

  // const handleAssignInputChange = (event, taskIndex) => {

  //   const { value, name, checked } = event.target;
  //   setAssignInputs((prevTaskFormInputs) => {
  //     const updatedTasks = [...prevTaskFormInputs];
  //     if (name === "ReminderAction") {
  //       updatedTasks[taskIndex][name] = checked;
  //       if (checked) {
  //         updatedTasks[taskIndex]["minutesBeforeReminder"] = 15;
  //       } else {
  //         updatedTasks[taskIndex]["minutesBeforeReminder"] = 0;
  //       }
  //     } else {
  //       // if (updatedTasks[taskIndex]["minutesBeforeReminder"])
  //       updatedTasks[taskIndex][name] = value;
  //     }
  //     return updatedTasks;
  //   });

  //   // setAssignInputs((prev) => {
  //   //   const updatedTasks = [...prev]
  //   //   updatedTasks[Index][name] = value
  //   //   return updatedTasks
  //   // })
  //   // Check if value is a string before calling .trim()
  //   if (typeof value === 'string' && value.trim().length > 0) {
  //     // setError((prevError) => {
  //     //     const updatedError = { ...prevError };
  //     //     delete updatedError[name];
  //     //     return updatedError;
  //     // });
  //   }
  // }

  // task error message
  const TaskFormInputsErrorMessage = {
    taskName: requiredMessage('task name'),
    description: requiredMessage('description'),
    project_id: requiredSelect('project'),
    priority: requiredSelect('priority'),
    assignTo_id: requiredSelect('assign'),
    assignStartTime: requiredSelect('assign start time'),
    assignEndTime: requiredSelect('assign end time'),
    ReminderAction: "Please select reminder",
    taskType: requiredSelect("task type"),
    workType: requiredSelect("work type"),
    subTask: requiredSelect("sub task"),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
  }

  // Error handling function
  const validateAllTasks = () => {
    const errorObjArray = [];

    for (let i = 0; i < assignInputs.length; i++) {
      const task = assignInputs[i];
      const errorObj = {};

      if (!task.taskName) {
        errorObj['taskName'] = TaskFormInputsErrorMessage['taskName'];
      }
      if (!task.description) {
        errorObj['description'] = TaskFormInputsErrorMessage['description'];
      }
      if (!task.project_id) {
        errorObj['project_id'] = TaskFormInputsErrorMessage['project_id'];
      }
      if (!task.priority) {
        errorObj['priority'] = TaskFormInputsErrorMessage['priority'];
      }
      if (!task.assignTo_id) {
        errorObj['assignTo_id'] = TaskFormInputsErrorMessage['assignTo_id'];
      }
      if (!task.assignStartTime) {
        errorObj['assignStartTime'] = TaskFormInputsErrorMessage['assignStartTime'];
      }
      if (!task.assignEndTime) {
        errorObj['assignEndTime'] = TaskFormInputsErrorMessage['assignEndTime'];
      }
      if (!task.taskType) {
        errorObj['taskType'] = TaskFormInputsErrorMessage['taskType'];
      }
      if (!task.workType) {
        errorObj['workType'] = TaskFormInputsErrorMessage['workType'];
      }
      if (!task.subTask) {
        errorObj['subTask'] = TaskFormInputsErrorMessage['subTask'];
      }
      if (!task.taskCategory) {
        errorObj['taskCategory'] = TaskFormInputsErrorMessage['taskCategory'];
      }
      if (!task.complexity) {
        errorObj['complexity'] = TaskFormInputsErrorMessage['complexity'];
      }
      if (!task.hoursType) {
        errorObj['hoursType'] = TaskFormInputsErrorMessage['hoursType'];
      }
      if (task.ReminderAction) {
        delete errorObj.ReminderAction;
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }

    setAssignErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };

  // Assign Multiple Agent submit api
  const MultipleAgentsAssignTicket = async () => {
    const isValid = validateAllTasks();
    if (!isValid) return toast.warn("Please fill all required fields")
    const convertData = (originalData) => {
      return originalData?.map((task) => {
        return {
          taskName: task?.taskName,
          project_id: task?.project_id[0]?._id,
          priority: task?.priority[0]?.value,
          assignTo_id: task?.assignTo_id[0]?._id,
          description: task?.description,
          tags: task?.tags?.map((tag) => tag?._id),
          assignStartTime: task?.assignStartTime,
          assignEndTime: task?.assignEndTime,
          minutesBeforeReminder: task?.minutesBeforeReminder,
          notes: task?.notes,
          attachments: task?.attachments,
          taskType: task?.taskType[0]?.value,
          workType: task?.workType[0]?.value,
          subTask: task?.subTask[0]?.value,
          taskCategory: task?.taskCategory[0]?.value,
          complexity: task?.complexity[0]?.value,
          hoursType: task?.hoursType[0]?.value,
          ticket_id: id
        };
      });
    };
    const newData = convertData(assignInputs);
    let newTaskFormInputs = [...newData]
    newTaskFormInputs?.map((cur, index) => {
      delete cur?.ReminderAction
      return cur
    })
    let payload = {
      // ticket_id: id,
      data: newTaskFormInputs
    }

    setSpinner(true)
    try {
      let response = await addTask(payload)
      navigate('/tickets')
      CloseFucntion("assignModel")
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
    // }
  }

  // Remove Assigned  Api
  const UnassignAgent = async () => {
    let payload = {
      ticket_id: id,
      agent_id: unAssignModel?.data
    }
    try {
      setSpinner(true)
      let response = await unassignAgent(payload)
      setUnAssignModel({ data: "", open: false })
      toast.success(response.message)
      getDetails()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)

  }

  // Handle Date
  const handleDateChange = (date, field, taskIndex) => {
    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks[taskIndex][field] = date
      return updatedTasks
    })
    setAssignErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[taskIndex][field] = null;
      return updatedErrors;
    });
  }

  // Agent Detail Multiple
  const AgentDetailMultiple = () => {
    if (agentDetailsInputs.length < 5) {
      setAgentDetailsInputs((prevTaskFormInputs) => [
        ...prevTaskFormInputs,
        {
          group: '',
          agent: '',
        },
      ])
    }
  }

  // Remove Agent Detail
  const removeAgentDetail = (assigIndex) => {
    setAgentDetailsInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks.splice(assigIndex, 1) // Remove the task at the specified index
      return updatedTasks
    })
  }

  // Assign Multiple Task

  // assignInputs.ticketFullDetails.agentData
  const AssignMultipleTask = () => {
    // Calculate the maximum number of forms that can be added
    const maxFormsToAdd = Math.max(0, 5 - (ticketsInputs?.ticketFullDetails?.agentData.length || 0));
    if (assignInputs.length < maxFormsToAdd) {
      setAssignInputs((prevTaskFormInputs) => [
        ...prevTaskFormInputs,
        {
          taskName: ticketsInputs?.subject,
          project_id: ticketsInputs?.project_id,
          priority: [ticketsInputs?.priority],
          assignTo_id: "",
          description: ticketsInputs?.description,
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 15,
          notes: "",
          ReminderAction: true,
          attachments: [],
          ticket_id: id,
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])

      // Clear errors for the newly added task
      setAssignErrors((prevErrors) => [
        ...prevErrors,
        {
          taskName: null,
          project_id: null,
          priority: '',
          assignTo_id: '',
          description: '',
          assignStartTime: null,
          assignEndTime: null,
          ReminderAction: false,
          minutesBeforeReminder: 15, // Reset ReminderAction error
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ]);

    }
  }

  // Remove Assigned
  const removeAssign = (assigIndex) => {
    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks.splice(assigIndex, 1) // Remove the task at the specified index
      return updatedTasks
    })
  }

  // Close modal And Empty
  const CloseFucntion = (type) => {
    if (type === "assignModel") {
      setAssignModel({ open: false, data: "" })
      setAssignInputs([{
        taskName: ticketsInputs?.subject,
        project_id: ticketsInputs?.project_id,
        priority: "",
        assignTo_id: "",
        description: ticketsInputs?.description,
        tags: [],
        assignStartTime: null,
        assignEndTime: null,
        minutesBeforeReminder: 15,
        notes: "",
        ReminderAction: true,
        attachments: [],
        ticket_id: id,
        workType: "",
        taskType: "",
        subTask: "",
        taskCategory: "",
        complexity: "",
        hoursType: "",
      }])
    }
  }

  return {
    data: {
      spinner: spinner || isprojectLoading || isTagLoading || isassignToLoading,
      error,
      ticketsInputs,
      state,
      fullTicketList: fullTicketList.filter((item) => item._id !== id),

      projectListData: projectListData?.data || [],
      tagListData: tagListData?.data || [],
      assignToListData: assignToListData?.data || [],

      allReportingUserList,
      allTagsList,
      projectList,
      priorityList,
      soucrsList,
      groupList,
      agentList,
      selectedFiles,
      agentFormopen,
      // editTicketModal,
      replyModel,
      noteModel,
      deleteModal,
      mergeModel,
      assignModel,
      searchTickets,
      slotId,
      assignInputs,
      agentDetailsInputs,
      noteListData: noteListData?.data || [],
      assignErrors,
      timeLineListData: timeLineError?.message === 'No record found' ? [] : timeLineListData?.data || [],
      unAssignModel,
      taskTypeList,
      SubTaskList,
      TaskCategoryList,
      complexityList,
      hoursTypeList,
      workTypeList,
      user
    },
    methods: {
      setTicketsInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      navigate,
      handleFileChange,
      handleRemoveFile,
      setAgentFormOpen,
      // setEditTicketModal,
      addReply,
      setReplyModel,
      setNoteModel,
      setDeleteModal,
      AddNote,
      handleDelete,
      TicketMerge,
      setMergeModel,
      setAssignModel,
      setSearchTickets,
      handleCheckSlots,
      AssignMultipleTask,
      removeAssign,
      setAssignInputs,
      handleDateChange,
      handleAssignInputChange,
      AgentDetailMultiple,
      removeAgentDetail,
      setAgentDetailsInputs,
      handleAgentDetailsInput,
      MultipleAgentsAssignTicket,
      CloseFucntion,
      UnassignAgent,
      setUnAssignModel,
      setError,
      handleTicketFileChange,
      handleTicketRemoveFile,
      setAssignErrors,
      fetchMoreData,
      setCount
    },
  }
}

export default useTicketAddUpdate

import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../../components/Modal'
import useProjectList from './Hooks/useProjectList'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import SearchAndStatusFilter from '../../components/SearchAndStatusFilter'

const Projects = () => {
  const navigate = useNavigate()

  const { data, methods } = useProjectList()
  const { projectList, search, statusType, totalCount, controller, isLoading, deleteModal, ProjectPermission } = data
  const { handleSearchChange, setStatusType, resetFilter, applayFilter, handlePagination, setDeleteModal, handleDelete,Export } = methods
  const { add, delete: projectDelete, edit,export: exportFile } = ProjectPermission?.userInfo?.permission?.project

  const TableHead = [
    { label: '#', id: '' },
    { label: 'Project Name', id: '' },
    { label: 'Project ID', id: '' },
    { label: 'Coordinator', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} />
            </div>
            <div className="d-flex pe-1">
              {exportFile?.value&&<div>
              <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
              </div>}
              {add?.value && <div className="ms-2">
                <Button className="dropDownButtons11" onClick={() => navigate(`/projects/project-form/add`)}>
                  <AddIcon /> Add New
                </Button>
              </div>}
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {projectList?.map((item, index) => {
                        return (
                          <tr
                            key={item._id + index}
                            className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                          >
                            <td className="table-link">{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                            <td className="table-link" onClick={() => navigate(`/projects/${item?._id}/ticket`, { state: { projectData: item } })}>
                              {item.name || '--'}
                            </td>
                            <td className="">{item.project_id || '--'}</td>
                            <td className="">{item.coordinatorData?.name || '--'}</td>
                            <td>
                              {(projectDelete?.value || edit?.value) ?
                                <div className="dropdown d-flex p-0 align-items-center justify-content-start" style={{ minHeight: '50px' }}>
                                  <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {edit?.value && <li style={{ cursor: 'pointer' }} onClick={() => navigate(`/projects/project-form/${item._id}`, { state: { projectId: item._id } })} className="dropdown-item">
                                      Edit
                                    </li>}
                                    {projectDelete?.value && <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item._id, isDeleted: item.isDeleted }, open: true })}>
                                      {item.isDeleted ? 'Recover' : 'Delete'}
                                    </li>}
                                  </ul>
                                </div> :
                                <div className='pt-3' style={{ minHeight: '50px' }}>
                                  <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />
                                </div>
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!projectList.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{deleteModal.data.isDeleted ? 'Recover' : 'Delete'} Project?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {deleteModal.data.isDeleted ? 'recover' : 'delete'} project? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default Projects

import React, { useRef } from 'react'
import { Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import useTicketAddUpdate from './Hooks/useTicketAddUpdate'
import TextField from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import Fab from '@mui/material/Fab'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { CKEditorCustomToolbar } from '../../constants'

import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import { uploadFileUrlTicket } from '../../repository/ticketRepo'
import MultipleSelect from "../../components/MultipleSelect"

const NewTicketForm = () => {
  const fileInputRef = useRef(null)
  const { data, methods } = useTicketAddUpdate()
  const { spinner, error, ticketsInputs, allReportingUserList, allTagsList, projectList, priorityList, soucrsList, selectedFiles, agentFormopen, } = data
  const {
    setTicketsInputs,
    handleInputChange,
    handleBlur,
    handleAddSubmit,
    navigate,
    setAgentFormOpen,
    setError,
    handleTicketFileChange,
    handleTicketRemoveFile
  } = methods

  const fileType = (file, fileIndex) => {
    let type = file?.split('.').pop()
    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={uploadFileUrlTicket + file} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }



  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              <div className="text-center pt-3">
                <h4>New Ticket</h4>
              </div>
              {/* <div className="col-lg-4">
                <TextField
                  Lable="Ticket ID"
                  Name="ticketId"
                  variant="outlined"
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={ticketsInputs.ticketId}
                  width={100}
                  Error={error.ticketId}
                />
              </div> */}
              <div className="col-lg-4 mt-4">
                <MultipleSelect
                  label="Project"
                  isRequired={true}
                  // multiple={true}
                  options={projectList}
                  searchBy={"name"}
                  keyName={"name"}
                  name={"project_id"}
                  onChange={(e) => handleInputChange({ target: { name: "project_id", value: e } })}
                  value={"_id"}
                  selectedValues={ticketsInputs.project_id}
                // disabled={true}
                // error={error.tags}
                />

                {/* <SelectInput
                  Lable={'Project'}
                  // width={"310px"}
                  Options={projectList}
                  keyName={'name'}
                  Name="project_id"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.project_id}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.project_id}
                  variant={'outlined'}
                  isRequired={true}
                /> */}
              </div>
              <div className="col-lg-4 mt-4">
                <MultipleSelect
                  label="Reporting User"
                  isRequired={true}
                  // multiple={true}
                  options={allReportingUserList}
                  searchBy={"firstName"}
                  keyName={"firstName"}
                  name={"reportedBy"}
                  onChange={(e) => handleInputChange({ target: { name: "reportedBy", value: e } })}
                  value={"_id"}
                  selectedValues={ticketsInputs.reportedBy}
                // disabled={true}
                // error={error.tags}
                />
                {/* <SelectInput
                  Lable={'Reporting User'}
                  // width={"310px"}
                  Options={allReportingUserList}
                  keyName={'firstName'}
                  Name="reportedBy"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.reportedBy}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.reportedBy}
                  variant={'outlined'}
                  isRequired={true}
                /> */}

                <div className="text-end">
                  <sub className="table-link" onClick={() => setAgentFormOpen((prev) => !prev)}>
                    {agentFormopen ? 'Add New Client' : <b>Cancel</b>}
                  </sub>
                </div>
              </div>
              {!agentFormopen && (
                <>
                  <div className="col-lg-11">
                    <div
                      style={{
                        backgroundColor: '#f5f7f97a',
                        border: '1px solid rgb(211 211 211)',
                      }}
                      className="row mx-auto py-4 mb-2"
                    >
                      <h5>Add New Client</h5>
                      <div className="col-lg-4">
                        <TextField
                          Lable="First Name"
                          Name="firstName"
                          variant="outlined"
                          ClassName="form-field-design"
                          Require={true}
                          HandleInputChange={handleInputChange}
                          onBlur={handleBlur}
                          Value={ticketsInputs?.firstName}
                          width={100}
                          Error={error.firstName}
                        />
                      </div>
                      <div className="col-lg-4 ">
                        <TextField
                          Lable="Last Name"
                          Name="lastName"
                          variant="outlined"
                          ClassName="form-field-design"
                          Require={true}
                          HandleInputChange={handleInputChange}
                          onBlur={handleBlur}
                          Value={ticketsInputs?.lastName}
                          width={100}
                          Error={error.lastName}
                        />
                      </div>
                      <div className="col-lg-4">
                        <TextField
                          Lable="Email"
                          Name="email"
                          variant="outlined"
                          ClassName="form-field-design"
                          Require={true}
                          HandleInputChange={handleInputChange}
                          onBlur={handleBlur}
                          Value={ticketsInputs.email}
                          width={100}
                          Error={error.email}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <h5 className="mt-2 mb-2">Ticket Info</h5>

              <div className="col-lg-4">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Reported On'}
                  handleDateChange={(e) => {
                    setTicketsInputs((prevProps) => ({ ...prevProps, reportDate: e }))
                    setError((prev) => ({ ...prev, reportDate: '' }))
                  }}
                  value={ticketsInputs?.reportDate || null}
                  Error={error?.reportDate}
                />

                {/* <InputLabel sx={{ fontSize: '13px' }}>
                  {'Reported On'}
                  <span className="text-danger"> *</span>
                </InputLabel>
                <TimePicker
                  placeholder="Select Time"
                  use24Hours
                  showSecond={false}
                  focusOnOpen={true}
                  format="hh:mm A"
                  name={`reportDate`}
                  onChange={(e) => {
                    setTicketsInputs((prevProps) => ({ ...prevProps, reportDate: e }))
                  }}
                  value={ticketsInputs.reportDate}
                  className="w-100"
                /> */}
              </div>

              <div className="col-lg-4">
                <SelectInput
                  Lable={'Priority'}
                  // width={"310px"}
                  Options={priorityList}
                  keyName={'title'}
                  Name="priority"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.priority}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.priority}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div>
              <div className="col-lg-4">
                <SelectInput
                  Lable={'Source'}
                  // width={"310px"}
                  Options={soucrsList}
                  keyName={'title'}
                  ClassName="form-field-design"
                  Name="source"
                  SelectValue={ticketsInputs.source}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.source}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-4 mt-2">
                <MultipleSelect
                  label="Tag"
                  // isRequired={true}
                  multiple={true}
                  options={allTagsList}
                  searchBy={"tagName"}
                  keyName={"tagName"}
                  name={"tags"}
                  onChange={(e) => handleInputChange({ target: { name: "tags", value: e } })}
                  value={"_id"}
                  selectedValues={ticketsInputs.tags}
                // disabled={true}
                // error={error.tags}
                />
                {/* <SelectInput
                  Lable={'Tag'}
                  multiple={true}
                  // width={"310px"}
                  Options={allTagsList}
                  keyName={'tagName'}
                  Name="tags"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.tags}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.tags}
                  variant={'outlined'}
                /> */}
              </div>
              <div className="col-lg-4 mt-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Due Date'}
                  handleDateChange={(date) => {
                    setTicketsInputs((pre) => ({ ...pre, dueDate: date }))
                    setError((prev) => ({ ...prev, dueDate: '' }))
                  }}
                  Error={error?.dueDate}
                />

                {/* <DateInput
                  size="small" // You can customize the size here
                  variant="outlined" // You can customize the variant here
                  className="w-100 form-field-design" // You can customize the width here
                  // isRequired={true}
                  Label={'Due Date'}
                  SelectedDate={ticketsInputs.dueDate}
                  handleDateChange={(date) => {
                    setTicketsInputs((pre) => ({ ...pre, dueDate: date }))
                  }}
                /> */}
              </div>

              <h5 className="mt-4 mb-2">Ticket Summary</h5>

              <div className="col-lg-12">
                <TextField
                  Lable="Subject"
                  Name="subject"
                  variant="outlined"
                  form-field-design
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={ticketsInputs.subject}
                  width={100}
                  Error={error.subject}
                />
              </div>

              <div className="col-lg-12  pt-2">
                <div className="App mt-2">
                  <p className="m-0 input-custom-title">
                    Description<span className="text-danger"> *</span>
                  </p>
                  <CKEditor
                    editor={ClassicEditor}
                    data={ticketsInputs.description} // Set CKEditor data to projectInputs.notes
                    config={{ toolbar: CKEditorCustomToolbar}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      // Update projectInputs.notes when CKEditor content changes
                      setTicketsInputs((prevProps) => ({ ...prevProps, description: data }))
                      setError((prev) => ({ ...prev, description: '' }))
                    }}
                    onBlur={(event, editor) => {
                      !ticketsInputs.description && setError((prev) => ({ ...prev, description: 'Please enter description' }))
                    }}
                  />
                  {error.description && <p className="text-danger">{error.description}</p>}
                </div>
              </div>

              <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
                <h5 className="">Attachments</h5>
                <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
                <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTicketFileChange} style={{ display: 'none' }} />
              </div>
              {selectedFiles.length > 0 && <div className="col-lg-12 ">
                <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                  <div>
                    <h4>Selected Files:</h4>
                    <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                      {selectedFiles.map((file, fileIndex) => (
                        <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                          {fileType(file, fileIndex)}
                          <Fab
                            className="m-2"
                            sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                            onClick={() => handleTicketRemoveFile(fileIndex)}
                          >
                            <ClearIcon sx={{ fontSize: '15px' }} />
                          </Fab>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>}

              {/* <h5 className="mt-4 pt-4 mb-2">Agent Details</h5>

              {agentDetailsInputs?.map((item, index) => (
                <div key={index}>
                  <div className="row">
                    <div className="col-lg-6 mx-auto mb-2 ">
                      <SelectInput
                        Lable={'Group'}
                        Options={groupList}
                        // keyName={"reportedBy.id"}
                        Name="group"
                        SelectValue={item.group}
                        HandleChange={(e) => handleAgentDetailsInput(e, index)}
                        // onBlur={handleBlur}
                        // Error={error.team}
                        variant={'outlined'}
                      // isRequired={true}
                      />
                    </div>
                    <div className="col-lg-6  mb-2">
                      <SelectInput
                        Lable={'Agent'}
                        // width={"310px"}
                        Options={agentList}
                        keyName={'firstName'}
                        Name="agent"
                        SelectValue={item.agent}
                        HandleChange={(e) => handleAgentDetailsInput(e, index)}
                        // onBlur={handleBlur}
                        // Error={error.agent}
                        variant={'outlined'}
                      // isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 text-end mt-3">
                    <Button className="dropDownButtons11 me-2" onClick={() => AgentDetailMultiple(index)}>
                      <AddIcon />
                    </Button>
                    {agentDetailsInputs?.length > 1 && ( // Only show the button if there is more than one task
                      <Button variant="outlined" color="error" onClick={() => removeAgentDetail(index)}>
                        <ClearIcon />
                      </Button>
                    )}
                  </div>
                </div>
              ))} */}

              {/* <div className="col-lg-4">
                <SelectInput
                  Lable={'Group'}
                  Options={groupList}
                  // keyName={"reportedBy.id"}
                  Name="team"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.team}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.team}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div>
              <div className="col-lg-4">
                <SelectInput
                  Lable={'Agent'}
                  // width={"310px"}
                  Options={agentList}
                  keyName={'firstName'}
                  Name="agent"
                  ClassName="form-field-design"
                  SelectValue={ticketsInputs.agent}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.agent}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div> */}
              <div className="col-lg-12  pt-2">
                <div className="App mt-2">
                  <p className="m-0 input-custom-title">Notes </p>
                  <CKEditor
                    editor={ClassicEditor}
                    data={ticketsInputs.notes} 
                    config={{toolbar: CKEditorCustomToolbar}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setTicketsInputs((prevProps) => ({ ...prevProps, notes: data }))
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-12 mb-5 mt-4">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="">{/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}</div>
                    <div className="row">
                      <div className=" d-flex justify-content-end ">
                        <div className="m-2">
                          <Button variant="outlined" onClick={() => navigate('/tickets')}>
                            Cancel
                          </Button>
                        </div>
                        <div className="m-2">
                          <Button variant="contained" onClick={handleAddSubmit}>
                            Create
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewTicketForm

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage } from '../../../../utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { addTeam, getTeamDetails, updateTeam } from '../../../../repository/teamRepo'
import { getTeamMemberList } from '../../../../repository/teamManagementRepo'

const useTeamAddUpdate = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(false)
  const [teamInputs, setTeamInputs] = useState({
    name: '',
    timezone: '',
    description: '',
    tl: null
  })
  const [timeZone] = useState([])
  const [error, setError] = useState({})
  const [users, setUsers] = useState([])

  useEffect(() => {
    // getTimeZone()
    if (state?.teamId) {
      getDetails()
    }
    getUsers()
  }, [])

  // const getTimeZone = async () => {
  //     try {
  //         setSpinner(true)
  //         let response = await timeZoneList()
  //         setTimeZone(response.data)
  //     } catch (error) {
  //         toast.error(error.message)
  //     }
  //     setSpinner(false)
  // }

  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTeamDetails(state?.teamId)
      const { name,description, teamLead_id } = response.data

      // const currentTimeZone = timeZonelist?.find(
      //     (item) => item.timezone === timezone
      // );
      setTeamInputs({
        name: name,
        // timezone: currentTimeZone,
        description: description,
        tl: teamLead_id
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const getUsers = async () => {
    try {
      let response = await getTeamMemberList({ count: 1000 })
      setUsers(response.data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  //Handle Input value
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target
  //   setTeamInputs((prevProps) => ({ ...prevProps, [name]: value }))
  //   value?.trim()?.length &&
  //     setError((pre) => {
  //       delete pre[name]
  //       return { ...pre }
  //     })
  // }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value ? value.trim() : "";
    setTeamInputs((prevProps) => ({ ...prevProps, [name]: trimmedValue }));
    if (trimmedValue.length > 0) {
      setError((pre) => {
        const updatedErrors = { ...pre };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  // const handleBlur = (event) => {
  //   const { name, value } = event.target
  //   const trimmedValue = value ? value?.trim() : "";
  //   !trimmedValue?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  // }
  const handleBlur = (event) => {
    const { name, value } = event.target;
    const trimmedValue = typeof value === 'string' ? value.trim() : "";
    !trimmedValue.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }));
  };

  // -***--------------------***-
  // Error Message
  const errorMessage = {
    name: requiredMessage('group name'),
    // timezone: requiredSelect("time-zone"),
    description: requiredMessage('description'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!teamInputs.name) {
      errorObj['name'] = errorMessage['name']
    }
    // if (!teamInputs.timezone) {
    //     errorObj['timezone'] = errorMessage['timezone']
    // }
    if (!teamInputs.description) {
      errorObj['description'] = errorMessage['description']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    let payload = {
      name: teamInputs.name,
      description: teamInputs.description,
      teamLead_id: teamInputs.tl
    }
    try {
      let response = await addTeam(payload)
      toast.success(response.message)
      setTimeout(() => {
        navigate('/setting/group')
      }, 1000)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  // Handle Update submit data..!
  const handleupdateSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        _id: state.teamId,
        name: teamInputs.name,
        // timezone: teamInputs.timezone?.timezone,
        description: teamInputs.description,
        teamLead_id: teamInputs.tl
      }
      let response = await updateTeam(payload)
      navigate('/setting/group')
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      spinner,
      error,
      teamInputs,
      state,
      timeZone,
      users
    },
    methods: {
      setTeamInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      navigate,
      setError,
    },
  }
}

export default useTeamAddUpdate

import React from 'react'
import { Outlet, useLocation, NavLink } from 'react-router-dom'
import { Tabs, Tab, AppBar, } from '@mui/material';

const WorkFlowMainPage = () => {
  const location = useLocation()
  const allTabs = [
      {
          label: "Team",
          value: `/setting/group`,
      },
      {
          label: "Skills",
          value: `/setting/skill-list`,
      },
      {
          label: "Shifts",
          value: `/setting/shift-list`,
      },
      {
          label: "Status",
          value: `/setting/status-list`,
      },
      {
          label: "Tags",
          value: `/setting/tag-list`,
      },
  ];

  return (
      <>
          <div className="border" style={{ backgroundColor: "white" }}>
              <AppBar position="static" sx={{ background: "#fff" }}>
                  <Tabs value={location.pathname}>
                      {allTabs.map((item, i) =>
                          <Tab key={i}
                              label={item.label}
                              value={item.value}
                              component={NavLink}
                              to={item.value}
                          />
                      )}
                  </Tabs>
              </AppBar>
              <Outlet
              // context={{ setSpinner, spinner, controller, setController, totalCount, checkOutList, GetCheckoutList, setSearchText, searchText, user_permission }}
              />
          </div>
      </>
  )
}

export default WorkFlowMainPage
import React from 'react'
import { Button } from '@mui/material'
import useUploadAttendance from './Hooks/useUploadAttendance'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import TextInput from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import DateInput from '../../components/DateInput'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { useNavigate } from 'react-router-dom'
import { attendanceStatus } from '../../constants'

const EditAttendance = () => {

    const navigate = useNavigate()
    const { data, methods } = useUploadAttendance()
    const { spinner, updateAttendanceInput, attendError } = data
    const { setAttendError, setUpdateAttendanceInput, handleupdateSubmit, } = methods

    //   const {  edit } = TeamPermission?.userInfo?.permission?.team


    return (
        <>
            <ToastContainer />
            {spinner && <Spinner />}
            <div className="contact">
                <div className="container bg">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="text-center pt-3">
                                <h4>{'Edit Attendance'}</h4>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-4 mt-4">
                                    <TextInput
                                        Lable="EMP ID"
                                        Name="empId"
                                        variant="outlined"
                                        ClassName="form-field-design"
                                        // Require={true}
                                        Value={updateAttendanceInput.empId}
                                        width={100}
                                        readOnly={false}
                                        Disable
                                    />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <TextInput
                                        Lable="Emp Name"
                                        Name="empName"
                                        variant="outlined"
                                        ClassName="form-field-design"
                                        // Require={true}
                                        Value={updateAttendanceInput.empName}
                                        width={100}
                                        readOnly={true}
                                        Disable
                                    />
                                </div>
                                <div className="col-lg-4 mt-4">

                                    <DateInput
                                        size="small" // You can customize the size here
                                        variant="outlined" // You can customize the variant here
                                        className="w-100 form-field-design" // You can customize the width here
                                        isRequired={true}
                                        Label={'Date'}
                                        SelectedDate={updateAttendanceInput?.date || null}
                                        handleDateChange={(date) => {
                                            setUpdateAttendanceInput((pre) => ({ ...pre, date: date }))
                                        }}
                                    />
                                    {attendError?.date && <p className='text-danger'>{attendError?.date}</p>}
                                </div>
                                <div className="col-lg-4 my-2">
                                    <SelectInput
                                        LabelButton
                                        Lable={'Status'}
                                        Options={attendanceStatus}
                                        keyName={'title'}
                                        Name="status:''"
                                        SelectValue={updateAttendanceInput?.status}
                                        HandleChange={(e) => {
                                            setUpdateAttendanceInput((pre) => ({ ...pre, status: e.target.value }))
                                            if (e.target.value === 'A') {
                                                setUpdateAttendanceInput((prevProps) => ({ ...prevProps, inTime: null, outTime: null }))
                                            }
                                        }}
                                        variant={'outlined'}
                                        isRequired={true}
                                    />
                                   {attendError?.status && <p className='text-danger'>{attendError?.status}</p>}
                                </div>
                                <div className="col-lg-4 my-2">


                                    {/* <TimePicker
                                        className="inputsize"
                                        name="inTime"
                                        use12Hours={false}
                                        onChange={(e) => {
                                            setUpdateAttendanceInput((prevProps) => ({ ...prevProps, inTime: e }))
                                            setAttendError((prev) => ({ ...prev, inTime: '' }))
                                        }}
                                        value={attendError?.inTime}
                                        autoComplete="off"
                                        format={'hh:mm'}
                                        placeholder="00:00"
                                        showSecond={false}
                                    /> */}

                                    <DesktopDateTimePicker
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'In Time'}
                                        handleDateChange={(e) => {
                                            setUpdateAttendanceInput((prevProps) => ({ ...prevProps, inTime: e }))
                                            setAttendError((prev) => ({ ...prev, inTime: '' }))
                                        }}
                                        SelectedDate={updateAttendanceInput?.inTime || null}
                                        Error={attendError?.inTime}
                                        disabled={updateAttendanceInput?.status === 'A' ? true : false}
                                    />
                                </div>
                                <div className="col-lg-4 my-2">

                                    <DesktopDateTimePicker
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'Out Time'}
                                        handleDateChange={(e) => {
                                            setUpdateAttendanceInput((prevProps) => ({ ...prevProps, outTime: e }))
                                            setAttendError((prev) => ({ ...prev, outTime: '' }))
                                        }}
                                        SelectedDate={updateAttendanceInput?.outTime || null}
                                        Error={attendError?.outTime}
                                        disabled={updateAttendanceInput?.status === 'A' ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 my-2 pe-4">
                                <div className=" d-flex justify-content-end ">
                                    <div className="m-2">
                                        <Button variant="outlined" onClick={() => navigate('/attendance')}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="m-2">
                                        {
                                            // edit?.value && 
                                            <Button variant="contained" onClick={handleupdateSubmit}>
                                                Update
                                            </Button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditAttendance
import { createSlice } from "@reduxjs/toolkit";
const notificationSlice = createSlice({
    name: "notification",
    initialState:0,
    reducers: {
        addCount(state, action) {
            return state = action.payload;
        },
    },
});
export const { addCount} = notificationSlice.actions;
export default notificationSlice.reducer;
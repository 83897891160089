/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Switch from '@mui/material/Switch'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { Table, TableBody, MenuItem } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import { TextField } from '@mui/material'

const label = { inputProps: { 'aria-label': 'Switch demo' } }
const Workflow = () => {
  const TableHeads = [
    { label: 'Report Name', id: '' },
    { label: 'Created by', id: '' },
    { label: 'Created Date', id: '' },
    { label: 'Last Modified by', id: '' },
    { label: 'Last Modified Date', id: '' },
    { label: 'Action', id: '' },
  ]
  const TableRows = [
    {
      reportname: 'Time sheet Summary',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
    {
      reportname: 'Canned Responses Report',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
    {
      reportname: 'Freddy Ticket Insights - BETA',
      createdby: 'System',
      createddate: '03/09/2023',
      modifiedby: '--',
      modifieddate: '03/09/2023',
    },
  ]
  const AgentNotifications = [
    {
      agentnotifications: 'New Ticket Created',
    },
    {
      agentnotifications: 'Ticket Assigned to Group',
    },
    {
      agentnotifications: 'Ticket Assigned to Agent',
    },
    {
      agentnotifications: 'Requester Replies to Ticket',
    },
  ]
  const RequesterNotifications = [
    {
      agentnotifications: 'New Ticket Created',
    },
    {
      agentnotifications: 'Agent Adds Comment to Ticket',
    },
    {
      agentnotifications: 'Agent Solves the Ticket',
    },
    {
      agentnotifications: 'Agent Closes the Ticket',
    },
  ]
  const CCNotifications = [
    {
      agentnotifications: 'New Ticket Created',
    },
    {
      agentnotifications: 'Note added to ticket',
    },
  ]
  const Templates = [
    {
      agentnotifications: 'Agent Reply Template',
    },
    {
      agentnotifications: 'Agent Forward Template',
    },
  ]
  const Navigate = useNavigate()
  const [value, setValue] = React.useState('1')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [innerTabValue, setInnerTabValue] = React.useState('11')
  const handleInnerTabChange = (event, newValue) => {
    setInnerTabValue(newValue)
  }
  return (
    <>
      <div className="container-fluid py-3">
        <div className="row my-4">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  {/* <Tab label="Ticket Forms" value="1" /> */}
                  <Tab label="Escalation" value="1" />
                  <Tab label="Notification" value="2" />
                  {/* <Tab label="Feedback Forms " value="4" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                      </div>
                      <div>
                        <Button variant="primary4" className="px-4 ms-3">
                          <SearchIcon />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex me-5 pe-1">
                      {/* <div>
              <Button variant="primary1">
                <ExitToAppIcon /> Export
              </Button>{" "}
            </div> */}
                      <div className="ms-2">
                        <Button className="dropDownButtons11 ms-4" onClick={() => Navigate(`/newticketworkflow/5899`)}>
                          <AddIcon /> Add New
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 ticet my-4">
                  <div className="bg shadow raduus pt-2">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-2">
                          <table className="table">
                            <Table TableHeadData={TableHeads} />
                            <tbody>
                              {TableRows?.map((item) => {
                                return (
                                  <tr
                                    className="text-start    rounded-row "
                                    style={{
                                      borderRadius: '50px',
                                      backgroundColor: '#fff',
                                    }}
                                  >
                                    <td className="table-link" onClick={() => Navigate(``)}>
                                      {item.reportname}{' '}
                                    </td>
                                    <td>{item.createdby}</td>
                                    <td>{item.createddate}</td>
                                    <td>{item.modifiedby}</td>
                                    <td>{item.modifieddate}</td>
                                    <td>
                                      <div>
                                        <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                          <MoreVertIcon />
                                        </a>
                                        <Menu
                                          id="fade-menu"
                                          MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                          }}
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleClose}
                                          TransitionComponent={Fade}
                                        >
                                          <MenuItem onClick={handleClose}>Clone Report</MenuItem>
                                        </Menu>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={innerTabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleInnerTabChange} aria-label="lab API tabs example">
                        <Tab label="Ticket Creation" value="11" />
                        <Tab label="Time Triggers" value="12" />
                        <Tab label="Ticket Updates" value="13" />
                      </TabList>
                    </Box>
                    <TabPanel value="11">
                      <>
                        {/* same table data different */}
                        <div className="row mb-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                              </div>
                              <div>
                                <Button variant="primary4" className="px-4 ms-3">
                                  <SearchIcon />
                                </Button>
                              </div>
                            </div>
                            <div className="d-flex">
                              {/* <div>
              <Button variant="primary1">
                <ExitToAppIcon /> Export
              </Button>{" "}
            </div> */}
                              <div className="ms-2">
                                <Button className="dropDownButtons11 ms-4" onClick={() => Navigate(`/ticketcreationform/2513`)}>
                                  <AddIcon /> Add New
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>
                                  <b>Name</b>
                                </TableCell>
                                <TableCell>
                                  <b>Status</b>
                                </TableCell>
                                <TableCell>
                                  <b>Action</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <Row key={row.name} row={row} />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    </TabPanel>
                    <TabPanel value="12">
                      {/* same table data different */}
                      <div className="row mb-4">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div>
                              <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                            </div>
                            <div>
                              <Button variant="primary4" className="px-4 ms-3">
                                <SearchIcon />
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <Button variant="primary1" className="py-2">
                                Reorder
                              </Button>{' '}
                            </div>
                            <div className="ms-2">
                              <Button className="dropDownButtons11 ms-4" onClick={() => Navigate(`/timetriggersform/4937`)}>
                                <AddIcon /> Add New
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <b>Name</b>
                              </TableCell>
                              <TableCell>
                                <b>Status</b>
                              </TableCell>
                              <TableCell>
                                <b>Action</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <Row key={row.name} row={row} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value="13">
                      {/* same table data different */}
                      <div className="row mb-4">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div>
                              <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                            </div>
                            <div>
                              <Button variant="primary4" className="px-4 ms-3">
                                <SearchIcon />
                              </Button>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <Button variant="primary1" className="py-2">
                                Reorder
                              </Button>{' '}
                            </div>
                            <div className="ms-2">
                              <Button className="dropDownButtons11 ms-4" onClick={() => Navigate(`/ticketupdatesforms/5599`)}>
                                <AddIcon /> Add New
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <b>Name</b>
                              </TableCell>
                              <TableCell>
                                <b>Status</b>
                              </TableCell>
                              <TableCell>
                                <b>Action</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <Row key={row.name} row={row} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabContext>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={innerTabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleInnerTabChange} aria-label="lab API tabs example">
                        <Tab label="Agent Notifications" value="11" />
                        <Tab label="Requester Notifications" value="12" />
                        <Tab label="CC Notifications" value="13" />
                        <Tab label="Templates" value="14" />
                      </TabList>
                    </Box>
                    <TabPanel value="11">
                      <div className="row py-3">
                        <div className="col-lg-12 ticet">
                          <div className="bg shadow raduus pt-2">
                            <div className="">
                              <div className="">
                                <table className="table">
                                  <tbody>
                                    {AgentNotifications?.map((item) => {
                                      return (
                                        <tr
                                          className="text-start    rounded-row "
                                          style={{
                                            borderRadius: '50px',
                                            backgroundColor: '#fff',
                                          }}
                                        >
                                          <td className="table-link" onClick={() => Navigate(``)}>
                                            {item.agentnotifications}{' '}
                                          </td>
                                          <td>
                                            <Switch {...label} defaultChecked />
                                          </td>
                                          <td>
                                            <div>
                                              <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                                <MoreVertIcon />
                                              </a>
                                              <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                  'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                              >
                                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                              </Menu>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="12">
                      <div className="row py-3">
                        <div className="col-lg-12 ticet">
                          <div className="bg shadow raduus pt-2">
                            <div className="">
                              <div className="">
                                <table className="table">
                                  <tbody>
                                    {RequesterNotifications?.map((item) => {
                                      return (
                                        <tr
                                          className="text-start    rounded-row "
                                          style={{
                                            borderRadius: '50px',
                                            backgroundColor: '#fff',
                                          }}
                                        >
                                          <td className="table-link" onClick={() => Navigate(``)}>
                                            {item.agentnotifications}{' '}
                                          </td>
                                          <td>
                                            <Switch {...label} />
                                          </td>
                                          <td>
                                            <div>
                                              <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                                <MoreVertIcon />
                                              </a>
                                              <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                  'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                              >
                                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                              </Menu>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="13">
                      <div className="row py-3">
                        <div className="col-lg-12 ticet">
                          <div className="bg shadow raduus pt-2">
                            <div className="">
                              <div className="">
                                <table className="table">
                                  <tbody>
                                    {CCNotifications?.map((item) => {
                                      return (
                                        <tr
                                          className="text-start    rounded-row "
                                          style={{
                                            borderRadius: '50px',
                                            backgroundColor: '#fff',
                                          }}
                                        >
                                          <td className="table-link" onClick={() => Navigate(``)}>
                                            {item.agentnotifications}{' '}
                                          </td>
                                          <td>
                                            <Switch {...label} />
                                          </td>
                                          <td>
                                            <div>
                                              <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                                <MoreVertIcon />
                                              </a>
                                              <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                  'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                              >
                                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                              </Menu>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="14">
                      <div className="row py-3">
                        <div className="col-lg-12 ticet">
                          <div className="bg shadow raduus pt-2">
                            <div className="">
                              <div className="">
                                <table className="table">
                                  <tbody>
                                    {Templates?.map((item) => {
                                      return (
                                        <tr
                                          className="text-start    rounded-row "
                                          style={{
                                            borderRadius: '50px',
                                            backgroundColor: '#fff',
                                          }}
                                        >
                                          <td className="table-link" onClick={() => Navigate(``)}>
                                            {item.agentnotifications}{' '}
                                          </td>
                                          <td>
                                            <Switch {...label} defaultChecked />
                                          </td>
                                          <td>
                                            <div>
                                              <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                                <MoreVertIcon />
                                              </a>
                                              <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                  'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                              >
                                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                              </Menu>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </TabPanel>
              <TabPanel value="4">
                <div className="contact">
                  <div className="container bg">
                    <div className="row">
                      <div className="row my-3">
                        <div className="col-lg-11 mt-4">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Form Heading"
                            // multiline
                            className="w-100"
                            maxRows={4}
                            variant="standard"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-lg-11 mt-5">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Form Submit Button"
                            // multiline
                            className="w-100"
                            maxRows={4}
                            variant="standard"
                            defaultValue=""
                          />
                        </div>
                        <div className="col-lg-11 mt-5">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Form Submit Message"
                            // multiline
                            className="w-100"
                            maxRows={4}
                            variant="standard"
                            defaultValue=""
                          />
                        </div>
                      </div>

                      <div className="col-lg-11 mb-5">
                        <div className="row">
                          <div className="d-flex justify-content-between">
                            <div className="mt-4"></div>
                            <div className="row">
                              <div className="col-lg-5 mt-4 text-center">
                                <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                              </div>
                              <div className="col-lg-7 mt-4 text-center">
                                <Button className="px-4 add-cc-btn mt-3 ">Submit</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  )
}

export default Workflow

function createData(name, status, action) {
  return {
    name,
    status,
    action,
    history: [
      {
        modified: 'a month ago',
        by: '---',
        ticket: '---',
      },
    ],
  }
}

const rows = [
  createData('Send payments to Billing - Sample Dispatcher rule'),
  createData('Add important tag, to escalated and urgent tickets'),
  createData('Add Billing Queries as tag, if subject contains refunds'),
]
function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const tableMenu = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="table-link">
          {row.name}
        </TableCell>
        <TableCell align="">
          <Switch {...label} defaultChecked />
        </TableCell>
        <TableCell align="">
          <div>
            <a id="fade-button" aria-controls={tableMenu ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={tableMenu ? 'true' : undefined} onClick={handleClick}>
              <MoreVertIcon />
            </a>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={tableMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Clone</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <h4 >
                History
              </h4> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Last Modified</b>
                    </TableCell>
                    <TableCell>
                      <b>By</b>
                    </TableCell>
                    <TableCell>
                      <b>Impacted tickets (Last 7 days)</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {historyRow.modified}
                      </TableCell>
                      <TableCell>{historyRow.by}</TableCell>
                      <TableCell>{historyRow.ticket}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

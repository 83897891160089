import React from 'react'
import { useOutletContext } from 'react-router-dom'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'
const TicketNotes = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const { data } = useOutletContext()

  const { noteListData } = data

  return (
    <>
      <div className="container-fluid contact mb-5">
        <div className="col-lg-12">
          {noteListData?.length ? (
            noteListData?.map((cur, index) => {
              return (
                <div className="my-3 pb-2" key={index}>
                  <div className="d-flex justify-content-between">
                    <span style={{ fontSize: '14px' }} className="table-link">{`${cur?.createdByData?.firstName} ${cur?.createdByData?.lastName} `}</span> &nbsp; &nbsp;
                    <span style={{ fontSize: '12px' }}> {getFormattedDateTime(cur?.createdAt, 'llll')}</span>
                  </div>
                  <p className="my-3 p-0" style={{ lineHeight: '16px' }} dangerouslySetInnerHTML={{ __html: cur?.note }}>
                    {/* {plainTextNotes} */}
                  </p>
                  <hr style={{ margin: '4px 0' }} />
                </div>
              )
            })
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
              No Notes found
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TicketNotes

import React from 'react'
import moment from 'moment'
import { useOutletContext } from 'react-router-dom'

const AgentJobDetails = () => {
    const { data } = useOutletContext()
    const { teamMemberInputs } = data

    return (
        <>
            <div className='row p-5'>
                <div className="col-lg-6">
                    <p className="">
                        <span className="fw-bold">Designation :</span> <span className="text-muted">{teamMemberInputs?.designation || "--"}</span>
                    </p>
                    <p className="">
                        <span className="fw-bold">Date Of Joining :</span>
                        {/* <span className="text-muted">{teamMemberInputs?.dateOfJoining ? `${moment(teamMemberInputs?.dateOfJoining).format('DD-MM-YYYY')}` : '--'}</span> */}
                        <span className="text-muted ms-1">
                            {teamMemberInputs?.dateOfJoining
                                ? moment(teamMemberInputs?.dateOfJoining, 'DD-MM-YYYY', true).isValid()
                                    ? moment(teamMemberInputs?.dateOfJoining, 'DD-MM-YYYY').format('DD-MM-YYYY')
                                    : '--'
                                : '--'}
                        </span>
                    </p>
                    <p className="">
                        <span className="fw-bold">Employee Type :</span>
                        <span className="text-muted ms-1">{teamMemberInputs?.empType === 'PERMANENT' ? 'Permanent' : teamMemberInputs?.empType === 'CONTRACTUAL' ? 'Contractual' : 'Trainee'}</span>
                    </p>
                </div>
                <div className="col-lg-6">
                    <p className="">
                        <span className="fw-bold">Role :</span> <span className="text-muted">{teamMemberInputs?.fullDetails?.roleData?.roleTitle || "--"}</span>
                    </p>
                    <p className="">
                        <span className="fw-bold">shift :</span> <span className="text-muted">{teamMemberInputs?.allDetails?.shiftData?.name || "--"}</span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default AgentJobDetails

import React from 'react'
import { Button, TextField, MenuItem } from "@mui/material";
const currencies = [
    {
        value: "USD",
        label: "Collaborator",
    },
    {
        value: "EUR",
        label: "Support Agent",
    },
];
const EmailForm = () => {
    return (
        <div className="contact">
            <div className="container bg">
                <div className="row">
                    <div className="row my-3">
                        <div className="col-lg-4 mt-4">
                            <TextField
                                id="standard-multiline-flexible"
                                label="Name"
                                // multiline
                                className="w-75"
                                maxRows={4}
                                variant="standard"
                                defaultValue=""
                            />
                        </div>
                        <div className="col-lg-4 mt-4">
                            <TextField
                                id="standard-multiline-flexible"
                                label="Your support email"
                                // multiline
                                className="w-75"
                                maxRows={4}
                                variant="standard"
                                defaultValue=""
                            />
                        </div>
                        <div className="col-lg-4 mt-4">
                            <TextField
                                id="standard-select-currency"
                                select
                                label="Assign to Group"
                                defaultValue=""
                                className="w-75"
                                variant="standard"
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-lg-11 mt-5">
                            <TextField
                                id="standard-select-currency"
                                select
                                label="Link this support email with a product"
                                defaultValue=""
                                className="w-100"
                                variant="standard"
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>

                    <div className="col-lg-11 mb-5">
                        <div className="row">
                            <div className="d-flex justify-content-between">
                                <div className="mt-4">
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 mt-4 text-center">
                                        <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                                    </div>
                                    <div className="col-lg-6 mt-4 text-center">
                                        <Button className="px-4 add-cc-btn mt-3">Save</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailForm
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getnotificationList, readSingle, readAll } from '../../../repository/notificationRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { addCount } from '../../../Slices/notificationCountSlice'

const UseNotification = () => {
    const { selector: userPermission } = useReduxStore('auth')
    const [notificationData, setNotificationData] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
    const [totalCount, settotalCount] = useState(0)
    const [spinner, setSpinner] = useState(false)
    const [status, setStatus] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        notificationList()
    }, [controller, status])


    const notificationList = async () => {
        const payLoad = {
            receiver: userPermission?.userInfo?._id,
            page: controller.page,
            count: controller.rowsPerPage,
            isReaded: status
        }
        setSpinner(true)
        try {
            let response = await getnotificationList(payLoad)
            setNotificationData(response)
            settotalCount(response.totalCount)
            dispatch(addCount(response?.unreadedCount))
            setSpinner(false)
        }
        catch (error) {
            setNotificationData([])
            setSpinner(false)
        }
    }

    const readsingleNotification = async (id) => {
        const payLoad = {
            reader_id: userPermission?.userInfo?._id,
            notification_id: id,
        }
        setSpinner(true)
        try {
            await readSingle(payLoad)
            notificationList()
            setSpinner(false)
        }
        catch (error) {
            toast.error(error.message)
            setSpinner(false)
        }
    }
    const readAllNotification = async () => {
        const payLoad = {
            user_id: userPermission?.userInfo?._id,
        }
        setSpinner(true)
        try {
            await readAll(payLoad)
            notificationList()
            setSpinner(false)
        }
        catch (error) {
            toast.error(error.message)
            setSpinner(false)
        }
    }
    const handlePagination = (obj) => {
        setController(obj)
    }
    return {
        data: {
            notificationData,
            spinner,
            controller,
            totalCount,
            status
        },
        method: {
            handlePagination,
            readsingleNotification,
            readAllNotification,
            setStatus
        }
    }
}

export default UseNotification

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { addProject, deleteProject, getProjectDetails, updateProject } from '../../../repository/projectRepo'
import { toast } from 'react-toastify'
import { requiredMessage } from '../../../utils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useToGetTicketList } from '../../../repository/ticketRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { useToGetUserFullList } from '../../../repository/teamManagementRepo'

const useProjectAddUpdate = () => {
  const { selector: user } = useReduxStore('auth')
  const { projectId } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [controller] = useState({ page: 1, rowsPerPage: 10 })
  const [spinner, setSpinner] = useState(false)
  // const [agentList, setAgentList] = useState([])
  const [projectInputs, setProjectInputs] = useState({
    project_id: '',
    name: '',
    coordinator: '',
    notes: '',
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const [error, setError] = useState({})
  const cacheTime = { cacheTime: 10000 }

  // Agent List
  const { data: fullAgentList,} = useToGetUserFullList(cacheTime)

  useEffect(() => {
    if (projectId !== "add" && fullAgentList) {
      getDetails();
    }
  }, [projectId, fullAgentList]);

  // Listing payLoad
  let payload = {
    // project_id: projectId,
    // project_id: projectId,
    count: controller.rowsPerPage,
    page: controller.page,
    project_id: projectId
  }
  // Fetch api
  const {
    data: ticketList,
    error: ticketError,
    isFetching: ticketIsFetching,
    refetch: refetchTicket,
  } = useToGetTicketList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTicket()
  }, [controller])

  const getDetails = async () => {
    try {
      setSpinner(true);
      let response = await getProjectDetails(projectId);
      const { channel, project_id, name, notes, isDeleted, coordinator } = response.data;
      const newCordinator = fullAgentList?.data?.filter(item => item?._id === coordinator?._id) || [];

      setProjectInputs({
        project_id: project_id,
        name: name,
        channel: channel,
        coordinator: newCordinator || [],
        notes: notes,
        isDeleted: isDeleted
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setProjectInputs((prevProps) => ({ ...prevProps, [name]: value }))
    if (typeof value === 'string' && !value.trim().length) {
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
    }
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    if (typeof value === 'string' && !value.trim().length) {
      setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
    }
    // !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    project_id: requiredMessage('project id'),
    name: requiredMessage('project name'),
    // coordinator: requiredMessage('coordinator'),
    notes: requiredMessage('notes'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!projectInputs.project_id) {
      errorObj['project_id'] = errorMessage['project_id']
    }
    if (!projectInputs.name) {
      errorObj['name'] = errorMessage['name']
    }
    // if (!projectInputs.coordinator) {
    //   errorObj['coordinator'] = errorMessage['coordinator']
    // }
    if (!projectInputs.notes?.trim()?.length) {
      errorObj['notes'] = errorMessage['notes']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    let payload = {
      project_id: projectInputs.project_id,
      name: projectInputs.name,
      coordinator: projectInputs?.coordinator.length ? projectInputs?.coordinator[0]?._id : null,
      notes: projectInputs.notes,
    }
    try {
      let response = await addProject(payload)
      navigate('/projects')
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  // Handle Update submit data..!
  const handleupdateSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        _id: projectId,
        project_id: projectInputs?.project_id,
        name: projectInputs.name,
        coordinator: projectInputs?.coordinator.length ? projectInputs?.coordinator[0]?._id : null,
        notes: projectInputs.notes,
      }

      let response = await updateProject(payload)
      navigate('/projects')
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }
  // handel delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteProject({
        _id: deleteModal?.data._id,
        isDeleted: !deleteModal.data.isDeleted,
      })
      setDeleteModal({ data: '', open: false })
      setTimeout(() => {
        toast.success(response.message)
      },)
      navigate('/projects')
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      spinner,
      error,
      projectInputs,
      state,
      controller,
      isLoading: spinner || ticketIsFetching,
      totalCount: ticketError?.message === "No record found" ? 0 : ticketList?.totalCount || 0,
      ticketList: ticketError?.message === "No record found" ? [] : ticketList?.data,
      ProjectPermission: user,
      deleteModal,
      agentList: fullAgentList?.data
    },
    methods: {
      setProjectInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      setDeleteModal,
      handleDelete,
    },
  }
}

export default useProjectAddUpdate

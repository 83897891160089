import { ApiStatus } from "../constants"
import { axiosInstance, baseUrl } from "./auth"



export const getnotificationList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/notification/list`,body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}


export const readSingle=async(body)=>{
    const response=await axiosInstance.post(`${baseUrl}/notification/readSingle`,body)
    if(response.data.code !==ApiStatus.success){
        throw new Error (response.data.message||ApiStatus.serverError)
    }
    return response.data

}
export const readAll=async(body)=>{
    const response=await axiosInstance.post(`${baseUrl}/notification/readAll`,body)
    if(response.data.code !==ApiStatus.success){
        throw new Error (response.data.message||ApiStatus.serverError)
    }
    return response.data

}

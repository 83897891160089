import  { useState } from 'react'
import { useToGetDashboardData } from '../../../repository/useDashboardRepo'
const useDashboard = () => {

    const [spinner] = useState(false);

    const cacheTime = { cacheTime: 10000 };

    // Fetch api
    const {
        data: dashboardDateList,
        // error: dashboardError,
        isFetching: dashboardIsFetching,
        // refetch: refetchTicket,
    } = useToGetDashboardData({
        enabled: false,
        ...cacheTime,
    });

    return (
        {
            data: {
                isLoading: spinner || dashboardIsFetching,
                // totalCount: dashboardDateList?.totalCount || 0,
                dashboardDateList: dashboardDateList?.data || [],
                // ticketModal,
                // deleteModal,
                // search,
                // statusType,

            },
            methods: {

            },
        }
    )
}

export default useDashboard
import React from 'react'
import { Button, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import FormGroup from '@mui/material/FormGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import Tab from '@mui/material/Tab'
// import TabContext from '@mui/lab/TabContext'
// import TabList from '@mui/lab/TabList'
// import TabPanel from '@mui/lab/TabPanel'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
// import Menu from '@mui/material/Menu'
// import Fade from '@mui/material/Fade'
// import { FormLabel, Select, Switch } from '@mui/material'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import FormControl from '@mui/material/FormControl'
import Table from '../../../components/Table/Table'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import NavDropdown from 'react-bootstrap/NavDropdown'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
]

const CreateNewShift = () => {
  const Navigate = useNavigate()

  // const TableHead = [
  //   { label: 'Dates', id: '' },
  //   { label: 'Name', id: '' },
  //   { label: 'Action', id: '' },
  // ]
  // const TableRow = [
  //   {
  //     date: 'Jan 01',
  //     name: 'New Year’s Day',
  //   },
  //   {
  //     date: 'Jan 16',
  //     name: 'Birthday of Martin Luther King Jr',
  //   },
  //   {
  //     date: 'Feb 20',
  //     name: 'Washington’s Birthday',
  //   },
  //   {
  //     date: 'May 28',
  //     name: 'Memorial Day',
  //   },
  //   {
  //     date: 'Jul 04',
  //     name: 'Independence Day',
  //   },
  //   {
  //     date: 'Sep 03',
  //     name: 'Labor Day',
  //   },
  //   {
  //     date: 'Oct 08',
  //     name: 'Columbus Day',
  //   },
  //   {
  //     date: 'Nov 11',
  //     name: 'Veterans Day',
  //   },
  //   {
  //     date: 'Nov 22',
  //     name: 'Thanksgiving Day',
  //   },
  //   {
  //     date: 'Dec 25',
  //     name: 'Christmas Day',
  //   },
  // ]
  const TableHeadd = [
    { label: <Checkbox {...label} className="m-0 p-0 ms-2 ps-1" />, id: '' },
    { label: 'Agent Name', id: '' },
    { label: 'Email ID', id: '' },
    { label: 'Team', id: '' },
  ]
  const TableRoww = [
    {
      name: 'Bob',
      email: 'bob@gmail.com',
      team: 'Billing',
    },
    {
      sr: 1,
      name: 'Sachin',
      email: 'sachin@gmail.com',
      team: ' Customer Support ',
    },
    {
      sr: 1,
      name: 'Rana',
      email: 'rana@gmail.com',
      team: 'Escalations',
    },
  ]
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  // const [value, setValue] = React.useState('1')

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }
  const [openmodal, setmodalOpen] = React.useState(false)
  const handlemodalOpen = () => setmodalOpen(true)
  const handlemodalClose = () => setmodalOpen(false)
  return (
    <>
      <div className="contact">
        <div className="container bg">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {/* <div>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="me-2 w-100"
                          aria-label="Search"
                        />
                      </div>
                      <div>
                        <Button variant="primary4" className="px-4 ms-3">
                          <SearchIcon />
                        </Button>
                      </div> */}
              </div>
              <div className="d-flex">
                {/* <div>
                <Button variant="primary1">
                  <ExitToAppIcon /> Export
                </Button>{" "}
              </div> */}
                <div className="me-4 mt-4">
                  <Button className="dropDownButtons11 ms-4" onClick={handlemodalOpen}>
                    <AddIcon /> Add Agent
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row mb-3">
              <div className="col-lg-4 mt-4">
                <TextField
                  id="standard-multiline-flexible"
                  label="Name of the shift"
                  // multiline
                  className="w-75"
                  maxRows={4}
                  variant="standard"
                  defaultValue=""
                />
              </div>
              <div className="col-lg-4 mt-4">
                <TextField id="standard-select-currency" select label="Select the time zone" defaultValue="" className="w-75" variant="standard">
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="mt-5">
              <div>
                <h6>Select the working days</h6>
                <div className="d-flex">
                  <div className="my-3">
                    <span className="diseblesun sun">Sun</span>
                    <span className="diseblemon mon">Mon</span>
                    <span className="diseblemon tue">Tue</span>
                    <span className="diseblemon wed">Wed</span>
                    <span className="diseblemon thu">Thu</span>
                    <span className="diseblemon fri">Fri</span>
                    <span className="diseblesun sat">Sat</span>
                  </div>
                  <div className="my-3">
                    <p>(5 days selected)</p>
                  </div>
                </div>
              </div>
              <div>
                <h6>Enter the working hours</h6>
                <div className="d-flex">
                  <div className="my-3">
                    <div className="d-flex">
                      <div className="col-lg-4">
                        <p>Monday</p>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input type="time" className="w-100 h-75" /> &nbsp; &nbsp; <span className="mt-2">to</span> &nbsp; &nbsp;
                        <input type="time" className="w-100 h-75" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-lg-4">
                        <p>Tuesday</p>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input type="time" className="w-100 h-75" /> &nbsp; &nbsp; <span className="mt-2">to</span> &nbsp; &nbsp;
                        <input type="time" className="w-100 h-75" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-lg-4">
                        <p>Wednesday</p>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input type="time" className="w-100 h-75" /> &nbsp; &nbsp; <span className="mt-2">to</span> &nbsp; &nbsp;
                        <input type="time" className="w-100 h-75" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-lg-4">
                        <p>Thursday</p>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input type="time" className="w-100 h-75" /> &nbsp; &nbsp; <span className="mt-2">to</span> &nbsp; &nbsp;
                        <input type="time" className="w-100 h-75" />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-lg-4">
                        <p>Friday</p>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <input type="time" className="w-100 h-75" /> &nbsp; &nbsp; <span className="mt-2">to</span> &nbsp; &nbsp;
                        <input type="time" className="w-100 h-75" />
                      </div>
                    </div>
                  </div>
                  <div className="ps-5 pt-4 table-link">
                    <sup>
                      <ContentCopyIcon style={{ width: '16px', height: '16px' }} />
                      &nbsp;Copy to all
                    </sup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mb-5">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className="mt-4">{/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}</div>
                  <div className="row">
                    <div className="col-lg-5 mt-4 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                    </div>
                    <div className="col-lg-7 mt-4 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Create Shift</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openmodal} onClose={handlemodalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title">
            <h2>Add agents</h2>
          </Typography>
          <Typography id="modal-modal-description" className="w-100">
            <div className="row py-2">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div>
                    <NavDropdown title="All Agents" className="dropDownButtons" id="navbarScrollingDropdown">
                      <NavDropdown.Item href="#action3" className="">
                        All Agent
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action4">Billing</NavDropdown.Item>
                      <NavDropdown.Item href="#action5">Customer Support</NavDropdown.Item>
                      <NavDropdown.Item href="#action6">Escalations</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="d-flex">
                  {/* <div>
                <Button variant="primary1">
                  <ExitToAppIcon /> Export
                </Button>{" "}
              </div> */}
                  <div className="d-flex">
                    <div>
                      <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                    </div>
                    <div>
                      <Button variant="primary4" className="px-4 ms-3">
                        <SearchIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-2">
                <table className="table">
                  <Table TableHeadData={TableHeadd} />
                  <tbody>
                    {TableRoww?.map((item) => {
                      return (
                        <tr
                          className="text-start rounded-row "
                          style={{
                            borderRadius: '50px',
                            backgroundColor: '#fff',
                          }}
                        >
                          <td>
                            <Checkbox {...label} />
                          </td>
                          <td className="table-link" onClick={() => Navigate(``)}>
                            {item.name}
                          </td>
                          <td className="">{item.email}</td>
                          <td className="">{item.team}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className="">{/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}</div>
                  <div className="row">
                    <div className="col-lg-5 mt-2 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                    </div>
                    <div className="col-lg-7 mt-2 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Create Shift</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default CreateNewShift

import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";


// report/getReport List
export const getReportList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/report/getReport`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetReportList(params, options = {}) {
    return useQuery(['GET_REPORT_LIST',], () => getReportList(params), options)
}


export const ExportReportList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/report/export`, params);
    if (response.data.code !== 200) {
      throw new Error(response.data.message);
    }
    return response.data;
  };
  
// getUserUtilisationReport
export const getUserUtilisationReport = async params => {
    const response = await axiosInstance.post(`${baseUrl}/report/getUserUtilisationTrackingReport`, params);
    if (response.data.code !== 200) {
      throw new Error(response.data.message);
    }
    return response.data;
  };
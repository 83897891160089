import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getTeamList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/team/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetTeamList(params, options = {}) {
    return useQuery(['GET_Team_LIST',], () => getTeamList(params), options)
}


// 
export const getAllTeamList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/team/fullList`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}

// Add Team
export const addTeam = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/team`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Team Details
export const getTeamDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/team/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Team Activity
export const getTeamActivity = async (params) => {
    // const response = await axiosInstance.get(`${baseUrl}/history/detail/${params}/${type}`,);
    const response = await axiosInstance.post(`${baseUrl}/history/detail`,params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}
export const getReportManager = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/listReportingManager`,params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}



// Update Team
export const updateTeam = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/team`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete team
export const deleteTeam = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/team/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

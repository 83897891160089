import React from 'react'
import Table from '../../components/Table/Table'
import { Button } from '@mui/material'
// import AddIcon from '@mui/icons-material/Add'
import useApproveTask from './Hooks/useApproveTask'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
// import SearchAndStatusFilter from '../../components/SearchAndStatusFilter'
import Modal from '../../components/Modal'
import { useNavigate } from 'react-router-dom'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'
// import TagForm from "./TagForm";

export const ApproveTask = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const { data, methods } = useApproveTask()
  const navigate = useNavigate()

  const { taskList, totalCount, controller, isLoading,  approvalModal } = data
  const { handlePagination, setApprovalModal } = methods

  const TableHead = [
    { label: 'Task Name', id: '' },
    { label: 'Project Name', id: '' },
    { label: 'Assign To', id: '' },
    { label: 'Priority', id: '' },
    { label: 'Due Date', id: '' },
    { label: 'Status', id: '' },
    { label: 'Tags', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {/* <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} /> */}
            </div>
            {/* <div className="d-flex align-items-end">
              <Button className="dropDownButtons11 ms-4" onClick={() => setSkillFormModel((prev) => ({ ...prev, open: true }))} startIcon={<AddIcon />}>
                Add New
              </Button>
            </div> */}
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} />
                    <tbody>
                      {taskList?.map((item, index) => {
                        return (
                          <tr
                            className="text-start rounded-row "
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td className="table-link" onClick={() => navigate(`/task/taskdetails/${item?._id}`)}>
                              {item.taskName || '--'}
                            </td>
                            <td className="table-link" onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}>
                              {item.project || '--'}
                            </td>
                            <td className="">{item.assignTo || '--'}</td>
                            <td>{item.priority || '--'}</td>
                            <td>{item.assignEndTime ? getFormattedDateTime(item?.assignEndTime, 'lll') : '--'}</td>
                            <td>{item.status || '--'}</td>
                            <td>
                              <span className="d-flex flex-wrap">
                                {item?.tagsData.length
                                  ? item?.tagsData?.map((tag) => {
                                    return (
                                      <div key={tag?._id} className="tag-list-chips cursor-pointer me-1" style={{ color: '#000', background: tag?.color, border: tag?.color }}>
                                        <span style={{ fontSize: '11px' }}>{tag?.tagName}</span>
                                      </div>
                                    )
                                  })
                                  : '--'}
                              </span>

                            </td>
                            <td>
                              {/* {item.approvalStatus === "PENDING" ? */}
                              <div className="dropdown d-flex p-0 align-items-center" style={{ minHeight: '50px' }}>
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <li style={{ cursor: 'pointer' }}
                                    onClick={() => setApprovalModal((prev) => ({ ...prev, data: item?._id, open: true }))}
                                    className="dropdown-item">
                                    Approve
                                  </li>
                                </ul>
                              </div>
                              {/* :
                                <b>{item.approvalStatus}</b>
                              } */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!taskList?.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Approval Modal */}
      <Modal
        size="md"
        show={approvalModal.open}
        onHide={() => setApprovalModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Approval?</h5>}
        bodyContent={
          <div className="">
            <p>Click Approve or Reject to change the approval status of task. </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={() => setApprovalModal((prev) => ({ ...prev, status: "APPROVED" }))}>
                APPROVE
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setApprovalModal((prev) => ({ ...prev, status: "REJECTED" }))} data-bs-dismiss="modal">
                REJECTED
              </Button>
            </div>
          </div>
        }
      />


      {/* Form Modal */}
      {/* <Modal
        size="sm"
        show={skillFormModel?.open}
        onHide={() => {
          setSkillFormModel({ data: '', open: false })
          setSkillInputs({ skillName: '' })
        }}
        headerContent={<h5>Skills</h5>}
        bodyContent={<CreateSkill data={FormData} methods={FormMethods} />}
      /> */}

      {/* Delete Modal */}
      {/* <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      /> */}
    </>
  )
}

import React from 'react'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DateInput from "../../components/DateInput"
import MultipleSelect from "../../components/MultipleSelect"
const AttendanceFilters = (props) => {
    const { data, methods } = props
    const { attandenceListInput, agentList, attendancePermission } = data
    const {
        resetFilter,
        applayFilter,
        setAttendanceListInput,
    } = methods
    const {viewSelf, } = attendancePermission?.userInfo?.permission?.attendance
    const statusList = [
        { color: '#97FFF4', title: 'Miss', value: 'MIS' },
        { color: '#793FDF', title: 'Present', value: 'P' },
        { color: '#97FFF4', title: 'Absent', value: 'A' },
        { color: '#97FFF4', title: 'Short', value: 'Short' },
    ]

    return (
        <>
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                <div className="filter">
                    <div className="col-lg-11 mx-auto mb-2">
                        {viewSelf.value ? "" : <MultipleSelect
                            label={"Agent"}
                            multiple={true}
                            options={agentList}
                            searchBy={"name"}
                            keyName={"name"}
                            name={"agent"}
                            onChange={(e) => setAttendanceListInput((prevProps) => ({ ...prevProps, agent: e }))}
                            value={"_id"}
                            selectedValues={attandenceListInput?.agent}
                        />}
                        <MultipleSelect
                            label={"Status"}
                            multiple={true}
                            options={statusList}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"status"}
                            onChange={(e) => setAttendanceListInput((prevProps) => ({ ...prevProps, status: e }))}
                            value={"value"}
                            selectedValues={attandenceListInput?.status}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small" // You can customize the size here
                            variant="outlined" // You can customize the variant here
                            className="w-100 form-field-design" // You can customize the width here
                            // isRequired={true}
                            Label={'Start Date'}
                            SelectedDate={attandenceListInput?.startDate || null}
                            handleDateChange={(date) => {
                                setAttendanceListInput((pre) => ({ ...pre, startDate: date }))
                            }}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small" // You can customize the size here
                            variant="outlined" // You can customize the variant here
                            className="w-100 form-field-design" // You can customize the width here
                            // isRequired={true}
                            Label={'End Date'}
                            SelectedDate={attandenceListInput?.endDate || null}
                            handleDateChange={(date) => {
                                setAttendanceListInput((pre) => ({ ...pre, endDate: date }))
                            }}
                        />
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </>
    )
}

export default AttendanceFilters
import React from "react";
import { Button, TextField } from "@mui/material";


const NewReport = () => {
  return (
    <>
    <div className="contact">
    <div className="container bg">
      <div className="row">
        <div className="row my-3">
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-multiline-flexible"
              label="Responses Report"
              // multiline
              className="w-75"
              maxRows={4}
              variant="standard"
              defaultValue=""
            />
          </div>
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-multiline-flexible"
              label="Support Handling Time"
              // multiline
              className="w-75"
              maxRows={4}
              variant="standard"
              defaultValue=""
            />
          </div>
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-multiline-flexible"
              label="Ticket Volume"
              // multiline
              className="w-75"
              maxRows={4}
              variant="standard"
              defaultValue=""
            />
          </div>
        </div>
        {/* <div className="row my-3">
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-select-currency"
              select
              label="Roles"
              defaultValue=""
              className="w-75"
              variant="standard"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-select-currency"
              select
              label="Group (Team)"
              defaultValue=""
              className="w-75"
              variant="standard"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-select-currency"
              select
              label="Status"
              defaultValue=""
              className="w-75"
              variant="standard"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div> */}
        <div className="col-lg-11 mb-5">
          <div className="row">
            <div className="d-flex justify-content-between">
            <div className="mt-4">
            {/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}
            </div>
              <div className="row">
              <div className="col-lg-6 mt-4 text-center">
                <Button className="px-4 add-cc-btn mt-3">
                  Cancel
                </Button>
              </div>
              <div className="col-lg-6 mt-4 text-center">
                <Button className="px-4 add-cc-btn mt-3">Create</Button>
              </div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </>
  )
}

export default NewReport

import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
    name: "auth",
    initialState: {},
    reducers: {
        add(state, action) {
            return state = action.payload;
        },
        update(state, action) {
            state.user = { ...state.user, ...action.payload }
        },
        clearStore(state, action) {
            // Object.keys(state).forEach(key => {
            //     delete state[key];
            // });
            return state={}
        }
    },
});
export const { add, update, clearStore } = authSlice.actions;
export default authSlice.reducer;
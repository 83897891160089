import { environment } from "../env/environment";
import axios from "axios";
import authHeader from "./auth-header";
export const baseUrl = `${environment.baseUrl}/api/v1`
export const axiosInstance = axios.create({ baseUrl })

// handle Request and add globle token in header section
axiosInstance.interceptors.request.use(async (config) => {
    let token = authHeader()
    // console.log(token, "tokennnn")
    if (token) {
        config.headers.Authorization = token.Authorization
    }
    return config
}, (error) => {
    return Promise.reject(error)
}
)

// Add a 401 response interceptor and handle
// axiosInstance.interceptors.response.use(function (response) {

//     let refreshToken = authHeader()
//     console.log(refreshToken, "refreshToken")

//     console.log(response, "responc errorauthh")
//     if (response.data.code == 401) {

//     }
//     if (response.data.code == 405) {
//         localStorage?.clear()
//         window.location = '/';
//     }
//     return response;
// }, function (error) {
//     if (error) {
//         if (error.response && error.response.status === 401) {
//             localStorage?.clear()
//             window.location = '/';
//         }
//         if (error.response && error.response.status === 404 && error.code !== "ERR_NETWORK") {
//             error.message = "Server is offline. Please try again later."
//         }
//         return Promise.reject(error);
//     }
// });

// user/checkResetTokenExpiration

export const UserTokenRefresh = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/refreshToken`, params);
    if (response.data.code !== 200) {
        if (response.data.code == 403) {
            setTimeout(() => {
                localStorage?.clear()
                window.location = '/';
                sessionStorage.setItem("authError", JSON.stringify("Your login session has timed out. Please log in again to continue."))
            }, 1000)
        }
        throw new Error(response.data.message)
    }
    return response.data;
};

// User Login 
export const loginUser = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/login`, params);
        if (response.data.code !== 200) {
            // console.log(response.data, "response.data");
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (error) {
        // console.log(error,'error')
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        // throw new Error('Network error: Unable to reach the server');
        throw new Error(error?.message);
    }
};



// User Logout
export const logOutUser = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/logOut`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

// Forgot Password
export const forgotPassword = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/forgotPassword`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

// Reset Password
export const resetPassword = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/resetPassword`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

// User Accept Invitation
export const AcceptInvetation = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/acceptInvitation`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

// User Re-Send Invite Email
export const ReSendInvetation = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/reSendInviteEmail`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};
// Change Password
export const UserChangePassword = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/changePassword`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// get userProfile
export const getUserProfile = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/user/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

export const profilePath = `${environment?.baseUrl}/upload/profileImg/`
export const personalDoc = `${environment?.baseUrl}/upload/personalDoc/`
// update userProfile
export const updateUserProfile = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/user`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Add Role And Permission
export const addRole = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/role`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Role List
export const roleList = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/role/list`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// without pagination list /role/fullList
export const getAllRoleList = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/role/fullList`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Role Details
export const getRoleDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/role/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update Role
export const updateRole = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/role`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Timezone List

export const timeZoneList = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/timezone/fullList`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// user/checkResetTokenExpiration
export const ChecqResetToken = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/checkResetTokenExpiration`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from "react-query";
import { ApiStatus } from "../constants";

// time log
export const dashboardData = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/dashboard`, body);
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError);
    }
    return response.data;
};
export function useToGetDashboardData(params, options = {}) {
    return useQuery(["GET_Dashboard_Data"], () => dashboardData(params), options);
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useToGetTeamList, deleteTeam } from "../../../../repository/teamRepo";
import { toast } from "react-toastify";
import useReduxStore from "../../../../hooks/useReduxStore";
import { useDispatch } from "react-redux";
import { addFilter } from "../../../../Slices/FiltersSlice";

const useTeamList = () => {
  const { selector: GroupFilter } = useReduxStore('filters')
  const dispatch = useDispatch()
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
  const [filterAction, setFilterAction] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [search, setSearchText] = useState(GroupFilter?.Group?.filters?.search || "");
  const [statusType, setStatusType] = useState(GroupFilter?.Group?.filters?.statusType ||'false');
  const [groupDetailsModal, setGroupDetailsModal] = useState({
    open: false,
    data: {},
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  });
  const cacheTime = { cacheTime: 10000 };
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    isDeleted: statusType==='true', 
    search: search
  }
  // if (typeof statusType == "boolean") payload.isDeleted = statusType 
  // if (typeof (statusType=='true') == "boolean") payload.isDeleted = statusType
  
  useEffect(() => {
    // Dispatch the addFilter action with the current 'Group' page and the filter 
    dispatch(addFilter({
      page: 'Group', filter: {
        search: search,
        statusType: statusType,
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, statusType]);
  // Fetch api
  const {
    data: teamList,
    error: teamError,
    isFetching: teamIsFetching,
    refetch,
  } = useToGetTeamList(payload,
    {
      enabled: false,
      ...cacheTime,
    }
  );

  useEffect(() => {
    refetch();
  }, [controller, filterAction]);

  // Error Toast Message
  useEffect(() => {
    if (teamError) {
      // toast.error(teamError?.message);
    }
  }, [teamError]);

  //   Reset filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev);
    setStatusType()
    setSearchText("")
  };

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetch()
    });
  };
  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj);
  };

  // Handle Search
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    // Perform any other actions you need here
  };

  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true);
      let response = await deleteTeam({
        _id: deleteModal?.data,
        isDeleted: true,
      });
      toast.success(response.message);
      setDeleteModal({ data: "", open: false });
      refetch();
    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  };

  return {
    data: {
      controller,
      isLoading: spinner || teamIsFetching,
      totalCount: teamError?.message === "No record found" ? 0 : teamList?.totalCount || 0,
      teamList: teamError?.message === "No record found" ? [] : teamList?.data || [],
      search,
      statusType,
      groupDetailsModal,
      deleteModal
    },
    methods: {
      handlePagination,
      resetFilter,
      applayFilter,
      handleSearchChange,
      setStatusType,
      setGroupDetailsModal,
      setDeleteModal,
      handleDelete
    },
  };
};

export default useTeamList;

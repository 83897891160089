/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useToGetTaskList, changeTaskStatus, deleteTask, addTask, uploadFile, DeleteFile, getTaskDetailsApi, updateTask, ExportTasktList, PermanentDelete, importTaskFile, useToGetFullIdleUser, shiftBreak, addBreak } from '../../../repository/taskRepo'
import { useToGetAllTagList } from '../../../repository/tagsRepo'
import { useToGetUserFullList } from '../../../repository/teamManagementRepo'
import { useToGetFullPriject } from '../../../repository/projectRepo'
// ===========================================================================================
import { userFullList } from '../../../repository/teamManagementRepo'
import { allTagList } from '../../../repository/tagsRepo'
import { allProjectList } from '../../../repository/projectRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { requiredMessage, requiredSelect } from '../../../utils'

import FileImg from '../../../assets/icons/file.png'
import VideoIcon from '../../../assets/icons/video.png'
import PDFIcon from '../../../assets/icons/pdf.png'
import ExcelIcon from '../../../assets/icons/excel.png'
import TextFileIcon from '../../../assets/icons/textfile.png'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../Slices/FiltersSlice'
import moment from 'moment'
import { priorityList, taskTypeList, SubTaskList, TaskCategoryList, complexityList, hoursTypeList, workTypeList } from '../../../constants'
import { getAllRoleList } from '../../../repository/auth'
const useTask = () => {
  const cacheTime = { cacheTime: 10000 }
  const { selector: user } = useReduxStore('auth')
  const { selector: TaskFilter } = useReduxStore('filters')
  const dispatch = useDispatch()
  const { state } = useLocation()
  // Assign TO List
  const { data: assignToListData, isLoading: isassignToLoading } = useToGetUserFullList(cacheTime)

  const [controller, setController] = useState({ page: TaskFilter?.Task?.filters?.page || 1, rowsPerPage:TaskFilter?.Task?.filters?.rowsPerPage|| 10 })
  const [filterAction, setFilterAction] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [teamModal, setTeamModal] = useState({
    open: false,
    data: [],
  })
  // console.log(controller, 'controller')
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const [permanentDeleteModal, setPermanentDeleteModal] = useState({
    open: false,
    data: "",
  })
  const [breakModal, setBreakModal] = useState({
    open: false,
    data: "",
    break: false,
    disabled: false
  })
  const [idleUsersModal, setIdleUsersModal] = useState({
    open: false,
    data: "",
  })

  const [taskModel, setTaskModel] = useState({
    open: false,
    data: '',
  })
  const [agentList, setAgentList] = useState([])
  const [allTagsList, setAllTagList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [allRolesList, setAllRoleList] = useState([])
  const [shiftList, setShiftList] = useState([])
  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')
  const [isReset, setIsReset] = useState(TaskFilter?.Task?.filters?.isReset);

  const [taskListInput, setTaskListInput] = useState({
    role: [],
    agent: getInitialAgent(),
    ticket: [],
    status: getInitialStatus(),
    priority: TaskFilter?.Task?.filters?.priority || [],
    Projects: TaskFilter?.Task?.filters?.Projects || [],
    startDate: getInitialStartDate(),
    endDate: TaskFilter?.Task?.filters?.endDate || null,
    workType: getInitialWorktype()
  })

  let initialTaskType = 'ALL_TASKS';
  // if (state?.filterTypes?.due_today) {
  //   initialTaskType = "DUE_TODAY";
  // }
  // if (state?.filterTypes?.overdue_task) {
  //   initialTaskType = "MY_OVERDUE";
  // }
  const [taskType, setTaskType] = useState(initialTaskType);
  function getInitialAgent() {

    if (state?.filterTypes) {
      return assignToListData?.data?.filter(item => item?._id === user?.userInfo?._id)
    }

    if (TaskFilter?.Task?.filters?.agent) {
      return TaskFilter.Task.filters.agent;
    }
    return []
  }

  function getInitialStatus() {
    if (state?.filterTypes?.completed) {
      return [{ color: '#793FDF', title: 'Completed', value: 'COMPLETED' }]
    }
    if (state?.filterTypes?.my_task || state?.filterTypes?.workType_backlog || state?.filterTypes?.workType_changeRequest || state?.filterTypes?.workType_delegate || state?.filterTypes?.workType_planned || state?.filterTypes?.workType_new || state?.filterTypes?.workType_rework) {
      return []
    }
    if (TaskFilter?.Task?.filters?.status) {
      return TaskFilter.Task.filters.status;
    }
    return []
  }

  function getInitialStartDate() {
    if (state?.filterTypes) {
      return new Date()
    }
    if (isReset && TaskFilter?.Task?.filters?.startDate == null) {
      return null;
    }
    if (!isReset && TaskFilter?.Task?.filters?.startDate) {
      return TaskFilter.Task.filters.startDate;
    }
    return new Date();
  }

  function getInitialWorktype() {
    if (state?.filterTypes?.completed) {
      return []
    }
    if (state?.filterTypes?.workType_backlog) {
      return [{ title: "Backlog", value: "Backlog" }]
    }
    if (state?.filterTypes?.workType_changeRequest) {
      return [{ title: "Change Request", value: "Change Request" }]
    }
    if (state?.filterTypes?.workType_delegate) {
      return [{ title: "Delegate", value: "Delegate" }]
    }
    if (state?.filterTypes?.workType_planned) {
      return [{ title: "Planned", value: "Planned" }]
    }
    if (state?.filterTypes?.workType_new) {
      return [{ title: "New", value: "New" }]
    }
    if (state?.filterTypes?.workType_rework) {
      return [{ title: "Rework", value: "Rework" }]
    }
    if (TaskFilter?.Task?.filters?.workType) {
      return TaskFilter.Task.filters.workType;
    }
    return [];
  }
  // ========================Form State==================================

  const [taskFormInputs, setTaskFormInputs] = useState([
    {
      taskName: '',
      project_id: '',
      priority: '',
      assignTo_id: '',
      description: '',
      tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      notes: '',
      ReminderAction: true,
      attachments: [],

      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ])

  const [errors, setErrors] = useState([
    {
      taskName: null,
      project_id: null,
      priority: '',
      assignTo_id: '',
      description: '',
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      ReminderAction: true,
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ]);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [importTaskModal, setImportTaskModal] = useState({
    open: false,
    data: ""
  })


  useEffect(() => {
    if (taskModel?.data) {
      TaskDetails()
    }
  }, [taskModel.data])

  const TaskDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTaskDetailsApi(taskModel?.data)
      let newPriority = priorityList.filter(item => item.value === response?.data?.priority)

      let newTaskType = taskTypeList.filter(item => item.value === response?.data?.taskType)
      let newWorkType = workTypeList.filter(item => item.value === response?.data?.workType)
      let newsubTask = SubTaskList.filter(item => item.value === response?.data?.subTask)
      let newTaskCategory = TaskCategoryList.filter(item => item.value === response?.data?.taskCategory)
      let newComplexity = complexityList.filter(item => item.value === response?.data?.complexity)
      let newHoursType = hoursTypeList.filter(item => item.value === response?.data?.hoursType)
      setTaskFormInputs([
        {
          taskName: response?.data?.taskName,
          project_id: [response?.data?.project_id],
          priority: newPriority,
          assignTo_id: [response?.data?.assignTo_id],
          description: response?.data?.description,
          tags: response?.data?.tags,
          assignStartTime: new Date(response?.data?.assignStartTime),
          assignEndTime: new Date(response?.data?.assignEndTime),
          minutesBeforeReminder: response?.data?.reminder?.minutesBefore,
          notes: response?.data?.notes,
          ReminderAction: response?.data?.reminder?.reminderOn,
          attachments: response?.data?.attachments,
          taskType: newTaskType,
          workType: newWorkType,
          subTask: newsubTask,
          taskCategory: newTaskCategory,
          complexity: newComplexity,
          hoursType: newHoursType,
        },
      ])

      // toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    // Perform any other actions you need here
  }

  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    search: search,
    otherFilters: taskType,
  }
  if (typeof statusType == 'boolean') payload.isActive = statusType
  else {
    statusType === 'false' && (payload.isActive = false)
    statusType === 'true' && (payload.isActive = true)
  }

  if (taskListInput?.agent?.length > 0) {
    payload.assignTo_ids = taskListInput.agent.map((item) => item._id)
  }
  if (taskListInput?.status?.length > 0) {
    payload.status = taskListInput.status.map((item) => item.value)
  }
  if (taskListInput?.priority?.length > 0) {
    payload.priority = taskListInput.priority.map((item) => item.value)
  }
  if (taskListInput?.workType?.length > 0) {
    payload.workType = taskListInput.workType.map((item) => item.value)
  }
  if (taskListInput?.role?.length > 0) {
    payload.role_id = taskListInput?.role[0]?._id;
  }
  if (taskListInput?.Projects?.length > 0) {
    payload.project_ids = taskListInput.Projects.map((item) => item._id)
  }

  if (taskListInput?.startDate) {
    payload.startDate = moment(taskListInput.startDate).format("YYYY-MM-DD")
  }
  if (taskListInput?.endDate) {
    payload.endDate = moment(taskListInput.endDate).format("YYYY-MM-DD")
  }

  useEffect(() => {
    // Dispatch the addFilter action with the current 'Task' page and the filter
    if (taskListInput.startDate) {
      setIsReset(false)
    }
    dispatch(addFilter({
      page: 'Task', filter: {
        agent: taskListInput.agent,
        status: taskListInput.status,
        priority: taskListInput.priority,
        Tags: taskListInput.Tags,
        Projects: taskListInput.Projects,
        startDate: isReset === true ? null : taskListInput.startDate,
        endDate: taskListInput.endDate,
        taskType: taskType,
        isReset: isReset,
        workType: taskListInput?.workType,
        page:controller?.page,
        rowsPerPage:controller?.rowsPerPage
      }
    }));
  }, [isReset, taskListInput, taskType,controller?.page,controller?.rowsPerPage]);

  // Fetch api
  const {
    data: taskList,
    error: taskError,
    isFetching: taskIsFetching,
    refetch: refetchTask,
  } = useToGetTaskList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTask() // Call the renamed refetch function
  }, [controller, filterAction, taskType,])

  // Export Task Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportTasktList(payload)

      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Status
  const status = async (id, toggle) => {
    try {
      setSpinner(true)
      let response = await changeTaskStatus({
        _id: id,
        isActive: toggle,
      })
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteTask({
        _id: deleteModal?.data?._id,
        isDeleted: deleteModal?.data?.isDeleted,
      })
      setDeleteModal({ data: '', open: false })
      refetchTask()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Filter's Api's
  useEffect(() => {
    getAgentList()
    // AllSkillList();
    // getAllGroupList();
    getAllRolesList()
    getAllProjectList()
    getAllTagList()
  }, [])

  // Get All Agent  List
  const getAgentList = async (groupId) => {
    // const teamIds = groupId?.map(item => item._id);
    // let payload = {
    //     team_id: teamIds,
    // };
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response.data)

      //   response?.data?.forEach((item) => {
      //     if (item?._id === user?.userInfo?._id) {
      //       setTaskListInput((prevProps) => ({ ...prevProps, agent: [item] }));
      //     }
      //   });
    } catch (error) {
      setAgentList([])
      setTaskListInput((prevProps) => ({ ...prevProps, agent: '' }))
    }
    setSpinner(false)
  }

  // Get All Tag List
  const getAllTagList = async () => {
    try {
      setSpinner(true)
      let response = await allTagList()
      setAllTagList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Project List
  const getAllProjectList = async () => {
    try {
      setSpinner(true)
      let response = await allProjectList()
      setProjectList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // Get All Role List
  const getAllRolesList = async () => {
    try {
      setSpinner(true)
      let response = await getAllRoleList()
      setAllRoleList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // useEffect(() => {
  //   if (state?.filterTypes?.completed) {
  //     if (!state?.filterTypes?.my_task) {
  //       setTaskListInput((prev) => ({
  //         ...prev,
  //         status: [{ color: '#793FDF', title: 'Completed', value: 'COMPLETED' }]
  //       }));
  //       refetchTask();
  //     }
  //   }
  // }, [state?.filterTypes?.completed]);

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchTask()
    });
  }
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
    setTaskType("ALL_TASKS")
    setIsReset(true);
    setTaskListInput((prevTaskListInput) => ({
      ...prevTaskListInput,
      agent: [],
      ticket: [],
      status: [],
      priority: [],
      Projects: [],
      startDate: null,
      endDate: null,
      role: "",
      workType: []
    }));

  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  // PermanentDelete
  const taskPermanentDelete = async () => {
    try {
      setSpinner(true)
      const response = await PermanentDelete({ task_id: permanentDeleteModal.data })
      refetchTask()
      toast.success(response.message)
      setPermanentDeleteModal({ open: false, data: "", })
    } catch (error) {

    }
    setSpinner(false)
  }
  // ===============================Add/Update Form function's=======================================

  // Fetch Filter api

  // Project List
  const { data: projectListData,isLoading: isprojectLoading } = useToGetFullPriject(cacheTime)

  // Tag List
  const { data: tagListData, isLoading: isTagLoading } = useToGetAllTagList(cacheTime)

  // Idle User List
  const { data: IdleUserListData, isLoading: isIdleUserLoading, refetch: idleFetched } = useToGetFullIdleUser(cacheTime, { enabled: idleUsersModal?.open });

  useEffect(() => {
    if (idleUsersModal?.open === true) {
      idleFetched();
    }
  }, [idleUsersModal?.open]);

  const handleFileChange = async (event, taskIndex) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) return toast.warn('You can only select up to 5 files.');
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', 'TASK');
    try {
      setSpinner(true);
      let response = await uploadFile(fd);

      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        if (updatedTasks[taskIndex].attachments.length >= 5) {
          toast.warn('You can only select up to 5 files.');
        } else {
          const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
          response?.data?.forEach((cur, index) => {
            if (newSelectedFiles.length >= 5) {
              toast.warn('You can only select up to 5 files.');
            } else {
              newSelectedFiles?.push(cur?.db_name);
            }
          });
          if (newSelectedFiles.length <= 5) {
            updatedTasks[taskIndex].attachments = newSelectedFiles;
          }
        }
        toast.success(response.message);
        return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  };

  // Handle Remove File
  const handleRemoveFile = async (taskIndex, fileIndex, file) => {
    let data = {
      fileName: file,
      fileType: 'TASK',
    }

    try {
      setSpinner(true)
      let response = await DeleteFile(data)
      toast.success(response.message)
      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs]
        updatedTasks[taskIndex].attachments = updatedTasks[taskIndex].attachments.filter((_, i) => i !== fileIndex)
        return updatedTasks
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Import File
  // handle new ticket Files
  const handleTaskImportFileChange = async (files) => {
    // const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      const fileExtension = fileData.name.split(".").pop();
      if (
        !["xls", "xlsx"].includes(fileExtension)
      ) {
        return toast.warn(`Invalid XLS file.`);
      }
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TASK_IMPORT");

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      // toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);
    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }

  // handle Remove File
  const handleTaskImportRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }

  // file shhow
  const fileType = (file, fileIndex, fileType) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          {/* <img key={fileIndex} src={fileType === "TIMELOG" ? uploadFileUrlTimeLog + file : uploadFileUrlTask + file} alt="" width="100%" height="100%" /> */}
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }

  const handleInputChange = (event, taskIndex) => {
    const { value, name, checked } = event.target;

    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs];
      updatedTasks[taskIndex][name] = value;

      if (name === 'ReminderAction') {
        updatedTasks[taskIndex][name] = checked;
        if (checked) {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 15;
        } else {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 0;
        }
      }
      // Clear errors for the current field
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (name !== "ReminderAction") {
          updatedErrors[taskIndex][name] = null;
        }
        return updatedErrors;
      });

      return updatedTasks;
    });
  };

  // Handle Date
  const handleDateChange = (date, field, taskIndex) => {
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks[taskIndex][field] = date
      return updatedTasks
    })

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[taskIndex][field] = null;
      return updatedErrors;
    });
  }

  const addMultipleTask = () => {
    if (taskFormInputs.length < 5) {
      setTaskFormInputs((prevTaskFormInputs) => [
        ...prevTaskFormInputs,
        {
          taskName: '',
          project_id: '',
          priority: '',
          assignTo_id: '',
          description: '',
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 0,
          notes: '',
          ReminderAction: false,
          attachments: [],
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])
      // Clear errors for the newly added task
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          taskName: null,
          project_id: null,
          priority: '',
          assignTo_id: '',
          description: '',
          assignStartTime: null,
          assignEndTime: null,
          ReminderAction: false,
          minutesBeforeReminder: 15, // Reset ReminderAction error
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ]);

    }
  }

  // Remove Task
  const removeTask = (taskIndex) => {
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks.splice(taskIndex, 1) // Remove the task at the specified index
      return updatedTasks
    })
  }
  // Close modal And Empty
  const CloseFucntion = (type) => {
    if (type === 'taskModel') {
      setTaskModel({ open: false, data: '' })
      setTaskFormInputs([
        {
          taskName: '',
          project_id: '',
          priority: '',
          assignTo_id: '',
          description: '',
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 15,
          notes: '',
          ReminderAction: true,
          attachments: [],
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])
    }
  }

  // task error message
  const errorMessage = {
    taskName: requiredMessage('task name'),
    description: requiredMessage('description'),
    project_id: requiredSelect('project'),
    priority: requiredSelect('priority'),
    assignTo_id: requiredSelect('assign'),
    assignStartTime: requiredSelect('assign start time'),
    assignEndTime: requiredSelect('assign end time'),
    ReminderAction: requiredSelect("reminder"),
    taskType: requiredSelect("task type"),
    workType: requiredSelect("work type"),
    subTask: requiredSelect("sub task"),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
  }

  // Error handling function
  const validateAllTasks = () => {
    const errorObjArray = [];

    for (let i = 0; i < taskFormInputs.length; i++) {
      const task = taskFormInputs[i];
      const errorObj = {};

      if (!task.taskName) {
        errorObj['taskName'] = errorMessage['taskName'];
      }
      if (!task.description) {
        errorObj['description'] = errorMessage['description'];
      }
      if (!task.project_id) {
        errorObj['project_id'] = errorMessage['project_id'];
      }
      if (!task.priority) {
        errorObj['priority'] = errorMessage['priority'];
      }
      if (!task.assignTo_id) {
        errorObj['assignTo_id'] = errorMessage['assignTo_id'];
      }
      if (!task.assignStartTime) {
        errorObj['assignStartTime'] = errorMessage['assignStartTime'];
      }
      if (!task.assignEndTime) {
        errorObj['assignEndTime'] = errorMessage['assignEndTime'];
      }
      if (!task.taskType) {
        errorObj['taskType'] = errorMessage['taskType'];
      }
      if (!task.workType) {
        errorObj['workType'] = errorMessage['workType'];
      }
      if (!task.subTask) {
        errorObj['subTask'] = errorMessage['subTask'];
      }
      if (!task.taskCategory) {
        errorObj['taskCategory'] = errorMessage['taskCategory'];
      }
      if (!task.complexity) {
        errorObj['complexity'] = errorMessage['complexity'];
      }
      if (!task.hoursType) {
        errorObj['hoursType'] = errorMessage['hoursType'];
      }


      if (task.ReminderAction) {
        delete errorObj.ReminderAction;
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }

    setErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };


  // ADD or Submit Task
  const submitTask = async () => {
    const isValid = validateAllTasks();
    if (!isValid) return toast.warn("Please fill all required fields")
    const convertData = (originalData) => {
      return originalData.map((task) => {
        return {
          taskName: task?.taskName,
          project_id: task?.project_id[0]?._id,
          priority: task?.priority[0]?.value,
          assignTo_id: task?.assignTo_id[0]?._id,
          description: task?.description,
          tags: task?.tags?.map((tag) => tag?._id),
          assignStartTime: task?.assignStartTime,
          assignEndTime: task?.assignEndTime,
          minutesBeforeReminder: task?.minutesBeforeReminder,
          notes: task?.notes,
          attachments: task?.attachments,
          taskType: task?.taskType[0]?.value,
          workType: task?.workType[0]?.value,
          subTask: task?.subTask[0]?.value,
          taskCategory: task?.taskCategory[0]?.value,
          complexity: task?.complexity[0]?.value,
          hoursType: task?.hoursType[0]?.value,
        };
      });
    };
    const newData = convertData(taskFormInputs);
    let newTaskFormInputs = [...newData]
    newTaskFormInputs?.map((cur, index) => {
      delete cur?.ReminderAction
      return cur
    })

    // return
    let data = {
      data: newTaskFormInputs,
    }
    setSpinner(true)
    try {
      let response = await addTask(data)
      if (response?.code === 200) {
        setTaskModel({ data: "", open: false })
        setTaskFormInputs([
          {
            taskName: '',
            project_id: '',
            priority: '',
            assignTo_id: '',
            description: '',
            tags: [],
            assignStartTime: null,
            assignEndTime: null,
            minutesBeforeReminder: 15,
            notes: '',
            ReminderAction: true,
            attachments: [],
            workType: "",
            taskType: "",
            subTask: "",
            taskCategory: "",
            complexity: "",
            hoursType: "",
          },
        ])
      }
      refetchTask()
      CloseFucntion("taskModel")
      toast.success(response.message)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      toast.error(error.message)
    }
  }

  // Udate Task
  const submitUpdateTask = async () => {
    const isValid = validateAllTasks();
    if (!isValid) return toast.warn("Please fill all required fields")
    const convertData = (originalData) => {
      return originalData.map((task) => {
        return {
          taskName: task?.taskName,
          project_id: task?.project_id[0]?._id,
          priority: task?.priority[0]?.value,
          assignTo_id: task?.assignTo_id[0]?._id,
          description: task?.description,
          tags: task?.tags?.map((tag) => tag?._id),
          assignStartTime: task?.assignStartTime,
          assignEndTime: task?.assignEndTime,
          minutesBeforeReminder: task?.minutesBeforeReminder,
          notes: task?.notes,
          attachments: task?.attachments,
          taskType: task?.taskType[0]?.value,
          workType: task?.workType[0]?.value,
          subTask: task?.subTask[0]?.value,
          taskCategory: task?.taskCategory[0]?.value,
          complexity: task?.complexity[0]?.value,
          hoursType: task?.hoursType[0]?.value,
        };
      });
    };
    const newData = convertData(taskFormInputs);

    let newTaskFormInputs = [...newData]
    newTaskFormInputs?.map((cur, index) => {
      delete cur?.ReminderAction
      return cur
    })
    let payload = {
      ...newTaskFormInputs[0],
      _id: taskModel?.data,
    }
    setSpinner(true)
    try {
      let response = await updateTask(payload)
      setTaskModel({ data: "", open: false })
      setTaskFormInputs([
        {
          taskName: '',
          project_id: '',
          priority: '',
          assignTo_id: '',
          description: '',
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 15,
          notes: '',
          ReminderAction: true,
          attachments: [],
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])
      refetchTask()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Import task Api
  const TaskUpload = async () => {
    if (!selectedFiles.length) return toast.warn("Please select file")
    setSpinner(true)
    try {
      let payload = {
        fileName: selectedFiles[0]
      }
      let response = await importTaskFile(payload)

      // navigate('/attendance')
      setImportTaskModal({ open: false, data: "" })
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  const shifts = async () => {
    try {
      setSpinner(true)
      let response = await shiftBreak(user?.userInfo?._id)
      setShiftList(response.data)
      let filterData = response?.data?.find((item) => item.status === 'END')
      setBreakModal((pre) => ({ ...pre, open: true, data: '', break: false }))
      if (filterData) {
        setBreakModal((pre) => ({ ...pre, data: { breakId: filterData?.breakId }, break: true, disabled: true }))
      }
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }


  const addBreaks = async (type) => {
    let payload = {
      userId: user?.userInfo?._id,
      breakId: breakModal?.data?.breakId,
      type: type
    }
    // console.log(payload,'payload')
    // setBreakModal((pre)=>({...pre,break:type=='START'?true:false}))
    // return
    try {
      setSpinner(true)
      let response = await addBreak(payload)
      toast.success(response.message)
      if (type !== 'END'){
        shifts()
      }
      // setBreakModal((pre) => ({ ...pre, open: false, break: type == 'START' ? true : false }))
      setBreakModal((pre) => ({ ...pre,break: type === 'START' ? true : false }))
      if (type === 'END') {
        // shifts()
        setBreakModal((pre) => ({ ...pre, data: '', open: false, break: false, disabled: false }))
      }
      // setBreakModal((pre)=>({...pre,open:false,data:'',break:type=='START'?true:false}))
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }


  return {
    data: {
      controller,
      isLoading: spinner || taskIsFetching || isTagLoading || isassignToLoading || isprojectLoading || isIdleUserLoading,
      totalCount: taskError?.message === 'No record found' ? 0 : taskList?.totalCount || 0,
      taskList: taskError?.message === 'No record found' ? [] : taskList?.data || [],
      teamModal,
      deleteModal,
      search,
      statusType,
      taskListInput,
      agentList,
      allTagsList,
      projectList,
      allRolesList,
      permanentDeleteModal,
      taskType,
      taskTypeList,
      SubTaskList,
      TaskCategoryList,
      complexityList,
      hoursTypeList,
      workTypeList,
      // ===============Form data=================================
      taskFormInputs,
      projectListData: projectListData?.data || [],
      tagListData: tagListData?.data || [],
      assignToListData: assignToListData?.data || [],
      taskModel,
      TaskPermission: user,
      errors,
      selectedFiles,
      importTaskModal,
      idleUsersModal,
      IdleUserList: IdleUserListData?.data || [],
      breakModal,
      shiftList
    },
    methods: {
      setTaskModel,
      handlePagination,
      resetFilter,
      applayFilter,
      setTeamModal,
      status,
      handleDelete,
      setDeleteModal,
      handleSearchChange,
      setStatusType,
      setTaskListInput,
      taskPermanentDelete,
      setPermanentDeleteModal,
      setTaskType,
      // resend
      // ================== Form Method's============================
      handleFileChange,
      handleRemoveFile,
      setTaskFormInputs,
      addMultipleTask,
      handleInputChange,
      handleDateChange,
      removeTask,
      submitTask,
      CloseFucntion,
      submitUpdateTask,
      setErrors,
      Export,
      handleTaskImportFileChange,
      fileType,
      handleTaskImportRemoveFile,
      setSelectedFiles,
      setImportTaskModal,
      TaskUpload,
      setIdleUsersModal,
      setBreakModal,
      shifts,
      addBreaks,
      setShiftList
    },
  }
}

export default useTask

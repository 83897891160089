/* eslint-disable react-hooks/exhaustive-deps */
import  { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage, } from '../../../utils'
import {  useParams } from 'react-router-dom'
import { addNote, getNoteDetails, updateNote, useToGetNoteList, deleteNote } from '../../../repository/noteRepo'

const useProjectNote = () => {
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  const [filterAction, setFilterAction] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')
  const cacheTime = { cacheTime: 10000 }
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  // Form States
  const [noteInputs, setNoteInputs] = useState({
    note: '',
  })
  const [error, setError] = useState({})

  const [noteFormModel, setNoteFormModel] = useState({
    open: false,
    data: '',
  })

  const { projectId } = useParams()
  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    // isDeleted: false,
    search: search,
    type: 'PROJECT',
    project_id: projectId
  }
  // if (typeof statusType == 'boolean') payload.isActive = statusType
  if (typeof statusType == "boolean") payload.isDeleted = statusType
  else {
    statusType === 'false' && (payload.isDeleted = false)
    statusType === 'true' && (payload.isDeleted = true)
  }

  // Fetch api
  const {
    data: noteList,
    error: noteError,
    isFetching: noteIsFetching,
    refetch,
  } = useToGetNoteList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetch()
  }, [controller, filterAction])

  // Error Toast Message
  useEffect(() => {
    if (noteError) {
      // toast.error(noteError?.message);
    }
  }, [noteError])

  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
  }
  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetch()
    });
  }

  // Handle Search
  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    // Perform any other actions you need here
  }
  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }
  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteNote({
        _id: deleteModal?.data,
        isDeleted: true,
      })
      toast.success(response.message)
      setDeleteModal({ data: '', open: false })
      refetch()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // ======================================================Add /Update/ section===============================================================

  useEffect(() => {
    if (noteFormModel.data) {
      getDetails()
    }
  }, [noteFormModel])

  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getNoteDetails(noteFormModel.data)
      const { note } = response.data
      setNoteInputs({
        note: note,
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNoteInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  // Handle Update submit data..!

  const handleupdateSubmit = async (event) => {
    event.preventDefault()

    if (!isValid()) return

    // const plainTextNotes = removeHtmlTags(noteInputs.notes)
    setSpinner(true)

    try {
      let payload = {
        _id: noteFormModel?.data,
        note: noteInputs.note,
        type: 'PROJECT',
        project_id: projectId
        // createdby_id: '',
      }
      let response = await updateNote(payload)
      setNoteFormModel((prev) => ({ ...prev, open: false }))
      setNoteInputs({ note: "" })
      refetch()
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
      setSpinner(false)
    }
    setSpinner(false)
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    note: requiredMessage('tag name'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!noteInputs.note) {
      errorObj['note'] = errorMessage['note']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    let payload = {
      note: noteInputs.note,
      type: 'PROJECT',
      project_id: projectId
    }
    try {
      let response = await addNote(payload)
      setNoteFormModel((prev) => ({ ...prev, open: false }))
      setNoteInputs({ note: "" })
      refetch()
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (err) {
      toast.error(err.message)
    }

    setSpinner(false)
  }

  return {
    data: {
      controller,
      isLoading: spinner || noteIsFetching,
      totalCount: noteError?.message === 'No record found' ? 0 : noteList?.totalCount || 0,
      noteList: noteError?.message === 'No record found' ? [] : noteList?.data || [],
      search,
      deleteModal,
      statusType,
      // =======================
      error,
      noteInputs,
      // state,
      noteFormModel,
    },
    methods: {
      handlePagination,
      resetFilter,
      applayFilter,
      handleSearchChange,
      setStatusType,
      handleDelete,
      setDeleteModal,
      refetch,

      // ======================
      setNoteInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      setError,
      setNoteFormModel,
    },
  }
}


export default useProjectNote

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import useUsersTimeLog from './Hooks/useUsersTimeLog'
import moment from 'moment/moment'
// import TimeLogFilters from './TimeLogFilters'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
// import Modal from '../../components/Modal'
// import { uploadFileUrlTimeLog } from '../../repository/taskRepo'
import UserTimeLogFilter from './UserTimeLogFilter'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'

// import FileImg from '../../assets/icons/file.png'
// import VideoIcon from '../../assets/icons/video.png'
// import PDFIcon from '../../assets/icons/pdf.png'
// import ExcelIcon from '../../assets/icons/excel.png'
// import TextFileIcon from '../../assets/icons/textfile.png'

const UserTimeLog = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const navigate = useNavigate()
  const { data, methods } = useUsersTimeLog()
  const { controller, isLoading, totalCount, TimeLogList, timeLogPermission } = data
  const { setController, Export } = methods
  const {  export: exportFile } = timeLogPermission?.userInfo?.permission?.timelog

  // const clickHandler = (event, timeLogId) => {
  //   if (event.detail === 2) {
  //     getLogDetails(timeLogId)
  //     setTimeLogDetailsModel((prev) => ({ ...prev, timeLogId: timeLogId, open: true }))
  //   }
  // }


  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {/* <SearchAndStatusFilter
                // onSearchChange={handleSearchChange}
                // setStatusType={setStatusType}
                // search={search}
                // statusType={statusType}
                // reset={resetFilter}
                // applayFilter={applayFilter}
                /> */}
            </div>
            {exportFile?.value && <div className="ms-2">
              <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
            </div>}
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-10 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  {TimeLogList?.map((item) => {
                    const calculateDuration = (duration) => {
                      const hours = Math.floor(duration.asHours());
                      const remainingMinutes = duration.minutes();
                      let result = '';
                      if (hours > 0) {
                        result += `${hours} hour${hours > 1 ? 's' : ''}`;
                      }
                      if (remainingMinutes > 0) {
                        result += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
                      }
                      return result || `${0} min`;
                    };

                    let spareHours = (item.punchedHrs - item.workDuration) > 0 ? item.punchedHrs - item.workDuration : 0
                    spareHours = moment.duration(spareHours, 'minutes');
                    const duration = moment.duration(item.workDuration, 'minutes');
                    const punchduration = moment.duration(item.punchedHrs, 'minutes');
                    const productiveDuration = moment.duration(item.productiveDuration, 'minutes');
                    const unProductiveDuration = moment.duration(item.unProductiveDuration, 'minutes');
                    return (
                      <div key={item._id}>
                        <div className="d-flex flex-column p-2 pointer" onDoubleClick={() => navigate(`/time-log/${item?.user_id}`, { state: { userDetails: item } })}>
                          <div className="d-flex justify-content-between">
                            <span>
                              <b>{item.name} |{item?.designation && <span> {item?.designation} | </span>} <span>{item?.taskCount || 0} Task</span></b>
                            </span>
                            <span>
                              <b>Logged Date :</b> {getFormattedDateTime(item?.reportDate,'DD MMM YYYY')}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="mt-3">
                              <b>Punched Hour's :</b> {calculateDuration(punchduration)}
                            </div>
                            <span className="table-link" onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}>
                              {item.project}
                            </span>
                            <span className='mt-3'>
                              <b>Total Worked Time :</b> {calculateDuration(duration)}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="mt-3">
                              <b style={{ background: "#20b60a54" }} className='border p-2 rounded-pill '>{calculateDuration(productiveDuration)}</b>
                              <b style={{ background: "#ff161654" }} className='border p-2 rounded-pill'> {calculateDuration(unProductiveDuration)}</b>
                            </div>

                            <span className="table-link" onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}>
                              {item.project}
                            </span>
                            <span className='mt-3'>
                              <b>Spare Hour's :</b> {calculateDuration(spareHours)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="mt-3">
                              <b >Approved : </b>{item?.approvedTimelogs}
                            </div>
                            <div className="mt-3">
                              <b >UnApproved : </b>{item?.unApprovedTimelogs}
                            </div>
                          </div>
                        </div>
                        <hr style={{ margin: '0.01rem' }} />
                      </div>
                    );
                  })}

                  {/* </tbody>
                    </table> */}
                  {!TimeLogList.length && <div className="text-center pt-3">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={setController} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 ticet mt-2">
            <UserTimeLogFilter data={data} methods={methods} />
          </div>
        </div>
      </div>

      {/* <Modal
          size="xl"
          CloseFucntion={() => setTimeLogDetailsModel((prev) => ({ ...prev, open: false }))}
          show={timeLogDetails.open}
          onHide={() => setTimeLogDetailsModel((prev) => ({ ...prev, open: true }))}
          headerContent={
            <h5 style={{ fontWeight: '600' }} className="mt-2">
              Time Log Details : {`${timeLogDetails?.data?.user_id?.firstName || "--"} ${timeLogDetails?.data?.user_id?.lastName || ""}`}
            </h5>
          }
          bodyContent={
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <span>
                  <b>{timeLogDetails.data.taskName}</b>
                </span>
                <span>
                  <b>Logged Date :</b> {moment(timeLogDetails.data?.reportDate).format('DD MMM YYYY')}
                </span>
              </div>
              <div className="time-log-detials-border" style={{ borderTop: '0' }}>
                <span>
                  <b>Description</b>
                </span>
                <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.task_id?.description }}></span>
              </div>
              <div className="time-log-detials-border" style={{ borderTop: '0' }}>
                <span>
                  <b>Notes</b>
                </span>
                <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.task_id?.notes }}></span>
              </div>
              <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                <span>
                  <b>Assigned Duration :</b> {moment(timeLogDetails.data?.assignStartTime).format('lll')} - {moment(timeLogDetails.data?.assignEndTime).format('lll')}
                </span>
                <span>
                  <b>Worked Duration :</b> {moment(timeLogDetails.data?.actualStartTime).format('lll')} - {moment(timeLogDetails.data?.actualEndTime).format('lll')}
                </span>
              </div>
              <div className="d-flex justify-content-between time-log-detials-border mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <div>
                  <span>
                    <b>Project :</b>{' '}
                  </span>
                  <span className="table-link" onClick={() => navigate('')}>
                    {timeLogDetails.data?.project_id?.name}
                  </span>
                </div>
                <span>
                  <b>Total Worked Time :</b> {timeLogDetails.data?.workDuration + ' min'}
                </span>
              </div>
              <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                <span>
                  <b>Remarks</b>
                </span>
                <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.notes }}></span>
              </div>
  
              <div className="col-lg-12 px-0">
                {timeLogDetails?.data?.attachments?.length > 0 ? (
                  <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                    <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                      {timeLogDetails?.data?.attachments?.map((file, fileIndex) => (
                        <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => setShowPDFModal({ open: true, data: file })}>
                          {fileType(file, fileIndex)}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                    <span>No Attachments</span>
                  </div>
                )}
              </div>
  
            </div>
          }
        /> */}
      {/*   
  <Modal
          size="lg"
          show={showPDFModal.open}
          onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
          headerContent={''}
          bodyContent={
            <div className="">
              {showPDFModal?.data ? (
                showPDFModal?.data?.split('.').pop() === 'pdf' ? (
                  <iframe src={uploadFileUrlTimeLog + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
                ) : (
                  <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlTimeLog + showPDFModal?.data} alt="" />
                )
              ) : (
                <div>No attachment available</div>
              )}
            </div>
          }
        /> */}

    </>
  )
}

export default UserTimeLog
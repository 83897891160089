/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import { addTag, getTagDetails, updateTag } from '../../../../repository/tagsRepo'
import useTagList from './useTagList'

const useTagAddUpdate = () => {
  const { methods } = useTagList()
  // const { state } = useLocation()
  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(false)
  const [tagInputs, setTagInputs] = useState({
    tagName: '',
    color: ""
  })
  const [error, setError] = useState({})

  const [tagFormModel, setTagFormModel] = useState({
    open: false,
    data: '',
  })

  useEffect(() => {
    if (tagFormModel.data) {
      getDetails()
    }
  }, [tagFormModel])

  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTagDetails(tagFormModel.data)
      const { tagName, color } = response.data
      setTagInputs({
        tagName: tagName,
        color: color
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setTagInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    tagName: requiredMessage('tag name'),
    color: requiredMessage('Color'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!tagInputs.tagName) {
      errorObj['tagName'] = errorMessage['tagName']
    }
    if (!tagInputs.color) {
      errorObj['color'] = errorMessage['color']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    let payload = {
      tagName: tagInputs.tagName,
      color: tagInputs.color
    }
    try {
      let response = await addTag(payload)
      setTagFormModel((prev) => ({ ...prev, open: false }))
      methods.refetch()
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  // Handle Update submit data..!
  const handleupdateSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        _id: tagFormModel?.data,
        tagName: tagInputs.tagName,
        color: tagInputs.color
      }
      let response = await updateTag(payload)
      setTagFormModel((prev) => ({ ...prev, open: false }))
      methods.refetch()
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      spinner,
      error,
      tagInputs,
      // state,
      tagFormModel,
    },
    methods: {
      setTagInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      navigate,
      setTagFormModel,
    },
  }
}

export default useTagAddUpdate

import React from 'react'
import { Button, FormLabel, FormControl,} from '@mui/material';
import TextField from "../../../components/TextField"


const TagForm = ({ data, methods }) => {

    const { error, tagInputs, tagFormModel } = data
    const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, setTagInputs, setTagFormModel } = methods

    const ColorArr = [
        { value: "#FFCE3C", },
        { value: "#E73E29", },
        { value: "#EE7110", },
        { value: "#3F9F4A", },
        { value: "#27938E", },
        { value: "#1E84D0", },
        { value: "#9A61C7", },
        { value: "#CC53B4", },
        { value: "#B0B7BA", },
        { value: "#FFBCB2", },
        { value: "#FFBF84", },
        { value: "#8ED290", },
        { value: "#7AD1CD", },
        { value: "#86C8F7", },
        { value: "#D4AFF6", },
        { value: "#F7AAE7", },
    ]
    return (
        <>
            <div className='col-12'>
                <TextField
                    Lable="Tag Name"
                    Name="tagName"
                    variant="outlined"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={tagInputs?.tagName}
                    width={100}
                    Error={error?.tagName}
                />
            </div>
            <div className='d-flex flex-column mt-4'>


                <FormControl>
                    <FormLabel className='mt-3' id="demo-radio-buttons-group-label">Color <span className='text-danger'>*</span></FormLabel>
                    <div className='d-flex flex-wrap'>
                        {ColorArr.map((item) =>
                            <div className='tags-radio-box-cover' style={{ border: item?.value === tagInputs.color ? "2px solid #03A9F4" : "none" }}>
                                <div className='tags-radio-box' style={{ background: item?.value }}>
                                    <label htmlFor={item.value} style={{ height: "35px", width: "35px" }}></label>
                                    <input type="radio" id={item?.value}
                                        style={{ accentColor: item?.value, outline: 'none' }} className='tags-color-style'
                                        onChange={(e) => setTagInputs((prevProps) => ({ ...prevProps, color: e.target.value }))}
                                        name="color"
                                        value={item?.value} />
                                </div>
                            </div>
                        )}
                    </div>
                    {error?.color && <span className='text-danger'>{error?.color}</span>}
                </FormControl>

                {/* <FormControl>
                    <FormLabel className='mt-3' id="demo-radio-buttons-group-label">Color <span className='text-danger'>*</span></FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => setTagInputs((prevProps) => ({ ...prevProps, color: e.target.value }))}
                    >
                        <div>
                            {ColorArr.map((item) =>
                                <FormControlLabel value={item?.value} control={<Radio sx={{
                                    color: item?.value,
                                    '&.Mui-checked': {
                                        color: item?.value,
                                    },
                                }} />}
                                />
                            )}
                        </div>
                    </RadioGroup>
                </FormControl> */}
            </div>

            <div className=" d-flex justify-content-end ">
                <div className="m-2">
                    <Button variant="outlined" onClick={() => { setTagFormModel({ data: "", open: false }); setTagInputs({ tagName: "", color: "" }) }}>Cancel</Button>
                </div>
                {tagFormModel?.data ? <div className="m-2">
                    <Button variant="contained" onClick={handleupdateSubmit}>Update</Button>
                </div>
                    :
                    <div className="m-2">
                        <Button variant="contained" onClick={handleAddSubmit}>Create</Button>
                    </div>
                }

            </div>
        </>
    )
}

export default TagForm
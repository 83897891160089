import React from 'react'
import { Outlet, useLocation, NavLink, useNavigate, useParams } from 'react-router-dom'
import { Tabs, Tab, AppBar, } from '@mui/material';
import { Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../components/Spinner/Spinner';
import useProjectAddUpdate from './Hooks/useProjectAddUpdate';
import Modal from '../../components/Modal'

const ProjectDetailsMainPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { projectId } = useParams()

    const { data, methods } = useProjectAddUpdate()
    const { setDeleteModal,
        handleDelete, } = methods
    const { projectInputs, isLoading,
        deleteModal
    } = data

    const allTabs = [
        {
            label: "Tickets",
            value: `/projects/${projectId}/ticket`,
        },
        {
            label: "Notes",
            value: `/projects/${projectId}/note`,
        },
        // {
        //     label: "Tags",
        //     value: `/setting/tag-list`,
        // },
    ];

    return (
        <>
            {isLoading && <Spinner />}
            <ToastContainer />
            <div className="contact">
                <div className="container bg">
                    <div className="row">
                        <div className="col-lg-12 pt-4">

                            <div className="dropdown d-flex pe-3 align-items-center justify-content-end" style={{ minHeight: '50px' }}>
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li style={{ cursor: 'pointer' }}
                                        onClick={() => navigate(`/projects/project-form/${projectId}`, { state: { projectId: projectId } })}
                                        className="dropdown-item">
                                        Edit
                                    </li>
                                    <li className="dropdown-item"
                                        onClick={() => setDeleteModal({ data: { _id: projectId, isDeleted: projectInputs?.isDeleted }, open: true })}
                                    >
                                        {projectInputs?.isDeleted ? 'Recover' : 'Delete'}
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-12 pt-4 mt-2 ms-4">
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <p className=""><span className="fw-bold">Project Name :</span> <span className="text-muted">{projectInputs?.name ? projectInputs?.name : "--"}</span></p>
                                        {/* <p className=""><span className="fw-bold">Emails :</span> <span className="text-muted">emily.garcia@freshdesk.com</span></p> */}
                                        <p className=""><span className="fw-bold">Project ID :</span> <span className="text-muted">{projectInputs?.project_id ? projectInputs?.project_id : "--"}</span></p>
                                        {/* <p className=""><span className="fw-bold">Channel :</span> <span className="text-muted">{projectInputs?.channel ? projectInputs?.channel : "--"}</span></p> */}
                                    </div>
                                    <div className="col-lg-6 pe-5">
                                        <p className=""><span className="fw-bold">Notes :</span > <span className="text-muted" dangerouslySetInnerHTML={{ __html: projectInputs.notes || "--" }} />
                                            {/* {projectInputs?.notes ? projectInputs?.notes.slice(0, 20) : "--"}</span> */}
                                        </p>
                                        <p className=""><span className="fw-bold">Coordinator :</span> <span className="text-muted">
                                            {projectInputs?.coordinator?.length ? `${projectInputs?.coordinator[0]?.firstName} ${projectInputs?.coordinator[0]?.lastName}` : "--"}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ticket's and note's tabs */}
                <div className="container bg my-4">
                    <div className="border" style={{ backgroundColor: "white" }}>
                        <AppBar position="static" sx={{ background: "#fff" }}>
                            <Tabs value={location.pathname}>
                                {allTabs.map((item, i) =>
                                    <Tab key={i}
                                        label={item.label}
                                        value={item.value}
                                        component={NavLink}
                                        to={item.value}
                                    />
                                )}
                            </Tabs>
                        </AppBar>
                        <Outlet
                            context={{ data, methods }}
                        />
                    </div>
                </div>
            </div>
            {/* Delete Modal */}
            <Modal
                size="md"
                show={deleteModal.open}
                onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
                headerContent={<h5 className="py-2">{deleteModal.data.isDeleted ? 'Recover' : 'Delete'} Project?</h5>}
                bodyContent={
                    <div className="">
                        <p>Are you sure you want to {deleteModal.data.isDeleted ? 'recover' : 'delete'} project? </p>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="contained" onClick={handleDelete}>
                                Yes
                            </Button>
                            <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                                No
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default ProjectDetailsMainPage
import React from 'react'
import { Button, TextField } from '@mui/material'
import { priorityList } from '../../constants'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
// import debounce from 'lodash/debounce';
import DateInput from '../../components/DateInput'
import MultipleSelect from "../../components/MultipleSelect"

const TaskFilters = (props) => {
  const { data, methods } = props
  const { agentList, projectList, taskListInput, search, allRolesList,workTypeList } = data
  const { resetFilter, applayFilter, setTaskListInput, handleSearchChange } = methods

  const statusList = [
    { color: '#97FFF4', title: 'Open', value: 'OPEN' },
    { color: '#793FDF', title: 'Completed', value: 'COMPLETED' },
    // { color: '#97FFF4', title: 'Paused', value: 'PAUSED' },
    // { color: '#B71375', title: 'In Progress', value: 'IN PROGRESS' },
    // { color: '#7091F5', title: 'Cancelled', value: 'CANCELLED' },
  ]

  return (
    <>
      <div className="shadow bg raduus py-2">
        <div className="col-lg-10">
          <h5 className="text-center">Filters</h5>
        </div>
        <div className="col-lg-11 mx-auto mb-2">
          <TextField size="small" type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" onChange={handleSearchChange} value={search} />
        </div>
        <div className="filter">
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Agent"}
              multiple={true}
              options={agentList}
              searchBy={"name"}
              keyName={"name"}
              name={"agent"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, agent: e }))}
              value={"_id"}
              selectedValues={taskListInput?.agent}
            />
            {/*             <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Agent
            </InputLabel>
            <Autocomplete
              multiple
              value={taskListInput?.agent}
              onChange={(event, newValue) => {
                setTaskListInput((prevProps) => ({ ...prevProps, agent: newValue }))
                // setValue([
                //     ...fixedOptions,
                //     ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                // ]);
              }}
              options={agentList}
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip key={index} label={option.name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} />}
              size="small"
            /> */}
          </div>

          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Status"}
              multiple={true}
              options={statusList}
              searchBy={"title"}
              keyName={"title"}
              name={"status"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, status: e }))}
              value={"value"}
              selectedValues={taskListInput?.status}
            />
            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Status
            </InputLabel>
            <Autocomplete
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={statusList}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTaskListInput((prevProps) => ({ ...prevProps, status: newValue }))
              }}
              value={taskListInput?.status}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2 ">
            <MultipleSelect
              label={"Role"}
              // multiple={true}
              options={allRolesList}
              searchBy={"roleTitle"}
              keyName={"roleTitle"}
              name={"priority"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, role: e }))}
              value={"_id"}
              selectedValues={taskListInput?.role}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2 ">
            <MultipleSelect
              label="Work Type"
              multiple={true}
              options={workTypeList}
              searchBy={"title"}
              keyName={"title"}
              name={"workType"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, workType: e }))}
              value={"value"}
              selectedValues={taskListInput?.workType}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2 ">
            <MultipleSelect
              label={"Priority"}
              multiple={true}
              options={priorityList}
              searchBy={"title"}
              keyName={"title"}
              name={"priority"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, priority: e }))}
              value={"value"}
              selectedValues={taskListInput?.priority}
            />
            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Priority
            </InputLabel>
            <Autocomplete
              value={taskListInput?.priority}
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={priorityList}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTaskListInput((prevProps) => ({ ...prevProps, priority: newValue }))
              }}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Project"}
              multiple={true}
              options={projectList}
              searchBy={"name"}
              keyName={"name"}
              name={"Projects"}
              onChange={(e) => setTaskListInput((prevProps) => ({ ...prevProps, Projects: e }))}
              value={"_id"}
              selectedValues={taskListInput?.Projects}
            />
            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Project
            </InputLabel>
            <Autocomplete
              value={taskListInput?.Projects}
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={projectList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTaskListInput((prevProps) => ({ ...prevProps, Projects: newValue }))
              }}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <DateInput
              size="small"
              variant="outlined"
              className="w-100 form-field-design "
              // isRequired={true}
              Label={'Start Date'}
              SelectedDate={taskListInput?.startDate || null}
              handleDateChange={(date) => {
                setTaskListInput((pre) => ({ ...pre, startDate: date }))
              }}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <DateInput
              size="small"
              variant="outlined"
              className="w-100 form-field-design"
              // isRequired={true}
              Label={'End Date'}
              SelectedDate={taskListInput?.endDate || null}
              handleDateChange={(date) => {
                setTaskListInput((pre) => ({ ...pre, endDate: date }))
              }}
            />
          </div>
        </div>
        <div className="text-center mx-auto ">
          <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
            Apply
          </Button>
          <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
            Reset
          </Button>
        </div>
      </div>
    </>
  )
}

export default TaskFilters

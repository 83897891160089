import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getProjectList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/project/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetPriject(params, options = {}) {
    return useQuery(['GET_PROJECT_LIST',], () => getProjectList(params), options)
}

// All Project List
export const allProjectList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/project/fullList`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}

export function useToGetFullPriject(params, options = {}) {
    return useQuery(['GET_FULLPROJECT_LIST',], () => allProjectList(params), options)
}


// Add Project
export const addProject = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/project`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Project Details
export const getProjectDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/project/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update Project
export const updateProject = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/project`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete Project
export const deleteProject = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/project/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

//Project Export
export const ExportProjecttList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/project/export`, params);
    if (response.data.code !== 200) {
      throw new Error(response.data.message);
    }
    return response.data;
  };
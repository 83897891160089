import React from 'react'
import { Outlet, useLocation, NavLink, useParams } from 'react-router-dom'
import { Tabs, Tab, AppBar, } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../components/Spinner/Spinner';
import useLeaveDetails from './Hooks/useLeaveDetails';


const LeavesDetailsMainPage = () => {
    const location = useLocation()
    const { id } = useParams()

    const { data, methods } = useLeaveDetails()
    // const { setDeleteModal, handleDelete, } = methods
    const { isLoading} = data

    const allTabs = [
        {
            label: "Leave Details",
            value: `/leaves-request/leave-details/${id}`,
        },
        {
            label: "Time-Line",
            value: `/leaves-request/leave-details/${id}/time-line`,
        },
    ];

    return (
        <>
            {isLoading && <Spinner />}
            <ToastContainer />
            <div className="contact">
              
                <div className="container bg mb-4">
                    <div className="border" style={{ backgroundColor: "white" }}>
                        <AppBar position="static" sx={{ background: "#fff" }}>
                            <Tabs value={location.pathname}>
                                
                                {allTabs.map((item, i) =>
                                    <Tab key={i}
                                        label={item.label}
                                        value={item.value}
                                        component={NavLink}
                                        to={item.value}
                                    />
                                )}
                            </Tabs>
                        </AppBar>
                        <Outlet
                            context={{ data, methods }}
                        />
                    </div>
                </div>
            </div>
       
        </>
    )
}

export default LeavesDetailsMainPage
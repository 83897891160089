import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { PieChart, Pie, Cell, Sector, Brush } from 'recharts'
import { useNavigate } from 'react-router-dom'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import useDashboard from './Hooks/useDashboard'
import { Spinner } from '../Spinner/Spinner'
const cardStyle = 'd-flex justify-content-around shadow3 border border-1 rounded pointer_task_tickets'


const Dashboard = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { data } = useDashboard()
  const { dashboardDateList, isLoading } = data

  const navigate = useNavigate()

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'center' : 'end'

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    )
  }
  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid">
        <div className="row py-2">
          <div className="d-flex justify-content-between">
            <div className="p-0 m-0">
              <h6 className="p-0 mt-4 pt-3">
                <b>Tickets</b>
              </h6>
            </div>
            {/* <div className="p-0 m-0">
              <h6 className="p-0 m-0">
                <div className="w-100">
                  <SelectInput Options={AllTicketEnum} keyName={'title'} Name="ticketType" SelectValue={ticketType} HandleChange={(e) => setTicketType(e.target.value)} variant={'outlined'} />
                </div>
              </h6>
            </div> */}
          </div>
          <div className="col-lg-3 px-2">
            <div className={`${cardStyle} mytask my-2`}
              onClick={() => {
                if (dashboardDateList?.tickets?.my_ticket !== 0) {
                  navigate(`/tickets`, {
                    state: { filterTypes: { my_ticket: dashboardDateList?.tickets?.my_ticket } },
                  });
                }
              }}>
              <div className="py-4 my-3">My Tickets</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tickets?.my_ticket || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} overduetickets my-2`}
              onClick={() => {
                if (dashboardDateList?.tickets?.overdue_ticket !== 0) {
                  navigate(`/tickets`, {
                    state: { filterTypes: { overdue_ticket: dashboardDateList?.tickets?.overdue_ticket } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Overdue Tickets</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tickets?.overdue_ticket || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 pe-3">
            <div className={`${cardStyle} duetodaytask my-2`}
              onClick={() => {
                if (dashboardDateList?.tickets?.due_today !== 0) {
                  navigate(`/tickets`, {
                    state: { filterTypes: { due_today: dashboardDateList?.tickets?.due_today } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Due Today Tickets</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tickets?.due_today || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-2">
            <div className={`${cardStyle} completedtickets my-2`}
              onClick={() => {
                if (dashboardDateList?.tickets?.completed !== 0) {
                  navigate(`/tickets`, {
                    state: { filterTypes: { completed: dashboardDateList?.tickets?.completed } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Completed Tickets</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tickets?.completed || 0}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <h6>
            <b>Task</b>
          </h6>
          <div className="col-lg-3 px-2">
            <div className={`${cardStyle} mytask my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.my_task !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { my_task: dashboardDateList?.tasks?.my_task } },
                  });
                }
              }}>
              <div className="py-4 my-3">My Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.my_task || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} overduetickets my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_backlog !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_backlog: dashboardDateList?.tasks?.workType_backlog } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Backlog Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_backlog || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} changerequesttask my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_changeRequest !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_changeRequest: dashboardDateList?.tasks?.workType_changeRequest } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Change Request Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_changeRequest || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} delegatetask my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_delegate !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_delegate: dashboardDateList?.tasks?.workType_delegate } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Delegate Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_delegate || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} plannedtask  my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_planned !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_planned: dashboardDateList?.tasks?.workType_planned } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Planned Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_planned || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} newworktype  my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_new !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_new: dashboardDateList?.tasks?.workType_new } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">New Work Type Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_new || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} reworktask my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.workType_rework !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { workType_rework: dashboardDateList?.tasks?.workType_rework } },
                  });
                }
              }}
            >
              <div className="py-4 my-3">Rework Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.workType_rework || 0}</b>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 ps-3">
            <div className={`${cardStyle} overduetickets my-2`}
             onClick={() => {
              if (dashboardDateList?.tasks?.overdue_task !== 0) {
                navigate(`/task`, {
                  state: { filterTypes: { overdue_task: dashboardDateList?.tasks?.overdue_task } },
                });
              }
            }}
           >
              <div className="py-4 my-3">Overdue Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.overdue_task || 0}</b>
              </div>
            </div>
          </div>
          <div className="col-lg-3 pe-3">
            <div className={`${cardStyle} duetodaytask my-2`}
            onClick={() => {
              if (dashboardDateList?.tasks?.due_today !== 0) {
                navigate(`/task`, {
                  state: { filterTypes: { due_today: dashboardDateList?.tasks?.due_today } },
                });
              }
            }}>
              <div className="py-4 my-3">Due Today Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.due_today || 0}</b>
              </div>
            </div>
          </div> */}
          <div className="col-lg-3 px-2">
            <div className={`${cardStyle} completedtickets my-2`}
              onClick={() => {
                if (dashboardDateList?.tasks?.completed !== 0) {
                  navigate(`/task`, {
                    state: { filterTypes: { completed: dashboardDateList?.tasks?.completed } },
                  });
                }
              }}>
              <div className="py-4 my-3">Completed Task</div>
              <div></div>
              <div className="py-4 my-3">
                <b>{dashboardDateList?.tasks?.completed || 0}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          {/* Last Week Activity */}
          <div className="col-lg-4 col-12 ticet">
            <h6>
              <b>Last Week Activity</b>
            </h6>
            <div className="bg shadow raduus py-2">
              <ResponsiveContainer width={'100%'} height={418}>
                <BarChart
                  data={dashboardDateList?.weekly_activity}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Brush dataKey="name" height={30} stroke="#8884d8" />
                  <Bar dataKey="Tickets" stackId="a" fill="#8884d8" />
                  <Bar dataKey="Tasks" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/*Ticket Source  */}
          <div className="col-lg-5 col-6 ticet">
            <h6>
              <b>Ticket Source </b>
            </h6>
            <div className="bg shadow raduus">
              <ResponsiveContainer width="100%" height={290}>
                <PieChart>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={dashboardDateList?.ticket_source}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {dashboardDateList?.ticket_source?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className="pb-3 px-4">

                {dashboardDateList?.ticket_source?.map((item, index) =>
                  <div key={index} className="d-flex justify-content-around">
                    <div className="py-1 text-start">
                      <span className={"email_dots"} style={{ backgroundColor: item?.color }} ></span> &nbsp; {item?.name}
                    </div>
                    <div></div>
                    <div className="py-1 text-end">
                      <b>{`${item?.value}%`}</b>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Priority Details */}
          <div className="col-lg-3 col-6 ticet">
            <h6>
              <b>Priority Details</b>
            </h6>
            <div className="bg shadow raduus">
              <ResponsiveContainer width="100%" height={320}>
                <PieChart>
                  <Pie data={dashboardDateList?.priority_details_ticket} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" >
                    {dashboardDateList?.priority_details_ticket?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                  </Pie>

                  <Pie data={dashboardDateList?.priority_details_task} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label>
                    {dashboardDateList?.priority_details_task?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className="pb-3 px-4 row">
                <div className='col-6'>
                  <span>Tickets</span>
                  {dashboardDateList?.priority_details_ticket?.map((item, index) =>
                    <div key={index} className="d-flex justify-content-around">
                      <div className="py-1 text-start">
                        <span className={"email_dots"} style={{ backgroundColor: item?.color }} ></span> &nbsp; {item?.name}
                      </div>
                      <div></div>
                      <div className="py-1 text-end">
                        <b>{`${item?.value}%`}</b>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <span>Tasks</span>
                  {dashboardDateList?.priority_details_task?.map((item, index) =>
                    <div key={index} className="d-flex justify-content-around">
                      <div className="py-1 text-start">
                        <span className={"email_dots"} style={{ backgroundColor: item?.color }} ></span> &nbsp; {item?.name}
                      </div>
                      <div></div>
                      <div className="py-1 text-end">
                        <b>{`${item?.value}%`}</b>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="d-flex justify-content-around">
                  <div className="py-1 text-start">
                    <span className="my_task_dots"></span> &nbsp; Low Priority &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div></div>
                  <div className="py-1 text-end">
                    <b>29%</b>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="py-1 text-start">
                    <span className="overdue_task_dots"></span> &nbsp; Medium Priority &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div></div>
                  <div className="py-1 text-end">
                    <b>21%</b>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="py-1 text-start">
                    <span className="duetoday_task_dots "></span> &nbsp; High Priority &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div></div>
                  <div className="py-1 text-end">
                    <b>15%</b>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard

import React from 'react'
import { Checkbox } from '@mui/material'
const Table = ({ TableHeadData, numberOfSelected, rowCount, onSelectAllClick }) => {
  return (
    <>
      <thead className=" table_header " style={{ border: 'none' }}>
        <tr className="text-start p-5" style={{ borderRadius: '50px' }}>
          {onSelectAllClick && (
            <th className=" border-0" style={{ backgroundColor: '#cec2de', color: '#313131', fontSize: '14px' }}>
              <Checkbox
                size="small"
                color="primary"
                indeterminate={numberOfSelected > 0 && numberOfSelected < rowCount}
                checked={rowCount > 0 && numberOfSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </th>
          )}
          {TableHeadData.map((item, index) => (
            <th key={rowCount + index} className="py-3 border-0" style={{ backgroundColor: '#cec2de', color: '#313131', fontSize: '14px' }}>
              {item.label}
            </th>
          ))}
        </tr>
      </thead>
    </>
  )
}

export default Table

import Modal from 'react-bootstrap/Modal';
import React from 'react'

const index = ({ show, onHide, headerContent, bodyContent, size, CloseFucntion }) => {
    return (
        <Modal size={size} show={show} onHide={onHide} centered>
            <Modal.Header closeButton className='py-2' onClick={CloseFucntion}>
                {headerContent}
            </Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>
        </Modal>
    )
}

export default index
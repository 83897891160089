/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { SandwichList, } from '../../../../constants';
import { requiredMessage, requiredSelect } from '../../../../utils';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addLeavePolicy, addTeamLeavePolicy, getLeavePolicyDetails, updateLeavePolicy } from '../../../../repository/leavePolicyRepo';
import { getShiftList, } from '../../../../repository/shiftRepo';
import { roleList } from '../../../../repository/auth';



const useLeavePolicyAddUpdate = () => {
    const navigate = useNavigate()
    const { id, agentId } = useParams()
    const [spinner, setSpinner] = useState(false)
    const [leavePloicyForm, setLeavePolicyForm] = useState({
        name: "",
        code: "",
        type: "",
        leaveType: "",
        shifts: [],
        sandwichAction: false,
        sandwich: [],
        carryForward: false,
        maxCarryForward: 0,
        entitledLeaves: "",
        policyStartDate: null,
        policyEndDate: null,
        isEarnedLeave: false
        // shortLeaveDuration: 0,
        // approvalLevel:'',
        // role:''
    })

    const [leavePolicyError, setLeavePloicyError] = useState({})
    const [shiftList, setShiftList] = useState([])
    const [RoleList, setRoleList] = useState([])

    let [approvalLevelInput, setApprovalLevelInput] = useState([{
        approvalLevel: "",
        role: "",
        permissionLeaveType: false
    }])
    const [errors, setErrors] = useState([
        {
            approvalLevel: '',
            role: '',

        },
    ]);
    // console.log(approvalLevelInput,'approvalLevelInput')
    const { pathname } = useLocation()
    const splitLocation = pathname.split('/')
    // console.log(leavePolicyError, 'leavePolicyError')


    const approvalLevels = approvalLevelInput.map((item, i) => {
        const roleId = item.role
        const result = {
            title: `Level ${i + 1}`,
            name: item.approvalLevel,
            type: item.approvalLevel === 'Reporting Manager' ? 'Reporting Manager' : (item.approvalLevel === 'Team Lead' ? 'Team Lead' : 'Role'),
            permissionLeaveType: item?.permissionLeaveType
        };
        if (roleId !== "") {
            result.roleId = roleId;
        }
        return result;
    });
    // console.log(approvalLevels, 'approvalLevels');



    const getDetails = async () => {
        try {
            let response = await getLeavePolicyDetails(id)
            let { name, code, policyStartDate, policyEndDate, type, sandwich, leaveType, carryForward, entitledLeaves, max_carry_forward, shift_id, approval_level, isEarnedLeave } = response.data
            const newSandwich = SandwichList?.filter((item) => sandwich?.includes(item?.value));
            setLeavePolicyForm({
                name: name,
                code: code,
                type: type,
                leaveType: leaveType,
                entitledLeaves: entitledLeaves,
                // shortLeaveDuration: shortLeaveDuration,
                policyStartDate: new Date(policyStartDate),
                policyEndDate: new Date(policyEndDate),
                sandwichAction: sandwich?.length ? true : false,
                sandwich: newSandwich,
                carryForward: carryForward,
                maxCarryForward: max_carry_forward,
                shifts: shift_id,
                isEarnedLeave: isEarnedLeave
            })
            const newData = approval_level.map(item => {
                return {
                    approvalLevel: item.name,
                    role: item.roleId,
                    Level: item.title,
                    permissionLeaveType: item?.permissionLeaveType
                };
            });
            setApprovalLevelInput(newData)
        } catch (error) {

        }
    }
    const getRoleList = async () => {
        let payLoad = {
            count: 100
        }
        setSpinner(true)
        try {
            let response = await roleList(payLoad)
            setRoleList(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }


    // get Details
    useEffect(() => {
        if (id !== "add") {
            getDetails()
        }
        shift()
        getRoleList()
    }, [])


    const addMultipleBreak = () => {
        if (approvalLevelInput.length < 3) {
            setApprovalLevelInput((prev) => [
                ...prev,
                {
                    approvalLevel: "",
                    role: "",
                    permissionLeaveType: false

                    // Level: `Level ${prev.length + 1}`
                },
            ])
            // Clear errors for the newly added task
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    approvalLevel: "",
                    role: ""
                },
            ]);
        }
        else {
            toast.warn('Maximum 3 times allowed')
        }
    }

    const removeBreak = async (breakIndex) => {
        setApprovalLevelInput((prev) => {
            const updatedBreaks = [...prev]
            updatedBreaks.splice(breakIndex, 1)
            return updatedBreaks
        })
    }

    // const handleBreakInput = (event, taskIndex) => {
    //     const { value, name,checked } = event.target;

    //     setApprovalLevelInput((prev) => {
    //         const updatedBreak = [...prev];
    //         updatedBreak[taskIndex][name] = value;
    //         if(checked){
    //             updatedBreak[taskIndex]['permissionLeaveType']=checked
    //         }
    //         else{
    //             updatedBreak[taskIndex]['permissionLeaveType']=checked
    //         }
    //         // Clear errors for the current field
    //         setErrors((prevErrors) => {
    //             const updatedErrors = [...prevErrors];
    //             // if (name != "ReminderAction") {
    //             //   updatedErrors[taskIndex][name] = null;
    //             // }
    //             return updatedErrors;
    //         });

    //         return updatedBreak;
    //     });
    // };

    const handleBreakInput = (event, taskIndex) => {
        const { value, name, checked } = event.target;

        setApprovalLevelInput((prev) => {
            const updatedBreak = [...prev];

            // Update the value for the specific task
            updatedBreak[taskIndex][name] = value;
            updatedBreak[taskIndex]['permissionLeaveType'] = checked;

            // Ensure all other tasks have their permissionLeaveType set to false
            updatedBreak.forEach((task, index) => {
                if (index !== taskIndex) {
                    task['permissionLeaveType'] = false;
                }
            });

            // Clear errors for the current field
            setErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                // if (name !== "ReminderAction") {
                //   updatedErrors[taskIndex][name] = null;
                // }
                return updatedErrors;
            });

            return updatedBreak;
        });
    };



    const shift = async () => {
        const payLoad = {
            isDeleted: false,
            page: 1,
            count: 100
        }
        try {
            let response = await getShiftList(payLoad)
            setShiftList(response.data)

        } catch (error) {

        }
    }

    // Handle Form Input's
    const handleInputChange = (event) => {
        const { value, name, checked } = event.target;

        setLeavePolicyForm((prev) => {
            const updatedShiftForm = { ...prev };
            updatedShiftForm[name] = value;

            if (name === 'sandwichAction') {
                // updatedShiftForm[name] = checked;
                if (checked) {
                    updatedShiftForm['sandwichAction'] = true
                    // updatedShiftForm['sandwich'] = value;
                } else {
                    updatedShiftForm['sandwichAction'] = false
                    updatedShiftForm['sandwich'] = [];
                }
            }
            if (name === 'carryForward') {
                // updatedShiftForm[name] = checked;
                if (checked) {
                    updatedShiftForm['carryForward'] = true;
                } else {
                    updatedShiftForm['carryForward'] = false;
                    updatedShiftForm['maxCarryForward'] = 0;
                }
            }

            // Clear errors for the current field
            setLeavePloicyError((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name !== "sandwichAction") {
                    updatedErrors[name] = null;
                }
                if (name !== "carryForward") {
                    updatedErrors[name] = null;
                }
                return updatedErrors;
            });

            return updatedShiftForm;
        });
    };

    // Handle Date
    const handleDateChange = (date, field) => {
        setLeavePolicyForm((prev) => {
            const updatedShiftForm = { ...prev }
            updatedShiftForm[field] = date
            return updatedShiftForm
        })
        setLeavePloicyError((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[field] = null;
            return updatedErrors;
        });
    }

    // Error Message
    const errorMessage = {
        name: requiredMessage('name'),
        code: requiredMessage('code'),
        type: requiredSelect('type'),
        leaveType: requiredSelect('leave type'),
        entitledLeaves: requiredSelect('entitled leaves'),
        // shortLeaveDuration: requiredSelect('short leave duration'),
        policyStartDate: requiredMessage('start date'),
        policyEndDate: requiredMessage('end date'),
        sandwich: requiredSelect('sandwich'),
        // carryForward: requiredSelect('carry Forward'),
        shifts: requiredSelect('shifts'),
        maxCarryForward: requiredSelect('max carry forward')

    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!leavePloicyForm.name) {
            errorObj['name'] = errorMessage['name']
        }
        if (!leavePloicyForm.code) {
            errorObj['code'] = errorMessage['code']
        }
        if (!leavePloicyForm.type) {
            errorObj['type'] = errorMessage['type']
        }
        if (!leavePloicyForm.leaveType) {
            errorObj['leaveType'] = errorMessage['leaveType']
        }
        if (!leavePloicyForm.entitledLeaves) {
            errorObj['entitledLeaves'] = errorMessage['entitledLeaves']
        }
        // if (leavePloicyForm.leaveType !== "NORMAL") {
        //     if (!leavePloicyForm.shortLeaveDuration) {
        //         errorObj['shortLeaveDuration'] = errorMessage['shortLeaveDuration']
        //     }
        // }
        if (!leavePloicyForm.policyStartDate) {
            errorObj['policyStartDate'] = errorMessage['policyStartDate']
        }
        if (!leavePloicyForm.policyEndDate) {
            errorObj['policyEndDate'] = errorMessage['policyEndDate']
        }
        if (leavePloicyForm?.sandwichAction) {
            if (!leavePloicyForm?.sandwich?.length) {
                errorObj['sandwich'] = errorMessage['sandwich'];
            }
        }
        if (splitLocation[3] !== 'leave') {
            if (leavePloicyForm?.carryForward) {
                if (!leavePloicyForm?.maxCarryForward) {
                    errorObj['maxCarryForward'] = errorMessage['maxCarryForward'];
                }
            }
            if (!leavePloicyForm.type) {
                errorObj['shifts'] = errorMessage['shifts']
            }
        }
        if (leavePloicyForm.sandwichAction) {
            delete errorObj.sandwichAction;
        }
        // if (!leavePloicyForm.carryForward) {
        //     errorObj['carryForward'] = errorMessage['carryForward']
        // }
        setLeavePloicyError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const errorBreakMessage = {
        approvalLevel: requiredSelect('approval level'),
        role: requiredSelect("role"),
    }

    const validateBreaks = () => {
        const errorObjArray = [];

        for (let i = 0; i < approvalLevelInput.length; i++) {
            const breaks = approvalLevelInput[i];
            const errorObj = {};

            if (!breaks.approvalLevel) {
                errorObj['approvalLevel'] = errorBreakMessage['approvalLevel'];
            }

            if (breaks.approvalLevel === 'ROLE' && !breaks.role) {
                errorObj['role'] = errorBreakMessage['role'];
            }

            // Add checks for other fields as needed
            errorObjArray.push(errorObj);
        }

        setErrors(errorObjArray);
        return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
    };

    // add Shift 
    const submit = async () => {
        if (!isValid()) return
        if (approvalLevelInput?.length > 0) {
            if (!validateBreaks()) return
        }
        setSpinner(true)
        let payload = {
            name: leavePloicyForm.name,
            code: leavePloicyForm.code,
            type: leavePloicyForm.type,
            leaveType: leavePloicyForm.leaveType,
            entitledLeaves: leavePloicyForm.entitledLeaves,
            // shortLeaveDuration: leavePloicyForm.leaveType === "NORMAL" ? 0 : leavePloicyForm.shortLeaveDuration,
            policyStartDate: leavePloicyForm.policyStartDate,
            policyEndDate: leavePloicyForm.policyEndDate,
            sandwich: leavePloicyForm?.sandwich?.map(item => item?.value),
            carryForward: leavePloicyForm.carryForward,
            max_carry_forward: leavePloicyForm.maxCarryForward,
            shift_id: leavePloicyForm.shifts,
            approval_level: approvalLevels,
        }
        if (splitLocation[3] === 'leave') {
            delete payload.shift_id;
            delete payload.carryForward;
            delete payload.max_carry_forward;
            delete payload.sandwich

            payload.user_id = agentId
        }
        // console.log(payload, 'AAAAAAAAAAAAAA')
        // return
        try {
            // let response = await splitLocation[3] == 'leave' ? addTeamLeavePolicy(payload) : addLeavePolicy(payload)
            let response;
            if (splitLocation[3] === 'leave') {
                response = await addTeamLeavePolicy(payload);
            } else {
                response = await addLeavePolicy(payload);
            }

            // navigate('/setting/leave-policy')
            navigate(-1)
            toast.success(response.message)
        } catch (error) {

        }
        setSpinner(false)
    }

    // Update Shift 
    const Update = async () => {
        if (leavePloicyForm?.name !== 'Earned Leave') {
            if (!isValid()) return
        }
        if (leavePloicyForm?.name === 'Earned Leave') {
            if (!approvalLevelInput?.length) return toast.warn('Please add at least one approval matrix')
        }
        if (approvalLevelInput?.length > 0) {
            if (!validateBreaks()) return
        }
        setSpinner(true)
        let payload = {
            _id: id,
            name: leavePloicyForm.name,
            code: leavePloicyForm.code,
            type: leavePloicyForm.type,
            leaveType: leavePloicyForm.leaveType,
            entitledLeaves: leavePloicyForm.entitledLeaves,
            policyStartDate: leavePloicyForm.policyStartDate,
            policyEndDate: leavePloicyForm.policyEndDate,
            sandwich: leavePloicyForm?.sandwich?.map(item => item?.value) || [],
            carryForward: leavePloicyForm.carryForward,
            max_carry_forward: leavePloicyForm.maxCarryForward,
            shift_id: leavePloicyForm.shifts,
            approval_level: approvalLevels,
            isEarnedLeave: leavePloicyForm?.isEarnedLeave
        }

        // console.log(payload, 'AAAAAAAAAAAAAA')
        // return
        try {
            let response = await updateLeavePolicy(payload)
            navigate('/setting/leave-policy')
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    // console.log(moment(leavePloicyForm.policyStartDate).format('DD-MM-YYYY'),'policyStartDate')
    return {
        data: {
            spinner,
            leavePloicyForm,
            leavePolicyError,
            leavePolicyId: id,
            shiftList,
            RoleList,
            approvalLevelInput,
            errors,
            splitLocation
        },
        methods: {
            setLeavePolicyForm,
            setLeavePloicyError,
            handleInputChange,
            handleDateChange,
            submit,
            Update,
            addMultipleBreak,
            removeBreak,
            handleBreakInput,
            setErrors

        }
    }
}

export default useLeavePolicyAddUpdate
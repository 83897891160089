import React from 'react'
import { Select, MenuItem, InputLabel, TextField, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
const SearchAndStatusFilter = ({ onSearchChange, setStatusType, search, statusType, reset, otherFilter, applayFilter }) => {
  const IsDeletedArr = [
    { value: 'All', title: 'All' },
    { value: 'false', title: 'Active' },
    { value: 'true', title: 'Deleted' },
  ]

  return (
    <>
      <div className="row ms-2">
        <TextField placeholder="Search" name={'searchText'} type="text" value={search} onChange={onSearchChange} variant={'outlined'} sx={{ width: '210px' }} size="small" />
      </div>
      <div className="ms-3">
        <Select variant="outlined" sx={{ width: '210px' }} size="small" value={statusType || 'All'} onChange={(e) => setStatusType(e.target.value)}>
          {IsDeletedArr?.map((item, index) => (
            <MenuItem key={IsDeletedArr.length + index} value={item?.value}>
              {item?.title}
            </MenuItem>
          ))}
        </Select>
      </div>
      {/* Other Fillters */}
      {otherFilter}
      {/* Apply or Reset section */}
      <div className=" d-flex align-items-end">
        <Button variant="contained" size="small" onClick={applayFilter} className="ms-2 p-2" startIcon={<SearchIcon />}>
          Search
        </Button>
        <Button variant="contained" size="small" className=" ms-2 p-2" onClick={reset} startIcon={<RestartAltIcon />}>
          Reset
        </Button>
      </div>
    </>
  )
}

export default SearchAndStatusFilter

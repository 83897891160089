import React from 'react'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { InputLabel } from '@mui/material';

const index = ({ label, value, handleChange, name, isRequired, disabled, className }) => {
    return (
        <div className={`${className}`}>
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    // label={label}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </LocalizationProvider>
        </div>
    )
}

export default index
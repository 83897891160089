import React, { useState,} from "react";
import "../credentials/credentials.css";
// import TextField from "@mui/material/TextField";
import TextField from "../../components/TextField"

import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "../../components/Spinner/Spinner";
import { requiredMessage } from "../../utils";
import { resetPassword } from "../../repository/auth";

const ResetPassword = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)
    const [createPasswordInputs, setCreatePasswordInputs] = useState({
      password: "",
      confirm_password: ""
    })
    const [error, setError] = useState({})
  
  
    // useEffect(() => {
    //   checqResetTokenApi()
    // }, [])
  
    // // Checq Token Error
    // const checqResetTokenApi = async () => {
    //   try {
    //     let response = await ChecqResetToken({ token: id })
    //   } catch (error) {
    //     toast.error(error.message)
    //   }
    // }
  
  
    //Handle Input value
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setCreatePasswordInputs((prevProps) => ({ ...prevProps, [name]: value }))
      value?.trim().length && setError(pre => {
        delete pre[name]
        return ({ ...pre })
      })
    }
  
    const handleBlur = (event) => {
      const { name, value } = event.target
      !value?.trim()?.length && setError(pre => ({ ...pre, [name]: errorMessage[name] }))
    }
    // -***--------------------***-
    // Error Message
    const errorMessage = {
      password: requiredMessage("password"),
      confirm_password: requiredMessage("confirm password"),
    }
  
    // Error hendling
    const isValid = () => {
      const errorObj = {}
      if (!createPasswordInputs.password?.trim()?.length) {
        errorObj['password'] = errorMessage['password']
      }
      if (!createPasswordInputs.confirm_password) {
        errorObj['confirm_password'] = errorMessage['confirm_password'];
      } else if (createPasswordInputs.password !== createPasswordInputs.confirm_password) {
        errorObj['confirm_password'] = "Passwords do not match.";
      }
      setError(errorObj)
      return !Object.keys(errorObj)?.length
    }
  
    // Handle submit data..!
    const handleSubmit = async (event) => {
      event.preventDefault()
  
      if (!isValid()) return
      setSpinner(true)
      try {
        let payload = {
        //   password: createPasswordInputs.password,
          newPassword: createPasswordInputs.confirm_password,
          token: id
        }
        let response = await resetPassword(payload)
        navigate('/')
        setTimeout(() => {
          toast.success(response.message)
        }, 500)
      } catch (err) {
        toast.error(err.message)
      }
      setSpinner(false)
    }
    return (
      <>
        {spinner && <Spinner />}
        <ToastContainer />
        <div className=" pt-5">
          <div className="container  mt-5 ">
            <div className="contact" style={{ height: "80vh" }}>
              <div className="col-lg-5 mx-auto bg2 shadow2 p-4">
                <div className="text-center">
                  <h4><b>Create New Password </b></h4>
                  <p className="login-font-waight" >Your new password must be different from <br /> previous used Password</p>
                </div>
                <div className="">
                  <TextField
                    Lable="Password"
                    Name="password"
                    variant="standard"
                    className="col-6 mt-4"
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={createPasswordInputs.password}
                    Require={true}
                    width={100}
                    Error={error.password}
                    type={"password"}
                  />
                  <TextField
                    Lable="Confirm Password"
                    Name="confirm_password"
                    variant="standard"
                    className="col-6 mt-4"
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={createPasswordInputs.confirm_password}
                    Require={true}
                    width={100}
                    Error={error.confirm_password}
                    type={"password"}
                  />
                  {/* <div className="text-end"><span className="table-link loginfont" onClick={() => navigate("/forgotpassword")}>Forgot your Password</span></div> */}
                  <div className="pt-3">
                    <Button variant="primary1" className="py-2 w-100" onClick={(event) => handleSubmit(event)}>Submit</Button></div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default ResetPassword
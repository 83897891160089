import moment from 'moment';
import React, { useState } from 'react'
import { useOutletContext } from "react-router-dom";
import Modal from '../../components/Modal'
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import { uploadFileUrlTimeLog } from '../../repository/taskRepo';
import UseDateTimeFormat from '../../hooks/useDateTimeFormat';
const ViewTimeLog = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
    const { data } = useOutletContext();
    const { taskDetails } = data
    const [showPDFModal, setShowPDFModal] = useState({ open: false, data: '' })

    const fileType = (file, fileIndex) => {
        let type = file?.split('.').pop()

        if (['png', 'jpeg', 'jpg'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={uploadFileUrlTimeLog + file} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['mp4'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['pdf'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['xlsx', 'xls'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['txt', 'doc', 'odt'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else {
            return (
                <>
                    <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
                </>
            )
        }
    }
    // show Attetchment's
    const filePreview = (file) => {
        let type = file?.split('.').pop()
        if (['png', 'jpeg', 'jpg'].includes(type)) {
            setShowPDFModal({ open: true, data: file })
        } else window.open(`${uploadFileUrlTimeLog}${file}`)
    }

    const duration = moment.duration(taskDetails?.timelogDetails?.workDuration, 'minutes');
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();
    let totalWorkHours = "";
    if (hours > 0) {
        totalWorkHours += `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (remainingMinutes > 0) {
        totalWorkHours += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }

    return (
        <>
            {taskDetails?.timelogDetails?.task_id?.isTimeLogged ? <div className='p-4'>
                <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <span>
                        <b> Project : </b>{taskDetails?.timelogDetails?.project_id?.name}
                    </span>
                    <span>
                        <b>Logged Date :</b> {getFormattedDateTime(taskDetails?.timelogDetails?.reportDate, 'DD MMM YYYY')}
                    </span>
                </div>
                <div className="time-log-detials-border d-flex justify-content-between " style={{ borderTop: '0' }}>
                    <span>
                        <b>Worked Duration :</b> {getFormattedDateTime(taskDetails?.timelogDetails?.actualStartTime, 'lll')} - {getFormattedDateTime(taskDetails?.timelogDetails?.actualEndTime, 'lll')}
                    </span>
                    <span>
                        <b>Total Worked Time :</b> {totalWorkHours || "--"}
                    </span>
                </div>
                <div className="time-log-detials-border" style={{ borderTop: '0', wordWrap: "break-word" }} >
                    <span>
                        <b>Remarks : </b>
                    </span>
                    <span className="mt-2" dangerouslySetInnerHTML={{ __html: taskDetails?.timelogDetails?.notes }}></span>
                </div>

                <div className="col-lg-12 px-0 mt-4">
                    {taskDetails?.timelogDetails?.attachments?.length > 0 ? (
                        <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                            <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                                {taskDetails?.timelogDetails?.attachments?.map((file, fileIndex) => (
                                    <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => filePreview(file)}>
                                        {fileType(file, fileIndex)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                            <span>No Attachments</span>
                        </div>
                    )}
                </div>

                <div className="d-flex justify-content-between time-log-detials-border mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <div>
                        <span>
                            <b>SubTask : </b>
                        </span>
                        <span className="">
                            {taskDetails?.timelogDetails?.subTask}
                        </span>
                    </div>
                    <span>
                        <b>Task Category  : </b> {taskDetails?.timelogDetails?.taskCategory}
                    </span>
                </div>
                <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                    <div className='d-flex justify-content-between'>
                        <span>
                            <b>Complexity : </b>{taskDetails?.timelogDetails?.complexity}
                        </span>
                        <span>
                            <b>Hours Type  : </b> {taskDetails?.timelogDetails?.hoursType}
                        </span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <span>
                            <b>Actual Status  : </b> {taskDetails?.timelogDetails?.actutalStatus}
                        </span>
                    </div>
                </div>
            </div>
                :
                <div className='p-1 '>
                    <div className='border m-3  p-4 text-center'>
                        No time log found
                    </div>
                </div>
            }
            {/* show documnet's and attetchment's */}
            <Modal
                size="lg"
                show={showPDFModal.open}
                onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
                headerContent={''}
                bodyContent={
                    <div className="">
                        {showPDFModal?.data ? (
                            showPDFModal?.data?.split('.').pop() === 'pdf' ? (
                                <iframe src={uploadFileUrlTimeLog + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
                            ) : (
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlTimeLog + showPDFModal?.data} alt="" />
                            )
                        ) : (
                            <div>No attachment available</div>
                        )}
                    </div>
                }
            />
        </>
    )
}

export default ViewTimeLog
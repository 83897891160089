import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    Ticketing: {
      page: "tickets",
      filters: {},
      pagination: 1,
    },
    Task: {
      page: "task",
      filters: {},
      pagination: 1,
    },
    Attendance: {
      page: "attendance",
      filters: {},
      pagination: 1,
    },
    TimeLog: {
      page: "time-log",
      filters: {},
      pagination: 1,
    },
    Projects: {
      page: "projects",
      filters: {},
      pagination: 1,
    },
    Team: {
      page: "team-management",
      filters: {},
      pagination: 1,
    },
    Group: {
      page: "group",
      filters: {},
      pagination: 1,
    },
    Skills: {
      page: "skills",
      filters: {},
      pagination: 1,
    },
    Tags: {
      page: "tags",
      filters: {},
      pagination: 1,
    },
    Leave:{
      page:'Leave',
      filters: {},
      pagination: 1,

    }
  },
  reducers: {
    addFilter(state, action) {
      const { page, filter } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = { ...state[page].filters, ...filter };
      }
    },
    update(state, action) {
      const { page, updates } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = { ...state[page].filters, ...updates };
      }
    },
    resetFilter(state, action) {
      const { page } = action.payload;
      // Check if the page exists in state
      if (state[page]) {
        state[page].filters = {};
      }
    },
  },
});

export const { addFilter, update, resetFilter } = filterSlice.actions;
export default filterSlice.reducer;

import React from 'react'
import { Button } from '@mui/material'
import useTeamList from './Hooks/useTeamList'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import SearchAndStatusFilter from '../../../components/SearchAndStatusFilter'
import { Pagination } from '../../../components/pagination/Pagination'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'
import Modal from "../../../components/Modal"

const Team = () => {
  const navigate = useNavigate()
  const { data, methods } = useTeamList()
  const { teamList, search, statusType, totalCount, controller, isLoading, groupDetailsModal, deleteModal } = data
  const { handleSearchChange, setStatusType, resetFilter, applayFilter, handlePagination, setGroupDetailsModal, setDeleteModal, handleDelete } = methods


  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex">
              <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} />
            </div>
            <div className="d-flex">
              <Button className="dropDownButtons11" onClick={() => navigate(`/setting/group/business-hours-form/add`)} startIcon={<AddIcon />}>
                Add New
              </Button>
            </div>
          </div>
        </div>
        <div className="col-lg-12 ticet">
          <div className="bg shadow raduus">
            {/* <div className=""> */}
            <div className="mt-4">
              <table className="table">
                <tbody className="mt-3">
                  {teamList?.map((item) => {
                    return (
                      <tr key={item?._id} className="rounded-row" >
                        <td className="p-2">
                          <p className="table-link" onClick={(event) => setGroupDetailsModal({ open: true, data: item })}>{item?.name}</p>
                          <span className="" dangerouslySetInnerHTML={{ __html: item?.description.substr(0, 300) }}></span>
                        </td>
                        <td className="text-end">
                          <div className="dropdown p-0  align-items-center justify-content-center">
                            <i className="fa-solid fa-ellipsis-vertical px-3" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}></i>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <li style={{ cursor: 'pointer' }} onClick={() => navigate(`/setting/group/business-hours-form/${item?._id}`, { state: { teamId: item?._id } })} className="dropdown-item">
                                Edit{' '}
                              </li>
                              <li className="dropdown-item" onClick={() => toast.warn('Under progress')}>
                                Clone
                              </li>
                              <li className="dropdown-item" onClick={() => setDeleteModal({ data: item._id, open: true })}>
                                Delete
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!teamList?.length && <div className="text-center">No record found</div>}
              <Pagination count={totalCount} controller={controller} setController={handlePagination} />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* Group Discription */}
      <Modal
        size="xl"
        CloseFucntion={() => setGroupDetailsModal((prev) => ({ ...prev, open: false }))}
        show={groupDetailsModal.open}
        onHide={() => setGroupDetailsModal((prev) => ({ ...prev, open: true }))}
        headerContent={
          <h5 style={{ fontWeight: '600' }} className="mt-2">
            Group Details
          </h5>
        }
        bodyContent={
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
              <span>
                <b>{groupDetailsModal.data.name}</b>
              </span>
            </div>
            <div className="time-log-detials-border" style={{ borderTop: '0' }}>
              <span>
                <b>Description</b>
              </span>
              <span className="mt-2" dangerouslySetInnerHTML={{ __html: groupDetailsModal.data?.description }} />
            </div>

            <div className="time-log-detials-border" style={{ borderTop: '0' }}>
              <span>
                <b>TL</b>
              </span>
              <br />

              {groupDetailsModal.data?.teamLead_id?.firstName ? <span className='mt-2'>{groupDetailsModal.data?.teamLead_id?.firstName} {groupDetailsModal.data?.teamLead_id?.lastName}</span> : '--'}
            </div>
          </div>
        }
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />


    </>
  )
}

export default Team

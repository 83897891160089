import React from 'react'
import { ToastContainer } from 'react-toastify'
import Table from '../../components/Table/Table'
import UseNotification from './Hooks/useNotification'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'

const Notification = () => {
    const { getFormattedDateTime } = UseDateTimeFormat()
    const { data, method } = UseNotification()
    const navigate = useNavigate()

    const { notificationData, spinner, controller, totalCount,status } = data
    const { handlePagination, readsingleNotification, readAllNotification,setStatus } = method

    const TableHead = [
        { label: '#', id: '' },
        { label: 'Title', id: '' },
        { label: 'Description', id: '' },
        { label: 'Date', id: '' },
    ]

    const navigation = (item) => {
        readsingleNotification(item._id)
        const typeToPath = {
            'TICKETREPLY': `/tickets/email/${item?.type_id}`,
            'TICKET': `/tickets/email/${item?.type_id}`,
            'MERGETICKET': '/tickets',
            'TASKASSIGNED': '/task',
            'TIMELOGSTATUS': '/time-log',
            'ATTENDANCE': '/attendance',
            'LEAVE': `/leaves-request/leave-details/${item?.type_leave}`,
            'APPROVED': `/leaves-request/leave-details/${item?.type_leave}`,
            'REJECTED': `/leaves-request/leave-details/${item?.type_leave}`,
            'PARTIALAPPROVED': `/leaves-request/leave-details/${item?.type_leave}`
        };
        const path = typeToPath[item.type];
        if (path) {
            navigate(path);
        }
    }

    return (
        <>
            <ToastContainer />
            {spinner && <Spinner />}
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={status?true:false} onClick={()=>setStatus(true)}/>
                                <label class="btn btn-outline-primary" for="btnradio1">All</label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={!status?true:false} onClick={()=>setStatus(false)}/>
                                <label class="btn btn-outline-primary" for="btnradio2">Unread</label>
                            </div>
                        </div>
                        <div className="d-flex pe-1">
                            <div>
                                <Button variant="contained" disabled={!notificationData?.unreadedCount ? true : false} onClick={() => { readAllNotification() }}>Mark All Read</Button>    
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-lg-12 ticet">
                        <div className="bg shadow raduus">   
                            <div className="">
                                <div className="mt-2">
                                    <table className="table">
                                        <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                                        <tbody>
                                            {notificationData?.data?.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={item._id + index}
                                                        className={item.isReaded ? 'text-start rounded-row tablebess cursor-pointer' : 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light cursor-pointer'}
                                                        onClick={() => {
                                                            // if (!item.isReaded) {
                                                                navigation(item)
                                                            // }
                                                        }}
                                                    >
                                                        <td className="table-link">{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                                                        <td className="py-3">{item?.title || '--'}</td>
                                                        <td className="py-3">{item?.message || '--'}</td>
                                                        <td className="py-3">{getFormattedDateTime(item?.createdAt, 'LLL')}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!notificationData?.data?.length && <div className="text-center">No record found</div>}
                                    <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Notification

import React from 'react'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
// import debounce from 'lodash/debounce';
import DateInput from '../../../components/DateInput'
import MultipleSelect from "../../../components/MultipleSelect"

const ReportFilter = (props) => {
    const { data, methods } = props
    const { reportListInput} = data
    const { resetFilter, applayFilter, setReportListInput } = methods

    const reportTypedata = [
        { title: 'Resource Utilisation', value: 'RESOURCE_UTILISATION' },
        { title: 'Project Allotment', value: 'PROJECT_ALLOTMENT' },
    ]
    const reportFromdata = [
        { title: 'Task', value: 'TASK' },
        { title: 'TimeLog', value: 'TIMELOG' },
    ]

    return (
        <>
        
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                {/* <div className="col-lg-11 mx-auto mb-2">
                    <TextField size="small" type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" onChange={handleSearchChange} value={search} />
                </div> */}
                <div className="filter">
                    <div className="col-lg-11 mx-auto mb-2 ">
                        <MultipleSelect
                            label={"Report From"}
                            // multiple={true}
                            options={reportFromdata}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"reportFrom"}
                            onChange={(e) => setReportListInput((prevProps) => ({ ...prevProps, reportFrom: e }))}
                            value={"value"}
                            selectedValues={reportListInput?.reportFrom}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2 ">
                        <MultipleSelect
                            label="Report Type"
                            // multiple={true}
                            options={reportTypedata}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"reportType"}
                            onChange={(e) => setReportListInput((prevProps) => ({ ...prevProps, reportType: e }))}
                            value={"value"}
                            selectedValues={reportListInput?.reportType}
                        />
                    </div>

                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'Start Date'}
                            SelectedDate={reportListInput?.startDate || null}
                            handleDateChange={(date) => {
                                setReportListInput((pre) => ({ ...pre, startDate: date }))
                            }}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'End Date'}
                            SelectedDate={reportListInput?.endDate || null}
                            handleDateChange={(date) => {
                                setReportListInput((pre) => ({ ...pre, endDate: date }))
                            }}
                        />
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ReportFilter

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { uploadAttendance, uploadFile, updateAttendance, getAttendanceDetails } from '../../../repository/attendanceRepo'
import { toast } from 'react-toastify'
import { uploadFileUrlTask, uploadFileUrlTimeLog } from '../../../repository/taskRepo'
import FileImg from '../../../assets/icons/file.png'
import VideoIcon from '../../../assets/icons/video.png'
import PDFIcon from '../../../assets/icons/pdf.png'
import ExcelIcon from '../../../assets/icons/excel.png'
import TextFileIcon from '../../../assets/icons/textfile.png'
import { useNavigate, useParams } from 'react-router-dom'
import { requiredMessage } from '../../../utils'
import moment from 'moment'
// import useReduxStore from '../../../hooks/useReduxStore'
const useUploadAttendance = () => {
  // const { selector: user } = useReduxStore('auth')

  const { id } = useParams()
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadAttendanceInput, setuploadAttendanceInput] = useState({
    startDate: null,
  })
  const [error, setError] = useState({})
  const [updateAttendanceInput, setUpdateAttendanceInput] = useState({
    empId: "",
    empName: "",
    date: null,
    inTime: null,
    outTime: null,
    status: ''
  })
  const [attendError, setAttendError] = useState({})

  useEffect(() => {
    getDetails()
  }, [])

  const getDetails = async () => {
    setSpinner(true)
    try {
      let response = await getAttendanceDetails(id)
      let { empId, user_id, inTime, outTime, attendanceDate, status } = response?.data
      let fullName = `${user_id?.firstName} ${user_id?.lastName}`

      setUpdateAttendanceInput({
        empId: empId,
        empName: fullName,
        date: new Date(attendanceDate),
        inTime: inTime ? new Date(inTime) : null,
        outTime: outTime ? new Date(outTime) : null,
        status: status === 'NA' ? '' : status           

      })

    } catch (error) {
      setSpinner(false)
    }
    setSpinner(false)
  }


  // Handle Date
  const handleDateChange = (date, field) => {
    setUpdateAttendanceInput((prevTaskFormInputs) => {
      const updatedTasks = { ...prevTaskFormInputs }
      updatedTasks[field] = date
      return updatedTasks
    })
  }

  const handleTimeLogFileChange = async (files) => {
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      const fileExtension = fileData.name.split(".").pop();
      // Check if the file is of type 'application/vnd.ms-excel' (XLS)
      if (
        !["xls", "xlsx"].includes(fileExtension)
      ) {
        return toast.warn(`Invalid XLS file.`);
      }
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }

      fd.append(`files`, fileData);
    }

    fd.append('type', 'ATTENDANCE_IMPORT');
    console.log(fd,"asdfdg fwfffs")

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      // toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  // handle Remove File
  const handleLogRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }

  // file shhow
  const fileType = (file, fileIndex, fileType) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={fileType === "TIMELOG" ? uploadFileUrlTimeLog + file : uploadFileUrlTask + file} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }

  // Error Message
  const errorMessage = {
    startDate: requiredMessage("date"),
    selectedFiles: "Please Select file "
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!uploadAttendanceInput.startDate) {
      errorObj['startDate'] = errorMessage['startDate']
    }
    if (!selectedFiles.length) {
      errorObj['selectedFiles'] = errorMessage['selectedFiles']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  const AttendanceUpload = async () => {
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {

        date: moment(uploadAttendanceInput.startDate).format("YYYY-MM-DD"),
        fileName: selectedFiles[0]
      }
      let response = await uploadAttendance(payload)

      navigate('/attendance')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Error Message
  const errorUpdateMessage = {
    date: requiredMessage("date"),
    inTime: "Please Select inTime ",
    outTime: "Please Select outTime",
    status: "Please Select status",
  }

  // Error hendling
  const isUpdateValid = () => {
    const errorObj = {}
    if (!updateAttendanceInput.date) {
      errorObj['date'] = errorUpdateMessage['date']
    }
    if (updateAttendanceInput?.status === 'P') {
      if (!updateAttendanceInput.inTime) {
        errorObj['inTime'] = errorUpdateMessage['inTime']
      }
      if (!updateAttendanceInput.outTime) {
        errorObj['outTime'] = errorUpdateMessage['outTime']
      }
    }
    if (!updateAttendanceInput.status) {
      errorObj['status'] = errorUpdateMessage['status']
    }
    setAttendError(errorObj)
    return !Object.keys(errorObj)?.length
  }


  const handleupdateSubmit = async () => {
    if (!isUpdateValid()) return
    setSpinner(true)
    try {
      let payload = {
        _id: id,
        inTime: updateAttendanceInput.status === 'A' ? null : moment(updateAttendanceInput?.inTime).utc(),
        outTime: updateAttendanceInput.status === 'A' ? null : moment(updateAttendanceInput?.outTime).utc(),
        attendanceDate: moment(updateAttendanceInput?.date).format("YYYY-MM-DD"),
        attendanceStatus: updateAttendanceInput?.status
      }
      let response = await updateAttendance(payload)
      navigate('/attendance')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
      setSpinner(false)

    }
    setSpinner(false)
  }

  return {
    data: {
      isLoading: spinner,
      uploadAttendanceInput,
      uploadFileUrlTask,
      selectedFiles,
      error,
      updateAttendanceInput,
      attendError,
      spinner
    },
    methods: {
      fileType,
      setuploadAttendanceInput,
      setSelectedFiles,
      handleLogRemoveFile,
      handleTimeLogFileChange,
      AttendanceUpload,
      setError,
      setAttendError,
      handleDateChange,
      setUpdateAttendanceInput,
      handleupdateSubmit
    },
  }
}

export default useUploadAttendance
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage } from '../../../../utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { addSkill, getSkillDetails, updateSkill } from '../../../../repository/skillsRepo'
import useSkillList from './useSkillList'
const useSkillAddUpdate = () => {
  const { methods } = useSkillList()
  const { state } = useLocation()
  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(false)
  const [skillInputs, setSkillInputs] = useState({
    skillName: '',
  })
  const [skillFormModel, setSkillFormModel] = useState({
    open: false,
    data: '',
  })
  const [error, setError] = useState({})
  // const debounceDelay = 500 // Milliseconds

  useEffect(() => {
    if (skillFormModel?.data) {
      getDetails()
    }
  }, [skillFormModel])

  const getDetails = async () => {
    try {
      // setSpinner(true)
      let response = await getSkillDetails(skillFormModel?.data)
      const { skillName } = response.data
      setSkillInputs({
        skillName: skillName,
      })
    } catch (error) {
      toast.error(error.message)
    }
    // setSpinner(false)
  }

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setSkillInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    skillName: requiredMessage('skill name'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!skillInputs.skillName) {
      errorObj['skillName'] = errorMessage['skillName']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle add submit data..!
  const handleAddSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    let payload = {
      skillName: skillInputs.skillName,
    }
    try {
      let response = await addSkill(payload)
      setSkillFormModel((prev) => ({ ...prev, open: false }))
      setSkillInputs({ skillName: '' })
      methods.refetch()
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  // Handle Update submit data..!
  const handleupdateSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        _id: skillFormModel.data,
        skillName: skillInputs.skillName,
      }
      let response = await updateSkill(payload)
      setSkillFormModel((prev) => ({ ...prev, open: false }))
      setSkillInputs({ skillName: '' })
      methods.refetch()
      toast.success(response.message)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      spinner,
      error,
      skillInputs,
      state,
      skillFormModel,
    },
    methods: {
      setSkillInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      navigate,
      setSkillFormModel,
    },
  }
}

export default useSkillAddUpdate

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getShiftList } from '../../../../repository/shiftRepo'
import { requiredMessage, requiredSelect } from '../../../../utils'
import { EarnLeavePolicy, EarnLeavePolicyList } from '../../../../repository/leavePolicyRepo';
import { toast } from 'react-toastify';


const UseEarnLeave = () => {
    const [shiftList, setShiftList] = useState([])
    const [listData, setListData] = useState([])
    const [earnLeaveModal, setEarnLeaveModal] = useState({ open: false, data: {} })
    const [spinner, setSpinner] = useState(true)
    const [earnLeaveInputs, setEarnLeaveInputs] = useState({
        workingDays: '',
        servicePeriod: '',
        creditEarnLeave: '',
        shift: '',
        maxCarryForward: '',
        isActive: false,
    })
    const [error, setError] = useState('')


    // console.log(spinner, 'spinner')
    // console.log(earnLeaveModal, 'earnLeaveModal')
    useEffect(() => {
        list()
    }, [])

    useEffect(() => {
        shift()
    }, [])

    const shift = async () => {
        const payLoad = {
            isDeleted: false,
            page: 1,
            count: 100
        }
        setSpinner(true)
        try {
            let response = await getShiftList(payLoad)
            setShiftList(response.data)
            setSpinner(false)

        } catch (error) {
            setSpinner(false)
        }
    }
    const list = async () => {
        const payLoad = {
            isDeleted: false,
        }
        try {
            setSpinner(true)
            let response = await EarnLeavePolicyList(payLoad)
            setListData(response.data)
            setSpinner(false)

        } catch (error) {
            setSpinner(false)
            setListData([])
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setEarnLeaveInputs((pre) => ({
            ...pre,
            [name]: value
        }))
        setError((pre) => {
            delete pre[name];
            return { ...pre }
        })
    }
    const errorMessage = {
        workingDays: requiredMessage('working days'),
        servicePeriod: requiredMessage('service period'),
        creditEarnLeave: requiredMessage('credit earn leave'),
        maxCarryForward: requiredMessage('max carry forward'),
        shift: requiredSelect('shift'),
    }
    // Error handling
    const isValid = () => {
        const errorObj = {}
        if (!earnLeaveInputs?.workingDays) {
            errorObj['workingDays'] = errorMessage['workingDays']
        }
        if (!earnLeaveInputs?.servicePeriod) {
            errorObj['servicePeriod'] = errorMessage['servicePeriod']
        }
        if (!earnLeaveInputs?.creditEarnLeave) {
            errorObj['creditEarnLeave'] = errorMessage['creditEarnLeave']
        }
        if (!earnLeaveInputs?.shift) {
            errorObj['shift'] = errorMessage['shift']
        }
        if (!earnLeaveInputs?.maxCarryForward) {
            errorObj['maxCarryForward'] = errorMessage['maxCarryForward']
        }

        setError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const clearForm = () => {
        setEarnLeaveInputs({
            workingDays: '',
            servicePeriod: '',
            creditEarnLeave: '',
            shift: '',
            maxCarryForward: '',
        })
    }
 
    const getDetails = (item) => {
        setEarnLeaveInputs((pre) => ({
            ...pre,
            workingDays: item?.data?.days,
            servicePeriod: item?.data?.servicePeriod,
            creditEarnLeave: item?.data?.creditEL,
            shift: item?._id,
            maxCarryForward: item?.data?.maxCarryForward,
            isActive: !item?.data?.isActive
        }))
        setEarnLeaveModal((pre) => ({ ...pre, data: item, open: true }))
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        const payLoad = {
            days: earnLeaveInputs?.workingDays,
            creditEL: +earnLeaveInputs?.creditEarnLeave,
            shiftId: earnLeaveInputs?.shift,
            maxCarryForward: earnLeaveInputs?.maxCarryForward,
            servicePeriod: earnLeaveInputs?.servicePeriod,
            isDeleted: earnLeaveInputs?.isActive,
            id: earnLeaveModal?.data?.data?.elId ? earnLeaveModal?.data?.data?.elId : null
        }
        // console.log(payLoad, 'payLoad')
        // return
        try {
            setSpinner(true)
            let response = await EarnLeavePolicy(payLoad)
            toast.success(response.message)
            setSpinner(false)
            list()
            setEarnLeaveModal({ open: false, data: {} });
            clearForm()

        } catch (error) {
            setSpinner(false)
        }
    }
    return {
        methods: {
            shift,
            handleInputChange,
            clearForm,
            handleSubmit,
            setEarnLeaveModal,
            getDetails,
            setEarnLeaveInputs
        },
        data: {
            shiftList,
            earnLeaveInputs,
            error,
            earnLeaveModal,
            spinner,
            listData,
        }
    }
}

export default UseEarnLeave

import { axiosInstance, baseUrl } from './auth'
import { useQuery } from 'react-query'
import { ApiStatus } from '../constants'

// time log
export const getTimeLogList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/timelog/list`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
export function useToGetTimeLogList(params, options = {}) {
  return useQuery(['GET_Time_Log_LIST'], () => getTimeLogList(params), options)
}

// User Time Log List
export const getUserTimeLogList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/timelog/groupedList`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
export function useToGetUserTimeLogList(params, options = {}) {
  return useQuery(['GET_User_Time_Log_LIST'], () => getUserTimeLogList(params), options)
}

export const getTimeLogDetails = async (params) => {
  const response = await axiosInstance.get(`${baseUrl}/timelog/${params}`)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//Timelog Export
export const ExportTimelogtList = async params => {
  const response = await axiosInstance.post(`${baseUrl}/timelog/export`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// timelog Edit
export const updatesubmitTimelog = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/timelog`, params);
  if (response.data.code !== 200) {
      throw new Error(response.data.message)
  }
  return response.data
}
// updateApprovalStatus
export const TimeLogApprovalStatus = async params => {
  const response = await axiosInstance.post(`${baseUrl}/timelog/updateApprovalStatus`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// /timelog/delete
export const deleteTimeLog = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/timelog/delete`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// TimeLog Time Line History
export const timeLogTimeLineHistory = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/history`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

export function useToGetTimeLogTimeLineHistoryList(params, options = {}) {
  return useQuery(['GET_TIMELOG_TIMELINE_LIST'], () => timeLogTimeLineHistory(params), options)
}
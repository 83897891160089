/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Allroutes from './routes/Allroutes'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import 'rc-time-picker/assets/index.css'
import { UserTokenRefresh, axiosInstance } from './repository/auth'
import authHeader from './repository/auth-header'
import { useDispatch } from 'react-redux'
import { add } from './Slices/AuthSlice'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {

    // Function to refresh the access token (replace this with your own logic)
    const refreshAccessToken = async () => {
      let token = authHeader()
      let data = { refreshToken: token?.refreshToken }
      try {
        const response = await UserTokenRefresh(data);
        // console.log(response, "tokennn")
        const newAccessToken = response.data;
        dispatch(add(response.data))
        return newAccessToken;
      } catch (error) {
        // Handle errors, e.g., logout the user, redirect, etc.
        throw error;
      }
    };

    // Axios interceptor to refresh the access token on 401 errors
    axiosInstance.interceptors.response.use(async (response) => {
      // console.log(response,"responseresponseresponseresponse")
      return response
    },
      async (error) => {
       if (error.code === 'ECONNREFUSED') {
          console.error('Connection refused:', error.message);
          // Handle this error as you wish, e.g., show a message to the user
          // or redirect them to an error page.
          // For example:
          // window.location = '/error';
        }
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newAccessToken = await refreshAccessToken();
            if (newAccessToken) {
              // Replace the expired token with the new token
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken?.token}`;
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken?.token}`;
              // Retry the original request
              return axiosInstance(originalRequest);
            }
          } catch (error) {
            // Handle errors, e.g., logout the user, redirect, etc.
            throw error;
          }
        } else if (error.response.status === 403) {
          // console.log(error.response.status, "error.response.status ")
          setTimeout(() => {
            localStorage?.clear()
            window.location = '/';
            sessionStorage.setItem("authError",JSON.stringify("Your login session has timed out. Please log in again to continue."))
        }, 1500)
          // localStorage?.clear()
          // window.location = '/';

        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <Allroutes />
    </>
  )
}

export default App

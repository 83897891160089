/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { ExportAttendanceList, useToGetAttendanceList, deleteAttendance } from '../../../repository/attendanceRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import moment from 'moment'
import { toast } from 'react-toastify'
import { addFilter } from '../../../Slices/FiltersSlice'
import { useDispatch } from 'react-redux'
import { userFullList } from '../../../repository/teamManagementRepo'

const useToAttendanceList = () => {
  const { selector: user } = useReduxStore('auth')
  const { selector: AttendanceFilter } = useReduxStore('filters')
  // console.log(AttendanceFilter,"AttendanceFilter")
  const dispatch = useDispatch()
  const [controller, setController] = useState({ page:AttendanceFilter?.Attendance?.filters?.pagination|| 1, rowsPerPage: 10,searchPage:1})
  const [spinner, setSpinner] = useState(false)
  const [filterAction, setFilterAction] = useState(false)
  const [AttendanceView, setAttendanceView] = useState(AttendanceFilter?.Attendance?.filters?.viewType ||user?.userInfo?.role_id?.roleTitle==='ADMIN'?true:false)

  const [timeLogDetails, setTimeLogDetailsModel] = useState({
    open: false,
    timeLogId: '',
    data: {},
  })

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: ""
  })


  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')
  const [agentList, setAgentList] = useState([])

  const [attandenceListInput, setAttendanceListInput] = useState({
    agent: AttendanceFilter?.Attendance?.filters?.agent || [],
    status: AttendanceFilter?.Attendance?.filters?.status || [],
    startDate: AttendanceFilter?.Attendance?.filters?.startDate || null,
    endDate: AttendanceFilter?.Attendance?.filters?.endDate || null,
  })

  const cacheTime = { cacheTime: 10000 }
  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    isDeleted: false,
    //   search: search,
  }
  if (attandenceListInput?.agent?.length > 0) {
    payload.user_ids = attandenceListInput?.agent?.map((item) => item._id)
  }
  if (attandenceListInput.startDate) {
    payload.startDate = moment(attandenceListInput.startDate).format("YYYY-MM-DD")
  }
  if (attandenceListInput.endDate) {
    payload.endDate = moment(attandenceListInput.endDate).format("YYYY-MM-DD")
  }
  if (attandenceListInput?.status?.length) {
    payload.status = attandenceListInput.status.map(item => item.value)
  }

  useEffect(() => {
    // Dispatch the addFilter action with the current 'Attendance' page and the filter
    dispatch(addFilter({
      page: 'Attendance', filter: {
        agent: attandenceListInput.agent,
        status: attandenceListInput.status,
        startDate: attandenceListInput.startDate,
        endDate: attandenceListInput.endDate,
        viewType:AttendanceView,
        pagination:controller?.page
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attandenceListInput.status, attandenceListInput.startDate, attandenceListInput.endDate,AttendanceView,controller]);

  const {
    data: attandenceList,
    error: AttandenceError,
    isFetching: AttandenceIsFetching,
    refetch: refetchAttandence,
  } = useToGetAttendanceList(controller.page, payload, { 
    enabled: false,
    ...cacheTime,
  })


  useEffect(() => {
    refetchAttandence()
  }, [controller, filterAction]) 

  useEffect(() => {
    getAgentList()                              
  }, [])
  // Get All Agent  List
  const getAgentList = async () => {
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response.data)
      // response?.data?.forEach((item) => {
      //   if (item?._id === user?.userInfo?._id) {
      //     setAttendanceListInput((prevProps) => ({ ...prevProps, agent: AttendanceFilter?.Attendance?.filters?.agent || [item] }));
      //   }
      // });
    } catch (error) {
      setAgentList([])
      setAttendanceListInput((prevProps) => ({ ...prevProps, agent: '' }))
    }
    setSpinner(false)
  }

  // Export Attendance Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportAttendanceList(payload)

      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchAttandence();
    });
    // setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 },()=>{
    //   refetchAttandence();
    // }))
  };
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
    setAttendanceListInput({
      agent: [],
      status: [],
      startDate: null,
      endDate: null,
    })
    setController({ page: 1, rowsPerPage: 10 })
    // setController((pre) => ({ ...pre, page: pre.searchPage, searchPage: 1 }))
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }
  const handleDelete = async () => {
    try {
      if (deleteModal?.data?.date && !deleteModal?.date) return toast.warn("Please select date")
      let payload = {
        // isDeleted: deleteModal?.data?.isDeleted,
      }
      if (deleteModal?.data?._id) {
        payload._id = deleteModal?.data?._id
      }
      if (deleteModal?.data?.date) {
        payload.date = moment(deleteModal?.date).format("YYYY-MM-DD")
      }
      setSpinner(true)
      let response = await deleteAttendance(payload)
      toast.success(response.message)
      setDeleteModal({ data: '', open: false })
      refetchAttandence()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  return {
    data: {
      controller,
      isLoading: spinner || AttandenceIsFetching,
      totalCount: AttandenceError?.message === 'No record found' ? 0 : attandenceList?.totalCount || 0,
      attandenceList: AttandenceError?.message === 'No record found' ? [] : attandenceList?.data || [],
      agentList,
      search,
      statusType,
      attandenceListInput,
      timeLogDetails,
      attendancePermission: user,
      deleteModal,
      AttendanceView
    },
    methods: {
      setTimeLogDetailsModel,
      // getLogDetails,
      setController,
      setAttendanceListInput,
      handlePagination,
      resetFilter,
      applayFilter,
      setSearchText,
      setStatusType,
      // getAgentList,
      Export,
      setDeleteModal,
      handleDelete,
      setAttendanceView
    },
  }
}

export default useToAttendanceList
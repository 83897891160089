import React from 'react';
import { Button, Switch } from '@mui/material';
import { SandwichList, LeaveTypeList, TypeList, EntitledLeavesList, ApprovalLevels } from '../../../constants';
import useLeavePolicyAddUpdate from "./Hooks/useLeavePolicyAddUpdate"
import SelectInput from '../../../components/SelectInput'
import TextInput from '../../../components/TextField'
import MultipleSelect from "../../../components/MultipleSelect"
import { Spinner } from '../../../components/Spinner/Spinner';
import { ToastContainer } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear'
import DateInput from "../../../components/DateInput"


const LeavePolicyForm = () => {
    const { data, methods } = useLeavePolicyAddUpdate()
    const {
        spinner,
        leavePloicyForm,
        leavePolicyError,
        leavePolicyId,
        shiftList,
        RoleList,
        approvalLevelInput,
        errors,
        splitLocation
    } = data

    const {
        handleInputChange,
        handleDateChange,
        submit,
        Update,
        addMultipleBreak,
        removeBreak,
        handleBreakInput,
        setErrors
    } = methods
    console.log(leavePloicyForm, 'leavePloicyForm')
    console.log(leavePolicyError, 'leavePolicyError')
    return (<>
        {spinner && <Spinner />}
        {<ToastContainer />}
        <div className='bg-white p-4 mx-4'>
            <div className='row'>
                {
                    leavePloicyForm?.name !== 'Earned Leave' &&
                        <>
                            <div className="col-lg-4 my-2">
                                <TextInput
                                    Lable="Name"
                                    Name="name"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={(e) => handleInputChange(e)}
                                    // onBlur={handleBlur}
                                    Value={leavePloicyForm.name}
                                    width={100}
                                //   Error={error.ticketId}
                                />
                                {leavePolicyError?.name && <span className='text-danger'>{leavePolicyError.name}</span>}
                            </div>
                            <div className="col-lg-4 my-2">
                                <TextInput
                                    Lable="Code"
                                    Name="code"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={(e) => handleInputChange(e)}
                                    // onBlur={handleBlur}
                                    Value={leavePloicyForm.code}
                                    width={100}
                                //   Error={error.ticketId}
                                />
                                {leavePolicyError?.code && <span className='text-danger'>{leavePolicyError.code}</span>}
                            </div>
                            <div className="col-lg-4 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Type'}
                                    // multiple={true}
                                    Options={TypeList}
                                    keyName={'title'}
                                    Name="type"
                                    SelectValue={leavePloicyForm.type}
                                    HandleChange={(e) => handleInputChange(e)}
                                    Error={leavePolicyError.type}
                                    variant={'outlined'}
                                    isRequired={true}
                                // disabled={leavePloicyForm?.graceInAction == false}
                                />
                                {/* {console.log(leavePloicyForm.year, "leavePloicyForm.year")} */}
                            </div>
                            <div className="col-lg-4 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Leave Type'}
                                    // multiple={true}
                                    Options={LeaveTypeList}
                                    keyName={'title'}
                                    Name="leaveType"
                                    SelectValue={leavePloicyForm.leaveType}
                                    HandleChange={(e) => handleInputChange(e)}
                                    Error={leavePolicyError.leaveType}
                                    variant={'outlined'}
                                    isRequired={true}
                                // disabled={leavePloicyForm?.graceInAction == false}
                                />
                            </div>
                            {
                                splitLocation[3] === 'leave' ? null
                                    :
                                    <div className="col-lg-4 my-2">
                                        <SelectInput
                                            Lable={'Shift'}
                                            multiple={true}
                                            Options={shiftList}
                                            keyName={'name'}
                                            className="form-field-design"
                                            Name="shifts"
                                            SelectValue={leavePloicyForm.shifts}
                                            HandleChange={handleInputChange}
                                            // onBlur={handleBlur}
                                            Error={leavePolicyError.shifts}
                                            variant={'outlined'}
                                            isRequired={true}
                                        />
                                    </div>
                            }

                            <div className="col-lg-4 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Entitled Leaves'}
                                    // multiple={true}
                                    Options={EntitledLeavesList}
                                    keyName={'title'}
                                    Name="entitledLeaves"
                                    SelectValue={leavePloicyForm.entitledLeaves}
                                    HandleChange={(e) => handleInputChange(e)}
                                    // onBlur={handleBlur}
                                    Error={leavePolicyError.entitledLeaves}
                                    variant={'outlined'}
                                    isRequired={true}
                                // disabled={leavePloicyForm?.graceInAction == false}
                                />
                            </div>


                            <div className="col-lg-4 my-2">
                                <DateInput
                                    size="small"
                                    variant="outlined"
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'Start Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'policyStartDate')}
                                    SelectedDate={leavePloicyForm?.policyStartDate}
                                />
                                {leavePolicyError?.policyStartDate && <span className='text-danger'>{leavePolicyError?.policyStartDate}</span>}
                            </div>
                            <div className="col-lg-4 my-2">
                                <DateInput
                                    size="small"
                                    variant="outlined"
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'End Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'policyEndDate')}
                                    SelectedDate={leavePloicyForm?.policyEndDate}
                                />
                                {leavePolicyError?.policyEndDate && <span className='text-danger'>{leavePolicyError?.policyEndDate}</span>}
                            </div>
                            {
                                splitLocation[3] === 'leave' ? null
                                    :
                                    <div className="col-lg-4 my-2 position-relative mt-3">
                                        <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '23px', zIndex: '1' }}>
                                            <Switch name="sandwichAction" checked={leavePloicyForm?.sandwichAction}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                        <MultipleSelect
                                            label="Sandwich"
                                            isRequired={leavePloicyForm?.sandwichAction && true}
                                            multiple={true}
                                            options={SandwichList}
                                            searchBy={"title"}
                                            keyName={"title"}
                                            name={"sandwich"}
                                            onChange={(e) => handleInputChange({ target: { name: "sandwich", value: e } })}
                                            value={"value"}
                                            selectedValues={leavePloicyForm?.sandwich}
                                            disabled={leavePloicyForm?.sandwichAction === false}
                                        // error={error.sandwich}
                                        />
                                        {leavePolicyError?.sandwich && <span className='text-danger'>{leavePolicyError?.sandwich}</span>}

                                    </div>
                            }
                            {
                                splitLocation[3] === 'leave' ? null
                                    :
                                    <div className="col-lg-4 my-2  mt-3">
                                        <div className="row">

                                            <div className="col-4 ">
                                                <label htmlFor="carryForward" className='text-secondary'> Carry Forward</label>
                                                <Switch name="carryForward" checked={leavePloicyForm?.carryForward}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>
                                            <div className="col-lg-8 ">
                                                <SelectInput
                                                    LabelButton
                                                    Lable={'Max Carry Forward'}
                                                    // multiple={true}
                                                    Options={EntitledLeavesList}
                                                    keyName={'title'}
                                                    Name="maxCarryForward"
                                                    SelectValue={leavePloicyForm.maxCarryForward}
                                                    HandleChange={(e) => handleInputChange(e)}
                                                    // onBlur={handleBlur}
                                                    Error={leavePolicyError.maxCarryForward}
                                                    variant={'outlined'}
                                                    isRequired={leavePloicyForm?.carryForward && true}
                                                    disabled={leavePloicyForm?.carryForward === false}
                                                />
                                            </div>
                                        </div>
                                        {/* {leavePolicyError?.maxCarryForward && <span className='text-danger'>{leavePolicyError?.maxCarryForward}</span>} */}
                                    </div>
                            }
                            <div className="col-lg-4 my-2"></div>

                        </>
                }
                <div className="col-lg-12 my-3 d-flex justify-content-between">
                    <div className='fw-bold mt-3'>Approval Matrix</div>
                    <Button size='small' variant='contained' color='success' className='' onClick={() => { addMultipleBreak() }}>
                        <AddIcon />Approval Level
                    </Button>
                </div>
                <hr />

                {
                    approvalLevelInput?.map((item, index) =>
                        <div className='d-flex'>
                            {/* <div className='mt-2'>{index+1}</div> */}
                            <div style={{ marginTop: '32px', width: "20px", fontSize: '15px' }}>
                                {index + 1}.
                            </div>
                            <div className="col-lg-3 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Approval Level'}
                                    Options={ApprovalLevels}
                                    keyName={'title'}
                                    Name="approvalLevel"
                                    SelectValue={approvalLevelInput[index].approvalLevel}
                                    HandleChange={(e) => {
                                        handleBreakInput(e, index);
                                        setErrors((prevErrors) => {
                                            const updatedErrors = [...prevErrors];
                                            updatedErrors[index] = { approvalLevel: '' };
                                            return updatedErrors;
                                        });
                                    }}
                                    variant={'outlined'}
                                    isRequired={true}
                                />
                                {errors[index]?.approvalLevel && <span className='text-danger'>{errors[index]?.approvalLevel}</span>}
                            </div>
                            <div className="col-lg-3 my-2 ms-4">
                                {
                                    approvalLevelInput[index].approvalLevel === 'Role' ?
                                        <SelectInput
                                            LabelButton
                                            Lable={'Role'}
                                            Options={RoleList}
                                            keyName={'roleTitle'}
                                            Name="role"
                                            SelectValue={approvalLevelInput[index].role}
                                            HandleChange={(e) => {
                                                handleBreakInput(e, index);
                                                setErrors((prevErrors) => {
                                                    const updatedErrors = [...prevErrors];
                                                    updatedErrors[index] = { role: '' };
                                                    return updatedErrors;
                                                });
                                            }}

                                            // onBlur={handleBlur}
                                            // Error={leavePolicyError.role}
                                            variant={'outlined'}
                                            isRequired={true}
                                        />

                                        : null
                                }
                                {errors[index]?.role && <span className='text-danger'>{errors[index]?.role}</span>}
                            </div>
                            <div className="col-lg-3 ms-4 mt-4">
                                <label htmlFor="permissionLeaveType" className='text-secondary mt-3'> Permission Leave Type</label>
                                <Switch name="permissionLeaveType" checked={approvalLevelInput[index]?.permissionLeaveType == true}
                                    onChange={(e) => { handleBreakInput(e, index); console.log(e?.target?.checked, 'LLLLLLLLLLLL') }}
                                />
                            </div>
                            <div className="col-lg-3 ms-4" style={{ marginTop: '25px' }}>
                                {approvalLevelInput?.length > 1 && index !== 0 &&
                                    <Button variant="outlined" size='small' color="error" onClick={() => removeBreak(index)}>
                                        <ClearIcon />
                                    </Button>
                                }
                            </div>
                        </div>
                    )
                }
                <div className="col-12 text-end my-3">
                    {
                        leavePolicyId !== "add" ?
                            <Button variant="contained" onClick={Update} color='info'> Update</Button>
                            :
                            <Button variant="contained" onClick={submit} color='info'> submit</Button>
                    }
                </div>
            </div>
        </div>
    </>
    );
}

export default LeavePolicyForm
import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
import Modal from '../../../components/Modal'
import useReportList from './Hooks/useReportList'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import ReportFilter from './ReportFilter'
import DateInput from '../../../components/DateInput'
import MultipleSelect from "../../../components/MultipleSelect"


//import {  Typography, Box } from '@mui/material'; 
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Slider from '@mui/material/Slider';s
const ReportsList = () => {

    const { data, methods } = useReportList()
    const { reportList, agentList, projectListData, isLoading, userUtilisationModal, userUtilisationInputs, ShiftPermission } = data
    const { setUserUtilisationModal, Export, handleUtilisation, setUserUtilisationInputs, clearuserUtilisationFunction } = methods
    const {  export: exportFile } = ShiftPermission?.userInfo?.permission?.project

    // console.log(reportList, "reportList")
    const keys = reportList.length ? Object?.keys(reportList[0]) : []

    function getRandomColor() {
        // Generating random values for RGB
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);

        // Constructing RGB color string
        return `rgb(${r},${g},${b})`;
    }
    return (
        <>
            <ToastContainer />
            {isLoading && <Spinner />}
            <div className='row'>
                <div className="col-12">
                    {exportFile?.value && <div className="text-end m-2">
                        <Button className='me-2' startIcon={<ExitToAppIcon />} variant="contained" onClick={() => setUserUtilisationModal((prev) => ({ ...prev, open: true }))}>User Utilisation Export</Button>
                        <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
                    </div>}
                </div>
                <div className="col-10">
                    <div className="bg shadow raduus py-2 d-flex align-items-center justify-content-center" style={{ minHeight: "50%" }}>
                        {reportList.length ? <ResponsiveContainer width={'100%'} height={618}>
                            <BarChart
                                data={reportList}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Brush dataKey="name" height={30} stroke="#8884d8" />
                                {
                                    keys.map((item, i) =>
                                        <Bar dataKey={item} fill={getRandomColor()} activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                    )
                                }
                            </BarChart>
                        </ResponsiveContainer>
                            :
                            <div className='text-center' >No record found</div>
                        }
                    </div>
                </div>
                <div className="col-2">
                    <ReportFilter data={data} methods={methods} />
                </div>
            </div>

            <Modal
                size="md"
                show={userUtilisationModal?.open}
                onHide={() => { clearuserUtilisationFunction(); setUserUtilisationModal({ data: "", open: false }) }}
                headerContent={<h5 className="py-2"> User Utilisation </h5>}
                bodyContent={
                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <MultipleSelect
                                label={"Agent"}
                                multiple={true}
                                options={agentList}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"agent"}
                                onChange={(e) => setUserUtilisationInputs((prevProps) => ({ ...prevProps, agent: e }))}
                                value={"_id"}
                                selectedValues={userUtilisationInputs?.agent}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <MultipleSelect
                                label={"Project"}
                                multiple={true}
                                options={projectListData}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"Projects"}
                                onChange={(e) => setUserUtilisationInputs((prevProps) => ({ ...prevProps, Projects: e }))}
                                value={"_id"}
                                selectedValues={userUtilisationInputs?.Projects}
                            />
                        </div>

                        <div className="col-lg-6 mb-2">
                            <DateInput
                                size="small"
                                variant="outlined"
                                className="w-100 form-field-design"
                                // isRequired={true}
                                Label={'Start Date'}
                                SelectedDate={userUtilisationInputs?.startDate || null}
                                handleDateChange={(date) => {
                                    setUserUtilisationInputs((pre) => ({ ...pre, startDate: date }))
                                }}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <DateInput
                                size="small"
                                variant="outlined"
                                className="w-100 form-field-design"
                                // isRequired={true}
                                Label={'End Date'}
                                SelectedDate={userUtilisationInputs?.endDate || null}
                                handleDateChange={(date) => {
                                    setUserUtilisationInputs((pre) => ({ ...pre, endDate: date }))
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="outlined" className="ms-2" onClick={() => { clearuserUtilisationFunction(); setUserUtilisationModal({ data: "", open: false }) }} data-bs-dismiss="modal">
                                Close
                            </Button>
                            <Button className="ms-2" variant="contained" onClick={handleUtilisation}>
                                submit
                            </Button>
                        </div>
                    </div>
                }
            />

        </>
    );
}

export default ReportsList
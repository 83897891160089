/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Pagination } from '../../components/pagination/Pagination'

import { roleList } from '../../repository/auth'
import { Spinner } from '../../components/Spinner/Spinner'
import { ToastContainer } from 'react-toastify'
import useReduxStore from '../../hooks/useReduxStore'

const RolesPermissions = () => {
  const { selector: userPermission } = useReduxStore('auth')
  const { add, edit, } = userPermission?.userInfo?.permission?.rolesAndPermission
  const navigate = useNavigate()
  const [RoleList, setRoleList] = useState([])
  const [totalCount, settotalCount] = useState(0)
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  useEffect(() => {
    getRoleList()
  }, [controller])
  const [spinner, setSpinner] = useState(false)

  // Get Role List
  const getRoleList = async () => {
    let payLoad = {
      count: controller.rowsPerPage,
      page: controller.page,
      search: '',
    }
    setSpinner(true)
    try {
      let response = await roleList(payLoad)
      setRoleList(response.data)
      settotalCount(response.totalCount)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }
  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex"></div>
            <div className="d-flex pe-1">
              {add?.value && <div className="ms-2">
                <Button color="success" variant="contained" onClick={() => navigate(`/roles-&-permissions/role-form/add`)} startIcon={<AddIcon />}>
                  Add New
                </Button>
              </div>}
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-10 ticet">
            <div className="bg shadow raduus pt-2">
              <table className="table">
                <tbody>
                  {RoleList?.map((item, index) => {
                    return (
                      <tr
                        key={item._id + index}
                        className="rounded-row "
                        style={{
                          borderRadius: '50px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <td onClick={() => edit.value && navigate(`/roles-&-permissions/role-form/${item._id}`, { state: { userId: item._id } })}>
                          <p className="table-link m-0 p-0">{item.roleTitle}</p>
                          <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                        </td>
                        <td className="table-link">{`${item.users} ${item?.roleTitle}`}</td>
                      </tr>
                    )
                  })}
                  {!RoleList.length && (
                    <tr style={{ height: '10px', textAlign: 'center', margin: '8px' }}>
                      <td colSpan={20}>No record found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination count={totalCount} controller={controller} setController={setController} />
              {/* </div> */}
            </div>
          </div>
          <div className="col-lg-2 ticet mt-2">
            <div className="shadow bg raduus p-2">
              <div className="d-flex justify-content-between pb-2">
                <div className="col-lg-10">
                  <h6 className="text-start">Understanding Roles</h6>
                </div>
              </div>
              <div className="Understanding ">
                <p>
                  Roles allow you to create and edit access permissions for agents. You can create new roles, specify what actions agents with these roles can perform within your help desk, and assign
                  the role to agents.
                </p>
                <p>For example, you can create a role for your Support Co-ordinators, allowing them to update fields and assign tickets, and even add notes internally, but not reply to customers.</p>
                <p>
                  Once you create and save a new Role you will be able to assign it to agents when you create or edit their profile by clicking on the Agents icon under the admin tab. <br /> You can
                  have up to 100 roles in your account.
                </p>
                <div className="col-lg-12 py-2">
                  <h6 className="text-start">Admin Privileges</h6>
                  <p>
                    You can nominate whether you want an agent to have access to settings under the Admin tab. Agents with admin access can be Operation Agents with limited access, or Super Admins
                    with the ability to edit all configurations. You can have as many Super Admins with the ability to view and modify your billing details, or as few as one.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolesPermissions

import React from 'react'
import { Button } from '@mui/material'
import { hoursTypeList, actutalStatusList } from '../../constants'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import MultipleSelect from "../../components/MultipleSelect"

const TimeLogFilters = (props) => {
    const { data, methods } = props
    const { projectList, timeLogListInput } = data
    const { resetFilter, applayFilter, setTimeLogListInput } = methods

    const statusList = [
        { color: '#97FFF4', title: 'Approved', value: 'APPROVED' },
        { color: '#793FDF', title: 'UnApproved', value: 'UNAPPROVED' },
        // { color: '#99FFF4', title: 'Rejected', value: 'REJECTED' },
    ]

    return (
        <>
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                <div className="filter">
                    <div className="col-lg-11 mx-auto mb-2">
                        {/* <MultipleSelect
                            label={'Approval Status'}
                            options={statusList}
                            keyName={'title'}
                            name="approvalstatus"
                            value={timeLogListInput?.approvalstatus}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, approvalstatus: e }))}
                            multiple={true}
                        /> */}
                        <MultipleSelect
                            label={"Approval Status"}
                            multiple={true}
                            options={statusList}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"approvalstatus"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, approvalstatus: e }))}
                            value={"value"}
                            selectedValues={timeLogListInput?.approvalstatus}
                        />

                    </div>
                    <div className="col-lg-11 mx-auto mb-2 ">
                        <MultipleSelect
                            label={"Hours Type"}
                            multiple={true}
                            options={hoursTypeList}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"hoursType"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, hoursType: e }))}
                            value={"value"}
                            selectedValues={timeLogListInput?.hoursType}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2 ">
                        <MultipleSelect
                            label={"Actutal Status"}
                            multiple={true}
                            options={actutalStatusList}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"actutalStatus"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, actutalStatus: e }))}
                            value={"value"}
                            selectedValues={timeLogListInput?.actutalStatus}
                        />
                        {/* <MultipleSelect
                            label={'Actutal Status'}
                            options={actutalStatusList}
                            keyName={'title'}
                            name="actutalStatus"
                            value={timeLogListInput?.actutalStatus}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, actutalStatus: e }))}
                            multiple={true}
                        /> */}
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <MultipleSelect
                            label={"Project"}
                            multiple={true}
                            options={projectList}
                            searchBy={"name"}
                            keyName={"name"}
                            name={"Projects"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, Projects: e }))}
                            value={"_id"}
                            selectedValues={timeLogListInput?.Projects}
                        />
                        {/* <MultipleSelect
                            label={'Project'}
                            options={projectList}
                            keyName={'name'}
                            name="Projects"
                            value={timeLogListInput?.Projects}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, Projects: e }))}
                            multiple={true}
                        /> */}
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </>
    )
}

export default TimeLogFilters
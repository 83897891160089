import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getSkillList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/skill/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetSkillList(params, options = {}) {
    return useQuery(['GET_Skill_LIST',], () => getSkillList(params), options)
}

// All skills List
export const getAllSkillList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/skill/fullList`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
// Add Skill
export const addSkill = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/skill`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Skill Details
export const getSkillDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/skill/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update Skill
export const updateSkill = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/skill`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}


// Delete Skill
export const deleteSkill = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/skill/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
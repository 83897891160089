/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { HolidayType } from '../../../constants';
import { requiredMessage, requiredSelect } from '../../../utils';
import { updateShift } from '../../../repository/shiftRepo';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { AttendanceCalanderList, AttendanceCalanderDetails } from '../../../repository/attendanceRepo';
import { LeaveCountList, addLeave, allLeavePolicyList } from '../../../repository/leavePolicyRepo';
import useReduxStore from '../../../hooks/useReduxStore';
import { uploadFile } from '../../../repository/taskRepo';

const useLeaveForm = () => {
    const navigate = useNavigate()
    const { selector: user } = useReduxStore('auth')
    // const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const fileInputRef = useRef(null)
    // Format the date as YYYY-MM-DD
    // const formattedDate = currentDate.format('YYYY-MM-DD');
    const { id } = useParams()
    const [spinner, setSpinner] = useState(false)
    const [shiftForm, setShiftForm] = useState({
        shiftName: "",
        description: "",
        year: id !== "add" ? null : currentYear,
        startTiming: null,
        endTiming: null,
        graceInAction: false,
        graceIn: 10,
        graceOutAction: false,
        graceOut: 10,
        timeZone: ""
    })
    const [leaveType, setLeaveType] = React.useState('');
    const [shiftError, setShiftError] = useState({})
    const [dayDetailModal, setDayDetailModal] = useState({
        open: false,
        data: [],
        description: ""
    })

    const [offDaysObj, setOffDayObj] = useState({
        selectedOffDays: 0,
        offDay: false
    });

    const [leaveForm, setLeaveForm] = useState({
        leaveType: "",
        leave: null,
        subLeaveType: null,
        appliedStartDate: null,
        appliedEndDate: null,
        reason: ""
    })
    // console.log(leaveForm,'leaveForm')
    const [leaveError, setLeaveError] = useState({})

    const [leaveApplyModal, setLeaveApplyModal] = useState({
        open: false,
        data: ""
    })
    const [leavePolicydata, setLeavePolicydata] = useState([])
    const [currentMonth, setCurrentMonth] = useState(new Date()); // Initialize with January (month 1)
    const [OneDayDetailModal, setOnedayDetailModal] = useState({
        open: false,
        data: ""
    })

    const [calendarData, setCalendarData] = useState({})

    const [leavePolicyCount, setLeavePolicyCount] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedDate, setSelectedDate] = useState('')

    // useEffect(() => {
    //     getLeaveCount()
    // }, [])
    const getLeaveCount = async () => {
        try {
            setSpinner(true)
            let response = await LeaveCountList()
            setLeavePolicyCount(response?.data)
        } catch (error) {

        }
        setSpinner(false)
    }
    // console.log(calendarData,'calendarData')

    // Function to get details
    const getDetails = async () => {
        try {
            let payload = {
                viewType: "CALENDAR",
                monthDate: moment(currentMonth).format('YYYY-MM-DD')
            };
            setSpinner(true);
            let response = await AttendanceCalanderList(payload);
            createCalendar(response?.data?.shiftData)
        } catch (error) {
            // Handle error
        }
        setSpinner(false);
    };


    const createCalendar = async (shiftData) => {
        let shiftDataArr = shiftData
        let ArrLength = 0
        switch (shiftDataArr.days[0].dayOfWeek) {
            case 'Tue':
                ArrLength = 1;
                break;
            case 'Wed':
                ArrLength = 2;
                break;
            case 'Thu':
                ArrLength = 3;
                break;
            case 'Fri':
                ArrLength = 4;
                break;
            case 'Sat':
                ArrLength = 5;
                break;
            case 'Sun':
                ArrLength = 6;
                break;
            default:
                break;
        }
        for (let index = 0; index < ArrLength; index++) {
            shiftDataArr.days.unshift({ isDay: true })
        }
        setCalendarData(shiftDataArr)
    }

    const GetDayDetails = async (SelectedDate) => {
        try {
            let payload = {
                // viewType: "CALENDAR",
                date: moment(SelectedDate || selectedDate).format('YYYY-MM-DD')
            };
            setSelectedDate(SelectedDate)
            setSpinner(true);
            let response = await AttendanceCalanderDetails(payload);
            // console.log(response, "response")
            setOnedayDetailModal((prev) => ({ ...prev, data: response.data, open: true, date: SelectedDate }))
            // setCalendarData(response?.data?.shiftData)

        } catch (error) {
            // console.log(error, "err")
            setOnedayDetailModal((prev) => ({ ...prev, data: "", open: true, date: SelectedDate }))
            // Handle error
        }
        setSpinner(false);
    }

    const getLeavePolicyList = async () => {
        try {
            let response = await allLeavePolicyList()
            setLeavePolicydata(response.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        getLeavePolicyList()
    }, [])
    // useEffect to get details on component mount or when currentMonth changes
    useEffect(() => {
        if (id !== "add") {
            getDetails()
        }
    }, [currentMonth]);

    // change Calendar baseed on year
    // useEffect(() => {
    //     setCalendarData(getMonthsInYear(shiftForm.year || currentYear))
    //     setOffDayObj({
    //         selectedOffDays: 0,
    //         offDay: false
    //     })

    // }, [shiftForm.year, currentYear])


    // Handle Form Input's
    const handleInputChange = (event, dateRange = null) => {
        const { value, name, } = event.target;

        setLeaveForm((prev) => {
            const updatedShiftForm = { ...prev };
            updatedShiftForm[name] = value;

            // If the date range is provided, update the appliedDate field
            if (dateRange !== null) {
                updatedShiftForm.appliedDate = dateRange;
            }

            // Clear errors for the current field
            setLeaveError((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name !== "sandwichAction") {
                    updatedErrors[name] = null;
                }
                return updatedErrors;
            });

            return updatedShiftForm;
        });
    };

    // Handle Date
    const handleDateChange = (date, field) => {
        setLeaveForm((prev) => {
            const updatedShiftForm = { ...prev }
            updatedShiftForm[field] = date
            return updatedShiftForm
        })
        setLeaveError((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[field] = null;
            return updatedErrors;
        });
    }
    // Error Message
    const errorMessage = {
        leaveType: requiredSelect('leave'),
        leave: requiredSelect('leave type'),
        subLeaveType: requiredSelect('sub leave type'),
        appliedStartDate: requiredMessage('start date'),
        appliedEndDate: requiredMessage('end date'),
        reason: requiredSelect('reason'),
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!leaveForm.leaveType) {
            errorObj['leaveType'] = errorMessage['leaveType']
        }
        if (leaveType === 'NORMAL') {
            if (!leaveForm.leave) {
                errorObj['leave'] = errorMessage['leave']
            }
        }
        if (leaveForm.leave === 'HALF' || leaveType === 'SHORT') {
            if (!leaveForm.subLeaveType) {
                errorObj['subLeaveType'] = errorMessage['subLeaveType']
            }
        }
        if (!leaveForm.reason) {
            errorObj['reason'] = errorMessage['reason']
        }
        if (!leaveForm.appliedStartDate) {
            errorObj['appliedStartDate'] = errorMessage['appliedStartDate']
        }
        if (!leaveForm.appliedEndDate) {
            errorObj['appliedEndDate'] = errorMessage['appliedEndDate']
        }

        setLeaveError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const checkType = (id) => {
        const type = leavePolicyCount?.find((item) => item?._id === id)
        setLeaveType(type?.leaveType)
    }

    // add Leave 
    const submit = async () => {
        if (!isValid()) return
        setSpinner(true)

        let shiftStartDateTime = moment.utc(leaveForm.shiftData.startDateTime).format("HH:mm")
        let shiftEndDateTime = moment.utc(leaveForm.shiftData.endDateTime).format("HH:mm")

        // const fullStartTime = moment(moment(leaveForm.appliedStartDate).format('YYYY-MM-DD') + ' ' + moment(leaveForm?.startDateFormat).format("HH:mm")).utc()
        // const fullEndTime = moment(moment(leaveForm.appliedEndDate).format('YYYY-MM-DD') + ' ' + moment(leaveForm?.endDateFormat).format("HH:mm")).utc()


        // console.log('leaveForm.appliedStartDate', leaveForm.appliedStartDate)


        const fullStartTime = moment.utc(leaveForm.appliedStartDate).set({ hour: shiftStartDateTime.split(":")[0], minutes: shiftStartDateTime.split(":")[1] })
        const fullEndTime = moment.utc(leaveForm.appliedEndDate).set({ hour: shiftEndDateTime.split(":")[0], minutes: shiftEndDateTime.split(":")[1] })


        // console.log('fullStartTime', fullStartTime)

        let payload = {
            policy_id: leaveForm.leaveType,
            appliedStartDate: fullStartTime,
            appliedEndDate: fullEndTime,
            reason: leaveForm.reason,
            attachments: selectedFiles,
            subLeaveType: leaveForm?.subLeaveType,
            leave: leaveForm?.leave
        }
        // console.log('payload', payload)
        //    return
        try {
            let response = await addLeave(payload)
            getDetails()
            setLeaveApplyModal({ open: false, data: "" })
            setLeaveForm({
                leaveType: "",
                appliedStartDate: null,
                appliedEndDate: null,
                reason: "",
                leave: null,
                subLeaveType: null,
            })
            setLeaveType()
            setSelectedFiles([])
            getLeaveCount()
            GetDayDetails()
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    // Update Shift 
    const Update = async () => {
        if (!isValid()) return
        setSpinner(true)
        let payload = {
            _id: id,
            // calendarData,
            name: shiftForm.shiftName,
            description: shiftForm.shiftName,
            year: shiftForm.year,
            startTime: shiftForm.startTiming,
            endTime: shiftForm.endTiming,
            graceIn: shiftForm.graceIn,
            graceOut: shiftForm.graceOut,
            timezone: shiftForm.timeZone[0].timezone,
        }
        try {
            let response = await updateShift(payload)
            setTimeout(() => {
                toast.success(response.message)
            }, 500);
            navigate('/setting/shifts')
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    const handleOffDays = (type) => {
        setOffDayObj((prev) => ({ ...prev, offDay: type === HolidayType.OffDays }));
        // setCompDayObj((prev) => ({ ...prev, compDay: type === HolidayType.Compensatoryoff }));
        // setPubHoliDayObj((prev) => ({ ...prev, pubHoliDay: type === HolidayType.PublicHolidays }));
        // setRestDayObj((prev) => ({ ...prev, restDay: type === HolidayType.RestDays }))
    };

    // Handle Checked Day's
    const handleCheckDays = (event, innerIndex) => {

        setLeaveApplyModal((prev) => ({ ...prev, open: true }))
    };

    // calendar Focus Function
    const focusHandle = () => {
        if (offDaysObj?.offDay) {
            return "1px solid #f3963b"
        }
        // if (restDaysObj.restDay) {
        //     return "1px solid #3fa7f3"
        // }
        // if (CompDaysObj.compDay) {
        //     return "1px solid #aa3b19"
        // }
        // if (pubHoliDaysObj.pubHoliDay) {
        //     return "1px solid #78c223"
        // }
        return "1px solid"
    }

    let colorsList = {
        UNAPPROVED: "#9b02f7",
        APPROVED: "#02f739",
        REJECTED: "#f1db2f",
        PENDING: "#077E8C",
        Present: "#02f7b1",
        Absent: "#ff0d48e3",
        MIS: "#0d6efd3b",
        compensationDay: "#aa3b19cc",
        restDay: "#70afdde0",
        offDay: "#f3963bdb",
        publicHoliday: "#78c223c4"
    }



    const BoxType = (item) => {
        let colors = [""];

        // Handling leave status
        if (item?.leaves?.REJECTED === 1) {
            colors.push(colorsList.REJECTED);
        }
        if (item?.leaves?.UNAPPROVED === 1) {
            colors.push(colorsList.UNAPPROVED);
        }
        if (item?.leaves?.APPROVED === 1) {
            colors.push(colorsList.APPROVED);
        }
        // if (item?.leaves?.PENDING === 1) {
        //     colors.push(colorsList.PENDING); 
        // }

        // Handling attendance status
        if (item?.attendance?.present === 1) {
            colors.push(colorsList.Present);
        }
        if (item?.attendance?.absent === 1) {
            colors.push(colorsList.Absent);
        }
        if (item?.attendance?.miss === 1) {
            colors.push(colorsList.MIS);
        }

        // Handling off days
        if (item.offDay) {
            colors.push(colorsList.offDay);
        }
        if (item.compensationDay) {
            colors.push(colorsList.compensationDay);
        }
        if (item.restDay) {
            colors.push(colorsList.restDay);
        }
        if (item.publicHoliday) {
            colors.push(colorsList.publicHoliday);
        }
        return colors;
    }
    const restDays = (item) => {
        let colors = [""];

        // Handling off days
        if (item.offDay) {
            colors.push(colorsList.offDay);
        }
        if (item.compensationDay) {
            colors.push(colorsList.compensationDay);
        }
        if (item.restDay) {
            colors.push(colorsList.restDay);
        }
        if (item.publicHoliday) {
            colors.push(colorsList.publicHoliday);
        }
        return colors;
    }

    // const renderSpan = (count, backgroundColor) => (
    //     <span
    //         className='rounded p-1 me-2 d-inline-block text-center'
    //         style={{ width: "25px", height: "25px", backgroundColor }}
    //     >
    //         {count}
    //     </span>
    // );

    const dayDataCount = (item) => {
        const { attendance, leaves } = item;

        let renderDiv = []
        if (attendance.absent > 0) {
            renderDiv.push({ count: attendance.absent, color: colorsList.Absent })
        }
        if (attendance.present > 0) {
            renderDiv.push({ count: attendance.present, color: colorsList.Present })
        }
        if (attendance.miss > 0) {
            renderDiv.push({ count: attendance.miss, color: colorsList.MIS })
        }
        // Leave Count's
        if (leaves.APPROVED > 0) {
            renderDiv.push({ count: leaves.APPROVED, color: colorsList.APPROVED })
        }
        if (leaves.REJECTED > 0) {
            renderDiv.push({ count: leaves.REJECTED, color: colorsList.REJECTED })
        }
        if (leaves.UNAPPROVED > 0) {
            renderDiv.push({ count: leaves.UNAPPROVED, color: colorsList.UNAPPROVED })
        }
        // if (offDay) {
        //     renderDiv.push({count: offDay, color: colorsList.offDay})
        // }
        // if (restDay) {
        //     renderDiv.push({count: restDay, color: colorsList.restDay})
        // }
        // if (publicHoliday) {
        //     renderDiv.push({count: publicHoliday, color: colorsList.publicHoliday})
        // }
        // if (compensationDay) {
        //     renderDiv.push({count: compensationDay, color: colorsList.compensationDay})
        // }

        // console.log('item', item, renderDiv.length)

        if (renderDiv.length > 0) return renderDiv
        else return [{ color: '#FFFFF' }]
    }


    function getDates(startDate, endDate) {
        const dates = [];
        let currentDate = moment(startDate);

        // Iterate through dates until reaching the end date
        while (currentDate.isSameOrBefore(endDate)) {
            dates.push(currentDate.format('MMM DD, YYYY')); // Format and add current date to the array
            currentDate.add(1, 'day'); // Move to the next day
        }
        // console.log(dates, "dates")
        return dates;
    }

    // const dateRange = getDates(startDate, endDate);
    // console.log(dateRange,"errrrrgtreg");
    const handleTimeLogFileChange = async (event) => {
        const files = event.target.files;
        const fd = new FormData();
        if (files.length > 5) {
            return toast.warn("You can only select up to 5 files.");
        }

        for (let i = 0; i < files.length; i++) {
            const fileData = files[i];
            if (fileData.size > 100 * 1024 * 1024) {
                return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
            }
            fd.append(`files`, fileData);
        }
        fd.append('type', "LEAVE");

        try {
            setSpinner(true);
            let response = await uploadFile(fd);
            toast.success(response.message);
            const newSelectedFiles = [...selectedFiles];
            response?.data.forEach((item) => {
                newSelectedFiles.push(item.db_name);
            });
            setSelectedFiles(newSelectedFiles);
            fileInputRef.current.value = null;

        } catch (error) {
            toast.error(error.message);
        }
        setSpinner(false);
    }
    const handleLogRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index)
        setSelectedFiles(updatedFiles)
    }
    return {
        data: {
            spinner,
            shiftForm,
            shiftError,
            offDaysObj,
            calendarData,
            shiftId: id,
            dayDetailModal,
            currentMonth,
            leaveForm,
            leavePolicydata,
            leaveError,
            leaveApplyModal,
            OneDayDetailModal,
            leavePolicyCount,
            attendancePermission: user,
            selectedFiles,
            fileInputRef,
            leaveType,
            user
        },
        methods: {
            setShiftForm,
            setShiftError,
            handleDateChange,
            handleCheckDays,
            focusHandle,
            setCalendarData,
            // bgHandle,
            handleOffDays,
            submit,
            Update,
            setDayDetailModal,
            handleInputChange,
            setLeaveError,
            getDates,
            setLeaveApplyModal,
            setLeaveForm,
            setCurrentMonth,
            setOnedayDetailModal,
            BoxType,
            GetDayDetails,
            getLeaveCount,
            dayDataCount,
            handleTimeLogFileChange,
            handleLogRemoveFile,
            restDays,
            checkType,
            setLeaveType
        }
    }
}

export default useLeaveForm
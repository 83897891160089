import { ApiStatus } from "../constants"
import { axiosInstance, baseUrl } from "./auth"



export const  ADD_ANNOUNCEMENT= async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/anouncement/create  `,body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export const  LIST_ANNOUNCEMENT= async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/anouncement/list  `,body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}

export const  UPDATE_ANNOUNCEMENT= async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/anouncement/update`,body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
                      
import React from 'react'
import { Button, TextField } from '@mui/material'
import { priorityList, statusList } from '../../constants'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import MultipleSelect from "../../components/MultipleSelect"
import DateInput from '../../components/DateInput'

const TicketFilters = (props) => {
  const { data, methods } = props
  const { agentList, allTagsList, projectList, ticketListInput,reportedList } = data
  const {
    resetFilter,
    applayFilter,
    setTicketListInput,
  } = methods
  return (
    <>
      <div className="shadow bg raduus py-2">
        <div className="col-lg-10">
          <h5 className="text-center">Filters</h5>
        </div>
        <div className="filter">
        <div className="col-lg-11 mx-auto mb-2">
          <TextField size="small" type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" 
          onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, search: e.target.value }))} value={ticketListInput?.search} />
        </div>
        <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Reported By"}
              multiple={true}
              options={reportedList}
              searchBy={"name"}
              keyName={"name"}
              name={"reportedBy"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, reportedBy: e }))}
              value={"_id"}
              selectedValues={ticketListInput?.reportedBy}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Agent"}
              multiple={true}
              options={agentList}
              searchBy={"name"}
              keyName={"name"}
              name={"agent"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, agent: e }))}
              value={"_id"}
              selectedValues={ticketListInput?.agent}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Status"}
              multiple={true}
              options={statusList}
              searchBy={"title"}
              keyName={"title"}
              name={"status"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, status: e }))}
              value={"value"}
              selectedValues={ticketListInput?.status}
            />

            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Status
            </InputLabel>
            <Autocomplete
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={statusList}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTicketListInput((prevProps) => ({ ...prevProps, status: newValue }))
              }}
              value={ticketListInput?.status}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2 ">
            <MultipleSelect
              label={"Priority"}
              multiple={true}
              options={priorityList}
              searchBy={"title"}
              keyName={"title"}
              name={"priority"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, priority: e }))}
              value={"value"}
              selectedValues={ticketListInput?.priority}
            />

            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Priority
            </InputLabel>
            <Autocomplete
              value={ticketListInput?.priority}
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={priorityList}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTicketListInput((prevProps) => ({ ...prevProps, priority: newValue }))
              }}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Tag"}
              multiple={true}
              options={allTagsList}
              searchBy={"tagName"}
              keyName={"tagName"}
              name={"Tags"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, Tags: e }))}
              value={"_id"}
              selectedValues={ticketListInput?.Tags}
            />
            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Tag
            </InputLabel>
            <Autocomplete
              value={ticketListInput?.Tags}
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={allTagsList}
              getOptionLabel={(option) => option.tagName}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTicketListInput((prevProps) => ({ ...prevProps, Tags: newValue }))
              }}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <MultipleSelect
              label={"Project"}
              multiple={true}
              options={projectList}
              searchBy={"name"}
              keyName={"name"}
              name={"Projects"}
              onChange={(e) => setTicketListInput((prevProps) => ({ ...prevProps, Projects: e }))}
              value={"_id"}
              selectedValues={ticketListInput?.Projects}
            />
            {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
              Project
            </InputLabel>

            <Autocomplete
              value={ticketListInput?.Projects}
              multiple
              // disableCloseOnSelect
              // limitTags={2}
              options={projectList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              size="small"
              onChange={(e, newValue) => {
                setTicketListInput((prevProps) => ({ ...prevProps, Projects: newValue }))
              }}
            /> */}
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <DateInput
              size="small"
              variant="outlined"
              className="w-100 form-field-design "
              // isRequired={true}
              Label={'Start Date'}
              SelectedDate={ticketListInput?.startDate || null}
              handleDateChange={(date) => {
                setTicketListInput((pre) => ({ ...pre, startDate: date }))
              }}
            />
          </div>
          <div className="col-lg-11 mx-auto mb-2">
            <DateInput
              size="small"
              variant="outlined"
              className="w-100 form-field-design"
              // isRequired={true}
              Label={'End Date'}
              SelectedDate={ticketListInput?.endDate || null}
              handleDateChange={(date) => {
                setTicketListInput((pre) => ({ ...pre, endDate: date }))
              }}
            />
          </div>
        </div>
        <div className="text-center mx-auto ">
          <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
            Apply
          </Button>
          <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
            Reset
          </Button>
        </div>
      </div>
    </>
  )
}

export default TicketFilters

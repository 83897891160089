import React from 'react'
import { Outlet, useLocation, NavLink } from 'react-router-dom'
import { Tabs, Tab, AppBar, } from '@mui/material';

const ReportMainPage = () => {

    const location = useLocation()
    const allTabs = [
        {
            label: "Reports",
            value: `/reports`,
        },
        {
            label: "Break Report",
            value: `/reports/break-report`,
        },
    ];

    return (
        <>
            <div className="border" style={{ backgroundColor: "white" }}>
                <AppBar position="static" sx={{ background: "#fff" }}>
                    <Tabs value={location.pathname}>
                        {allTabs.map((item, i) =>
                            <Tab key={i}
                                label={item.label}
                                value={item.value}
                                component={NavLink}
                                to={item.value}
                            />
                        )}
                    </Tabs>
                </AppBar>
                <Outlet
                // context={{ setSpinner, spinner, controller, setController, totalCount, checkOutList, GetCheckoutList, setSearchText, searchText, user_permission }}
                />
            </div>
        </>
    )
}

export default ReportMainPage
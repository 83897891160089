import React, { createRef, } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { MenuItem, Select, Button, Checkbox, TextField } from '@mui/material'
import SelectInput from '../../components/SelectInput'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import MergeOutlinedIcon from '@mui/icons-material/MergeOutlined'
import Modal from '../../components/Modal'
import useTicketListing from './Hooks/useTicketListing'
import { AllTicketEnum, statusList, CKEditorCustomToolbar, priorityList, ReminderList } from '../../constants'
import TicketFilters from './TicketFilters'
import { toast, ToastContainer } from 'react-toastify'
import { Pagination } from '../../components/pagination/Pagination'
import { Spinner } from '../../components/Spinner/Spinner'
import Chip from '@mui/material/Chip'
import MultipleSelect from "../../components/MultipleSelect"


import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import TextInput from '../../components/TextField'
import Switch from '@mui/material/Switch'
import Fab from '@mui/material/Fab'
import ClearIcon from '@mui/icons-material/Clear'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { fileType } from './Email'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'
const Tickets = () => {
  // const fileInputRef = useRef(null)
  const navigate = useNavigate()
  const { data, methods } = useTicketListing()
  const {
    controller,
    isLoading,
    totalCount,
    ticketList,
    fullTicketList,
    mergeModel,
    assignModel,
    deleteModal,
    numberOfSelected,
    ticketType,
    searchTickets,
    selectedTickets,
    ticketStatusList,
    slotId,
    TicketPermission,
    assignInputs,
    allTagsList,
    projectList,
    assignToListData,
    assignErrors,
    permanentDeleteModal,
    taskTypeList,
    SubTaskList,
    TaskCategoryList,
    complexityList,
    hoursTypeList,
    workTypeList,
    user
  } = data
  const {
    handlePagination,
    setDeleteModal,
    handleDelete,
    setAssignModel,
    TicketMerge,
    setMergeModel,
    onSelectAllClick,
    setTicketType,
    handleRowCheck,
    setSearchTickets,
    handleCheckSlots,
    handleStatusChange,
    AssignMultipleTask,
    removeAssign,
    CloseFucntion,
    MultipleAgentsAssignTicket,
    handleAssignInputChange,
    handleDateChange,
    handleFileChange,
    handleRemoveFile,
    setAssignErrors,
    Export,
    ticketPermanentDelete,
    setPermanentDeleteModal,
  } = methods
  const fileInputRef = assignInputs.map(() => createRef());
  const { getFormattedDateTime } = UseDateTimeFormat()

  const { add, edit, assign, delete: ticketDelete, merge, export: exportFile } = TicketPermission?.userInfo?.permission?.ticket
  const TableHead = [
    { label: 'Ticket ID', id: '' },
    { label: 'Reported Date/Time', id: '' },
    { label: 'Reported By', id: '' },
    { label: 'Project', id: '' },
    { label: 'Created By', id: '' },
    { label: 'Assigned To', id: '' },
    { label: 'Priority', id: '' },
    { label: 'Tags', id: '' },
    { label: 'Status', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2 w-25">
              <SelectInput
                // Lable={"All Tickets"}
                Options={AllTicketEnum}
                keyName={'title'}
                Name="ticketType"
                SelectValue={ticketType}
                HandleChange={(e) => setTicketType(e.target.value)}
                variant={'outlined'}
              />

            </div>
            <div className="d-flex pe-1">
              <div className=" d-flex ">
                {/* {assign?.value && <div>
                  <Button
                    variant="contained"
                    className="px-4"
                    onClick={() => {
                      if (!selectedTickets.length) return toast.warn('Please select ticket')
                      setAssignModel((prev) => ({ ...prev, open: true }))
                    }}
                  >
                    <AssignmentIndOutlinedIcon className="contained" /> Assign
                  </Button>
                </div>} */}
                {merge?.value && <div className="ms-2">
                  <Button
                    variant="contained"
                    className="px-4"
                    onClick={() => {
                      if (!selectedTickets.length) return toast.warn('Please select ticket')
                      setMergeModel((prev) => ({ ...prev, open: true }))
                    }}
                  >
                    <MergeOutlinedIcon className="contained" /> Merge
                  </Button>
                </div>}
              </div>
              {exportFile?.value && <div className="ms-2">
                <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
              </div>}
              {add?.value && <div className="ms-2">
                <Button className="dropDownButtons11" onClick={() => navigate(`/tickets/new-ticket-form`)}>
                  <AddIcon /> Add New
                </Button>
              </div>}
            </div>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-10 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} numberOfSelected={numberOfSelected} rowCount={ticketList?.length} onSelectAllClick={onSelectAllClick} />
                    <tbody>
                      {ticketList.map((item, index) => (
                        <tr
                          className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                          key={item._id + index}
                        >
                          <td>
                            <Checkbox
                              disabled={item?.isMerged}
                              size="small"
                              color="primary"
                              value={item._id}
                              checked={selectedTickets.includes(item._id)}
                              onChange={(e) => {
                                handleRowCheck(e, item._id)
                              }}
                            />
                          </td>
                          <td
                            className="table-link"
                            onClick={() =>
                              navigate(`/tickets/email/${item._id}`, {
                                state: { ticketId: item._id },
                              })
                            }
                          >
                            {item?.ticketId || '--'}
                          </td>
                          <td className="table-link" onClick={() => navigate(`/tickets/user/${item?.reportedBy_id}/ticket`, { state: { reportedBy: item } })}>
                            {/* {moment(item?.reportedDate).format('DD-MM-YYYY h:mm A') || '--'} */}
                            {getFormattedDateTime(item?.reportedDate, 'DD-MM-YYYY h:mm A')}
                          </td>
                          <td className="table-link" onClick={() => navigate(`/tickets/user/${item?.reportedBy_id}/ticket`, { state: { reportedBy: item } })}>
                            {item?.reportedBy || '--'}
                          </td>
                          <td className="table-link" onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}>{item?.project || '--'}</td>
                          <td>{item?.createdby || '--'}</td>

                          <td>
                            <span className="d-flex flex-wrap">
                              {item?.agentData.length
                                ? item.agentData.map((team) => {
                                  return (
                                    <div key={team._id} className="cursor-pointer">
                                      <Chip
                                        className="m-1"
                                        color="secondary"
                                        size="small"
                                        label={team.name.charAt(0).toUpperCase() + team.name.split(' ')[1].charAt(0).toUpperCase()}
                                        title={team.name.toUpperCase()}
                                      />
                                    </div>
                                  )
                                })
                                : '--'}
                            </span>
                          </td>
                          <td>{item?.priority || '--'}</td>
                          <td>
                            <span className="d-flex flex-wrap">
                              {item?.tagsData.length
                                ? item?.tagsData?.map((tag) => {
                                  return (
                                    <div key={tag?._id} className="tag-list-chips cursor-pointer me-1 mb-1" style={{ color: '#000', background: tag?.color, border: tag?.color }}>
                                      <span style={{ fontSize: '11px' }}>{tag?.tagName}</span>
                                    </div>
                                  )
                                })
                                : '--'}
                            </span>
                          </td>
                          <td>
                            <Select
                              className="droptable"
                              sx={{ width: '120px' }}
                              value={ticketStatusList[item?._id] || ''}
                              onChange={(e) => {
                                handleStatusChange(e, item?._id)
                              }}
                              name="status"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              {statusList.map((cur, statusIndex) => (
                                <MenuItem
                                  key={statusIndex}
                                  //  sx={{ color: cur?.color }}
                                  value={cur.value}
                                >
                                  {cur.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </td>
                          <td>
                            {(ticketDelete?.value || edit?.value || assign?.value) ?
                              (<div className="dropdown p-0 align-items-center justify-content-center">
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  {edit?.value && <li style={{ cursor: 'pointer' }} onClick={() => navigate(`/tickets/email/${item._id}`, { state: { ticketId: item._id }, })} className="dropdown-item">  Edit</li>}
                                  {(ticketDelete?.value && item.status !== 'CLOSED') && <>

                                    {!item.isDeleted ? <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item, isDeleted: true }, open: true, })}>Delete</li>
                                      :
                                      <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item, isDeleted: false }, open: true })}>
                                        Recover
                                      </li>}
                                  </>
                                  }
                                  {assign?.value && <li className="dropdown-item" onClick={() => setAssignModel({ data: item, open: true, })}>Assign</li>}
                                  {item.isDeleted && <li className="dropdown-item pointer" onClick={() => setPermanentDeleteModal({ data: item?._id, open: true })}>
                                    Permanent Delete
                                  </li>
                                  }
                                </ul>
                              </div>)
                              :
                              (<div className='pt-3' style={{ minHeight: '50px' }}>
                                <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />
                              </div>)
                            }
                          </td>
                        </tr>
                        // </tr>
                      ))}
                    </tbody>
                  </table>
                  {!ticketList.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 ticet mt-2">
            <TicketFilters data={data} methods={methods} />
          </div>
        </div>
      </div>
      {/* Assign Model */}
      <Modal
        size="lg"
        show={assignModel.open}
        onHide={() => {
          CloseFucntion('assignModel')
        }}
        // onHide={() => setAssignModel((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Assign to</h5>}
        // bodyContent={
        //   <div className="row">
        //     <div className="col-lg-11 mx-auto mb-2">
        //       <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
        //         Group
        //       </InputLabel>
        //       <Autocomplete
        //         onChange={(e, newValue) => {
        //           setTicketListInput((prevProps) => ({ ...prevProps, team: newValue }))
        //           getAgentList(newValue)
        //         }}
        //         // disableCloseOnSelect
        //         disableClearable={true}
        //         value={ticketListInput?.team || null}
        //         size="small"
        //         options={groupList}
        //         getOptionLabel={(option) => `${option.name}`}
        //         renderInput={(params) => <TextField {...params} variant="outlined" />}
        //         renderOption={(props, option) => {
        //           return <li {...props}>{`${option?.name}`}</li>
        //         }}
        //       />
        //     </div>
        //     <div className="col-lg-11 mx-auto mb-2">
        //       <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
        //         Agent
        //       </InputLabel>
        //       <Autocomplete
        //         onChange={(e, newValue) => {
        //           setTicketListInput((prevProps) => ({ ...prevProps, agent: newValue }))
        //         }}
        //         // disableCloseOnSelect
        //         disableClearable={true}
        //         value={ticketListInput?.agent || null}
        //         size="small"
        //         options={agentList}
        //         getOptionLabel={(option) => `${option.name}`}
        //         renderInput={(params) => <TextField {...params} variant="outlined" />}
        //         renderOption={(props, option) => {
        //           return <li {...props}>{`${option?.name}`}</li>
        //         }}
        //       />
        //     </div>

        //     <div className=" d-flex justify-content-end ">
        //       <div className="m-2">
        //         <Button variant="contained" onClick={TicketAssign}>
        //           Assign
        //         </Button>
        //       </div>
        //       <div className="m-2">
        //         <Button variant="contained" onClick={() => setAssignModel((prev) => ({ ...prev, open: false }))}>
        //           Cancel
        //         </Button>
        //       </div>
        //     </div>
        //   </div>
        // }
        bodyContent={
          <div className="App">
            {assignInputs?.map((item, index) => (
              <div key={index}>
                <div className="row">
                  {/* <span className='border rounded '><b>{index + 1}</b></span> */}
                  <div className="col-lg-12 my-2">
                    <TextInput
                      Lable="Task Name"
                      Name="taskName"
                      variant="outlined"
                      ClassName="form-field-design "
                      Require={true}
                      HandleInputChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      Value={item.taskName}
                      width={100}
                    //   Error={error.ticketId}
                    />
                    {assignErrors[index]?.taskName && <span className='text-danger'>{assignErrors[index].taskName}</span>}
                  </div>

                  <div className="col-lg-12 my-2">
                    <label htmlFor="">
                      <sub>Description </sub>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: CKEditorCustomToolbar,
                      }}
                      data={item?.description || {}}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        handleAssignInputChange({ target: { name: 'description', value: data } }, index);

                        setAssignErrors((prevErrors) => {
                          const updatedErrors = [...prevErrors];
                          updatedErrors[index] = { description: '' };
                          return updatedErrors;
                        });

                      }}
                      onBlur={(event, editor) => {
                        if (!item.description) {
                          // Set the description error if it's empty on blur
                          setAssignErrors((prevErrors) => {
                            const updatedErrors = [...prevErrors];
                            updatedErrors[index] = { description: 'Please enter description' };
                            return updatedErrors;
                          });
                        }
                      }}
                    />
                    {assignErrors[index]?.description && <p className="text-danger">{assignErrors[index]?.description}</p>}
                  </div>

                  <div className="col-lg-6 my-2">

                    <MultipleSelect
                      label="Project"
                      isRequired={true}
                      // multiple={true}
                      options={projectList}
                      searchBy={"name"}
                      keyName={"name"}
                      name={"project_id"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "project_id", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item?.project_id}
                    // disabled={true}
                    // error={error.project_id}
                    />

                    {/* <SelectInput
                      Lable={'Project '}
                      // width={"310px"}
                      Options={projectList}
                      keyName={'name'}
                      Name="project_id"
                      ClassName="form-field-design"
                      SelectValue={item.project_id}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    {assignErrors[index]?.project_id && <span className='text-danger'>{assignErrors[index]?.project_id}</span>}
                  </div>

                  <div className="col-lg-6 my-2">
                    {/* <SelectInput
                      Lable={'Priority'}
                      // width={"310px"}
                      Options={priorityList}
                      keyName={'title'}
                      Name="priority"
                      ClassName="form-field-design"
                      SelectValue={item.priority}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    <MultipleSelect
                      label="Priority"
                      isRequired={true}
                      // multiple={true}
                      options={priorityList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"priority"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "priority", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.priority}
                    // disabled={true}
                    // error={error.priority}
                    />
                    {assignErrors[index]?.priority && <span className='text-danger'>{assignErrors[index]?.priority}</span>}
                  </div>

                  <div className="col-lg-6 my-2">

                    <MultipleSelect
                      label="Assign To"
                      isRequired={true}
                      // multiple={true}
                      options={assignToListData}
                      searchBy={"name"}
                      keyName={"name"}
                      name={"assignTo_id"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "assignTo_id", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item?.assignTo_id}
                    // disabled={true}
                    // error={error.assignTo_id}
                    />

                    {/* <SelectInput
                      Lable={'Assign To '}
                      extrakey={"isDeleted"}
                      message={"Inactive"}
                      Options={assignToListData}
                      keyName={'title'}
                      Name="assignTo_id"
                      ClassName="form-field-design"
                      SelectValue={item.assignTo_id}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    {assignErrors[index]?.assignTo_id && <span className='text-danger'>{assignErrors[index]?.assignTo_id}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">

                    <MultipleSelect
                      label="Tag"
                      // isRequired={true}
                      multiple={true}
                      options={allTagsList}
                      searchBy={"tagName"}
                      keyName={"tagName"}
                      name={"tags"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "tags", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item.tags}
                    // disabled={true}
                    // error={error.tags}
                    />
                    {/* <SelectInput
                      Lable={'Tag'}
                      multiple={true}
                      Options={allTagsList}
                      keyName={'tagName'}
                      Name="tags"
                      SelectValue={item.tags}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      // Error={error.tags}
                      variant={'outlined'}
                    /> */}
                  </div>

                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Start Date '}
                      handleDateChange={(date) => handleDateChange(date, 'assignStartTime', index)}
                      SelectedDate={assignInputs[index]?.assignStartTime}
                    />
                    {assignErrors[index]?.assignStartTime && <span className='text-danger'>{assignErrors[index]?.assignStartTime}</span>}
                  </div>
                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Due Date'}
                      handleDateChange={(date) => handleDateChange(date, 'assignEndTime', index)}
                      SelectedDate={assignInputs[index]?.assignEndTime}
                    />
                    {assignErrors[index]?.assignEndTime && <span className='text-danger'>{assignErrors[index]?.assignEndTime}</span>}
                  </div>
                  <div className="col-lg-12 my-2 position-relative mt-3">
                    <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
                      <Switch name="ReminderAction" checked={item?.ReminderAction} onChange={(e) => handleAssignInputChange(e, index)} />
                    </div>
                    <SelectInput
                      LabelButton
                      Lable={'Reminder'}
                      // multiple={true}
                      Options={ReminderList}
                      keyName={'title'}
                      Name="minutesBeforeReminder"
                      SelectValue={item.minutesBeforeReminder}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      // Error={error.tags}
                      variant={'outlined'}
                      // isRequired={true}
                      disabled={item?.ReminderAction === false}
                    />
                  </div>

                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Task Type"
                      isRequired={true}
                      // multiple={true}
                      options={taskTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"taskType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "taskType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.taskType}
                    />
                    {assignErrors[index]?.taskType && <span className='text-danger'>{assignErrors[index]?.taskType}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="SubTask"
                      isRequired={true}
                      // multiple={true}
                      options={SubTaskList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"subTask"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "subTask", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.subTask}
                    />
                    {assignErrors[index]?.subTask && <span className='text-danger'>{assignErrors[index]?.subTask}</span>}
                  </div>

                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Task Category"
                      isRequired={true}
                      // multiple={true}
                      options={TaskCategoryList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"taskCategory"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "taskCategory", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.taskCategory}
                    />
                    {assignErrors[index]?.taskCategory && <span className='text-danger'>{assignErrors[index]?.taskCategory}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Complexity"
                      isRequired={true}
                      // multiple={true}
                      options={complexityList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"complexity"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "complexity", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.complexity}
                    />
                    {assignErrors[index]?.complexity && <span className='text-danger'>{assignErrors[index]?.complexity}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Hours Type"
                      isRequired={true}
                      // multiple={true}
                      options={hoursTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"hoursType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "hoursType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.hoursType}
                    />
                    {assignErrors[index]?.hoursType && <span className='text-danger'>{assignErrors[index]?.hoursType}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Work Type"
                      isRequired={true}
                      // multiple={true}
                      options={workTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"workType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "workType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.workType}
                    />
                    {assignErrors[index]?.workType && <span className='text-danger'>{assignErrors[index]?.workType}</span>}
                  </div>

                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <h5 className="mt-4 mb-2">Attachments</h5>
                  {item?.attachments?.length < 5 && <Button variant="contained" onClick={() => fileInputRef[index].current.click()}>
                    choose file
                  </Button>}
                  <input type="file" ref={fileInputRef[index]} name={'attachments'} id="choosefile" multiple onChange={(e) => handleFileChange(e, index)} style={{ display: 'none' }} />
                </div>
                <div className="col-lg-12">
                  {item?.attachments?.length > 0 && (
                    <div className="row border border-1" style={{ margin: 0 }}>
                      {/* <h4>Selected Files:</h4> */}
                      {/* <ul> */}
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {item?.attachments?.map((file, fileIndex) => (
                          <div style={{ width: '80px', height: '80px' }} className="position-relative">
                            {fileType(file, fileIndex, "TASK")}
                            <Fab
                              className="m-2"
                              sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                              onClick={() => handleRemoveFile(index, fileIndex, file)}
                            >
                              <ClearIcon sx={{ fontSize: '15px' }} />
                            </Fab>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-12 my-2">
                  <label htmlFor="">
                    <sub>Notes </sub>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    data={item?.notes || {}} // Set CKEditor data to projectInputs.notes
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      handleAssignInputChange({ target: { name: 'notes', value: data } }, index)
                    }}
                  />
                </div>
                {/* {!taskModel.data && ( */}
                <div className="col-lg-12 text-end mt-3">
                  <Button className="dropDownButtons11 me-2" onClick={() => AssignMultipleTask(index)}>
                    <AddIcon />
                  </Button>
                  {assignInputs.length > 1 && ( // Only show the button if there is more than one task
                    <Button variant="outlined" color="error" onClick={() => removeAssign(index)}>
                      <ClearIcon />
                    </Button>
                  )}
                </div>
                {/* )} */}
              </div>
            ))}

            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button
                  variant="outlined"
                  onClick={() => {
                    CloseFucntion('assignModel')
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="m-2">
                <Button variant="contained" onClick={MultipleAgentsAssignTicket}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* Merge Modal */}
      <Modal
        size="md"
        show={mergeModel.open}
        onHide={() => setMergeModel((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Merge to</h5>}
        bodyContent={
          <div className="p-3">
            {/* <div className="border rounded my-2 p-3"> */}
            {/* <h5>Tickets</h5>
              <hr /> */}
            <div className="row">
              <div className="col-12 text-center">
                {/* <label htmlFor="" className="w-100"> */}
                <TextField type="text" onChange={(e) => setSearchTickets(e.target.value.toLowerCase())} value={searchTickets} placeholder="Search" sx={{ width: '380px' }} size="small" />
                {/* </label> */}
              </div>
            </div>
            <div className="row">
              <div className="col-11 mt-4 mx-auto " style={{ height: '400px', overflowY: 'auto' }}>
                {/* {fullTicketList.map((item, index) => */}
                {fullTicketList
                  .filter((cur) => cur.ticketId.toLowerCase().includes(searchTickets))
                  .map((item, index) => (
                    <article
                      // onClick={ }
                      key={index}
                      className={`${slotId === index ? 'slot_InActive' : ' slot_Active'} article_show_status `}
                      id={item?.status !== 'Available' && 'Booked_slot'}
                    >
                      <input
                        type="checkbox"
                        id="feature1"
                        defaultChecked={item?.isChecked}
                        onChange={(e) => handleCheckSlots(e.target.checked, item, index)}
                      // disabled={DisableTimeSlot(item)}
                      />
                      <div className="row p-2">
                        <div className="col-12  d-flex justify-content-between ">
                          <p className="mb-0">Ticket Id: {item.ticketId}</p>
                          {/* <p className="mb-0">{moment(item.createdAt).calendar()}</p> */}
                          <p className="mb-0">{getFormattedDateTime(item?.createdAt, 'calendar')}</p>

                        </div>

                        <div className="col-12 d-flex justify-content-start  ">
                          <p className="mb-0">Subject: {item.subject.slice(0, 80)}</p>
                        </div>
                      </div>
                    </article>
                  ))}
              </div>
            </div>
            {/* </div> */}

            <div className="text-center mt-3">
              <Button variant="contained" onClick={TicketMerge}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setMergeModel((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{!deleteModal?.data?._id?.isDeleted ? "Delete Ticket?" : "Recover Ticket?"}</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {!deleteModal?.data?._id?.isDeleted ? "delete" : "Recover"} ticket? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={handleDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

      {/* Delete Permanent Modal */}
      <Modal
        size="md"
        show={permanentDeleteModal.open}
        onHide={() => setPermanentDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{'Permanent Delete'} Ticket?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {'permanent delete'} ticket? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setPermanentDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={ticketPermanentDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

    </>
  )
}

export default Tickets

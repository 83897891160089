import React from 'react'
import { Outlet, useLocation, NavLink, useParams, useNavigate } from 'react-router-dom'
import { Tabs, Tab, AppBar, Chip } from '@mui/material'
import { Button } from '@mui/material'
import useTamManagementAddUpdate from './Hooks/useTamManagementAddUpdate'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import Modal from '../../components/Modal'
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment'
import Dummy from '../../assets/images/dummy.png'
const AgentMainPage = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { agentId } = useParams()
  const [value, setValue] = React.useState('Basic Information');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, methods } = useTamManagementAddUpdate()
  const { handleDelete, setDeleteModal, resend, setCount } = methods
  const { teamMemberInputs, isLoading, deleteModal, TeamPermission, profilePath } = data

  const { edit, delete: teamDelete } = TeamPermission?.userInfo?.permission?.team

  // console.log(teamMemberInputs?.allDetails?.shiftData?.name    ,'allDetails')

  const allTabs = [
    {
      label: 'Personal Details',
      value: `/team-management/${agentId}/personal-details`,
    },
    {
      label: 'Job Details',
      value: `/team-management/${agentId}/job-details`,
    },
    {
      label: 'Other Details',
      value: `/team-management/${agentId}/other-details`,
    },
    {
      label: 'Leave',
      value: `/team-management/${agentId}/leave`,
    },
    {
      label: 'Timeline',
      value: `/team-management/${agentId}/timeline`,
    },
    {
      label: 'Activity',
      value: `/team-management/${agentId}/activity`,
    },
  
   
    

  ]

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <div className="contact">
        <div className="container bg">
          <div className="row">
            <div className="col-lg-12 pt-2">
              <div className="col-lg-12 ms-4">
                 {/* Basic Details  */}
                <div className="row my-5">
                  <div className='col-lg-2'>
                    <div className=''>
                      <img src={teamMemberInputs?.profileImage ? profilePath + teamMemberInputs?.profileImage : Dummy} alt="" height={120} width={120} style={{ borderRadius: '50%' }} />
                    </div>
                  </div>
                  <div className="col-lg-5 ">
                    <p>
                      <span className="fw-bold">EMP ID :</span> <span className="text-muted">{teamMemberInputs?.empId ? `${teamMemberInputs?.empId} ` : '--'}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Name :</span> <span className="text-muted">{teamMemberInputs?.firstName ? `${teamMemberInputs?.firstName} ${teamMemberInputs?.lastName}` : '--'}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Email :</span> <span className="text-muted">{teamMemberInputs?.email || "--"}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Mobile Number :</span> <span className="text-muted">--</span>
                    </p>
                    <p>
                      <span className="fw-bold">Time Zone :</span> <span className="text-muted">{teamMemberInputs?.timezone?.text || "--"}</span>
                    </p>
                  </div>
                  <div className="col-lg-5 ">
                 
                    <p>
                      <span className="fw-bold">Role :</span> <span className="text-muted">{teamMemberInputs?.fullDetails?.roleData?.roleTitle || "--"}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Group :</span> <span className="text-muted">{teamMemberInputs?.groupName || "--"}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Skills :</span> <span className="text-muted">
                        {teamMemberInputs?.fullDetails?.skills?.length
                          ? teamMemberInputs?.fullDetails?.skills?.map((skill) => {
                            return (
                              <span key={skill._id} className="cursor-pointer">
                                <Chip
                                  className="m-1"
                                  color="secondary"
                                  size="small"
                                  label={skill.skillName}
                                  // label={skill.skillName?.charAt(0).toUpperCase() + skill.skillName?.split(' ')[1].charAt(0).toUpperCase()}
                                  title={skill.skillName.toUpperCase()}
                                />
                              </span>
                            )
                          })
                          : '--'}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">Date of birth :</span> 
                      <span className='ms-1'>
                      {teamMemberInputs?.dob
                                ? moment(teamMemberInputs?.dob, 'DD-MM-YYYY', true).isValid()
                                    ? moment(teamMemberInputs?.dob, 'DD-MM-YYYY').format('DD-MM-YYYY')
                                    : '--'
                                : '--'}
                                </span>
                      
                      {/* <span className="text-muted">{moment(teamMemberInputs?.dob).format('DD-MM-YYYY') || "--"}</span> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container bg my-4">
          <div className="border" style={{ backgroundColor: 'white' }}>
            <AppBar position="static" sx={{ background: '#fff' }}>
              <Tabs value={location.pathname}>
                {allTabs.map((item, i) => (
                  <Tab key={i} label={item.label} value={item.value} component={NavLink} to={item.value} onClick={() => setCount(10)} />
                ))}
              </Tabs>
            </AppBar>
            <Outlet context={{ data, methods }} />
          </div>
        </div>
      </div>
      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{deleteModal.data.isDeleted ? 'Recover' : 'Delete'} Project?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {deleteModal.data.isDeleted ? 'recover' : 'delete'} project? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default AgentMainPage

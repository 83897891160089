/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useToGetTimeLogList, getTimeLogDetails, ExportTimelogtList, updatesubmitTimelog, TimeLogApprovalStatus, deleteTimeLog, useToGetTimeLogTimeLineHistoryList } from '../../../repository/TimeLog'
import { toast } from 'react-toastify'
import { useToGetUserFullList, userFullList } from '../../../repository/teamManagementRepo'
import { allTagList, useToGetAllTagList } from '../../../repository/tagsRepo'
import { allProjectList, useToGetFullPriject } from '../../../repository/projectRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { useParams, useLocation } from 'react-router-dom'
import { requiredMessage, requiredSelect } from '../../../utils'
import { DeleteFile, addBreaks, deleteBreakTime, getTaskDetailsApi, reAssignTask, uploadFile, updateBreak } from '../../../repository/taskRepo'
import { priorityList, taskTypeList, SubTaskList, TaskCategoryList, complexityList, hoursTypeList, workTypeList } from '../../../constants'
import moment from 'moment'

const useTimeLog = () => {
  const { state } = useLocation()
  const { userTimeLog } = useParams()
  const { selector: user } = useReduxStore('auth')
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  const [spinner, setSpinner] = useState(false)
  const [filterAction, setFilterAction] = useState(false)
  const [breakModal, setBreakModal] = useState({
    open: false,
    data: ""
  })
  const [breakForm, setBreakForm] = useState({
    startTime: null,
    endTime: null,
  })

  const [timeLogDetails, setTimeLogDetailsModel] = useState({
    open: false,
    timeLogId: '',
    data: {},
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const [timeLineModal, setTimeLineModal] = useState({
    open: false,
    data: "",
  })

  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')
  const [agentList, setAgentList] = useState([])
  const [skillAllList] = useState([])

  const [allTagsList, setAllTagList] = useState([])
  const [projectList, setProjectList] = useState([])

  const [timeLogListInput, setTimeLogListInput] = useState({
    agent: [],
    // team: "",
    ticket: [],
    // created: "",
    // skill: "",
    approvalstatus: [],
    hoursType: [],
    actutalStatus: [],
    Projects: [],
    startDate: null,
    endDate: null,
  })
  const [timelogFillInputs, setTimeLoFillInput] = useState({
    subTask: "",
    taskCategory: "",
    complexity: "",
    hoursType: "",
    actutalStatus: "",
    actualStartTime: null,
    actualEndTime: null,
    notes: ""
  })
  const [timeLogError, setTimeLogError] = useState({})

  // ================================================================
  const [taskModel, setTaskModel] = useState({
    open: false,
    data: '',
  })
  const [taskFormInputs, setTaskFormInputs] = useState([
    {
      taskName: '',
      project_id: '',
      priority: '',
      assignTo_id: '',
      description: '',
      tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      notes: '',
      ReminderAction: true,
      attachments: [],
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ])

  const [errors, setErrors] = useState([
    {
      taskName: null,
      project_id: null,
      priority: '',
      assignTo_id: '',
      description: '',
      // tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      // notes: '',
      ReminderAction: true,
      // attachments: [],
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ]);

  let [breakHoursInput, setBreakHoursInput] = useState([
    //   {
    //   startTime: null,
    //   endTime: null,
    //   reason: ""
    // }
  ])
  const [breakErrors, setBreakErrors] = useState([
    {
      startTime: null,
      endTime: null,
      reason: ""
    },
  ]);

  const [selectedFiles, setSelectedFiles] = useState([])
  const [count, setCount] = useState(10)

  const cacheTime = { cacheTime: 10000 }

  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    isDeleted: false,
    search: search,
    startDate: timeLogListInput.startDate,
    endDate: timeLogListInput.endDate,
    reportDate: state?.userDetails?.reportDate,
    user_ids: [userTimeLog]
  }
  // if (typeof statusType == "boolean") payload.isActive = statusType;
  // if (timeLogListInput?.agent?.length > 0) {
  //   payload.user_ids = timeLogListInput.agent.map((item) => item._id)
  // }
  if (timeLogListInput?.approvalstatus?.length > 0) {
    payload.status = timeLogListInput.approvalstatus.map((item) => item.value)
  }
  if (timeLogListInput?.hoursType?.length > 0) {
    payload.hoursType = timeLogListInput.hoursType.map((item) => item.value)
  }
  if (timeLogListInput?.actutalStatus?.length > 0) {
    payload.actutalStatus = timeLogListInput.actutalStatus.map((item) => item.value)
  }
  if (timeLogListInput?.Projects?.length > 0) {
    payload.project_ids = timeLogListInput.Projects.map((item) => item._id)
  }

  const {
    data: TimeLogList,
    error: TimeLogError,
    isFetching: TimeLogIsFetching,
    refetch: refetchTimeLog,
  } = useToGetTimeLogList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTimeLog()
  }, [controller, filterAction])

  // Export TimeLog Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportTimelogtList(payload)
      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Filter's Api's
  useEffect(() => {
    // getAllTicketList()
    // AllSkillList();
    // getAllGroupList();
    getAllProjectList()
    getAllTagList()
    getAgentList()
  }, [])
  // Get All Tickets List
  //   const getAllTicketList = async () => {
  //     try {
  //       setSpinner(true)
  //       let response = await AllTicketList()
  //       setAllTicketList(response.data)
  //     } catch (error) {
  //       // toast.error(error.message)
  //     }
  //     setSpinner(false)
  //   }

  // Get All Group List
  //   const getAllGroupList = async () => {
  //     try {
  //       setSpinner(true)
  //       let response = await getAllTeamList()
  //       setGroupList(response.data)
  //     } catch (error) {
  //       // toast.error(error.message)
  //     }
  //     setSpinner(false)
  //   }

  // Get All Agent  List
  const getAgentList = async (groupId) => {
    // const teamIds = groupId?.map(item => item._id);
    // let payload = {
    //     team_id: teamIds,
    // };
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response.data)
    } catch (error) {
      setAgentList([])
      setTimeLogListInput((prevProps) => ({ ...prevProps, agent: '' }))
    }
    setSpinner(false)
  }

  // Get All Tag List
  const getAllTagList = async () => {
    try {
      setSpinner(true)
      let response = await allTagList()
      setAllTagList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Skill List
  // const AllSkillList = async () => {
  //   try {
  //     setSpinner(true)
  //     let response = await getAllSkillList()
  //     setAllSkillList(response.data)
  //   } catch (error) {
  //     // toast.error(error.message)
  //   }
  //   setSpinner(false)
  // }

  // Get All Project List
  const getAllProjectList = async () => {
    try {
      setSpinner(true)
      let response = await allProjectList()
      setProjectList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // TimeLog Time Line List
  const {
    data: timeLineListData,
    error: timeLineError,
    // isLoading: timeLineIsLoading,
    refetch: timeLineIsFetched
  } = useToGetTimeLogTimeLineHistoryList({ type: "TIMELOG", type_id: timeLineModal?.data, page: 1, count: count }, {
    cacheTime: 0,
    enabled: timeLineModal?.data ? true : false,
  });
  const fetchMoreData = () => { setCount((prev) => prev + 10); };

  useEffect(() => {
    if (timeLineModal?.data && timeLineModal.open === true) {
      timeLineIsFetched()
    }
  }, [timeLineModal?.data, timeLineModal?.open, count])

  // useEffect(() => {
  //     timeLineIsFetched()
  // }, [count])

  // console.log(count,'count')

  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteTimeLog({
        _id: deleteModal?.data,
        // isDeleted: deleteModal?.data?.isDeleted,
      })
      setDeleteModal({ data: '', open: false })
      refetchTimeLog()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  //===================================== ReAssign Form =====================================================
  // Project List
  const { data: projectListData,} = useToGetFullPriject(cacheTime)

  // Tag List
  const { data: tagListData, } = useToGetAllTagList(cacheTime)

  // Assign TO List
  const { data: assignToListData,} = useToGetUserFullList(cacheTime)

  useEffect(() => {
    if (taskModel?.data) {
      TaskDetails()
    }
  }, [taskModel.data])

  const TaskDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTaskDetailsApi(taskModel?.data)
      let newPriority = priorityList.filter(item => item.value === response?.data?.priority)

      let newTaskType = taskTypeList.filter(item => item.value === response?.data?.taskType)
      let newWorkType = workTypeList.filter(item => item.value === response?.data?.workType)
      let newsubTask = SubTaskList.filter(item => item.value === response?.data?.subTask)
      let newTaskCategory = TaskCategoryList.filter(item => item.value === response?.data?.taskCategory)
      let newComplexity = complexityList.filter(item => item.value === response?.data?.complexity)
      let newHoursType = hoursTypeList.filter(item => item.value === response?.data?.hoursType)

      setTaskFormInputs([
        {
          taskName: response?.data?.taskName,
          project_id: [response?.data?.project_id],
          priority: newPriority,
          assignTo_id: [response?.data?.assignTo_id],
          description: response?.data?.description,
          tags: response?.data?.tags,
          assignStartTime: new Date(response?.data?.assignStartTime),
          assignEndTime: new Date(response?.data?.assignEndTime),
          minutesBeforeReminder: response?.data?.reminder?.minutesBefore,
          notes: response?.data?.notes,
          ReminderAction: response?.data?.reminder?.reminderOn,
          attachments: response?.data?.attachments,
          taskType: newTaskType,
          workType: newWorkType,
          subTask: newsubTask,
          taskCategory: newTaskCategory,
          complexity: newComplexity,
          hoursType: newHoursType,
        },
      ])

      // toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const handleBreakInput = (event, taskIndex) => {
    const { value, name } = event.target;

    setBreakHoursInput((prev) => {
      const updatedBreak = [...prev];
      updatedBreak[taskIndex][name] = value;

      setBreakErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        // if (name != "ReminderAction") {
        //   updatedErrors[taskIndex][name] = null;
        // }
        return updatedErrors;
      });

      return updatedBreak;
    });
  };

  // const handleBrakeTime = (date, field, breakIndex) => {
  //   setBreakHoursInput((prev) => {
  //     const updatedTasks = [...prev]
  //     updatedTasks[breakIndex][field] = date
  //     return updatedTasks
  //   })

  //   // setBreakErrors((prevErrors) => {
  //   //   const updatedErrors = [...prevErrors];
  //   //   updatedErrors[breakIndex][field] = null;
  //   //   return updatedErrors;
  //   // });
  // }

  const handleBrakeTime = (date, field, breakIndex) => {
    setBreakHoursInput((prev) => {
      const updatedTasks = [...prev]
      updatedTasks[breakIndex][field] = date
      return updatedTasks
    })

    if (field === 'startTime') {
      // Check if start date is greater than end date
      const endTime = breakHoursInput[breakIndex]?.endTime;

      if (endTime && date > endTime) {
        setBreakErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { startTime: 'Start date should not be greater than end date' };
          return updatedErrors;
        });
      } else {
        setBreakErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { startTime: '' };
          return updatedErrors;
        });
      }
    }

    if (field === 'endTime') {
      // Check if end date is less than start date
      const startTime = breakHoursInput[breakIndex]?.startTime;

      if (startTime && date < startTime) {
        setBreakErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { endTime: 'End date should not be less than start date' };
          return updatedErrors;
        });
      } else {
        setBreakErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { endTime: '' };
          return updatedErrors;
        });
      }
    }
  }


  const addMultipleBreak = () => {
    if (breakHoursInput.length < 10) {
      setBreakHoursInput((prev) => [
        ...prev,
        {
          startTime: null,
          endTime: null,
          reason: ""
        },
      ])
      // Clear errors for the newly added task
      setBreakErrors((prevErrors) => [
        ...prevErrors,
        {
          startTime: null,
          endTime: null,
          reason: ""
        },
      ]);

    }
  }

  // Remove Brake time
  const removeBreak = async (breakIndex, item) => {
    if (item) {
      setSpinner(true)
      await deleteBreakTime(item)
      setSpinner(false)
    }
    setBreakHoursInput((prev) => {
      const updatedBreaks = [...prev]
      updatedBreaks.splice(breakIndex, 1)
      return updatedBreaks
    })
  }
  // Close modal And Empty
  // const CloseFucntion = (type) => {
  //   if (type == 'breatModal') {
  //     // setTaskModel({ open: false, data: '' })
  //     setBreakHoursInput([
  //       {
  //         startTime: null,
  //         endTime: null,
  //         reason: ""
  //       },
  //     ])
  //   }
  // }

  // Error Message
  const errorBreakMessage = {
    startTime: requiredSelect('break start'),
    endTime: requiredSelect("break end"),
    reason: requiredSelect("reason"),
  }
  const validateBreaks = () => {
    const errorObjArray = [];

    for (let i = 0; i < breakHoursInput.length; i++) {
      const breaks = breakHoursInput[i];
      const errorObj = {};

      if (!breaks.startTime) {
        errorObj['startTime'] = errorBreakMessage['startTime'];
      }
      if (!breaks.endTime) {
        errorObj['endTime'] = errorBreakMessage['endTime'];
      }
      if (!breaks.reason) {
        errorObj['reason'] = errorBreakMessage['reason'];
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }
    // console.log(errorObjArray, "errorObjArray")
    setBreakErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };


  const handleFileChange = async (event, taskIndex) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) return toast.warn('You can only select up to 5 files.');
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', 'TASK');
    try {
      setSpinner(true);
      let response = await uploadFile(fd);

      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        if (updatedTasks[taskIndex].attachments.length >= 5) {
          toast.warn('You can only select up to 5 files.');
        } else {
          const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
          response?.data?.forEach((cur, index) => {
            if (newSelectedFiles.length >= 5) {
              toast.warn('You can only select up to 5 files.');
            } else {
              newSelectedFiles?.push(cur?.db_name);
            }
          });
          if (newSelectedFiles.length <= 5) {
            updatedTasks[taskIndex].attachments = newSelectedFiles;
          }
        }
        toast.success(response.message);
        return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  };

  // Handle Remove File
  const handleRemoveFile = async (taskIndex, fileIndex, file) => {
    let data = {
      fileName: file,
      fileType: 'TASK',
    }

    try {
      setSpinner(true)
      let response = await DeleteFile(data)
      toast.success(response.message)
      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs]
        updatedTasks[taskIndex].attachments = updatedTasks[taskIndex].attachments.filter((_, i) => i !== fileIndex)
        return updatedTasks
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const handleInputChange = (event, taskIndex) => {
    const { value, name, checked } = event.target;

    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs];
      updatedTasks[taskIndex][name] = value;

      if (name === 'ReminderAction') {
        updatedTasks[taskIndex][name] = checked;
        if (checked) {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 15;
        } else {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 0;
        }
      }
      // Clear errors for the current field
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (name !== "ReminderAction") {
          updatedErrors[taskIndex][name] = null;
        }
        return updatedErrors;
      });
      return updatedTasks;
    });
  };

  // Handle Date
  const handleDateChange = (date, field, taskIndex) => {
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks[taskIndex][field] = date
      return updatedTasks
    })

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[taskIndex][field] = null;
      return updatedErrors;
    });
  }

  const addMultipleTask = () => {
    if (taskFormInputs.length < 10) {
      setTaskFormInputs((prevTaskFormInputs) => [
        ...prevTaskFormInputs,
        {
          taskName: '',
          project_id: '',
          priority: '',
          assignTo_id: '',
          description: '',
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 0,
          notes: '',
          ReminderAction: false,
          attachments: [],
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])
      // Clear errors for the newly added task
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          taskName: null,
          project_id: null,
          priority: '',
          assignTo_id: '',
          description: '',
          assignStartTime: null,
          assignEndTime: null,
          ReminderAction: false,
          minutesBeforeReminder: 15,// Reset ReminderAction error
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ]);

    }
  }

  // Remove Task
  const removeTask = (taskIndex) => {
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks.splice(taskIndex, 1) // Remove the task at the specified index
      return updatedTasks
    })
  }
  // Close modal And Empty
  const CloseFucntion = (type) => {

    if (type === 'breatModal') {
      // setTaskModel({ open: false, data: '' })
      setTimeLogDetailsModel({
        open: false,
        timeLogId: '',
        data: {},
      })
      setBreakHoursInput([
        {
          startTime: null,
          endTime: null,
          reason: ""
        },
      ])
    }

    if (type === 'taskModel') {
      setTaskModel({ open: false, data: '' })
      setTaskFormInputs([
        {
          taskName: '',
          project_id: '',
          priority: '',
          assignTo_id: '',
          description: '',
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 15,
          notes: '',
          ReminderAction: true,
          attachments: [],
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ])
    }
  }

  // task error message
  const errorMessage = {
    taskName: requiredMessage('task name'),
    description: requiredMessage('description'),
    project_id: requiredSelect('project'),
    priority: requiredSelect('priority'),
    assignTo_id: requiredSelect('assign'),
    assignStartTime: requiredSelect('assign start time'),
    assignEndTime: requiredSelect('assign end time'),
    ReminderAction: "Please select reminder",
    taskType: requiredSelect("task type"),
    workType: requiredSelect("work type"),
    subTask: requiredSelect("sub task"),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
  }

  // Error handling function
  const validateAllTasks = () => {
    const errorObjArray = [];

    for (let i = 0; i < taskFormInputs.length; i++) {
      const task = taskFormInputs[i];
      const errorObj = {};

      if (!task.taskName) {
        errorObj['taskName'] = errorMessage['taskName'];
      }
      if (!task.description) {
        errorObj['description'] = errorMessage['description'];
      }
      if (!task.project_id) {
        errorObj['project_id'] = errorMessage['project_id'];
      }
      if (!task.priority) {
        errorObj['priority'] = errorMessage['priority'];
      }
      if (!task.assignTo_id) {
        errorObj['assignTo_id'] = errorMessage['assignTo_id'];
      }
      if (!task.assignStartTime) {
        errorObj['assignStartTime'] = errorMessage['assignStartTime'];
      }
      if (!task.assignEndTime) {
        errorObj['assignEndTime'] = errorMessage['assignEndTime'];
      }
      if (!task.taskType) {
        errorObj['taskType'] = errorMessage['taskType'];
      }
      if (!task.workType) {
        errorObj['workType'] = errorMessage['workType'];
      }
      if (!task.subTask) {
        errorObj['subTask'] = errorMessage['subTask'];
      }
      if (!task.taskCategory) {
        errorObj['taskCategory'] = errorMessage['taskCategory'];
      }
      if (!task.complexity) {
        errorObj['complexity'] = errorMessage['complexity'];
      }
      if (!task.hoursType) {
        errorObj['hoursType'] = errorMessage['hoursType'];
      }
      if (task.ReminderAction) {
        delete errorObj.ReminderAction;
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }

    setErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };

  // ADD or Submit Task
  const submitTask = async () => {
    const isValid = validateAllTasks();
    if (!isValid) return toast.warn("Please fill all required fields")
    const convertData = (originalData) => {
      return originalData?.map((task) => {
        return {
          taskName: task?.taskName,
          project_id: task?.project_id[0]?._id,
          priority: task?.priority[0]?.value,
          assignTo_id: task?.assignTo_id[0]?._id,
          description: task?.description,
          tags: task?.tags?.map((tag) => tag?._id),
          assignStartTime: task?.assignStartTime,
          assignEndTime: task?.assignEndTime,
          minutesBeforeReminder: task?.minutesBeforeReminder,
          notes: task?.notes,
          attachments: task?.attachments,
          taskType: task?.taskType[0]?.value,
          workType: task?.workType[0]?.value,
          subTask: task?.subTask[0]?.value,
          taskCategory: task?.taskCategory[0]?.value,
          complexity: task?.complexity[0]?.value,
          hoursType: task?.hoursType[0]?.value,
        };
      });
    };
    const newData = convertData(taskFormInputs);
    let newTaskFormInputs = [...newData]
    newTaskFormInputs?.map((cur, index) => {
      delete cur?.ReminderAction
      return cur
    })
    // return
    let data = {
      data: newTaskFormInputs,
    }
    setSpinner(true)
    try {
      let response = await reAssignTask(data)
      if (response?.code === 200) {
        setTaskModel({ data: "", open: false })
        setTaskFormInputs([
          {
            taskName: '',
            project_id: '',
            priority: '',
            assignTo_id: '',
            description: '',
            tags: [],
            assignStartTime: null,
            assignEndTime: null,
            minutesBeforeReminder: 15,
            notes: '',
            ReminderAction: true,
            attachments: [],
            workType: "",
            taskType: "",
            subTask: "",
            taskCategory: "",
            complexity: "",
            hoursType: "",
          },
        ])
      }
      // refetchTask()
      CloseFucntion("taskModel")
      toast.success(response.message)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      toast.error(error.message)
    }
  }
  // ====================================ReAssign Form End=====================================================

  // ====================================== Time Log upload File Handler ============================================
  const handleTimeLogFileChange = async (event) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TIMELOG");

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);

    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }

  // handle Remove File
  const handleLogRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }

  // ]==============================================================================================

  const getLogDetails = async (timeLogId) => {
    try {
      setSpinner(true)
      let response = await getTimeLogDetails(timeLogId)
      let { actualEndTime, actualStartTime, actutalStatus, complexity, hoursType, taskCategory, subTask, notes, attachments } = response?.data
      const updatedBreaks = response?.data?.task_id?.breaks?.map((item) => ({
        startTime: new Date(item.startTime),
        endTime: new Date(item.endTime),
        reason: item.reason,
        _id: item?._id
      }));

      setBreakHoursInput(updatedBreaks?.length ? updatedBreaks : breakHoursInput);
      setTimeLogDetailsModel((prev) => ({ ...prev, data: response.data }))
      setTimeLoFillInput({
        subTask: subTask,
        taskCategory: taskCategory,
        complexity: complexity,
        hoursType: hoursType,
        actutalStatus: actutalStatus,
        actualStartTime: new Date(actualStartTime),
        actualEndTime: new Date(actualEndTime),
        notes: notes
      })

      setSelectedFiles(attachments);
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchTimeLog()
    });
  }
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
    setTimeLogListInput({
      agent: [],
      // team: [],
      ticket: [],
      approvalstatus: [],
      hoursType: [],
      actutalStatus: [],
      Projects: [],
      startDate: null,
      endDate: null,
    })
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  //Handle Input value
  const handleTimeLogInputChange = (event) => {
    const { name, value } = event.target

    setTimeLoFillInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }))
    if (typeof value === 'string' && value.trim().length > 0) {
      setTimeLogError((pre) => {
        delete pre[name]
        return { ...pre }
      })
    }
  }
  // Error Message
  const timelogerrorMessage = {
    subTask: requiredSelect('sub task'),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
    actutalStatus: requiredSelect("actutal status"),
    actualStartTime: requiredSelect("actual startTime"),
    actualEndTime: requiredSelect("actual endTime"),
  }
  // Error hendling
  const istimeLogValid = () => {
    const errorObj = {}
    if (!timelogFillInputs.subTask) {
      errorObj['subTask'] = timelogerrorMessage['subTask']
    }
    if (!timelogFillInputs.taskCategory) {
      errorObj['taskCategory'] = timelogerrorMessage['taskCategory']
    }
    if (!timelogFillInputs.complexity) {
      errorObj['complexity'] = timelogerrorMessage['complexity']
    }
    if (!timelogFillInputs.hoursType) {
      errorObj['hoursType'] = timelogerrorMessage['hoursType']
    }
    if (!timelogFillInputs.actutalStatus) {
      errorObj['actutalStatus'] = timelogerrorMessage['actutalStatus']
    }
    if (!timelogFillInputs.actualStartTime) {
      errorObj['actualStartTime'] = timelogerrorMessage['actualStartTime']
    }
    if (!timelogFillInputs.actualEndTime) {
      errorObj['actualEndTime'] = timelogerrorMessage['actualEndTime']
    }
    setTimeLogError(errorObj)
    return !Object.keys(errorObj)?.length
  }
  // Submit log Time
  const SubmitLogTime = async () => {
    if (!istimeLogValid()) return

    // if (breakHoursInput?.length >= 0) {
    //   // console.log("one")

    //   if (breakHoursInput?.length === 1) {
    //     // console.log("two")
    //     if ((breakHoursInput[0]?.startTime && !breakHoursInput[0]?.endTime && !breakHoursInput[0]?.reason) ||

    //       (!breakHoursInput[0]?.startTime && breakHoursInput[0]?.endTime && !breakHoursInput[0]?.reason) || (!breakHoursInput[0]?.startTime && !breakHoursInput[0]?.endTime && breakHoursInput[0]?.reason))
    //       return validateBreaks()
    //   }
    //   else {
    //     // console.log("three")
    //     for (let i = 1; i < breakHoursInput?.length; i++) {
    //       if (!breakHoursInput[i]?.startTime || !breakHoursInput[i]?.endTime || !breakHoursInput[i]?.reason) {
    //         return validateBreaks()
    //       }
    //     }
    //   }
    // }
    if (breakHoursInput?.length > 0) {
      if (!validateBreaks()) return
    }
    let payload = {
      _id: timeLogDetails?.timeLogId,
    }
    // let newBreakHours = []

    // if (breakHoursInput && breakHoursInput?.length > 0 && (breakHoursInput[0]?.startTime != null || breakHoursInput[0]?.endTime != null || breakHoursInput[0]?.reason != "")) {
    //   await Promise.all(breakHoursInput.map(async (item) => {
    //     if (!item?._id) {
    //       let payload = {
    //         task_id: timeLogDetails?.data?.task_id?._id,
    //         breaks: [item]
    //       }
    //       let response = await addBreaks(payload);
    //       newBreakHours.push(response.data.breaks)
    //     }
    //   })
    //   )
    // }
    // if (newBreakHours && newBreakHours.length == 0) {
    //   newBreakHours = breakHoursInput
    // }
    // else
    //   breakHoursInput = newBreakHours[0]

    // if (breakHoursInput) {
    //   let newArray = []
    //   breakHoursInput?.forEach((item) => {
    //     if (!breakHoursInput.length || (!item.endTime && !item.startTime && !item.reason)) {
    //       newArray = []
    //     } else {
    //       newArray = breakHoursInput
    //     }
    //   })
    //   payload.breaks = newArray
    // }

    let newBreakHours = [];
    // await Promise.all(breakHoursInput.map(async (item) => {
    //   if (!item?._id) {
    //     try {
    //       let response = await addBreaks({ task_id: timeLogDetails?.data?.task_id?._id, breaks: [item] });
    //       newBreakHours.push(response.data.breaks);
    //     } catch (error) {
    //       // Handle error 
    //     }
    //   }
    // }));
    breakHoursInput = newBreakHours.length > 0 ? newBreakHours[0] : breakHoursInput;
// console.log(breakHoursInput,'breakHoursInput')
    payload.breaks = breakHoursInput.filter(item => moment(item.endTime).utc() || moment(item.startTime).utc() || item.reason);

    if (timelogFillInputs.subTask) {
      payload.subTask = timelogFillInputs.subTask
    }
    if (timelogFillInputs.taskCategory) {
      payload.taskCategory = timelogFillInputs.taskCategory
    }
    if (timelogFillInputs.complexity) {
      payload.complexity = timelogFillInputs.complexity
    }
    if (timelogFillInputs.hoursType) {
      payload.hoursType = timelogFillInputs.hoursType
    }
    if (timelogFillInputs.actutalStatus) {
      payload.actutalStatus = timelogFillInputs.actutalStatus
    }
    if (timelogFillInputs.actualStartTime) {
      payload.actualStartTime = timelogFillInputs.actualStartTime
    }
    if (timelogFillInputs.actualEndTime) {
      payload.actualEndTime = timelogFillInputs.actualEndTime
    }
    if (timelogFillInputs?.notes) {
      payload.notes = timelogFillInputs?.notes
    }
    payload.attachments = selectedFiles
    // console.log(payload,'payload')
    // return
    try {
      setSpinner(true)
      let response = await updatesubmitTimelog(payload)
      if (response?.code === 200) {
        setTimeLogDetailsModel({
          open: false,
          timeLogId: '',
          data: {},
        })
        refetchTimeLog()
      } else {
      }
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // TimeLogApprovalStatus

  const ApprovalStatus = async (logId, type) => {
    try {
      let payload = {
        _id: logId,
        status: type
      }
      setSpinner(true)
      let responce = await TimeLogApprovalStatus(payload)
      toast.success(responce.message)
      refetchTimeLog()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // const breakUpdate = () => {
  //   // if (taskFormInputs.length < 10) {

  //   // }
  // }
// console.log(TimeLogList?.data[0]?.userId,'lllllll')
  const breakUpdate = async () => {
    if(breakForm?.startTime>breakForm?.endTime) return toast.warn('Break end time should be greater than break start time')
    const payLoad = {
      breakId: breakForm?.breakId,
      startTime:moment(breakForm?.startTime).utc(),
      endTime:moment(breakForm?.endTime).utc(),
      shiftBreak_id:breakForm?.shiftBreak_id,
      user_id:TimeLogList?.data[0]?.userId

    }
    // console.log(payLoad, 'payLoad')
    // return
    try {
      setSpinner(true)
      let response = await updateBreak(payLoad)
      toast.success(response.message);
      refetchTimeLog()
      setBreakModal((prev) => ({ ...prev, open: false }))
      setBreakForm({ startTime: null, endTime: null })


    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  return {
    data: {
      controller,
      isLoading: spinner || TimeLogIsFetching,
      totalCount: TimeLogError?.message === 'No record found' ? 0 : TimeLogList?.totalCount || 0,
      TimeLogList: TimeLogError?.message === 'No record found' ? [] : TimeLogList?.data || [],
      agentList,
      allTagsList,
      skillAllList,
      projectList,
      search,
      statusType,
      timeLogListInput,
      timeLogDetails,
      timeLogPermission: user,
      timelogFillInputs,
      timeLogError,
      taskModel,
      taskFormInputs,
      projectListData: projectListData?.data || [],
      tagListData: tagListData?.data || [],
      assignToListData: assignToListData?.data || [],
      errors,
      selectedFiles,
      userTimeLog,
      deleteModal,
      timeLineListData: timeLineError?.message === 'No record found' ? [] : timeLineListData?.data || [],
      timeLineModal,
      taskTypeList,
      SubTaskList,
      TaskCategoryList,
      complexityList,
      hoursTypeList,
      workTypeList,
      breakHoursInput,
      breakErrors,
      breakModal,
      breakForm,
      user
    },
    methods: {
      breakUpdate,
      setBreakForm,
      setTimeLogDetailsModel,
      getLogDetails,
      setController,
      setTimeLogListInput,
      handlePagination,
      resetFilter,
      applayFilter,
      setSearchText,
      setStatusType,
      getAgentList,
      Export,
      setTimeLoFillInput,
      setTimeLogError,
      handleTimeLogInputChange,
      SubmitLogTime,
      ApprovalStatus,
      handleFileChange,
      handleRemoveFile,
      handleInputChange,
      handleDateChange,
      addMultipleTask,
      removeTask,
      CloseFucntion,
      submitTask,
      setTaskModel,
      setTaskFormInputs,
      setErrors,
      handleTimeLogFileChange,
      handleLogRemoveFile,
      setSelectedFiles,
      setDeleteModal,
      handleDelete,
      setTimeLineModal,
      setBreakHoursInput,
      handleBrakeTime,
      removeBreak,
      addMultipleBreak,
      setBreakErrors,
      handleBreakInput,
      fetchMoreData,
      setBreakModal
      // setBreakModal,
      // submitBreak,
      // CloseFucntion
    },
  }
}

export default useTimeLog

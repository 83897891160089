/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useToGetTeamMemberList, userStatus, deleteUser, ExportUserList, removeEl } from '../../../repository/teamManagementRepo'
import { ReSendInvetation } from '../../../repository/auth'
import { getAllTeamList } from '../../../repository/teamRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../Slices/FiltersSlice'
const useTeamManagementList = () => {
  const { selector: user } = useReduxStore('auth')
  const { selector: TeamFilter } = useReduxStore('filters')
  const dispatch = useDispatch()
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  const [filterAction, setFilterAction] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [teamModal, setTeamModal] = useState({
    open: false,
    data: [],
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const [deleteEL, setDeleteEL] = useState({
    open: false,
    data: [],
    note: '',
  })
  const [search, setSearchText] = useState(TeamFilter?.Team?.filters?.search || '')
  const [statusType, setStatusType] = useState(TeamFilter?.Team?.filters?.statusType || '')
  const [groupList, setGroupList] = useState([])

  const [selectedGroup, setSelectedGroup] = useState(TeamFilter?.Team?.filters?.selectedGroup || [])
  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    // Perform any other actions you need here
  }
  const cacheTime = { cacheTime: 10000 }

  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    // isDeleted: false,
    search: search,
  }

  if (typeof statusType == 'boolean') {
    payload.isDeleted = statusType;
  } else {
    if (statusType === 'false') { payload.isDeleted = false; }
    else if (statusType === 'true') { payload.isDeleted = true; }
  }
  if (selectedGroup.length > 0) {
    payload.teams = selectedGroup?.map((item) => item._id)
  }

  useEffect(() => {
    // Dispatch the addFilter action with the current 'Team' page and the filter
    dispatch(addFilter({
      page: 'Team', filter: {
        search: search,
        statusType: statusType,
        selectedGroup: selectedGroup
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, statusType, selectedGroup]);
  // Fetch api
  const {
    data: teamMemberList,
    error: teamMemberError,
    isFetching: teamMemberIsFetching,
    refetch: refetchTeamMembers,
  } = useToGetTeamMemberList(payload, {
    enabled: false,
    ...cacheTime,
  })

  // Get All Group List
  const getAllGroupList = async () => {
    try {
      setSpinner(true)
      let response = await getAllTeamList()
      setGroupList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  useEffect(() => {
    refetchTeamMembers() // Call the renamed refetch function
  }, [controller, filterAction])

  // Export User Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportUserList(payload)
      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  useEffect(() => {
    getAllGroupList()
  }, [])
  // Resens Invetation
  const resend = async (id) => {
    try {
      setSpinner(true)
      const response = await ReSendInvetation({ user_id: id })
      toast.success(response.message)
    } catch (error) {
      toast.error(error?.message)
    }
    setSpinner(false)
  }

  // Status
  const status = async (id, toggle) => {
    try {
      setSpinner(true)
      let response = await userStatus({
        _id: id,
        isActive: toggle,
      })
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Error Toast Message
  useEffect(() => {
    if (teamMemberError) {
      // toast.error(teamMemberError?.message);
    }
  }, [teamMemberError])

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchTeamMembers()
    });
  }
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
    setSelectedGroup([])
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteUser({
        _id: deleteModal?.data?._id,
        isDeleted: true,
      })
      setDeleteModal({ data: '', open: false })
      refetchTeamMembers()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  const handleRemoveEl = async () => {
    try {
      if (!deleteEL?.note) return toast.warn('Please enter note')
      setSpinner(true)
      const payLoad = { id: deleteEL?.data?._id, notes: deleteEL?.note }
      let response = await removeEl(payLoad)
      setDeleteEL({ data: '', open: false })
      refetchTeamMembers()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  return {
    data: {
      controller,
      isLoading: spinner || teamMemberIsFetching,
      totalCount: teamMemberError?.message === 'No record found' ? 0 : teamMemberList?.totalCount || 0,
      teamMemberList: teamMemberError?.message === 'No record found' ? [] : teamMemberList?.data || [],
      teamModal,
      deleteModal,
      search,
      statusType,
      groupList,
      selectedGroup,
      TeamPermission: user,
      deleteEL
    },
    methods: {
      handlePagination,
      resetFilter,
      applayFilter,
      setTeamModal,
      status,
      handleDelete,
      setDeleteModal,
      handleSearchChange,
      setStatusType,
      resend,
      setSelectedGroup,
      Export,
      setDeleteEL,
      handleRemoveEl
    },
  }
}

export default useTeamManagementList

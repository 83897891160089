import React from 'react'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DateInput from "../../components/DateInput"
import MultipleSelect from "../../components/MultipleSelect"
const IdleUserFilter = (props) => {
    const { data, methods } = props
    const { allRoleList, filter, shiftList,errors } = data
    const {
        resetFilter,
        setFilter,
        idleUserList,
        setErrors
    } = methods
    return (
        <>
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                <div className="filter">
                    <div className="col-lg-11 mx-auto mb-2">
                        <MultipleSelect
                            label={"Role"}
                            options={allRoleList}
                            searchBy={"roleTitle"}
                            keyName={"roleTitle"}
                            name={"role"}
                            onChange={(e) => setFilter((prevProps) => ({ ...prevProps, role: e }))}
                            value={"_id"}
                            selectedValues={filter?.role}
                           
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <MultipleSelect
                            isRequired={true}
                            label={"Shift"}
                            options={shiftList}
                            searchBy={"roleTitle"}
                            keyName={"name"}
                            name={"shift"}
                            onChange={(e) => {setFilter((prevProps) => ({ ...prevProps, shift: e }));setErrors((pre)=>({...pre,shift:''}))}}
                            value={"_id"}
                            selectedValues={filter?.shift}
                            error={errors.shift}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            isRequired={true}
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            Label={'Start Date'}
                            SelectedDate={filter?.startDate || null}
                            handleDateChange={(date) => {
                                setFilter((pre) => ({ ...pre, startDate: date }))
                                setErrors((pre)=>({...pre,startDate:''}))
                            }}
                        />
                        {errors?.startDate && <p className='text-danger'>{errors?.startDate}</p>}
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            isRequired={true}
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            Label={'End Date'}
                            SelectedDate={filter?.endDate || null}
                            handleDateChange={(date) => {
                                setFilter((pre) => ({ ...pre, endDate: date }))
                                setErrors((pre)=>({...pre,endDate:''}))
                            }}
                        />
                        {errors?.endDate && <p className='text-danger'>{errors?.endDate}</p>}
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" startIcon={<SearchIcon />} onClick={idleUserList}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </>
    )
}

export default IdleUserFilter
import React, { useRef} from 'react'
import TextField from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Button } from '@mui/material'
import { CKEditorCustomToolbar, priorityList, ReminderList } from '../../constants'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import Switch from '@mui/material/Switch'
import MultipleSelect from "../../components/MultipleSelect"

import { uploadFileUrlTask } from '../../repository/taskRepo'
import "./selectStyle.css"


const TaskForm = ({ data, methods }) => {
  const fileInputRef = useRef(null)
  const { taskFormInputs, tagListData, assignToListData, projectListData, taskModel, errors, taskTypeList,
    SubTaskList,
    TaskCategoryList,
    complexityList,
    hoursTypeList,
    workTypeList
  } = data
  const { handleFileChange, handleRemoveFile, addMultipleTask, handleInputChange, handleDateChange, removeTask, submitTask, CloseFucntion, submitUpdateTask, setErrors } = methods

  // const fileType = () => {
  //   let type = ""
  //   if (state?.helpId?._id) type = imageFile?.type
  //   else
  //     type = imageFile?.file?.type?.split('/').pop();
  //   if (["png", "jpeg"].includes(type)) {
  //     return <img src={state?.helpId?._id ? imageFile?.file : URL.createObjectURL(imageFile?.file)} alt="" width="30%" height="100%" />;
  //   }
  //   // else if (["mp4", "wmv", "MOV", "webm"].includes(type)) {
  //   //     return <video src={URL.createObjectURL(imageFile.file)} controls autoPlay width="40%" height="100%" />
  //   // }
  //   else {
  //     return <img src={FileImg} alt="" width="20%" height="100%" />;
  //   }
  // };

  const fileType = (file, fileIndex) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (<img key={fileIndex} src={uploadFileUrlTask + file} alt="" width="100%" height="100%" />)
    } else if (['mp4'].includes(type)) {
      return (<img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />)
    } else if (['pdf'].includes(type)) {
      return (<img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />)
    } else if (['xlsx', 'xls'].includes(type)) {
      return (<img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />)
    } else if (['txt', 'doc'].includes(type)) {
      return (<img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />)
    } else {
      return (<img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />)
    }
    // else if (["mp4", "wmv", "MOV", "webm"].includes(type)) {
    //   return (
    //     <video
    //       key={fileIndex}
    //       src={URL.createObjectURL(file)}
    //       controls
    //       autoPlay
    //       width="100%"
    //       height="100%"
    //     />
    //   );
    // }
  }


  return (
    <>
      <div className="pe-2"
      // style={{ height: '85vh', overflowY: 'auto', overflowX: 'hidden' }}
      >
        {taskFormInputs?.map((item, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-lg-12 my-2">
                <TextField
                  Lable="Task Name"
                  Name="taskName"
                  variant="outlined"
                  ClassName="form-field-design "
                  Require={true}
                  HandleInputChange={(e) => handleInputChange(e, index)}
                  onBlur={(e) => handleInputChange(e, index)}
                  Value={item.taskName}
                  width={100}
                // Error={errors[index].taskName}
                />
                {errors[index]?.taskName && <span className='text-danger'>{errors[index].taskName}</span>}
              </div>
              {/* <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Description </sub>
                </label>
                <textarea name="" id="" className="w-100 mt-1" rows="3"></textarea>
                <CKEditor
                  editor={ClassicEditor}
                  data={item.description} // Set CKEditor data to projectInputs.notes
                // onChange={(event, editor) => {
                //   const data = editor.getData();
                //   setTaskFormInputs((prev) => ({ ...prev, description: data }));
                // }}
                />
              </div> */}

              {/* <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Description <span className='text-danger'>*</span></sub>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  data={item.description}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleInputChange({ target: { name: 'description', value: data } }, index)
                  }}
                />
              </div> */}

              <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Description <span className='text-danger'>*</span></sub>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  data={item.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleInputChange({ target: { name: 'description', value: data } }, index);
                    // Clear the description error when there is a change
                    setErrors((prevErrors) => {
                      const updatedErrors = [...prevErrors];
                      updatedErrors[index] = { description: '' };
                      return updatedErrors;
                    });
                  }}
                  onBlur={(event, editor) => {
                    if (!item.description) {
                      // Set the description error if it's empty on blur
                      setErrors((prevErrors) => {
                        const updatedErrors = [...prevErrors];
                        updatedErrors[index] = { description: 'Please enter description' };
                        return updatedErrors;
                      });
                    }
                  }}
                />
                {errors[index]?.description && <p className="text-danger">{errors[index]?.description}</p>}
              </div>

              <div className="col-lg-6 my-2">
                {/* <SelectInput
                  Lable={'Project '}
                  // width={"310px"}
                  Options={projectListData}
                  keyName={'name'}
                  Name="project_id"
                  ClassName="form-field-design"
                  SelectValue={item.project_id}
                  HandleChange={(e) => handleInputChange(e, index)}
                  //   onBlur={handleBlur}
                  //   Error={error.priority}
                  variant={'outlined'}
                  isRequired={true}
                /> */}

                <MultipleSelect
                  label="Project"
                  isRequired={true}
                  // multiple={true}
                  options={projectListData}
                  searchBy={"name"}
                  keyName={"name"}
                  name={"project_id"}
                  onChange={(e) => handleInputChange({ target: { name: "project_id", value: e } }, index)}
                  value={"_id"}
                  selectedValues={item?.project_id}
                />
                {errors[index]?.project_id && <span className='text-danger'>{errors[index]?.project_id}</span>}
              </div>

              <div className="col-lg-6 my-2">
                {/* <SelectInput
                  Lable={'Priority'}
                  // width={"310px"}
                  Options={priorityList}
                  keyName={'title'}
                  Name="priority"
                  ClassName="form-field-design"
                  SelectValue={item.priority}
                  HandleChange={(e) => handleInputChange(e, index)}
                  //   onBlur={handleBlur}
                  //   Error={error.priority}
                  variant={'outlined'}
                  isRequired={true}
                /> */}
                <MultipleSelect
                  label="Priority"
                  isRequired={true}
                  // multiple={true}
                  options={priorityList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"priority"}
                  onChange={(e) => handleInputChange({ target: { name: "priority", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.priority}
                />
                {errors[index]?.priority && <span className='text-danger'>{errors[index]?.priority}</span>}
              </div>

              <div className="col-lg-6 my-2 ">
                {/* <div style={{ position: "relative" }}> */}
                {/* <CustomSelect
                    options={assignToListData}
                    onSelect={(selectedOption) => {
                      handleInputChange({ target: { value: selectedOption._id, name: 'assignTo_id' } }, index);
                    }}
                  /> */}

                {/* <CustomSelect options={assignToListData} onSelect={(e) =>handleInputChange(e, index)} /> */}
                {/* <p>Selected option: {selectedOption}</p> */}
                {/* </div> */}
                <MultipleSelect
                  label="Assign To"
                  isRequired={true}
                  // multiple={true}
                  options={assignToListData}
                  searchBy={"name"}
                  keyName={"name"}
                  name={"assignTo_id"}
                  onChange={(e) => handleInputChange({ target: { name: "assignTo_id", value: e } }, index)}
                  value={"_id"}
                  selectedValues={item?.assignTo_id}
                />

                {errors[index]?.assignTo_id && <span className='text-danger'>{errors[index]?.assignTo_id}</span>}
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Tag"
                  // isRequired={true}
                  multiple={true}
                  options={tagListData}
                  searchBy={"tagName"}
                  keyName={"tagName"}
                  name={"tags"}
                  onChange={(e) => handleInputChange({ target: { name: "tags", value: e } }, index)}
                  value={"_id"}
                  selectedValues={item.tags}
                />
              </div>

              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Start Date '}
                  handleDateChange={(date) => handleDateChange(date, 'assignStartTime', index)}
                  SelectedDate={taskFormInputs[index]?.assignStartTime}
                />
                {errors[index]?.assignStartTime && <span className='text-danger'>{errors[index]?.assignStartTime}</span>}
              </div>
              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Due Date'}
                  handleDateChange={(date) => handleDateChange(date, 'assignEndTime', index)}
                  SelectedDate={taskFormInputs[index]?.assignEndTime}
                />
                {errors[index]?.assignEndTime && <span className='text-danger'>{errors[index]?.assignEndTime}</span>}
              </div>
              <div className="col-lg-12 my-2 position-relative mt-3">
                <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
                  <Switch name="ReminderAction" checked={item?.ReminderAction} onChange={(e) => handleInputChange(e, index)} />
                </div>
                <SelectInput
                  LabelButton
                  Lable={'Reminder'}
                  // multiple={true}
                  Options={ReminderList}
                  keyName={'title'}
                  Name="minutesBeforeReminder"
                  SelectValue={item.minutesBeforeReminder}
                  HandleChange={(e) => handleInputChange(e, index)}
                  // onBlur={handleBlur}
                  // Error={error.tags}
                  variant={'outlined'}
                  // isRequired={true}
                  disabled={item?.ReminderAction === false}
                />
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Task Type"
                  isRequired={true}
                  // multiple={true}
                  options={taskTypeList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"taskType"}
                  onChange={(e) => handleInputChange({ target: { name: "taskType", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.taskType}
                />
                {errors[index]?.taskType && <span className='text-danger'>{errors[index]?.taskType}</span>}
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="SubTask"
                  isRequired={true}
                  // multiple={true}
                  options={SubTaskList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"subTask"}
                  onChange={(e) => handleInputChange({ target: { name: "subTask", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.subTask}
                />
                {errors[index]?.subTask && <span className='text-danger'>{errors[index]?.subTask}</span>}
              </div>

              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Task Category"
                  isRequired={true}
                  // multiple={true}
                  options={TaskCategoryList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"taskCategory"}
                  onChange={(e) => handleInputChange({ target: { name: "taskCategory", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.taskCategory}
                />
                {errors[index]?.taskCategory && <span className='text-danger'>{errors[index]?.taskCategory}</span>}
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Complexity"
                  isRequired={true}
                  // multiple={true}
                  options={complexityList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"complexity"}
                  onChange={(e) => handleInputChange({ target: { name: "complexity", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.complexity}
                />
                {errors[index]?.complexity && <span className='text-danger'>{errors[index]?.complexity}</span>}
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Hours Type"
                  isRequired={true}
                  // multiple={true}
                  options={hoursTypeList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"hoursType"}
                  onChange={(e) => handleInputChange({ target: { name: "hoursType", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.hoursType}
                />
                {errors[index]?.hoursType && <span className='text-danger'>{errors[index]?.hoursType}</span>}
              </div>
              <div className="col-lg-6 mt-2">
                <MultipleSelect
                  label="Work Type"
                  isRequired={true}
                  // multiple={true}
                  options={workTypeList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"workType"}
                  onChange={(e) => handleInputChange({ target: { name: "workType", value: e } }, index)}
                  value={"value"}
                  selectedValues={item?.workType}
                />
                {errors[index]?.workType && <span className='text-danger'>{errors[index]?.workType}</span>}
              </div>


            </div>

            <div className="col-12 d-flex justify-content-between align-items-center">
              <h5 className="mt-4 mb-2">Attachments</h5>
              {item?.attachments?.length < 5 && <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                choose file
              </Button>}
              <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={(e) => handleFileChange(e, index)} style={{ display: 'none' }} />
            </div>
            <div className="col-lg-12">
              {item?.attachments?.length > 0 && (
                <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                  {/* <h4>Selected Files:</h4> */}
                  {/* <ul> */}
                  <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                    {item?.attachments?.map((file, fileIndex) => (
                      <div style={{ width: '80px', height: '80px' }} className="position-relative">
                        {fileType(file, fileIndex)}
                        <Fab
                          className="m-2"
                          sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                          onClick={() => handleRemoveFile(index, fileIndex, file)}
                        >
                          <ClearIcon sx={{ fontSize: '15px' }} />
                        </Fab>
                      </div>
                    ))}

                  </div>
                </div>
              )}
            </div>
            {/* <div className="row mt-3">
          <div className="d-flex justify-content-between">
            <div>
              <sub>Attachments</sub>
            </div>
            <div className="mb-1">
              <Button
                variant="outlined"
                className="p-0"
                startIcon={<AttachFileIcon />}
              >
                File
              </Button>
            </div>
          </div>
          <div className="col-lg-12">
            <label htmlFor="" className='mb-1'><sub>Attachments *</sub></label>
            <div
              className="row border border-1"
              style={{
                borderRadius: "4px",
                margin: "0",
                // height: "100px",
              }}
            >
              <div className="col-lg-4 p-2">
                <div
                  className="d-flex justify-content-between rounded"
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <div>
                    <img
                      src=""
                      alt="images"
                      height={"100px"}
                      width={"100px"}
                      className="p-3"
                    />
                  </div>
                  <div className="task">
                    <p className="mt-3 me-3 bg">
                      <b>X</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-2">
                <div
                  className="d-flex justify-content-between rounded"
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <div>
                    <img
                      src=""
                      alt="images"
                      height={"100px"}
                      width={"100px"}
                      className="p-3"
                    />
                  </div>
                  <div className="task">
                    <p className="mt-3 me-3 bg">
                      <b>X</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-2">
                <div
                  className="d-flex justify-content-between rounded"
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <div>
                    <img
                      src=""
                      alt="images"
                      height={"100px"}
                      width={"100px"}
                      className="p-3"
                    />
                  </div>
                  <div className="task">
                    <p className="mt-3 me-3 bg">
                      <b>X</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-2">
                <div
                  className="d-flex justify-content-between rounded"
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <div>
                    <img
                      src=""
                      alt="images"
                      height={"100px"}
                      width={"100px"}
                      className="p-3"
                    />
                  </div>
                  <div className="task">
                    <p className="mt-3 me-3 bg">
                      <b>X</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-2">
                <div
                  className="d-flex justify-content-between rounded"
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  <div>
                    <img
                      src=""
                      alt="images"
                      height={"100px"}
                      width={"100px"}
                      className="p-3"
                    />
                  </div>
                  <div className="task">
                    <p className="mt-3 me-3 bg">
                      <b>X</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            <div className="col-lg-12 my-2">
              <label htmlFor="">
                <sub>Notes </sub>
              </label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: CKEditorCustomToolbar,
                }}
                data={item.notes} // Set CKEditor data to projectInputs.notes
                onChange={(event, editor) => {
                  const data = editor.getData()
                  handleInputChange({ target: { name: 'notes', value: data } }, index)
                }}
              />
            </div>
            {!taskModel.data && (
              <div className="col-lg-12 text-end mt-3">
                <Button className="dropDownButtons11 me-2" onClick={() => addMultipleTask(index)}>
                  <AddIcon />
                </Button>
                {taskFormInputs.length > 1 && ( // Only show the button if there is more than one task
                  <Button variant="outlined" color="error" onClick={() => removeTask(index)}>
                    <ClearIcon />
                  </Button>
                )}
              </div>
            )}
          </div>
        ))}
        <div className="col-lg-12 mt-3">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className=""></div>
              <div className="row">
                {/* {add?.value &&  */}
                <div className=" d-flex justify-content-end">
                  <div className="m-2">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        CloseFucntion('taskModel')
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="m-2">
                    <Button
                      variant="contained"
                      onClick={taskModel?.data && !taskModel?.clone ? submitUpdateTask : submitTask}
                    >
                      {taskModel?.data && !taskModel?.clone ? 'Update Task' : 'Create Task'}
                    </Button>
                  </div>
                </div>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <select
onChange={(e)=>handelChange(e)}
value={value}
>
 {assignToListData.map((item)=>
  <option value={item._id}>{item.name}</option>
 )}
  
</select> */}

      {/* <div style={{position:"relative"}}>
      <CustomSelect options={assignToListData} onSelect={setSelectedOption} />
      <p>Selected option: {selectedOption}</p>
    </div> */}

    </>
  )
}

export default TaskForm

// const CustomSelect = ({ options, onSelect }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredOptions, setFilteredOptions] = useState(options);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const selectRef = useRef(null);

//   useEffect(() => {
//     setFilteredOptions(
//       options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//   }, [searchTerm, options]);

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (option) => {
//     onSelect(option); // Send the _id to the backend
//     setIsOpen(false);
//     setSearchTerm('');
//     setSelectedOption(option);
//   };

//   const handleInputChange = (e) => {
//     if (selectedOption) {
//       setSelectedOption(null);
//     }
//     setSearchTerm(e.target.value);
//   };

//   const handleInputKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       if (searchTerm.trim() !== '') {
//         // Find the corresponding option object by name
//         const selected = options.find(option => option.name === searchTerm);
//         if (selected) {
//           onSelect(selected); // Send the _id to the backend
//           setSearchTerm('');
//           setSelectedOption(selected);
//         }
//       }
//     }
//   };

//   const handleFocusOut = () => {
//     if (selectRef.current && !selectRef.current.contains(document.activeElement)) {
//       setIsOpen(false);
//     }
//   };

//   return (
//     <div ref={selectRef} className="custom-select" onBlur={handleFocusOut} tabIndex={0}>
//       <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
//         <input
//           type="text"
//           value={selectedOption ? selectedOption.name : searchTerm}
//           placeholder="Search..."
//           onChange={handleInputChange}
//           onKeyDown={handleInputKeyDown}
//           onFocus={() => setIsOpen(true)}
//         />
//       </div>
//       {isOpen && (
//         <ul className="options">
//           {filteredOptions.map((option, index) => (
//             <li key={index} onClick={() => handleSelect(option)}>
//               {option.name}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// correct on remove 

// const CustomSelect = ({ options, onSelect }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredOptions, setFilteredOptions] = useState(options);
//   const [selectedOption, setSelectedOption] = useState(null);

//   useEffect(() => {
//     setFilteredOptions(
//       options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//   }, [searchTerm, options]);

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (option) => {
//     onSelect(option.name);
//     setIsOpen(false);
//     setSearchTerm('');
//     setSelectedOption(option);
//   };

//   const handleInputChange = (e) => {
//     if (selectedOption) {
//       setSelectedOption(null); // Clear the selected value when typing
//     }
//     setSearchTerm(e.target.value);
//   };

//   const handleInputKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       if (searchTerm.trim() !== '') {
//         onSelect(searchTerm);
//         setSearchTerm('');
//       }
//     }
//   };

//   return (
//     <div className="custom-select">
//       <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
//         <input
//           type="text"
//           value={selectedOption ? selectedOption.name : searchTerm}
//           placeholder="Search..."
//           onChange={handleInputChange}
//           onKeyDown={handleInputKeyDown}
//         />
//       </div>
//       {isOpen && (
//         <ul className="options">
//           {filteredOptions.map((option, index) => (
//             <li key={index} onClick={() => handleSelect(option)}>
//               {option.name}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };




// const CustomSelect = ({ options, onSelect }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredOptions, setFilteredOptions] = useState(options);
//   const [selectedOption, setSelectedOption] = useState('');

//   useEffect(() => {
//     setFilteredOptions(
//       options.filter(option => option.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//   }, [searchTerm, options]);

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (option) => {
//     onSelect(option);
//     setIsOpen(false);
//     setSearchTerm('');
//     setSelectedOption(option);
//   };

//   const handleInputChange = (e) => {
//     if (selectedOption) {
//       setSelectedOption(''); // Clear the selected value when typing
//     }
//     setSearchTerm(e.target.value);
//   };

//   const handleInputKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       if (searchTerm.trim() !== '') {
//         onSelect(searchTerm);
//         setSearchTerm('');
//       }
//     }
//   };

//   return (
//     <div className="custom-select">
//       <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
//         <input
//           type="text"
//           value={selectedOption || searchTerm}
//           placeholder="Search..."
//           onChange={handleInputChange}
//           onKeyDown={handleInputKeyDown}
//         />
//       </div>
//       {isOpen && (
//         <ul className="options">
//           {filteredOptions.map((option, index) => (
//             <li key={index} onClick={() => handleSelect(option)}>
//               {option}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// const CustomSelect = ({ options, onSelect }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredOptions, setFilteredOptions] = useState(options);
//   const [selectedOption, setSelectedOption] = useState('');

//   useEffect(() => {
//     setFilteredOptions(
//       options.filter(option => option.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//   }, [searchTerm, options]);

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (option) => {
//     onSelect(option);
//     setIsOpen(false);
//     setSearchTerm('');
//     setSelectedOption(option);
//   };

//   const handleClear = () => {
//     setSelectedOption('');
//   };

//   return (
//     <div className="custom-select">
//       <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
//         <input
//           type="text"
//           value={selectedOption || searchTerm}
//           placeholder="Search..."
//           onChange={(e) => setSearchTerm(e.target.value)}
//         />
//         {selectedOption && (
//           <button className="clear-button" onClick={handleClear}>
//             Clear
//           </button>
//         )}
//       </div>
//       {isOpen && (
//         <ul className="options">
//           {filteredOptions.map((option, index) => (
//             <li key={index} onClick={() => handleSelect(option)}>
//               {option}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };
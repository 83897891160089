import moment from 'moment-timezone';
import useReduxStore from './useReduxStore';


const UseDateTimeFormat = () => {
    const { selector: user } = useReduxStore('auth')

    const getFormattedDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
        const momentDate = moment(date).tz(user?.userInfo?.timezone);
        if (format === 'calendar') {
            return momentDate.calendar();
        }
        return momentDate.format(format);
    };
    return {
        getFormattedDateTime
    }
}

export default UseDateTimeFormat
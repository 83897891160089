// import React from 'react'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { DatePicker } from '@mui/x-date-pickers';
// import { TextField, InputLabel } from '@mui/material'


// export default function index({ SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, minDate, views }) {
//     return (
//         <>
//             <div className={`${className} m-3`}>
//                 <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{Label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
//                 <LocalizationProvider dateAdapter={AdapterDateFns}>
//                     <DatePicker
//                         views={views}
//                         className='customDatePickerWidth'
//                         minDate={minDate}
//                         inputFormat="MM/dd/yyyy"
//                         value={SelectedDate || null}
//                         onChange={handleDateChange}
//                         onBlur={handleDateChange}
//                         name={name}
//                         size={size}
//                         renderInput={(params) => <TextField variant={variant ? variant : "standard"}  {...params} error={false}
                        
//                         />}
//                     />
//                 </LocalizationProvider>
//             </div>
//         </>
//     )
// }


import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField, InputLabel } from '@mui/material'

export default function Index({ SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, minDate, views }) {
    return (
        <div className={`${className}`}>
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>
                {Label}{isRequired && <span className="text-danger"> *</span>}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    views={views}
                    minDate={minDate}
                    inputFormat="MM/dd/yyyy"
                    value={SelectedDate || null}
                    onChange={handleDateChange}
                    onBlur={handleDateChange}
                    name={name}
                    renderInput={(params) => (
                        <TextField
                            variant={variant || "standard"}
                            {...params}
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-input": {
                                    height: "1.5em !important", // Adjust height as needed
                                    fontSize: "0.875rem", // Small size text
                                    padding: "10px 12px", // Adjust padding to control input size
                                }
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
}

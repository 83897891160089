import { axiosInstance, baseUrl } from './auth'
import { useQuery } from 'react-query'
import { ApiStatus } from '../constants'
import { environment } from '../env/environment'

export const TaskList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/task/list`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}
export function useToGetTaskList(params, options = {}) {
  return useQuery(['GET_Task_LIST'], () => TaskList(params), options)
}

// All Task List
export const allTaskList = async (body) => {
  let response = await axiosInstance.post(`${baseUrl}/task/fullList`, body)
  if (response.data.code !== ApiStatus.success) {
    throw new Error(response.data.message || ApiStatus.serverError)
  }
  return response.data
}

// Add Task
export const addTask = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// uploadFile Task
export const uploadFile = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/common/multipleUploads`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// delete file Task
export const DeleteFile = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/common/deleteFile`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}
export const uploadFileUrlTask = `${environment?.baseUrl}/upload/tasks/`
export const uploadFileUrlTimeLog = `${environment?.baseUrl}/upload/timelog/`
export const uploadFileUrlLeave = `${environment?.baseUrl}/upload/leaves/`


// Change Task Status
export const changeTaskStatus = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/changeTaskStatus`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//  Get Task Details
export const getTaskDetailsApi = async (params) => {
  const response = await axiosInstance.get(`${baseUrl}/task/${params}`)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// add commit
export const AddCommitApi = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/addComment`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Update Task
export const updateTask = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/task`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Delete Task
export const deleteTask = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/toggleDelete`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// change Approval Status
export const changeApprovalStatus = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/changeApprovalStatus`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Task Add Timelog
export const submitTimelog = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/addTimelog`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

//Task Export
export const ExportTasktList = async params => {
  const response = await axiosInstance.post(`${baseUrl}/task/export`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

//Permanent Delete
export const PermanentDelete = async params => {
  const response = await axiosInstance.post(`${baseUrl}/task/permanentDelete`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};


// uploadTaskFile
export const importTaskFile = async params => {
  const response = await axiosInstance.post(`${baseUrl}/task/import`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};
// /task/reassign

export const reAssignTask = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/reassign`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Task Time Line History
export const taskTimeLineHistory = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/history`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

export function useToGetTaskTimeLineHistoryList(params, options = {}) {
  return useQuery(['GET_TASK_TIMELINE_LIST'], () => taskTimeLineHistory(params), options)
}

//

// Get Full IdleUser
export const idleuserLists = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/task/getIdleUser`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}
export const idleuserExport = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/taskIdleUser/export`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}


export function useToGetFullIdleUser(params, options = {}) {
  return useQuery(['GET_IDLE_USER_LIST'], () => idleuserLists(params), options)
}

// addBreaks
export const addBreaks = async params => {
  const response = await axiosInstance.post(`${baseUrl}/task/addBreaks`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// updateBreaks
export const updateBreaks = async params => {
  const response = await axiosInstance.put(`${baseUrl}/task/updateBreaks`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

// shiftBreak
export const shiftBreak = async params => {
  const response = await axiosInstance.get(`${baseUrl}/shift/shiftBreak/${params}`);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const addBreak = async params => {
  const response = await axiosInstance.post(`${baseUrl}/shift/addBreak`,params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};


// deleteBreakTime
export const deleteBreakTime = async params => {
  const response = await axiosInstance.delete(`${baseUrl}/task/break/${params}`);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const updateBreak = async params => {
  const response = await axiosInstance.post(`${baseUrl}/shift/updateBreak`,params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};


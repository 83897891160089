import React from 'react'
import DateInput from '../../../../components/DateInput'
import MultipleSelect from "../../../../components/MultipleSelect"
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
const BreakFilter = (props) => {
    const { data, methods } = props
    const { agentList, filterInput, shiftList, } = data
    const { resetFilter, getBreakList, setFilterInput } = methods
    return (
        <div>
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                <div className="filter">
                    <div className="col-lg-11 mx-auto mb-2">
                        <MultipleSelect
                            label={"Agent"}
                            // multiple={true}
                            options={agentList}
                            searchBy={"name"}
                            keyName={"name"}
                            name={"agent"}
                            onChange={(e) => setFilterInput((prevProps) => ({ ...prevProps, agent: e }))}
                            value={"_id"}
                            selectedValues={filterInput?.agent}
                        />
                    </div>

                    <div className="col-lg-11 mx-auto mb-2">
                        <MultipleSelect
                            label={"Shift"}
                            // multiple={true}
                            options={shiftList?.data}
                            searchBy={"name"}
                            keyName={"name"}
                            name={"shift"}
                            onChange={(e) => setFilterInput((prevProps) => ({ ...prevProps, shift: e }))}
                            value={"_id"}
                            selectedValues={filterInput?.shift}
                        />
                    </div>


                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design "
                            // isRequired={true}
                            Label={'Date'}
                            SelectedDate={new Date(filterInput?.date) || null}
                            handleDateChange={(date) => {
                                setFilterInput((pre) => ({ ...pre, date: date }))
                            }}
                        />
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" onClick={()=>getBreakList()} startIcon={<SearchIcon />}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BreakFilter

import React from 'react'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DateInput from "../../components/DateInput"
import MultipleSelect from "../../components/MultipleSelect"

const UserTimeLogFilter = (props) => {
    const { data, methods } = props
    const { agentList, timeLogListInput } = data
    const {
        resetFilter,
        applayFilter,
        setTimeLogListInput,
    } = methods
    const statusList = [
        { color: '#97FFF4', title: 'Approved', value: 'APPROVED' },
        { color: '#793FDF', title: 'UnApproved', value: 'UNAPPROVED' },
    ]
    return (
        <>
            <div className="shadow bg raduus py-2">
                <div className="col-lg-10">
                    <h5 className="text-center">Filters</h5>
                </div>
                <div className="filter">
                <div className="col-lg-11 mx-auto mb-2">
                        
                <MultipleSelect
                            label={"Approval Status"}
                            multiple={true}
                            options={statusList}
                            searchBy={"title"}
                            keyName={"title"}
                            name={"approvalstatus"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, approvalstatus: e }))}
                            value={"value"}
                            selectedValues={timeLogListInput?.approvalstatus}
                        />
                      
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        
                        <MultipleSelect
                            label={"Agent"}
                            multiple={true}
                            options={agentList}
                            searchBy={"name"}
                            keyName={"name"}
                            name={"agent"}
                            onChange={(e) => setTimeLogListInput((prevProps) => ({ ...prevProps, agent: e }))}
                            value={"_id"}
                            selectedValues={timeLogListInput?.agent}
                        />
                        {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>Agent</InputLabel>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setTimeLogListInput((prevProps) => ({ ...prevProps, agent: newValue }))
                            }}
                            options={agentList}
                            getOptionLabel={(option) => option.name}
                            value={timeLogListInput?.agent}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            size="small"
                        /> */}
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'Start Date'}
                            SelectedDate={timeLogListInput?.startDate || null}
                            handleDateChange={(date) => {
                                setTimeLogListInput((pre) => ({ ...pre, startDate: date }))
                            }}
                        />
                    </div>
                    <div className="col-lg-11 mx-auto mb-2">
                        <DateInput
                            size="small"
                            variant="outlined"
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'End Date'}
                            SelectedDate={timeLogListInput?.endDate || null}
                            handleDateChange={(date) => {
                                setTimeLogListInput((pre) => ({ ...pre, endDate: date }))
                            }}
                        />
                    </div>
                </div>
                <div className="text-center mx-auto ">
                    <Button variant="contained" className="px-4 m-2" onClick={applayFilter} startIcon={<SearchIcon />}>
                        Apply
                    </Button>
                    <Button variant="contained" className="px-4" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                        Reset
                    </Button>
                </div>
            </div>
        </>
    )
}

export default UserTimeLogFilter
import  { useEffect, useState } from 'react'
import { userFullList } from '../../../repository/teamManagementRepo'
import moment from 'moment'
import { addAttendance } from '../../../repository/attendanceRepo'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const UseAddAttendance = () => {
    const [agentList, setAgentList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [attendError, setAttendError] = useState({})
    const [addAttendanceInput, setAddAttendanceInput] = useState({
        empName: "",
        inTime: null,
        outTime: null,
        status: '',
        date: null
    })
    const navigate = useNavigate()
    useEffect(() => {
        getAgentList()
    }, [])
    const getAgentList = async () => {
        try {
            setSpinner(true)
            let response = await userFullList({})
            setAgentList(response.data)
        } catch (error) {
            setAgentList([])
        }
        setSpinner(false)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const trimmedValue = value ? value.trim() : "";
        setAddAttendanceInput((prevProps) => ({ ...prevProps, [name]: trimmedValue }));
        if (trimmedValue.length > 0) {
            setAttendError((pre) => {
                const updatedErrors = { ...pre };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const errorUpdateMessage = {
        inTime: "Please Select inTime ",
        outTime: "Please Select outTime",
        status: "Please Select status",
        empName: "Please Select employee",
    }

    // Error hendling
    const isUpdateValid = () => {
        const errorObj = {}
        if (!addAttendanceInput?.empName) {
            errorObj['empName'] = errorUpdateMessage['empName']
        }
        if (addAttendanceInput?.status === 'P') {
            if (!addAttendanceInput.inTime) {
                errorObj['inTime'] = errorUpdateMessage['inTime']
            }
            if (!addAttendanceInput.outTime) {
                errorObj['outTime'] = errorUpdateMessage['outTime']
            }
        }
        if (!addAttendanceInput.status) {
            errorObj['status'] = errorUpdateMessage['status']
        }
        setAttendError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const handleupdateSubmit = async () => {
        if (!isUpdateValid()) return

        if (addAttendanceInput?.inTime > addAttendanceInput?.outTime) return toast.warn('In time can not be greater than out time')
        if (moment(addAttendanceInput?.inTime).format("YYYY-MM-DD") !== moment(addAttendanceInput?.outTime).format("YYYY-MM-DD")) return toast.warn('Please select same date for attendance')

        let payload = {
            userId: addAttendanceInput?.empName,
            inTime: addAttendanceInput.status === 'A' ? null : moment(addAttendanceInput?.inTime).utc(),
            outTime: addAttendanceInput.status === 'A' ? null : moment(addAttendanceInput?.outTime).utc(),
            attendanceStatus: addAttendanceInput?.status,
            attendanceDate: addAttendanceInput?.status === 'A' ? moment(addAttendanceInput?.date).format("YYYY-MM-DD") : moment(addAttendanceInput?.inTime).format("YYYY-MM-DD")
        }

        if (addAttendanceInput?.status === 'A' && !addAttendanceInput?.date) return toast.warn('Please select date')
        setSpinner(true)
        try {
            let response = await addAttendance(payload)
            navigate('/attendance')
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        } catch (error) {
            toast.error(error.message)
            setSpinner(false)

        }
        setSpinner(false)
    }
    return {
        data: {
            agentList,
            spinner,
            attendError,
            addAttendanceInput
        },
        methods: {
            setAttendError,
            setAddAttendanceInput,
            handleupdateSubmit,
            handleInputChange
        }
    }
}

export default UseAddAttendance

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { deleteShift } from '../../../../repository/shiftRepo'
import { toast } from 'react-toastify'
import useReduxStore from '../../../../hooks/useReduxStore'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../../Slices/FiltersSlice'
import { useToGetReportList, ExportReportList, getUserUtilisationReport } from '../../../../repository/reportRepo'
import moment from 'moment'
import { useToGetFullPriject } from '../../../../repository/projectRepo'
import { userFullList } from '../../../../repository/teamManagementRepo'

const useReportList = () => {
    const { selector: user } = useReduxStore('auth')
    const { selector: ShiftFilter } = useReduxStore('filters')
    const dispatch = useDispatch()
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
    const [filterAction, setFilterAction] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [search, setSearchText] = useState(ShiftFilter?.Shift?.filters?.search || '')
    const [statusType, setStatusType] = useState(ShiftFilter?.Shift?.filters?.statusType || '')
    const [reportListInput, setReportListInput] = useState({
        reportFrom: ShiftFilter?.Shift?.filters?.reportFrom || [{ title: 'Task', value: 'TASK' },],
        reportType: ShiftFilter?.Shift?.filters?.reportType || [{ title: 'Resource Utilisation', value: 'RESOURCE_UTILISATION' }],
        startDate: ShiftFilter?.Shift?.filters?.startDate || new Date(moment().startOf("week")),
        endDate: ShiftFilter?.Shift?.filters?.endDate || new Date(moment().endOf("week")),
    })
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: [],
    })

    const [userUtilisationModal, setUserUtilisationModal] = useState({
        open: false,
        data: "",
    })
    const [userUtilisationInputs, setUserUtilisationInputs] = useState({
        agent: [],
        Projects: [],
        startDate: null,
        endDate: null
    })

    const clearuserUtilisationFunction = () => {
        setUserUtilisationInputs({
            agent: [],
            Projects: [],
            startDate: null,
            endDate: null
        })
    }
    const [agentList, setAgentList] = useState([])
    const cacheTime = { cacheTime: 10000 }

    // Project List
    const { data: projectListData,} = useToGetFullPriject(cacheTime)

    useEffect(() => {
        getAgentList()
    }, [])

    // Get All Agent  List
    const getAgentList = async () => {

        try {
            setSpinner(true)
            let response = await userFullList(payload)
            setAgentList(response.data)
        } catch (error) {
            setAgentList([])
        }
        setSpinner(false)
    }



    // Listing payLoad
    let payload = {
        reportType: reportListInput?.reportType[0]?.value,
        reportFrom: reportListInput?.reportFrom[0]?.value,
        startDate: reportListInput?.startDate,
        endDate: reportListInput?.endDate
    }
    // if (typeof statusType == 'boolean') payload.isDeleted = statusType
    // else {
    //     statusType == 'false' && (payload.isDeleted = false)
    //     statusType == 'true' && (payload.isDeleted = true)
    // }

    useEffect(() => {
        // Dispatch the addFilter action with the current 'Projects' page and the filter
        dispatch(addFilter({
            page: 'Shifts', filter: {
                search: search,
                statusType: statusType,
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, statusType]);
    // Fetch api
    const {
        data: reportList,
        error: reportError,
        isFetching: reportIsFetching,
        refetch,
    } = useToGetReportList(payload, {
        enabled: false,
        ...cacheTime,
    })

    useEffect(() => {
        refetch()
    }, [controller, filterAction])

    // Error Toast Message
    useEffect(() => {
        if (reportError) {
            // toast.error(reportError?.message);
        }
    }, [reportError])


    // handleUtilisation
    const handleUtilisation = async () => {
        try {
            setSpinner(true)
            let payLoad = {}

            if (userUtilisationInputs?.agent?.length > 0) {
                payLoad.user_ids = userUtilisationInputs?.agent?.map((item) => item?._id)
            }
            if (userUtilisationInputs?.Projects?.length > 0) {
                payLoad.project_ids = userUtilisationInputs?.Projects?.map((item) => item?._id)
            }
            if (userUtilisationInputs?.startDate) {
                payLoad.startDate = moment(userUtilisationInputs?.startDate).format("YYYY-MM-DD")
            }
            if (userUtilisationInputs?.endDate) {
                payLoad.endDate = moment(userUtilisationInputs?.endDate).format("YYYY-MM-DD")
            }
            let response = await getUserUtilisationReport(payLoad)
            window.open(response.data.fileName);
            let tempLink = document.createElement('a');
            tempLink.setAttribute('download', response.data.fileName);
            tempLink.click();
            setUserUtilisationModal({ data: "", open: false })
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    // Export Report Data
    const Export = async () => {
        try {
            setSpinner(true)
            let response = await ExportReportList(payload)
            window.open(response.data.fileName);
            let tempLink = document.createElement('a');
            tempLink.setAttribute('download', response.data.fileName);
            tempLink.click();

        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    const resetFilter = () => {
        setFilterAction((prev) => !prev)
        setSearchText('')
        setStatusType()
    }
    // Apply Search Filter
    const applayFilter = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            refetch()
        });
    }
    // Handle Search
    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
        // Perform any other actions you need here
    }

    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj)
    }

    const handleDelete = async () => {
        try {
            setSpinner(true)
            let response = await deleteShift({
                _id: deleteModal?.data._id,
                isDeleted: !deleteModal.data.isDeleted,
            })
            toast.success(response.message)
            setDeleteModal({ data: '', open: false })
            refetch()
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    return {
        data: {
            controller,
            isLoading: spinner || reportIsFetching,
            totalCount: reportError?.message === "No record found" ? 0 : reportList?.totalCount || 0,
            reportList: reportError?.message === "No record found" ? [] : reportList?.data || [],
            search,
            statusType,
            deleteModal,
            ShiftPermission: user,
            reportListInput,
            userUtilisationModal,
            agentList,
            projectListData: projectListData?.data || [],
            userUtilisationInputs
        },
        methods: {
            handlePagination,
            resetFilter,
            applayFilter,
            handleSearchChange,
            setStatusType,
            setDeleteModal,
            handleDelete,
            Export,
            setReportListInput,
            setUserUtilisationModal,
            handleUtilisation,
            setUserUtilisationInputs,
            clearuserUtilisationFunction
        },
    }
}

export default useReportList
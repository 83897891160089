import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getAttendanceList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/attendance/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetAttendanceList(page,params, options = {}) {
    return useQuery(['GET_Attendance_LIST',page], () => getAttendanceList(params), options)
}

export const addNote = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/note`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}
// uploadFile Task  /multipleUploadsApiAttendance
export const uploadFile = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/common/multipleUploads`, params)
    if (response.data.code !== 200) {
      throw new Error(response.data.message)
    }
    return response.data
  }


//   attendance/import
export const uploadAttendance = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/attendance/import`, params)
    if (response.data.code !== 200) {
      throw new Error(response.data.message)
    }
    return response.data
  }

  //Attendance Export
export const ExportAttendanceList = async params => {
  const response = await axiosInstance.post(`${baseUrl}/attendance/export`, params);
  if (response.data.code !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};
// deleteAttendance

//  Get attendance Details
export const getAttendanceDetails = async (params) => {
  const response = await axiosInstance.get(`${baseUrl}/attendance/${params}`)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Update Attendance
export const updateAttendance = async (params) => {
  const response = await axiosInstance.put(`${baseUrl}/attendance`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}
// Add Attendance
export const addAttendance = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/attendance/create`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// Delete Attendance
export const deleteAttendance = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/attendance/delete`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

// ---------------------------------------------


export const AttendanceCalanderList = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/attendance/newList`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}


// get Clander Details
export const AttendanceCalanderDetails = async (params) => {
  const response = await axiosInstance.post(`${baseUrl}/attendance/getDayDetails`, params)
  if (response.data.code !== 200) {
    throw new Error(response.data.message)
  }
  return response.data
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import { useToGetTaskList, changeApprovalStatus } from '../../../repository/taskRepo';

const useApproveTask = () => {


    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [filterAction, setFilterAction] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [search, setSearchText] = useState("");
    const [statusType, setStatusType] = useState("");
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: [],
    });
    const [approvalModal, setApprovalModal] = useState({
        open: false,
        data: "",
        status: ""
    });
    const cacheTime = { cacheTime: 10000 };

    // Listing payLoad
    let payload = {
        count: controller.rowsPerPage,
        page: controller.page,
        isDeleted: false,
        search: search,
        approvalStatus: "PENDING"
    }
    if (typeof statusType == "boolean") payload.isActive = statusType

    // Fetch api
    const {
        data: taskList,
        error: taskError,
        isFetching: taskIsFetching,
        refetch,
    } = useToGetTaskList(payload,
        {
            enabled: false,
            ...cacheTime,
        }
    );

    useEffect(() => {
        refetch();
    }, [controller, filterAction]);
    // Error Toast Message
    useEffect(() => {
        if (taskError) {
            // toast.error(taskError?.message);
        }
    }, [taskError]);

    useEffect(() => {
        if (approvalModal?.status === "APPROVED" || approvalModal?.status === "REJECTED") {
            handelApprovalStatus()
        }
    }, [approvalModal.status])

    const resetFilter = () => {
        setFilterAction((prev) => !prev)
        setStatusType()
        setSearchText("")
    }

    // Apply Search Filter
    const applayFilter = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            refetch()
          });
    };

    // Handle Search
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        // Perform any other actions you need here
    };

    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj);
    };

    // Handel Approval Status
    const handelApprovalStatus = async () => {
        try {
            setSpinner(true);
            let response = await changeApprovalStatus({
                task_id: approvalModal?.data,
                approvalStatus: approvalModal?.status,
            });
            toast.success(response.message);
            setApprovalModal({ data: "", open: false, status: "" });
            refetch();
        } catch (error) {
            toast.error(error.message);
        }
        setSpinner(false);
    };


    // handle Delete
    // const handleDelete = async () => {
    //     try {
    //         setSpinner(true);
    //         let response = await deleteSkill({
    //             _id: deleteModal?.data,
    //             isDeleted: true,
    //         });
    //         toast.success(response.message);
    //         setDeleteModal({ data: "", open: false });
    //         refetch();
    //     } catch (error) {
    //         toast.error(error.message);
    //     }
    //     setSpinner(false);
    // };

    return {
        data: {
            controller,
            isLoading: spinner || taskIsFetching,
            totalCount: taskError?.message === "No record found" ? 0 : taskList?.totalCount || 0,
            taskList: taskError?.message === "No record found" ? [] : taskList?.data || [],
            search,
            deleteModal,
            statusType,
            approvalModal
        },
        methods: {
            handlePagination,
            resetFilter,
            applayFilter,
            handleSearchChange,
            setStatusType,
            // handleDelete,
            setDeleteModal,
            refetch,
            setApprovalModal
        }
    }

}

export default useApproveTask
import React from 'react'
import { Outlet } from 'react-router-dom'
import Announcement from '../components/Headers/announcement'
import Header from '../components/Headers/Header'
// import Navbar from '../components/Navbars/Navbar'

const Private = () => {
  return (
    <>
      <Header />
      <Announcement/>
      <div className="project-body">
        <Outlet />
      </div>
    </>
  )
}

export default Private

import React, { useState } from 'react'
import '../credentials/credentials.css'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../../components/Spinner/Spinner'
import { forgotPassword } from '../../repository/auth'
import { requiredMessage } from '../../utils'
import { ToastContainer, toast } from 'react-toastify'
import TextField from '../../components/TextField'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(false)
  const [forgotInputs, setForgotInputs] = useState({
    email: '',
  })
  const [error, setError] = useState({})
 

  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setForgotInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    email: requiredMessage('email'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!forgotInputs.email) {
      errorObj['email'] = errorMessage['email']
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        email: forgotInputs.email,
      }
      let response = await forgotPassword(payload)
      navigate('/login')
      setTimeout(() => {
        toast.success(response.message)
      }, 400)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className=" pt-5">
        <div className="container  mt-5 ">
          <div className="contact" style={{ height: '80vh' }}>
            <div className="col-lg-5 mx-auto bg2 shadow2 p-4">
              <div className="text-center">
                <h4>
                  <b>Forgot Password ?</b>
                </h4>
                <p className="login-font-waight mt-4">Enter the email associated with your account and we'll send an email with instructions to reset your Password account</p>
              </div>
              <div className="mt-4">
                <div className="col-12 mt-4">
                  <TextField
                    Lable="Enter Email"
                    Name="email"
                    variant="standard"
                    ClassName="form-field-design"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={forgotInputs.email}
                    width={100}
                    Error={error.email}
                    type={'email'}
                  />
                </div>
                <div className="text-end mt-5">
                  <span className="table-link loginfont" onClick={() => navigate('/login')}>
                    Back to log-in
                  </span>
                </div>
                <div className="mt-2">
                  <Button variant="primary1" className="py-2 w-100" onClick={(event) => handleSubmit(event)}>
                    Send link
                  </Button>
                </div>
              </div>
              {/* <div className="text-center my-4">
              <p className="fw-1 text-muted">---- Or, Sign in With ----</p>
              </div>
              <div className="">
                  <div className="text-center">
                    <p className="loginfont google"><img src={Google} className="login-icon" alt="" /> &nbsp; Continue With Google</p>   
                  </div>
              </div>
              <div className="my-3">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Apple} className="login-icon" alt="" /> &nbsp; &nbsp; &nbsp; Continue With Apple</p>   
                  </div>
              </div>
              <div className="">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Facebook} className="login-icon" alt="" /> Continue With Facebook</p>   
                  </div>
              </div>
              <p className="loginfont text-center mt-4 pt-2">Don't have an account? <span className="table-link">Register Now</span></p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword

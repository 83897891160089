/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { AddCommitApi, DeleteFile, changeTaskStatus, getTaskDetailsApi, updateTask, uploadFile, uploadFileUrlTask, submitTimelog, uploadFileUrlTimeLog, useToGetTaskTimeLineHistoryList, addBreaks, updateBreaks, deleteBreakTime } from '../../../repository/taskRepo'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FileImg from '../../../assets/icons/file.png'
import VideoIcon from '../../../assets/icons/video.png'
import PDFIcon from '../../../assets/icons/pdf.png'
import ExcelIcon from '../../../assets/icons/excel.png'
import TextFileIcon from '../../../assets/icons/textfile.png'
import { useToGetFullPriject } from '../../../repository/projectRepo'
import { useToGetAllTagList } from '../../../repository/tagsRepo'
import { useToGetUserFullList } from '../../../repository/teamManagementRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { requiredSelect } from '../../../utils'
import { SubTaskList, TaskCategoryList, complexityList, hoursTypeList, } from "../../../constants"
import moment from 'moment'

const useTaskDetails = () => {
  const { selector: user } = useReduxStore('auth')

  const [taskDetails, setTaskDetails] = useState({})
  const [spinner, setSpinner] = useState(false)
  const editorRef = useRef(null)
  const [selectedFiles, setSelectedFiles] = useState([])

  const [OpenTextEditerModal, setOpenTextEditerModal] = useState({
    open: false,
    OpenCompleteTaks: false,
    AddCommitTask: false,
    PauseTime: false,
    value: '',
  })
  const [logTimeModal, setLogTimeModal] = useState({
    open: false,
    data: ""
  })
  const [updateTaskModal, setUpdateTaskModal] = useState(false)
  const [timelogFillInputs, setTimeLoFillInput] = useState({
    subTask: "",
    taskCategory: "",
    complexity: "",
    hoursType: "",
    actutalStatus: "",
    actualStartTime: null,
    actualEndTime: null,
  })
  let [breakHoursInput, setBreakHoursInput] = useState([
    //   {
    //   startTime: null,
    //   endTime: null,
    //   reason: ""
    // }
  ])
  const [errors, setErrors] = useState([
    {
      startTime: null,
      endTime: null,
      reason: ""
    },
  ]);
  const [scrollBreak, setScrollBreak] = useState(false)
  const [count, setCount] = useState(10)

  const [deleteBreakHoursModal, setDeleteBreakHoursModal] = useState({
    data: "",
    open: false
  })
  const [breatModal, setBreakModal] = useState({
    data: "",
    open: false
  })
  const [timeLogError, setTimeLogError] = useState({})
  const [taskFormInputs, setTaskFormInputs] = useState([
    {
      taskName: '',
      project_id: '',
      priority: '',
      assignTo_id: '',
      description: '',
      tags: '',
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      notes: '',
      ReminderAction: true,
      attachments: [],
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ])
  const { id } = useParams()
  const cacheTime = { cacheTime: 10000 }
  // Project List
  const { data: projectListData, isLoading: isprojectLoading, } = useToGetFullPriject(cacheTime)
  // Tag List
  const { data: tagListData, isLoading: isTagLoading, } = useToGetAllTagList(cacheTime)
  // Assign TO List
  const { data: assignToListData, isLoading: isassignToLoading, } = useToGetUserFullList(cacheTime)

  const getTaskDetails = async () => {
    try {
      setSpinner(true)
      let response = await getTaskDetailsApi(id)
      if (response?.code === 200) {
        let newsubTask = SubTaskList.filter(item => item.value === response?.data?.subTask)
        let newTaskCategory = TaskCategoryList.filter(item => item.value === response?.data?.taskCategory)
        let newComplexity = complexityList.filter(item => item.value === response?.data?.complexity)
        let newHoursType = hoursTypeList.filter(item => item.value === response?.data?.hoursType)

        if (logTimeModal?.open) {
          const updatedBreaks = response?.data?.breaks
            .filter(item => item?.isDeleted === false)
            .map(item => ({
              startTime: new Date(item?.startTime),
              endTime: new Date(item?.endTime),
              reason: item?.reason,
              _id: item?._id
            }));
          setBreakHoursInput(updatedBreaks?.length ? updatedBreaks : breakHoursInput);
        }

        setTaskDetails(response?.data)
        setTaskFormInputs({
          taskName: response?.data?.taskName,
          project_id: response?.data?.project_id?._id,
          priority: response?.data?.priority,
          assignTo_id: response?.data?.assignTo_id?._id,
          description: response?.data?.description,
          tags: response?.data?.tags?.map((cur) => cur?._id),
          assignStartTime: response?.data?.assignStartTime,
          assignEndTime: response?.data?.assignEndTime,
          minutesBeforeReminder: response?.data?.reminder?.minutesBefore,
          notes: response?.data?.notes,
          ReminderAction: response?.data?.reminder?.reminderOn,
          attachments: response?.data?.attachments,
        })
        setTimeLoFillInput((prev) => ({
          ...prev,
          subTask: newsubTask,
          taskCategory: newTaskCategory,
          complexity: newComplexity,
          hoursType: newHoursType,
        }))
      } else {
        setTaskDetails({})
      }
      // toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Task Time Line List
  const {
    data: timeLineListData,
    error: timeLineError,
    // isLoading: timeLineIsLoading,
    refetch: timeLineIsFetched
  } = useToGetTaskTimeLineHistoryList({ type: "TASK", type_id: id, page: 1, count: count }, {
    ...cacheTime,
    enabled: id ? true : false,
  });
  useEffect(() => {
    if (count > 10) {
      timeLineIsFetched()
    }
  }, [count])
  const fetchMoreData = () => { setCount((prev) => prev + 10); };

  // add commit
  const AddCommit = async () => {
    let payload = {
      task_id: id,
      comment: OpenTextEditerModal?.value,
    }
    try {
      setSpinner(true)
      let response = await AddCommitApi(payload)
      if (response?.code === 200) {
        setOpenTextEditerModal((pre) => ({ ...pre, open: false, AddCommitTask: false, OpenCompleteTaks: false, PauseTime: false, value: '' }))
        getTaskDetails()
      } else {
      }
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // Change Task Status
  const ChangeTaskStatusFun = async (status) => {
    let payload = {
      task_id: id,
      comment: OpenTextEditerModal?.value,
      status: status,
      notes: OpenTextEditerModal?.value,
    }
    if (status === 'IN PROGRESS') {
      delete payload.comment
      delete payload.notes
    }
    if (status === 'PAUSED') {
      delete payload.notes
    }
    if (status === 'COMPLETED') {
      delete payload.comment
    }
    try {
      setSpinner(true)
      let response = await changeTaskStatus(payload)
      getTaskDetails()
      setOpenTextEditerModal((pre) => ({ ...pre, open: false, AddCommitTask: false, OpenCompleteTaks: false, PauseTime: false, value: '' }))
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }





  // handle new ticket Files
  const handleTimeLogFileChange = async (event) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TIMELOG");

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);

    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }

  // handle Remove File
  const handleLogRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }



  // file shhow
  const fileType = (file, fileIndex, fileType) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={fileType === "TIMELOG" ? uploadFileUrlTimeLog + file : uploadFileUrlTask + file} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }

  // Handle Input value
  const handleInputChange = (event, taskIndex) => {
    const { value, name, checked } = event.target
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = { ...prevTaskFormInputs }
      if (name === 'ReminderAction') {
        updatedTasks[name] = checked
        if (checked) {
          updatedTasks['minutesBeforeReminder'] = 15
        } else {
          updatedTasks['minutesBeforeReminder'] = 0
        }
      } else {
        // if (updatedTasks[taskIndex]["minutesBeforeReminder"])
        updatedTasks[name] = value
      }
      return updatedTasks
    })

    // Check if value is a string before calling .trim()
    if (typeof value === 'string' && value.trim().length > 0) {
    }

    // Update CKEditor content
    if (name === 'description') {
      const editor = editorRef.current
      if (editor) {
        // Update CKEditor's content programmatically
        editor.setData(value)
      }
    }
  }
  const handleFileChange = async (event, taskIndex) => {
    const files = event.target.files
    const fd = new FormData()
    if (files.length > 5) return toast.warn('You can only select up to 5 files.')
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i]
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`)
      }
      fd.append(`files`, fileData)
    }
    fd.append('type', 'TASK')
    try {
      setSpinner(true)
      let response = await uploadFile(fd)
      toast.success(response.message)
      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = { ...prevTaskFormInputs }
        const newSelectedFiles = [...updatedTasks.attachments]
        response?.data?.forEach((cur, index) => {
          newSelectedFiles?.push(cur?.db_name)
        })
        updatedTasks.attachments = newSelectedFiles
        return updatedTasks
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // Handle Remove File
  const handleRemoveFile = async (file, fileIndex) => {
    let data = {
      fileName: file,
      fileType: 'TASK',
    }
    try {
      setSpinner(true)
      let response = await DeleteFile(data)
      toast.success(response.message)
      setTaskFormInputs((prevTaskFormInputs) => {
        const updatedTasks = { ...prevTaskFormInputs }
        updatedTasks.attachments = updatedTasks.attachments.filter((_, i) => i !== fileIndex)
        return updatedTasks
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Handle Date
  const handleDateChange = (date, field) => {
    setTaskFormInputs((prevTaskFormInputs) => {
      const updatedTasks = { ...prevTaskFormInputs }
      updatedTasks[field] = date
      return updatedTasks
    })
  }


  const handleBreakInput = (event, taskIndex) => {
    const { value, name } = event.target;

    setBreakHoursInput((prev) => {
      const updatedBreak = [...prev];
      updatedBreak[taskIndex][name] = value;

      // if (name === 'ReminderAction') {
      //   updatedBreak[taskIndex][name] = checked;
      //   if (checked) {
      //     updatedBreak[taskIndex]['minutesBeforeReminder'] = 15;
      //   } else {
      //     updatedBreak[taskIndex]['minutesBeforeReminder'] = 0;
      //   }
      // }
      // Clear errors for the current field
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        // if (name != "ReminderAction") {
        //   updatedErrors[taskIndex][name] = null;
        // }
        return updatedErrors;
      });

      return updatedBreak;
    });
  };

  // Handle Break time
  const handleBrakeTime = (date, field, breakIndex) => {
    setBreakHoursInput((prev) => {
      const updatedTasks = [...prev]
      updatedTasks[breakIndex][field] = date
      return updatedTasks
    })

    if (field === 'startTime') {
      // Check if start date is greater than end date
      const endTime = breakHoursInput[breakIndex]?.endTime;

      if (endTime && date > endTime) {
        setErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { startTime: 'Start date should not be greater than end date' };
          return updatedErrors;
        });
      } else {
        setErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { startTime: '' };
          return updatedErrors;
        });
      }
    }

    if (field === 'endTime') {
      // Check if end date is less than start date
      const startTime = breakHoursInput[breakIndex]?.startTime;

      if (startTime && date < startTime) {
        setErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { endTime: 'End date should not be less than start date' };
          return updatedErrors;
        });
      } else {
        setErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[breakIndex] = { endTime: '' };
          return updatedErrors;
        });
      }
    }

    // setErrors((prevErrors) => {
    //   const updatedErrors = [...prevErrors];
    //   updatedErrors[breakIndex][field] = null;
    //   return updatedErrors;
    // });
  }

  const addMultipleBreak = () => {
    if (breakHoursInput.length < 10) {
      setBreakHoursInput((prev) => [
        ...prev,
        {
          startTime: null,
          endTime: null,
          reason: ""
        },
      ])
      // Clear errors for the newly added task
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          startTime: null,
          endTime: null,
          reason: ""
        },
      ]);
    }
  }

  // Remove Brake time
  const removeBreak = async (breakIndex, item) => {
    if (item) {
      setSpinner(true)
      await deleteBreakTime(item)
      setSpinner(false)
    }
    setBreakHoursInput((prev) => {
      const updatedBreaks = [...prev]
      updatedBreaks.splice(breakIndex, 1)
      return updatedBreaks
    })
  }
  // Close modal And Empty
  const CloseFucntion = (type) => {
    if (type === 'breatModal') {
      // setTaskModel({ open: false, data: '' })
      setBreakHoursInput([
        // {
        //   startTime: null,
        //   endTime: null,
        //   reason: ""
        // },
      ])
    }
  }

  // Error Message
  const errorBreakMessage = {
    startTime: requiredSelect('break start'),
    endTime: requiredSelect("break end"),
    reason: requiredSelect("reason"),
  }
  const validateBreaks = () => {
    const errorObjArray = [];

    for (let i = 0; i < breakHoursInput.length; i++) {
      const breaks = breakHoursInput[i];
      const errorObj = {};

      if (!breaks.startTime) {
        errorObj['startTime'] = errorBreakMessage['startTime'];
      }
      if (!breaks.endTime) {
        errorObj['endTime'] = errorBreakMessage['endTime'];
      }
      if (!breaks.reason) {
        errorObj['reason'] = errorBreakMessage['reason'];
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }
    setErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };

  // add break's
  const submitBreak = async () => {
    if (!validateBreaks()) return
    try {
      setSpinner(true)
      let payload = {
        task_id: id,
        breaks: breakHoursInput
      }
      let response = await addBreaks(payload)
      setBreakModal({ data: "", open: false })
      getTaskDetails()
      timeLineIsFetched()
      CloseFucntion("breatModal")
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // updateBreaks
  const updateBreak = async () => {
    if (!validateBreaks()) return
    try {
      setSpinner(true)
      let payload = {
        // task_id: id,
        breaks: breakHoursInput,
        // break_id: breatModal?.data
      }
      let response = await updateBreaks(payload)
      setBreakModal({ data: "", open: false })
      getTaskDetails()
      timeLineIsFetched()
      CloseFucntion("breatModal")
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // update Break Time
  const upateTask = async () => {
    const data = { ...taskFormInputs }
    data._id = id
    delete data?.ReminderAction
    try {
      let response = await updateTask(data)
      if (response?.code === 200) {
        setUpdateTaskModal(false)
        getTaskDetails()
      }
      toast.success(response.message)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      toast.error(error.message)
    }
  }

  // handel break delete
  const handleBreakDelete = async () => {
    try {

      setSpinner(true)
      let response = await deleteBreakTime(deleteBreakHoursModal?.data)
      setDeleteBreakHoursModal({ open: false, data: "" })
      toast.success(response?.message)
      getTaskDetails()
      timeLineIsFetched()
    } catch (error) {
      toast.error(error?.message)
    }
    setSpinner(false)
  }

  //Handle Input value
  const handleTimeLogInputChange = (event) => {
    const { name, value } = event.target

    setTimeLoFillInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }))
    if (typeof value === 'string' && value.trim().length > 0) {
      setTimeLogError((pre) => {
        delete pre[name]
        return { ...pre }
      })
    }
  }

  // Error Message
  const errorMessage = {
    subTask: requiredSelect('sub task'),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
    actutalStatus: requiredSelect("actutal status"),
    actualStartTime: requiredSelect("actual startTime"),
    actualEndTime: requiredSelect("actual endTime"),

  }
  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!timelogFillInputs.subTask) {
      errorObj['subTask'] = errorMessage['subTask']
    }
    if (!timelogFillInputs.taskCategory) {
      errorObj['taskCategory'] = errorMessage['taskCategory']
    }
    if (!timelogFillInputs.complexity) {
      errorObj['complexity'] = errorMessage['complexity']
    }
    if (!timelogFillInputs.hoursType) {
      errorObj['hoursType'] = errorMessage['hoursType']
    }
    if (!timelogFillInputs.actutalStatus) {
      errorObj['actutalStatus'] = errorMessage['actutalStatus']
    }
    if (!timelogFillInputs.actualStartTime) {
      errorObj['actualStartTime'] = errorMessage['actualStartTime']
    }
    if (!timelogFillInputs.actualEndTime) {
      errorObj['actualEndTime'] = errorMessage['actualEndTime']
    }
    setTimeLogError(errorObj)
    return !Object.keys(errorObj)?.length
  }
  // Submit log Time
  const SubmitLogTime = async () => {
    if (!isValid()) return
    if (breakHoursInput?.length > 0) {
      if (!validateBreaks()) return
    }
    let payload = {
      task_id: id,
    }

    let newBreakHours = [];

    // await Promise.all(breakHoursInput.map(async (item) => {
    //   if (!item?._id) {
    //     try {
    //       let response = await addBreaks({ task_id: id, breaks: [item] });
    //       newBreakHours.push(response.data.breaks);
    //     } catch (error) {
    //       // console.error("Error adding breaks:", error);
    //       // Handle error if needed
    //     }
    //   }
    // }));

    breakHoursInput = newBreakHours.length > 0 ? newBreakHours[0] : breakHoursInput;
    // console.log(breakHoursInput, 'breakHoursInput')

    payload.breaks = breakHoursInput.filter(item => moment(item.endTime).utc() || moment(item.startTime).utc() || item.reason);

    if (timelogFillInputs?.subTask?.length) {
      payload.subTask = timelogFillInputs?.subTask[0]?.value
    }
    if (timelogFillInputs.taskCategory?.length) {
      payload.taskCategory = timelogFillInputs?.taskCategory[0]?.value
    }
    if (timelogFillInputs.complexity?.length) {
      payload.complexity = timelogFillInputs?.complexity[0]?.value
    }
    if (timelogFillInputs.hoursType?.length) {
      payload.hoursType = timelogFillInputs?.hoursType[0]?.value
    }
    if (timelogFillInputs.actutalStatus?.length) {
      payload.actutalStatus = timelogFillInputs?.actutalStatus[0]?.value
    }
    if (timelogFillInputs.actualStartTime) {
      payload.actualStartTime = timelogFillInputs.actualStartTime
    }
    if (timelogFillInputs.actualEndTime) {
      payload.actualEndTime = timelogFillInputs.actualEndTime
    }
    if (logTimeModal?.value) {
      payload.notes = logTimeModal?.value
    }
    payload.attachments = selectedFiles
    // console.log(payload, 'payload')
    // return
    try {
      setSpinner(true)
      let response = await submitTimelog(payload)
      if (response?.code === 200) {
        setLogTimeModal({ data: "", open: false })
        getTaskDetails()
        timeLineIsFetched()
      } else {
      }
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  useEffect(() => {
    getTaskDetails()
  }, [logTimeModal.open])
  return {
    data: {
      isLoading: spinner || isprojectLoading || isTagLoading || isassignToLoading,
      taskDetails,
      OpenTextEditerModal,
      updateTaskModal,
      projectListData: projectListData?.data || [],
      tagListData: tagListData?.data || [],
      assignToListData: assignToListData?.data || [],
      taskFormInputs,
      uploadFileUrlTask,
      TaskPermission: user,
      logTimeModal,
      selectedFiles,
      timelogFillInputs,
      timeLogError,
      taskId: id,
      timeLineListData: timeLineError?.message === 'No record found' ? [] : timeLineListData?.data || [],
      breakHoursInput,
      errors,
      breatModal,
      deleteBreakHoursModal,
      scrollBreak
    },
    methods: {
      setScrollBreak,

      fileType,
      setOpenTextEditerModal,
      AddCommit,
      ChangeTaskStatusFun,
      setUpdateTaskModal,
      handleInputChange,
      handleFileChange,
      handleRemoveFile,
      handleDateChange,
      upateTask,
      setLogTimeModal,
      SubmitLogTime,
      setSelectedFiles,
      handleLogRemoveFile,
      handleTimeLogFileChange,
      setTimeLoFillInput,
      setTimeLogError,
      handleTimeLogInputChange,
      setBreakHoursInput,
      handleBrakeTime,
      removeBreak,
      addMultipleBreak,
      setErrors,
      handleBreakInput,
      setBreakModal,
      submitBreak,
      updateBreak,
      CloseFucntion,
      setDeleteBreakHoursModal,
      handleBreakDelete,
      fetchMoreData
    },
  }
}

export default useTaskDetails

import { useEffect, useState } from "react"
import { getAllRoleList } from "../../../repository/auth"
import { idleuserLists, idleuserExport } from "../../../repository/taskRepo"
import { getShiftList } from "../../../repository/shiftRepo"
import { requiredSelect } from "../../../utils"
import moment from "moment"


const UseIdleUser = () => {
    const [spinner, setSpinner] = useState(false)
    const [idleListData, setIdleListData] = useState([])
    const [allRoleList, setAllRoleList] = useState([])
    const [shiftList, setShiftList] = useState([])
    const [filter, setFilter] = useState({
        role: [],
        shift: [],
        startDate: "",
        endDate: "",
        reset: false
    })
    const [errors, setErrors] = useState(
        {
            shift: '',
            startDate: '',
            endDate: '',
        }
    );
    // console.log(errors, 'errors')
    useEffect(() => {
        getAllRolesList()
        getShiftLists()
        // if (filter.reset) {
        //     idleUserList()
        // }
    }, [])
    // }, [filter.reset])

    const getAllRolesList = async () => {
        setSpinner(true)
        try {
            let response = await getAllRoleList()
            setAllRoleList(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }

    const getShiftLists = async () => {
        const payLoad = {
            count: 100,
            page: 1
        }
        setSpinner(true)
        try {
            let response = await getShiftList(payLoad)
            setShiftList(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }
    const errorMessage = {
        shift: requiredSelect('shift'),
        startDate: requiredSelect('start date'),
        endDate: requiredSelect('end date'),
    }
    const isValid = () => {
        const errorObj = {};

        if (!filter.shift?.length) {
            errorObj['shift'] = errorMessage['shift'];
        }
        if (!filter.startDate) {
            errorObj['startDate'] = errorMessage['startDate'];
        }
        if (!filter.endDate) {
            errorObj['endDate'] = errorMessage['endDate'];
        }

        setErrors(errorObj)
        return !Object.keys(errorObj)?.length
    };
    const idleUserList = async () => {
        // if (!filter?.shift?.length) return toast.warn('Please select shift')
        // if (!filter?.startDate && !filter?.endDate) return toast.warn('Please select start date or end date')
        // if (!filter?.startDate) return toast.warn('Please select start date')
        // if (!filter?.endDate) return toast.warn('Please select end date')
        // const isValid = validateAllTasks();
        // if (!isValid) return
        if (!isValid()) return
        const payLoad = {
            startDate: filter?.startDate,
            endDate: moment(filter?.endDate).endOf('day').utc(),
            roleTitle: filter?.role?.[0]?.roleTitle,
            shiftId: filter?.shift?.[0]?._id,
            cacheTime: 10000
        }
        setSpinner(true)
        try {
            let response = await idleuserLists(payLoad)
            setIdleListData(response.data)
            setFilter((prevTaskListInput) => ({
                ...prevTaskListInput,
                reset: false
            }));
        } catch (error) {
        }
        setSpinner(false)
    }
    const idleUserExports = async () => {
        const payLoad = {
            startDate: filter?.startDate,
            endDate: moment(filter?.endDate).endOf('day').utc(),
            roleTitle: filter.role?.[0]?.roleTitle,
            shiftId: filter?.shift?.[0]?._id,
            cacheTime: 10000
        }
        try {
            setSpinner(true)
            let response = await idleuserExport(payLoad)
            window.open(response.data.fileName);
        } catch (error) {
        }
        setSpinner(false)
    }
    const resetFilter = () => {
        // setSpinner(true)
        setTimeout(() => {
            setFilter((prevTaskListInput) => ({
                ...prevTaskListInput,
                role: [],
                startDate: '',
                endDate: '',
                reset: true,
                shift: []
            }));
            setErrors({
                shift: '',
                startDate: '',
                endDate: '',
            })
            setIdleListData([])
            // setSpinner(false)
        }, 500);
    }
    // console.log(filter, 'filter')
    return {
        data: {
            allRoleList,
            filter,
            spinner,
            idleListData,
            shiftList,
            errors
        },
        methods: {
            setFilter,
            resetFilter,
            idleUserList,
            idleUserExports,
            setErrors
        }

    }
}

export default UseIdleUser

import React from 'react'

const FeedbackForm = () => {
  return (
    <>
      Feedback Forms
    </>
  )
}

export default FeedbackForm

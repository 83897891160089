import React from 'react'
import { useOutletContext } from 'react-router-dom'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'


const TicketReply = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const { data, fileType, filePreview } = useOutletContext()
  const { ticketsInputs } = data
  // const { setReplyModel, setNoteModel } = methods
  return (
    <>
      {ticketsInputs?.replies?.length ? (
        ticketsInputs?.replies?.map((item) => (
          <div key={item._id} className="container-fluid contact my-4">
            <div className="row bg">
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <h6 className="m-0">
                    <span className="table-link"
                    // onClick={() => Navigate(`/tickets/user/656d550484ff5978bcc3be95/ticket`)}
                    >
                      {`${item?.createdByData?.firstName} ${item?.createdByData?.lastName}`}
                    </span>
                    <span className="ms-2">replied</span>
                  </h6>
                  <div>{getFormattedDateTime(item?.createdAt, 'lll')}</div>
                </div>
                {/* <div className="my-2 d-flex">
                  <p>
                    <b>To : </b> {` ${ticketsInputs?.email}`}
                  </p>
                </div> */}
                <h6 className="mt-3">
                  <b> Subject : </b>
                  {ticketsInputs?.subject}
                </h6>
                <div dangerouslySetInnerHTML={{ __html: item.reply }}></div>
                {item.attachments?.length > 0 && <div className="mb-4">
                  <span>
                    <b>Attachments : </b>
                  </span>
                  {item.attachments?.length > 0 ? (
                    <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {item.attachments?.map((file, fileIndex) => (
                          <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => filePreview(file)}>
                            {fileType(file, fileIndex, "TICKET")}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                      <span>No Attachments</span>
                    </div>
                  )}
                </div>}
                <hr />
                {/* <div className="d-flex mt-3">
                  <div className="">
                    <Button variant="contained" className="px-4" onClick={() => setReplyModel({ description: '', open: true })}>
                      Reply
                    </Button>
                  </div>
                  <div className="mx-3">
                    <Button variant="contained" className="px-4" onClick={() => setNoteModel({ description: '', open: true })}>
                      Add Note
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
          No replies found
        </div>
      )}
    </>
  )
}

export default TicketReply

import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import AuthSlice from '../Slices/AuthSlice';
import thunk from 'redux-thunk';
import FiltersSlice from '../Slices/FiltersSlice';
import notificationCountSlice from '../Slices/notificationCountSlice';

const encryptor = encryptTransform({
    secretKey: 'Pritam_Singh_Zimo_Infotech_74',
});
const reducers = combineReducers({
    auth: AuthSlice,
    filters:FiltersSlice,
    notification:notificationCountSlice
});
const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
    blacklist: ['filters',],
    whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export default store;
import React, { useRef, useState } from 'react'
import { Button, Fab, Switch, Tabs, Tab, AppBar } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import CheckIcon from '@mui/icons-material/Check'
import useTaskDetails from './Hooks/useTaskDetails'
import moment from 'moment/moment'
import ClearIcon from '@mui/icons-material/Clear'
import Modal from '../../components/Modal'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import TextField from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import { CKEditorCustomToolbar, priorityList, ReminderList, SubTaskList, TaskCategoryList, complexityList, hoursTypeList, actutalStatusList } from '../../constants'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { useNavigate, NavLink, Outlet, useLocation } from 'react-router-dom'
// import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import MultipleSelect from "../../components/MultipleSelect"
import AddIcon from '@mui/icons-material/Add'
import TimerOffIcon from '@mui/icons-material/TimerOff';
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'
const TaskDetails = () => {
  const scrollRef = useRef()
  const fileInputRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { data, methods } = useTaskDetails()
  const { isLoading, taskDetails, OpenTextEditerModal, updateTaskModal, taskFormInputs, projectListData, assignToListData, tagListData, uploadFileUrlTask, TaskPermission, logTimeModal,
    selectedFiles,
    timelogFillInputs,
    timeLogError,
    taskId,
    breakHoursInput,
    errors,
    breatModal,
    deleteBreakHoursModal,
  } = data
  const { fileType, setOpenTextEditerModal, AddCommit, ChangeTaskStatusFun, setUpdateTaskModal, handleInputChange, handleDateChange, handleFileChange, handleRemoveFile, upateTask, setLogTimeModal, SubmitLogTime, handleLogRemoveFile, handleTimeLogFileChange,
    setTimeLoFillInput,
    setTimeLogError,
    handleTimeLogInputChange,
    removeBreak,
    addMultipleBreak,
    handleBrakeTime,
    setErrors,
    handleBreakInput,
    setBreakModal,
    submitBreak,
    CloseFucntion,
    updateBreak,
    setDeleteBreakHoursModal,
    handleBreakDelete,
    setBreakHoursInput,
    setScrollBreak
  } = methods
  const { getFormattedDateTime } = UseDateTimeFormat()

  const [showPDFModal, setShowPDFModal] = useState({ open: false, data: '' })

  let allTabs = [
    {
      label: 'Time Line',
      value: `/task/taskdetails/${taskId}`,
    },
  ]

  if (taskDetails?.isTimeLogged) {
    let newTab = {
      label: 'Time Log',
      value: `/task/taskdetails/${taskId}/time-log`,
    }
    allTabs?.push(newTab)
  }
  if (taskDetails?.breaks?.length > 0) {
    let newTab = {
      label: 'Non Working Time',
      value: `/task/taskdetails/${taskId}/break-time`,
    }
    allTabs?.push(newTab)
  }

  // useEffect(() => {
  //   if (scrollRef) {
  //     scrollRef.current?.addEventListener('DOMNodeInserted', event => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  //     });
  //   }
  // }, [scrollBreak])

  // const calculateTaskTime = () => {
  //   if (taskDetails.status === 'COMPLETED' || taskDetails.status === 'OPEN') {
  //     const actualTimeTaken = taskDetails?.actualTimeTaken || 0
  //     return actualTimeTaken > 1 ? actualTimeTaken + ' mins' : actualTimeTaken + ' min'
  //   } else {
  //     if (taskDetails?.paused?.pauseDateTime) {
  //       if (taskDetails?.paused?.resumedDateTime) {
  //         const taskTime = moment().diff(moment(taskDetails?.paused?.resumedDateTime), 'minutes') + (taskDetails?.paused?.durationWorked || 0)
  //         return taskTime > 1 ? taskTime + ' mins' : taskTime + ' min'
  //       } else {
  //         const taskTime = taskDetails?.paused?.durationWorked || 0
  //         return taskTime > 1 ? taskTime + ' mins' : taskTime + ' min'
  //       }
  //     } else {
  //       const taskTime = moment().diff(moment(taskDetails?.actualStartTime), 'minutes') || 0
  //       return taskTime > 1 ? taskTime + ' mins' : taskTime + ' min'
  //     }
  //   }
  // }

  const duration = moment.duration(taskDetails?.actualTimeTaken, 'minutes');
  const hours = Math.floor(duration.asHours());
  const remainingMinutes = duration.minutes();
  let workHours = "";
  if (hours > 0) {
    workHours += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (remainingMinutes > 0) {
    workHours += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  }
  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-9   px-3 ">
            <div className="col bg-white rounded">
              <div className="row ">
                <div className="col-lg-12 ticet m">
                  <div className="">
                    <div className="container-fluid">
                      <div className="d-flex justify-content-between pt-4">
                        <div>
                          <p className="">
                            <span className="fw-bold"> </span>{' '}
                            <span className="text-muted">
                              <b>{taskDetails?.taskName}</b>
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="p-o">
                            <span className="fw-bold">Expected Duration : </span>
                            <span className="text-muted">
                              {(taskDetails?.assignStartTime &&
                                taskDetails?.assignEndTime &&
                                `${getFormattedDateTime(taskDetails?.assignStartTime, 'DD-MM-YYYY h:mm A')} - ${getFormattedDateTime(taskDetails?.assignEndTime, 'DD-MM-YYYY h:mm A')}`) || (
                                  <span className="mx-4 px-4">--</span>
                                )}
                            </span>
                          </p>
                        </div>
                        {/* <div className="d-flex">
                          <div className="me-1">
                            <div className=" me-4 dotsbtn">
                              <a id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                <MoreVertIcon />
                              </a>
                              <Menu
                                id="fade-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                              >
                                <MenuItem onClick={() => handleClose('EDIT')}>Edit</MenuItem>
                                <MenuItem onClick={handleClose}>History</MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <hr className="m-0" />

                      <div className="col-lg-12 pt-4">
                        <div className="mb-3">
                          {/* <div className="col-lg-12">
                            <p className="">
                              <span className="fw-bold">Task Name : </span> <span className="text-muted">{taskDetails?.taskName}</span>
                            </p>
                          </div> */}
                          <div className="col-lg-12" style={{ wordWrap: "break-word" }}>
                            <p className="mb-0">
                              <span className="fw-bold">Description :</span>
                            </p>
                            <span className="text-muted" dangerouslySetInnerHTML={{ __html: taskDetails?.description }}></span>
                          </div>
                          <div className="col-lg-12 mt-4">
                            {/* <p className="">
                              <span className="fw-bold">Time :</span>{' '}
                              <span className="text-muted">
                                <b>{taskDetails?.assignDuration && `${Math.floor(taskDetails?.assignDuration / 60)}h ${taskDetails?.assignDuration % 60}m`}</b>
                              </span>
                            </p> */}
                            <p className="mb-0">
                              <span className="fw-bold">Notes :</span>
                            </p>
                            <span className="text-muted" dangerouslySetInnerHTML={{ __html: taskDetails?.notes }}></span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5 mx-1">
                        <div className="d-flex justify-content-between px-0">
                          <div>
                            <h6 className="px-0">
                              <b> Attachments</b>
                            </h6>
                          </div>
                        </div>
                        <div className="col-lg-12 px-0">
                          {taskDetails?.attachments?.length > 0 ? (
                            <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                              <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                                {taskDetails?.attachments?.map((file, fileIndex) => (

                                  <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => setShowPDFModal({ open: true, data: file })}>
                                    {fileType(file, fileIndex)}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                              <span>No Attachments</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Tags and Ticket link */}
                    <div className='row mt-4 mx-2'>
                      <div className='col-6'>
                        <span className="d-flex flex-wrap">
                          <b className='me-1'>Tags : </b>
                          {taskDetails?.tags?.length
                            ? taskDetails?.tags?.map((tag) => {
                              return (
                                <div key={tag?._id} className="tag-list-chips cursor-pointer me-1"
                                  style={{ color: '#000', background: tag?.color ? tag?.color : "text-secondary", border: tag?.color }}>
                                  <span style={{ fontSize: '11px' }}>{tag?.tagName}</span>
                                </div>
                              )
                            })
                            : '--'}
                        </span>
                        <div className='mt-3'>
                          <span><b>Project : </b></span>
                          <span>{taskDetails?.project_id?.name || "--"}</span>
                        </div>
                      </div>
                      {taskDetails?.ticket_id && <div className='col-6'>
                        <b>Ticket </b>
                        <div
                          className="tag-list-chips cursor-pointer me-1"
                          style={{ color: '#9c27b0', border: "1px solid #9c27b0" }}
                          onClick={() => navigate(`/tickets/email/${taskDetails?.ticket_id?._id}`, { state: { ticketId: taskDetails?.ticket_id?._id }, })}
                        >
                          <span style={{ fontSize: '11px' }}>{taskDetails?.ticket_id?.ticketId}</span>
                        </div>
                      </div>}
                    </div>

                    <div className="col-lg-12  pb-3 pe-4">
                      <div className="row pe-2">
                        <div className="d-flex justify-content-between">
                          <div className=""></div>
                          {/* <div className="row">
                        <div className=" d-flex justify-content-end ">
                          <div className="m-2">
                            <Button variant="contained">
                              Getting Started
                            </Button>
                          </div>
                          <div className="m-2">
                            <Button variant="contained">Cancel</Button>
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                (taskDetails?.assignTo_id?._id === TaskPermission?.userInfo?._id) &&
                <div className="row p-4">
                  <div className="d-flex justify-content-between me-5 mt-4 pe-1">
                    {/* <div className="d-flex">
                      {taskDetails?.status === 'OPEN' && (
                        <Button variant="contained" onClick={() => ChangeTaskStatusFun('IN PROGRESS')}>
                          <PlayCircleOutlineIcon /> &nbsp; Start Timer
                        </Button>
                      )}
                      {taskDetails?.status === 'IN PROGRESS' && !taskDetails?.paused?.pauseDateTime && (
                        <Button variant="contained" onClick={() => setOpenTextEditerModal((pre) => ({ ...pre, open: true, PauseTime: true }))}>
                          <PauseCircleOutlineIcon /> &nbsp; Pause Timer
                        </Button>
                      )}
                      {taskDetails?.status === 'PAUSED' && (
                        <Button variant="contained" onClick={() => ChangeTaskStatusFun('IN PROGRESS')}>
                          <PauseCircleOutlineIcon /> &nbsp; Resume Timer
                        </Button>
                      )}
                      {(taskDetails?.status === 'IN PROGRESS' || taskDetails?.status === 'PAUSED') && (
                        <div>
                          <Button className="dropDownButtons11 ms-2" onClick={() => setOpenTextEditerModal((pre) => ({ ...pre, open: true, OpenCompleteTaks: true }))}>
                            {' '}
                            <CheckIcon /> &nbsp; Complete Task
                          </Button>
                        </div>
                      )}
                      {taskDetails?.status === 'COMPLETED' && !taskDetails.isTimeLogged && (
                        <div>
                          <Button className="dropDownButtons11"
                            onClick={() => setLogTimeModal((pre) => ({ ...pre, open: true }))}
                          >
                            <CheckIcon /> &nbsp; Log Time
                          </Button>
                        </div>
                      )}
                    </div> */}
                    {!taskDetails.isTimeLogged && <div>
                      <Button className="dropDownButtons11"
                        onClick={() => setLogTimeModal((pre) => ({ ...pre, open: true }))}
                      >
                        <CheckIcon /> &nbsp; Log Time
                      </Button>
                      <Button color='warning' variant='contained' className="ms-2"
                        onClick={() => {

                          setBreakHoursInput([{
                            startTime: null,
                            endTime: null,
                            reason: ""
                          }])
                          setBreakModal((pre) => ({ ...pre, open: true }))
                        }}
                      >
                        <TimerOffIcon /> &nbsp; Non Working Time
                      </Button>
                    </div>}
                    {taskDetails?.status !== 'OPEN' && <div>
                      <span>
                        <b>Task Time :</b>
                      </span>
                      <span> {workHours || "--"}</span>
                    </div>}

                  </div>
                </div>}
            </div>
            {/* Time line Tab */}
            <div className="mt-2 " style={{ backgroundColor: 'white', borderRadius: '5px' }}>
              <AppBar position="static" sx={{ background: '#fff' }}>
                <Tabs value={location.pathname}>
                  {allTabs.map((item, i) => (
                    <Tab key={i} label={item.label} value={item.value} component={NavLink} to={item.value} />
                  ))}
                </Tabs>
              </AppBar>
              <Outlet context={{ data, methods }} />
            </div>
          </div>
          <div className="col-3  bg  px-3">
            <div className="col bg-white rounded" style={{ minHeight: '30vh' }}>
              <div className="row  mx-3">
                <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-0">
                  <h5 className="px-0">
                    <b>Comments</b>
                  </h5>
                  <Button className="my-2" variant="contained" size="small" onClick={() => setOpenTextEditerModal((pre) => ({ ...pre, open: true, AddCommitTask: true }))}>
                    add
                  </Button>
                </div>
                <hr />
                <div className="container-fluid contact">
                  <div className="col-lg-12">
                    {taskDetails?.comments?.map((cur, index) => {
                      return (
                        <div className="my-3" key={index}>
                          <div className="d-flex justify-content-between">
                            <span style={{ fontSize: '14px' }} className="table-link">{`${cur?.by?.firstName} ${cur?.by?.lastName} `}</span> &nbsp; &nbsp;
                            <span style={{ fontSize: '12px' }}>{getFormattedDateTime(cur?.time, 'lll')}</span>
                          </div>
                          <p className="my-3 p-0" style={{ lineHeight: '16px' }} dangerouslySetInnerHTML={{ __html: cur?.comment }}>
                            {/* {plainTextNotes} */}
                          </p>
                          <hr style={{ margin: '4px 0' }} />
                        </div>
                      )
                    })}
                    {!taskDetails?.comments?.length && <div>No Comments</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Attatchment Modal */}

      {/* <Modal size="md" show={showPDFModal.open} onHide={() => { setShowPDFModal(prev => ({ ...prev, open: false })); }} centered>
        <Modal.Body className="p-0" style={{ height: "400px" }}>
          {showPDFModal?.data?.attachment ? (
            showPDFModal?.data?.attachment?.split(".").pop() === "pdf" ? (
              <iframe src={showPDFModal?.data?.attachment} title={showPDFModal?.data?.attachment} style={{ width: '100%', height: '100%' }} />
            ) : (
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={showPDFModal?.data?.attachment} alt="" />
            )
          ) : (
            <div>No attachment available</div>
          )}
        </Modal.Body>
      </Modal> */}

      <Modal
        size="lg"
        show={showPDFModal.open}
        onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
        headerContent={''}
        bodyContent={
          <div className="">
            {showPDFModal?.data ? (
              showPDFModal?.data?.split('.').pop() === 'pdf' || 'mp4' || 'MOV' ? (
                <iframe src={uploadFileUrlTask + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
              ) : (
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlTask + showPDFModal?.data} alt="" />
              )
            ) : (
              <div>No attachment available</div>
            )}
          </div>
        }
      />

      {/* submit log time modal */}
      <Modal
        size="lg"
        show={logTimeModal.open}
        onHide={() => {
          CloseFucntion("breatModal");
          setLogTimeModal((pre) => ({ ...pre, open: false }))
        }}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2">Log Time</h5>
          </div>
        }
        bodyContent={
          <div className="App">
            {/* <p className="m-0 mb-2">{OpenTextEditerModal?.AddCommitTask ? 'Comment' : OpenTextEditerModal?.PauseTime ? 'Comment' : 
            OpenTextEditerModal?.OpenCompleteTaks ? 'Note' : null} </p> */}
            {/* subTask,
  taskCategory,
  complexity,
  hoursType,
  actutalStatus,
  assignStartTime,
  assignEndTime; */}
            <div className='row'>
              <div className="col-lg-4  mt-4">
                <MultipleSelect
                  label="SubTask"
                  isRequired={true}
                  // multiple={true}
                  options={SubTaskList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"subTask"}
                  onChange={(e) => handleTimeLogInputChange({ target: { name: "subTask", value: e } })}
                  value={"value"}
                  selectedValues={timelogFillInputs.subTask}
                />
                {timeLogError?.subTask && <span className='text-danger'>{timeLogError?.subTask}</span>}
              </div>
              <div className="col-lg-4  mt-4">
                <MultipleSelect
                  label="Task Category"
                  isRequired={true}
                  // multiple={true}
                  options={TaskCategoryList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"taskCategory"}
                  onChange={(e) => handleTimeLogInputChange({ target: { name: "taskCategory", value: e } })}
                  value={"value"}
                  selectedValues={timelogFillInputs?.taskCategory}
                />
                {timeLogError?.taskCategory && <span className='text-danger'>{timeLogError?.taskCategory}</span>}
              </div>
              <div className="col-lg-4  mt-4">
                <MultipleSelect
                  label="Complexity"
                  isRequired={true}
                  // multiple={true}
                  options={complexityList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"complexity"}
                  onChange={(e) => handleTimeLogInputChange({ target: { name: "complexity", value: e } })}
                  value={"value"}
                  selectedValues={timelogFillInputs?.complexity}
                />
                {timeLogError?.complexity && <span className='text-danger'>{timeLogError?.complexity}</span>}
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-4  mt-4">
                <MultipleSelect
                  label="Hours Type"
                  isRequired={true}
                  // multiple={true}
                  options={hoursTypeList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"hoursType"}
                  onChange={(e) => handleTimeLogInputChange({ target: { name: "hoursType", value: e } })}
                  value={"value"}
                  selectedValues={timelogFillInputs?.hoursType}
                />
                {timeLogError?.hoursType && <span className='text-danger'>{timeLogError?.hoursType}</span>}
              </div>
              <div className="col-lg-4  my-4">
                <MultipleSelect
                  label="Actual Status"
                  isRequired={true}
                  // multiple={true}
                  options={actutalStatusList}
                  searchBy={"title"}
                  keyName={"title"}
                  name={"actutalStatus"}
                  onChange={(e) => handleTimeLogInputChange({ target: { name: "actutalStatus", value: e } })}
                  value={"value"}
                  selectedValues={timelogFillInputs?.actutalStatus}
                />
                {timeLogError?.actutalStatus && <span className='text-danger'>{timeLogError?.actutalStatus}</span>}
              </div>
              <div className="col-lg-4  my-4">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Actual Start Time'}
                  handleDateChange={(e) => {
                    setTimeLoFillInput((prevProps) => ({ ...prevProps, actualStartTime: e }))
                    setTimeLogError((prev) => ({ ...prev, actualStartTime: '' }))
                  }}
                  value={timelogFillInputs?.actualStartTime || null}
                  Error={timeLogError?.actualStartTime}
                />
              </div>
            </div>

            <div className='row d-flex justify-content-between'>
              <div className="col-lg-4  mb-4">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Actual End Time'}
                  handleDateChange={(e) => {
                    const selectedEndTime = e instanceof Date ? e : new Date(e);
                    if (
                      timelogFillInputs?.actualStartTime &&
                      selectedEndTime &&
                      selectedEndTime < timelogFillInputs.actualStartTime
                    ) {
                      setTimeLogError((prev) => ({
                        ...prev,
                        actualEndTime: 'End time must be equal to or after the start time',
                      }));
                      // toast.warn('End time must be equal to or after the start time');
                    } else {
                      setTimeLogError((prev) => ({ ...prev, actualEndTime: '' }));
                      setTimeLoFillInput((prevProps) => ({ ...prevProps, actualEndTime: selectedEndTime }));
                    }
                  }}
                  value={timelogFillInputs?.actualEndTime || null}
                  Error={timeLogError?.actualEndTime}
                />
              </div>
              <div className="col-lg-4 text-end my-3">
                <Button size='small' variant='contained' color='success' className='' onClick={() => { addMultipleBreak(0); setScrollBreak(prev => !prev) }}>
                  <AddIcon />Add Non Working Time
                </Button>
              </div>
            </div>
            <div className="row">
              <div ref={scrollRef} style={{ height: `${breakHoursInput?.length > 0 ? "225px" : "0px"}`, width: "100%", overflowY: "auto" }}>
                {breakHoursInput?.map((item, index) => <>
                  {!item?.isDeleted && <><div className="row">
                    <div className="col-lg-6 my-2">
                      <DesktopDateTimePicker
                        className="w-100 form-field-design"
                        // isRequired={true}
                        Label={'Start'}
                        handleDateChange={(date) => handleBrakeTime(date, 'startTime', index)}
                        SelectedDate={breakHoursInput[index]?.startTime}
                      />
                      {errors[index]?.startTime && <span className='text-danger'>{errors[index]?.startTime}</span>}
                    </div>
                    <div className="col-lg-6 my-2">
                      <DesktopDateTimePicker
                        className="w-100 form-field-design"
                        // isRequired={true}
                        Label={'End'}
                        handleDateChange={(date) => handleBrakeTime(date, 'endTime', index)}
                        SelectedDate={breakHoursInput[index]?.endTime}
                      />
                      {errors[index]?.endTime && <span className='text-danger'>{errors[index]?.endTime}</span>}
                    </div>
                    <div className="col-lg-12 my-2">
                      <label htmlFor="">
                        <sub>Reason
                          {/* <span className='text-danger'>*</span> */}
                        </sub>
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: CKEditorCustomToolbar,
                        }}
                        data={item?.reason}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          handleBreakInput({ target: { name: 'reason', value: data } }, index);
                          // Clear the reason error when there is a change
                          setErrors((prevErrors) => {
                            const updatedErrors = [...prevErrors];
                            updatedErrors[index] = { reason: '' };
                            return updatedErrors;
                          });
                        }}
                        onBlur={(event, editor) => {
                          if (!item.reason) {
                            // Set the reason error if it's empty on blur
                            setErrors((prevErrors) => {
                              const updatedErrors = [...prevErrors];
                              updatedErrors[index] = { reason: 'Please enter reason' };
                              return updatedErrors;
                            });
                          }
                        }}
                      />
                      {errors[index]?.reason && <p className="text-danger">{errors[index]?.reason}</p>}
                    </div>
                  </div>
                    <div className="col-lg-12 text-end mt-3">
                      {/* <Button size='samll' className="dropDownButtons11 me-2" onClick={() => addMultipleBreak(index)}>
                    <AddIcon />
                  </Button> */}
                      {/* {breakHoursInput.length > 1 && ( // Only show the button if there is more than one task */}
                      <Button variant="outlined" color="error" onClick={() => removeBreak(index, item?._id)}>
                        <ClearIcon />
                      </Button>
                      {/* )} */}
                    </div>
                  </>}
                </>)}
              </div>
            </div>


            {/* <div className='d-flex'>
              <div className="col-lg-3 me-4 mb-4">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Actual End Time'}
                  handleDateChange={(e) => {
                    setTimeLoFillInput((prevProps) => ({ ...prevProps, actualEndTime: e }))
                    setTimeLogError((prev) => ({ ...prev, actualEndTime: '' }))
                  }}
                  value={timelogFillInputs?.actualEndTime || null}
                  Error={timeLogError?.actualEndTime}
                />
              </div>
            </div> */}
            <label>Remarks</label>
            {!OpenTextEditerModal?.OpenCompleteTaks && <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: CKEditorCustomToolbar,
              }}
              data={OpenTextEditerModal.value}
              onChange={(event, editor) => {
                const data = editor.getData()
                setLogTimeModal((prev) => ({ ...prev, value: data }))
              }}
            />}

            <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
              <h5 className="">Attachments</h5>
              <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
              <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
            </div>
            {selectedFiles.length > 0 && <div className="col-lg-12 ">
              <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                <div>
                  <h4>Selected Files:</h4>
                  <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                    {selectedFiles.map((file, fileIndex) => (
                      <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                        {fileType(file, fileIndex, "TIMELOG")}
                        <Fab
                          className="m-2"
                          sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                          onClick={() => handleLogRemoveFile(fileIndex)}
                        >
                          <ClearIcon sx={{ fontSize: '15px' }} />
                        </Fab>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>}

            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button variant="contained" onClick={() => SubmitLogTime()}>Submit</Button>
              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => setLogTimeModal({ data: '', open: false, })}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* Add Comment || add Note Modal */}
      <Modal
        size="lg"
        show={OpenTextEditerModal.open}
        onHide={() => setOpenTextEditerModal((pre) => ({ ...pre, open: false, AddCommitTask: false, OpenCompleteTaks: false, PauseTime: false }))}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2"> {OpenTextEditerModal?.AddCommitTask ? 'Comment' : OpenTextEditerModal?.PauseTime ? 'Pause' : OpenTextEditerModal?.OpenCompleteTaks ? 'Complete task' : null}</h5>
          </div>
        }
        bodyContent={
          <div className="App">

            {/* <p className="m-0 mb-2">{OpenTextEditerModal?.AddCommitTask ? 'Comment' : OpenTextEditerModal?.PauseTime ? 'Comment' : OpenTextEditerModal?.OpenCompleteTaks ? 'Note' : null} </p> */}
            {!OpenTextEditerModal?.OpenCompleteTaks ? <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: CKEditorCustomToolbar,
              }}
              data={OpenTextEditerModal.value}
              onChange={(event, editor) => {
                const data = editor.getData()
                setOpenTextEditerModal((prev) => ({ ...prev, value: data }))
              }}
            /> : "Looks like you have finished your work. Click confirm to mark this task completed."}
            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                {/* <Button
                  variant="contained"
                  onClick={() =>
                    OpenTextEditerModal?.AddCommitTask
                      ? AddCommit()
                      : OpenTextEditerModal?.PauseTime
                        ? ChangeTaskStatusFun('PAUSED')
                        : OpenTextEditerModal?.OpenCompleteTaks
                          ? ChangeTaskStatusFun('COMPLETED')
                          : null
                  }
                >
                  {OpenTextEditerModal?.AddCommitTask ? 'Add' : OpenTextEditerModal?.PauseTime ? 'Pause' : OpenTextEditerModal?.OpenCompleteTaks ? 'Confirm' : null}
                </Button> */}

                <Button
                  variant="contained"
                  onClick={() => {
                    if (OpenTextEditerModal?.AddCommitTask) {
                      AddCommit();
                    } else if (OpenTextEditerModal?.PauseTime) {
                      ChangeTaskStatusFun('PAUSED');
                    } else if (OpenTextEditerModal?.OpenCompleteTaks) {
                      const currentTime = moment(); // Assuming you have moment.js imported
                      const startTime = moment(taskDetails.actualStartTime);
                      const timeDifference = currentTime.diff(startTime, 'minutes');

                      if (timeDifference >= 5) {
                        ChangeTaskStatusFun('COMPLETED');
                      } else {
                        toast.warn("Minimum time to complete the task can not be less than 5 minutes");
                      }
                    }
                  }}
                >
                  {OpenTextEditerModal?.AddCommitTask ? 'Add' : OpenTextEditerModal?.PauseTime ? 'Pause' : OpenTextEditerModal?.OpenCompleteTaks ? 'Confirm' : null}
                </Button>

                {/* */}
              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => setOpenTextEditerModal({ value: '', open: false, AddCommitTask: false, OpenCompleteTaks: false, PauseTime: false })}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* add Breaks Modal */}
      <Modal
        size="lg"
        show={breatModal.open}
        onHide={() => {
          CloseFucntion("breatModal");
          setBreakModal((pre) => ({ ...pre, open: false, }))
        }}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2"> {"Non Working Time"}</h5>
          </div>
        }
        bodyContent={
          <div className="App">

            <div>
              {breakHoursInput.map((item, index) => <>
                <div className="row">
                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Start'}
                      handleDateChange={(date) => handleBrakeTime(date, 'startTime', index)}
                      SelectedDate={breakHoursInput[index]?.startTime}
                    />
                    {errors[index]?.startTime && <span className='text-danger'>{errors[index]?.startTime}</span>}
                  </div>
                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'End'}
                      handleDateChange={(date) => handleBrakeTime(date, 'endTime', index)}
                      SelectedDate={breakHoursInput[index]?.endTime}
                    />
                    {errors[index]?.endTime && <span className='text-danger'>{errors[index]?.endTime}</span>}
                  </div>
                  <div className="col-lg-12 my-2">
                    <label htmlFor="">
                      <sub>Reason <span className='text-danger'>*</span></sub>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: CKEditorCustomToolbar,
                      }}
                      data={item?.reason}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleBreakInput({ target: { name: 'reason', value: data } }, index);
                        // Clear the reason error when there is a change
                        setErrors((prevErrors) => {
                          const updatedErrors = [...prevErrors];
                          updatedErrors[index] = { reason: '' };
                          return updatedErrors;
                        });
                      }}
                      onBlur={(event, editor) => {
                        if (!item.reason) {
                          // Set the reason error if it's empty on blur
                          setErrors((prevErrors) => {
                            const updatedErrors = [...prevErrors];
                            updatedErrors[index] = { reason: 'Please enter reason' };
                            return updatedErrors;
                          });
                        }
                      }}
                    />
                    {errors[index]?.reason && <p className="text-danger">{errors[index]?.reason}</p>}
                  </div>
                </div>
                {/* <div className="col-lg-12 text-end mt-3">
                  <Button size='samll' className="dropDownButtons11 me-2" onClick={() => addMultipleBreak(index)}>
                    <AddIcon />
                  </Button>
                  {breakHoursInput.length > 1 && ( // Only show the button if there is more than one task
                    <Button variant="outlined" color="error" onClick={() => removeBreak(index)}>
                      <ClearIcon />
                    </Button>
                  )}
                </div> */}
              </>)}
            </div>
            <div className=" d-flex justify-content-end ">
              <div className="m-2">

              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => {
                  CloseFucntion("breatModal");
                  setBreakModal({ value: '', open: false, })
                }}>
                  Cancel
                </Button>
              </div>
              <div className="m-2">
                <Button
                  variant="contained"
                  onClick={breatModal?.data ? updateBreak : submitBreak}
                >
                  {breatModal?.data ? "update" : 'submit'}
                </Button>
              </div>
            </div>
          </div>
        }
      />


      {/* Edit Task Modal */}
      <Modal
        size="xl"
        show={updateTaskModal}
        onHide={() => setUpdateTaskModal(false)}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2">Update Taks</h5>
          </div>
        }
        bodyContent={
          <div className="App">
            <p className="m-0 mb-2">{OpenTextEditerModal?.AddCommitTask ? 'Commit' : OpenTextEditerModal?.PauseTime ? 'Commit' : OpenTextEditerModal?.OpenCompleteTaks ? 'Note' : null} </p>
            <div className="row">
              <div className="col-lg-12 my-2">
                <TextField
                  Lable="Task Name"
                  Name="taskName"
                  variant="outlined"
                  ClassName="form-field-design "
                  Require={true}
                  HandleInputChange={(e) => handleInputChange(e)}
                  Value={taskFormInputs?.taskName}
                  width={100}
                />
              </div>
              <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Description </sub>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  data={taskFormInputs?.description}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleInputChange({ target: { name: 'description', value: data } })
                  }}
                />
              </div>
              <div className="col-lg-6 my-2">
                <SelectInput
                  Lable={'Project '}
                  // width={"310px"}
                  Options={projectListData}
                  keyName={'name'}
                  Name="project_id"
                  ClassName="form-field-design"
                  SelectValue={taskFormInputs.project_id}
                  HandleChange={(e) => handleInputChange(e)}
                  //   onBlur={handleBlur}
                  //   Error={error.priority}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-6 my-2">
                <SelectInput
                  Lable={'Priority'}
                  // width={"310px"}
                  Options={priorityList}
                  keyName={'title'}
                  Name="priority"
                  ClassName="form-field-design"
                  SelectValue={taskFormInputs.priority}
                  HandleChange={(e) => handleInputChange(e)}
                  //   onBlur={handleBlur}
                  //   Error={error.priority}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-6 my-2">
                <SelectInput
                  Lable={'Assign To '}
                  // width={"310px"}
                  Options={assignToListData}
                  keyName={'title'}
                  Name="assignTo_id"
                  ClassName="form-field-design"
                  SelectValue={taskFormInputs.assignTo_id}
                  HandleChange={(e) => handleInputChange(e)}
                  //   onBlur={handleBlur}
                  //   Error={error.priority}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-6">
                <SelectInput
                  Lable={'Tag'}
                  multiple={true}
                  Options={tagListData}
                  keyName={'tagName'}
                  Name="tags"
                  SelectValue={taskFormInputs.tags}
                  HandleChange={(e) => handleInputChange(e)}
                  // onBlur={handleBlur}
                  // Error={error.tags}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>

              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Start Date '}
                  handleDateChange={(date) => handleDateChange(date, 'assignStartTime')}
                  SelectedDate={taskFormInputs?.assignStartTime}
                />
              </div>
              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Due Date'}
                  handleDateChange={(date) => handleDateChange(date, 'assignEndTime')}
                  SelectedDate={taskFormInputs?.assignEndTime}
                />
              </div>
              <div className="col-lg-6 my-2 position-relative mt-3">
                <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
                  <Switch name="ReminderAction" checked={taskFormInputs?.ReminderAction} onChange={(e) => handleInputChange(e)} />
                </div>
                <SelectInput
                  LabelButton
                  Lable={'Reminder'}
                  // multiple={true}
                  Options={ReminderList}
                  keyName={'title'}
                  Name="minutesBeforeReminder"
                  SelectValue={taskFormInputs.minutesBeforeReminder}
                  HandleChange={(e) => handleInputChange(e)}
                  // onBlur={handleBlur}
                  // Error={error.tags}
                  variant={'outlined'}
                  isRequired={true}
                  disabled={taskFormInputs?.ReminderAction === false}
                />
              </div>
              <div className="col-12 d-flex justify-content-between align-items-center">
                <h5 className="mt-4 mb-2">Attachments</h5>
                <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                  choose file
                </Button>
                <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={(e) => handleFileChange(e)} style={{ display: 'none' }} />
              </div>
              <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                {taskFormInputs?.attachments?.map((file, fileIndex) => (
                  <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                    {fileType(file)}
                    <Fab
                      className="m-2"
                      sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                      onClick={() => handleRemoveFile(file, fileIndex)}
                    >
                      <ClearIcon sx={{ fontSize: '15px' }} />
                    </Fab>
                  </div>
                ))}
              </div>
              <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Remarks </sub>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  data={taskFormInputs?.notes} // Set CKEditor data to projectInputs.notes
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleInputChange({ target: { name: 'notes', value: data } })
                  }}
                />
              </div>
            </div>
            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button variant="contained" onClick={upateTask}>
                  update task
                </Button>
                {/* */}
              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => setUpdateTaskModal(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* delete break time */}
      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteBreakHoursModal.open}
        onHide={() => setDeleteBreakHoursModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{"Delete Break time?"}</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete Break time? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setDeleteBreakHoursModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={handleBreakDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

    </>
  )
}

export default TaskDetails

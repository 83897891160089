/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Outlet, useLocation, NavLink, useNavigate, useParams } from 'react-router-dom'
import { Tabs, Tab, AppBar, TextField } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { Button } from '@mui/material';
import useReportidByUser from '../Hooks/useReportidByUser';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner/Spinner';
import Modal from "../../../components/Modal"


const ReportedByUserMainPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { id } = useParams()
    const { data, methods } = useReportidByUser()
    const { setMergeModel,
        setSearchTickets,
        handleCheckSlots,
        UserMerge
    } = methods
    const { reportedUserData, isLoading, mergeModel, allReportingUserList, searchTickets,
        slotId
    } = data

    const allTabs = [
        {
            label: "Tickets",
            value: `/tickets/user/${id}/ticket`,
        },
        {
            label: "Notes",
            value: `/tickets/user/${id}/note`,
        },
        // {
        //     label: "Tags",
        //     value: `/setting/tag-list`,
        // },
    ];

    return (
        <>
            {isLoading && <Spinner />}
            <ToastContainer />
            <div className="contact">
                <div className="container bg">
                    <div className="row">
                        <div className="col-lg-12 pt-4">
                            <div className="d-flex justify-content-end">
                                <div className="d-flex">
                                    <div>
                                        <Button variant="contained" className="px-2" onClick={() => navigate(`/tickets/new-ticket-form`)}                                    >
                                            <AddIcon /> New Ticket
                                        </Button>
                                    </div>
                                    <div className="pe-4 mx-2">
                                        <Button className=' p-2' >

                                            {/* </button> */}
                                            <div className="dropdown p-0 align-items-center justify-content-center">
                                                <i
                                                    className="fas fa-ellipsis-v pointer"
                                                    id="dropdownMenuLink"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                />
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink"
                                                >
                                                    <a style={{ cursor: "pointer" }}
                                                        // onClick={() => 
                                                        // navigate(`/tickets/email`, {
                                                        //     state: { ticketId: item._id },
                                                        // })                                                } 
                                                        className="dropdown-item">
                                                        Edit
                                                    </a>
                                                    <a className="dropdown-item" onClick={() => {
                                                        setMergeModel((prev) => ({ ...prev, open: true }))
                                                    }}>
                                                        Merge
                                                    </a>
                                                </ul>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 pt-4 mt-2 ms-4">
                                <div className="row my-3">
                                    <div className="col-lg-6">
                                        <p className=""><span className="fw-bold">Name :</span> <span className="text-muted">{
                                            reportedUserData?.firstName ? `${reportedUserData?.firstName} ${reportedUserData?.lastName}` : "--"}</span></p>
                                        <p className=""><span className="fw-bold">Email :</span> <span className="text-muted">{reportedUserData?.email || "--"}</span></p>
                                        {/* <p className=""><span className="fw-bold">Address :</span> <span className="text-muted">86-90 Paul Street, London, EC2A 4NE</span></p>
                                        <p className=""><span className="fw-bold">Language :</span> <span className="text-muted">English</span></p> */}
                                    </div>
                                    {/* <div className="col-lg-6">
                                        <p className=""><span className="fw-bold">Time Zone :</span> <span className="text-muted">Casablanca</span></p>
                                        <p className=""><span className="fw-bold">Phone :</span> <span className="text-muted">+448081698824</span></p>
                                        <p className=""><span className="fw-bold">Type :</span> <span className="text-muted">Phone</span></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container bg my-4">
                    <div className="border" style={{ backgroundColor: "white" }}>
                        <AppBar position="static" sx={{ background: "#fff" }}>
                            <Tabs value={location.pathname}>
                                {allTabs.map((item, i) =>
                                    <Tab key={i}
                                        label={item.label}
                                        value={item.value}
                                        component={NavLink}
                                        to={item.value}
                                    />
                                )}
                            </Tabs>
                        </AppBar>

                        {/* Merge Modal */}
                        <Modal
                            size="md"
                            show={mergeModel.open}
                            onHide={() => setMergeModel((prev) => ({ ...prev, open: false }))}
                            headerContent={<h5>Merge to</h5>}
                            bodyContent={
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            {/* <label htmlFor="" className="w-100"> */}
                                            <TextField
                                                type="text"
                                                onChange={(e) =>
                                                    setSearchTickets(e.target.value)
                                                }
                                                value={searchTickets}
                                                placeholder="Search"
                                                sx={{ width: "380px" }}
                                                size="small"
                                            />
                                            {/* </label> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col-11 mt-4 mx-auto "
                                            style={{ height: "400px", overflowY: "auto" }}
                                        >
                                            {/* {fullTicketList.map((item, index) => */}
                                            {allReportingUserList?.filter((cur) => cur?.name?.toLowerCase().includes(searchTickets))?.map((item, index) => (
                                                <article
                                                    key={item?._id}
                                                    className={`${slotId === index ? "slot_Active" : "slot_InActive"
                                                        } article_show_status `}
                                                    id={item?.status !== "Available" && "Booked_slot"}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id="feature1"
                                                        defaultChecked={item?.isChecked}
                                                        onChange={(e) =>
                                                            handleCheckSlots(e.target.checked, item, index)
                                                        }
                                                    // disabled={DisableTimeSlot(item)}
                                                    />
                                                    <div className="row p-2">
                                                        <div className="col-12  d-flex justify-content-between ">
                                                            <p className="mb-0">Name: {item.name}</p>
                                                            {/* <p className="mb-0">{moment(item.createdAt).calendar()}</p> */}
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-start  ">
                                                            {/* <p className="mb-0">Subject: {item.subject}</p> */}
                                                        </div>
                                                    </div>
                                                </article>
                                            ))}
                                        </div>
                                    </div>
                                    {/* </div> */}

                                    <div className="text-center mt-3">
                                        <Button variant="contained" onClick={UserMerge}>Yes</Button>
                                        <Button
                                            variant="outlined"
                                            className="ms-2"
                                            onClick={() =>
                                                setMergeModel((prev) => ({ ...prev, open: false }))
                                            }
                                            data-bs-dismiss="modal"
                                        >
                                            No
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                        <Outlet
                            context={{ data, methods }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportedByUserMainPage
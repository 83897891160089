import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getNoteList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/Note/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetNoteList(params, options = {}) {
    return useQuery(['GET_Note_LIST',], () => getNoteList(params), options)
}


// All Note List
export const allNoteList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/note/fullList`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}

// Add Note
export const addNote = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/note`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  Get Note Details
export const getNoteDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/note/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update Note
export const updateNote = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/note`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete Note
export const deleteNote = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/note/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};



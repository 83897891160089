import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
import Table from '../../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../../../components/Modal'
import useLeavePolicyList from './Hooks/useLeavePolicyList'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'
import { Pagination } from '../../../components/pagination/Pagination'
import SearchAndStatusFilter from '../../../components/SearchAndStatusFilter'
import moment from 'moment'
import UseDateTimeFormat from '../../../hooks/useDateTimeFormat'
const LeavePolicyList = () => {
    const navigate = useNavigate()
    const { getFormattedDateTime } = UseDateTimeFormat()

    const { data, methods } = useLeavePolicyList()
    const { leavePolicyList, search, statusType, totalCount, controller, isLoading, deleteModal, ShiftPermission } = data
    const { handleSearchChange, setStatusType, resetFilter, applayFilter, handlePagination, setDeleteModal, handleDelete, Export } = methods
    const { add, delete: projectDelete, edit, export: exportFile } = ShiftPermission?.userInfo?.permission?.project

    const TableHead = [
        { label: '#', id: '' },
        { label: 'Policy Name', id: '' },
        { label: 'Code', id: '' },
        { label: 'Leave Type', id: '' },
        { label: 'Policy Start Date', id: '' },
        { label: 'Policy End Date', id: '' },
        { label: 'Type', id: '' },
        { label: 'Entitled', id: '' },
        { label: 'Action', id: '' },
    ]

    return (
        <>
            <ToastContainer />
            {isLoading && <Spinner />}
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} />
                        </div>
                        <div className="d-flex pe-1">
                            {exportFile?.value && <div>
                                <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
                            </div>}
                            {add?.value && <div className="ms-2">
                                <Button className="dropDownButtons11" onClick={() => navigate(`/setting/leave-policy/leave-policy-form/add`)}>
                                    <AddIcon /> Add New
                                </Button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-lg-12 ticet">
                        <div className="bg shadow raduus">
                            <div className="">
                                <div className="mt-2">
                                    <table className="table">
                                        <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                                        <tbody>
                                            {leavePolicyList?.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={item._id + index}
                                                        className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                                                    >
                                                        <td className="table-link">{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                                                        <td className="table-link" > {item.name || '--'} </td>
                                                        <td className="">{item.code || '--'}</td>
                                                        <td className="">{item.leaveType || '--'}</td>
                                                        <td className="">{getFormattedDateTime(item.policyStartDate, 'DD-MM-YYYY')  || '--'}</td>
                                                        <td className="">{getFormattedDateTime(item.policyEndDate, 'DD-MM-YYYY') || '--'}</td>
                                                        <td className="">{item.type || '--'}</td>
                                                        <td className="">{item.entitledLeaves || '--'}</td>
                                                        <td>
                                                            {(projectDelete?.value || edit?.value) ?
                                                                <div className="dropdown d-flex p-0 align-items-center justify-content-start" style={{ minHeight: '50px' }}>
                                                                    <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                        {edit?.value && <li style={{ cursor: 'pointer' }}
                                                                            onClick={() => navigate(`/setting/leave-policy/leave-policy-form/${item._id}`)} className="dropdown-item">
                                                                            Edit
                                                                        </li>}
                                                                        {projectDelete?.value && <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item._id, isDeleted: item.isDeleted }, open: true })}>
                                                                            {item.isDeleted ? 'Recover' : 'Delete'}
                                                                        </li>}
                                                                    </ul>
                                                                </div> :
                                                                <div className='pt-3' style={{ minHeight: '50px' }}>
                                                                    <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!leavePolicyList.length && <div className="text-center">No record found</div>}
                                    <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <Modal
                size="md"
                show={deleteModal.open}
                onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
                headerContent={<h5 className="py-2">{deleteModal.data.isDeleted ? 'Recover' : 'Delete'} Leave Policy?</h5>}
                bodyContent={
                    <div className="">
                        <p>Are you sure you want to {deleteModal.data.isDeleted ? 'recover' : 'delete'} Leave Policy? </p>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="contained" onClick={handleDelete}>
                                Yes
                            </Button>
                            <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                                No
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default LeavePolicyList
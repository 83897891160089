import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import InfiniteScroll from "react-infinite-scroll-component";
import UseDateTimeFormat from '../../hooks/useDateTimeFormat';

const TimeLogTimeLine = ({ data, methods }) => {
    const { getFormattedDateTime } = UseDateTimeFormat()
    // const { data } = useOutletContext();
    const { timeLineListData } = data;
    const { fetchMoreData } = methods

    const TimeLine = () => {
        return (
            timeLineListData?.map((item, index) =>
                <TimelineItem key={index}>
                    <TimelineOppositeContent color="text.secondary">
                        {getFormattedDateTime(item?.createdAt, 'calendar')}
                        <div>
                            By : {item?.createdby_id?.firstName ? `${item?.createdby_id?.firstName} ${item?.createdby_id?.lastName}` : "--"}
                        </div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{item?.title}
                        <div>
                            {Object.keys(item.data).map((key) => {
                                let newKey = key.split('_').join(' ').toUpperCase()
                                return <div key={key}>
                                    <strong>{newKey} : </strong>{" "}<span className="text-break" dangerouslySetInnerHTML={{ __html: item.data[key] }} />
                                </div>
                            })}
                        </div>
                    </TimelineContent>
                </TimelineItem>
            ))
    }

    return (
        <div>
            <InfiniteScroll
                dataLength={timeLineListData?.length}
                next={fetchMoreData}
                hasMore={true}
                scrollableTarget="scrollableDiv"
            >
                {timeLineListData?.length > 0 ? <Timeline position="alternate">
                    {TimeLine()}
                </Timeline> :
                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                        <span>No record found</span>
                    </div>
                }
            </InfiniteScroll>
        </div>
    );
};

export default TimeLogTimeLine;

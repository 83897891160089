import React from 'react'
import { useNavigate } from 'react-router-dom'
const ErrorPage = () => {

    const navigate = useNavigate()
    return (
        <>
            <div class="container-fluid" style={{ height: "100vh", background: "#fff" }}>
                <div class="d-flex align-items-center justify-content-center h-100">
                    <div class="col-lg-4 d-flex align-items-center ">
                        <div>
                            <div class="col-lg-12">
                                <h1 style={{ color: "#2b124c", fontSize: "40px" }}>
                                    Page not found !..
                                </h1>
                            </div>
                            <div class="mt-5 d-flex">
                                <button class="btn btn-primary" onClick={() => navigate(-1)}>Go Back</button>
                                <button class="btn btn-primary ms-4" onClick={() => navigate("/")}>Go Back Home</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4  d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                        <img src={require("../../assets/images/dribbble.gif")} class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage
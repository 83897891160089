import React from 'react'
import Button from 'react-bootstrap/Button'
import AddIcon from '@mui/icons-material/Add'
import Table from '../../../components/Table/Table'

const StatusList = () => {
  // const [openmodal, setmodalOpen] = React.useState(false)
  // const handlemodalOpen = () => setmodalOpen(true)
  // const handlemodalClose = () => setmodalOpen(false)
  const TableHeadd = [
    { label: 'Name', id: '' },
    { label: 'Type', id: '' },
    { label: 'State', id: '' },
  ]
  const TableRoww = [
    {
      name: '',
      type: '',
      state: '',
    },
    {
      name: '',
      type: '',
      state: '',
    },
    {
      name: '',
      type: '',
      state: '',
    },
  ]

  return (
    <>
      <div className="row m-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {/* <div>
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            className="me-2 w-100"
                            aria-label="Search"
                          />
                        </div>
                        <div>
                          <Button variant="primary4" className="px-4 ms-3">
                            <SearchIcon />
                          </Button>
                        </div> */}
          </div>
          <div className="d-flex">
            {/* <div>
                  <Button variant="primary1">
                    <ExitToAppIcon /> Export
                  </Button>{" "}
                </div> */}
            <div className="ms-2">
              <Button className="dropDownButtons11 ms-4">
                <AddIcon /> Add New
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-3">
        <div className="col-lg-12 ticet">
          <div className="bg shadow raduus pt-2">
            <div className="">
              <div className="mt-2">
                <table className="table">
                  <Table TableHeadData={TableHeadd} />
                  <tbody>
                    {TableRoww?.map((item) => {
                      return (
                        <tr
                          className="text-start rounded-row "
                          style={{
                            borderRadius: '50px',
                            backgroundColor: '#fff',
                          }}
                        >
                          <td className="table-link">{item.name}</td>
                          <td className="">{item.type}</td>
                          <td>{item.state}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusList

import React from "react";
import { Button, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const top100Films = [
  { title: "The Godfather", year: 1972 },
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Shawshank Redemption", year: 1994 },
];

const NewSupportPhone = () => {
  return (
    <>
         <div className="contact">
    <div className="container bg">
      <div className="row">
        <div className="row my-3">
         
        <div className="col-lg-11 mt-4">
            <Autocomplete
              multiple
              id="Created-tags-demo"
              className="w-100"
              options={top100Films}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: 255 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose agents"
                  placeholder="Favorites"
                  variant="standard"
                  size="small"
                />
              )}
            />
          </div>
          {/* <div className="col-lg-4 mt-4">
            <TextField
              id="standard-multiline-flexible"
              label="Your support email"
              // multiline
              className="w-75"
              maxRows={4}
              variant="standard"
              defaultValue=""
            />
          </div>
          <div className="col-lg-4 mt-4">
            <TextField
              id="standard-select-currency"
              select
              label="Assign to Group"
              defaultValue=""
              className="w-75"
              variant="standard"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
          {/* <div className="col-lg-11 mt-5">
            <TextField
              id="standard-select-currency"
              select
              label="Link this support email with a product"
              defaultValue=""
              className="w-100"
              variant="standard"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
        </div>
        {/* <div className="row my-3">
          <div className="col-lg-11 mt-4">
            <TextField
              id="standard-multiline-flexible"
              label="Description"
              // multiline
              className="w-100"
              maxRows={4}
              variant="standard"
              defaultValue=""
            />
          </div>
        </div> */}
    
        <div className="col-lg-11 mb-5">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="mt-4">
                {/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}
              </div>
              <div className="row">
                <div className="col-lg-4 mt-4 text-center">
                  <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                </div>
                <div className="col-lg-8 mt-4 text-center">
                  <Button className="px-4 add-cc-btn mt-3">Save Changes</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default NewSupportPhone

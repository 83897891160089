import { useEffect, useState } from "react"
import { getUserBreaks } from "../../../../repository/leavePolicyRepo"
import { userFullList } from "../../../../repository/teamManagementRepo"
import { getShiftList, useToGetShiftList } from "../../../../repository/shiftRepo"
import moment from "moment"



const UseBreakReport = () => {
    const [breaksData, setBreaksData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [agentList, setAgentList] = useState([])
    // const [shiftList, setshiftList] = useState([])
    const [isReset, setIsReset] = useState(false);
    const [filterDate, setFilterDate] = useState('')
    const [shiftList, setShiftList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
    const [totalCount, setTotalCount] = useState(0)

    const handlePagination = (obj) => {
        setController(obj)
    }
    // const cacheTime = { cacheTime: 10000 }
    // const {
    //     data: shiftList,
    //     error: shiftError,
    //     isFetching: shiftIsFetching,
    //     refetch,
    // } = useToGetShiftList({}, {
    //     enabled: false,
    //     ...cacheTime,
    // })

    useEffect(() => {
        // refetch()
        getAgentList()
        shift()


    }, [])
    // console.log(shiftList?.data?.[0]?._id,'shiftList')
    const [filterInput, setFilterInput] = useState({ agent: [], date: moment().subtract(1, 'days').startOf('day'), shift: '' })
    // console.log(filterInput, 'filterInput')

    useEffect(() => {
        getBreakList()
    }, [isReset, controller.page, controller.rowsPerPage])


    function convertToMidnight(dateString) {
        let date = moment(dateString).utc();
        date.add(1, 'days').startOf('day');
        return date.toISOString();
    }

    // const originalDate = "2024-07-22T18:30:00.000Z";
    const convertedDate = convertToMidnight(filterInput?.date);


    const getBreakList = async (id) => {
        try {
            const payLoad = {
                userId: filterInput?.agent?.length ? filterInput?.agent?.map((item) => item?._id) : [],
                reportDate: convertedDate,
                shiftId: id ? id : filterInput?.shift[0]?._id,
                count: controller.rowsPerPage,
                page: controller.page,
            }
            setSpinner(true)
            let response = await getUserBreaks(payLoad)
            setBreaksData(response.data)
            setFilterDate(filterInput?.date)
            setTotalCount(response.totalCount)
        } catch (error) {
            setBreaksData([])
        }
        setSpinner(false)
    }
    const shift = async () => {

        try {
            let response = await getShiftList({})
            setShiftList(response)
            getBreakList(response.data?.[1]?._id)
            setFilterInput((pre) => ({ ...pre, shift: [response.data?.[1]] }))
        } catch (error) {
            setShiftList([])
        }
    }
    const resetFilter = () => {
        setFilterInput({ agent: '', date: '', shift: '' })
        setIsReset(true)

    }
    const getAgentList = async (groupId) => {
        // const teamIds = groupId?.map(item => item._id);
        // let payload = {
        //     team_id: teamIds,
        // };
        try {
            setSpinner(true)
            let response = await userFullList({})
            setAgentList(response.data)

            //   response?.data?.forEach((item) => {
            //     if (item?._id === user?.userInfo?._id) {
            //       setTaskListInput((prevProps) => ({ ...prevProps, agent: [item] }));
            //     }
            //   });
        } catch (error) {
            setAgentList([])
            //   setTaskListInput((prevProps) => ({ ...prevProps, agent: '' }))
        }
        setSpinner(false)
    }

    return {
        data: {
            spinner,
            breaksData,
            controller,
            agentList,
            filterInput,
            shiftList,
            filterDate,
            totalCount
        },
        methods: {
            handlePagination,
            setFilterInput,
            resetFilter,
            getBreakList
        }
    }
}

export default UseBreakReport

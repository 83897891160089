import React from 'react'
import { Button } from '@mui/material'
import Modal from '../../../components/Modal'
import TextField from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import UseEarnLeave from './Hooks/useEarnLeave'
import { Spinner } from '../../../components/Spinner/Spinner'
import { ToastContainer } from 'react-toastify'
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EarnLeave = () => {
    const { methods, data } = UseEarnLeave()
    const {
        handleInputChange,
        clearForm,
        handleSubmit,
        setEarnLeaveModal,
        setEarnLeaveInputs,
        getDetails
    } = methods
    const {
        shiftList,
        earnLeaveInputs,
        earnLeaveModal,
        error,
        spinner,
        listData,
    } = data
    return (
        <>
            {spinner && <Spinner />}
            {<ToastContainer />}
            <div className="container-fluid py-3">
                <div className='row'>
                    <div className="d-flex justify-content-end">
                    </div>
                    <div className="col-lg-12 ticet">
                        {
                            listData?.map((item, index) =>
                                <div className='mt-4' key={index}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            {item?.shift}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='d-flex justify-content-between'>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Name</h6>
                                                    {item?.data?.name ? item?.data?.name : '--'}
                                                </div>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Code</h6>
                                                    {item?.data?.code ? item?.data?.code : '--'}
                                                </div>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Working Days</h6>
                                                    {item?.data?.days ? item?.data?.days : '--'}
                                                </div>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Service Period</h6>
                                                    {item?.data?.servicePeriod ? item?.data?.servicePeriod : '--'}
                                                </div>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Credit Earn Leave</h6>
                                                    {item?.data?.creditEL ? item?.data?.creditEL : '--'}
                                                </div>
                                                <div className='text-center'>
                                                    <h6 className='fw-bold'>Max Carry Forward</h6>
                                                    {item?.data?.maxCarryForward ? item?.data?.maxCarryForward : '--'}
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                        <AccordionActions>
                                            <Button onClick={() => { setEarnLeaveModal((pre) => ({ ...pre, open: true, data: item }));; clearForm(); getDetails(item); }}>
                                                Edit
                                            </Button>
                                        </AccordionActions>
                                    </Accordion>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Modal
                    size="md"
                    show={earnLeaveModal.open}
                    onHide={() => setEarnLeaveModal((prev) => ({ ...prev, open: false }))}
                    headerContent={<h5 className="py-2">Edit Earn Leave</h5>}
                    bodyContent={
                        <div className="row p-3">
                            <div className="col-lg-12">
                                <TextField
                                    Lable="Working Days"
                                    Name="workingDays"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={handleInputChange}
                                    Value={earnLeaveInputs.workingDays}
                                    width={100}
                                    type='number'
                                    Error={error.workingDays}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <TextField
                                    Lable="Service Period"
                                    Name="servicePeriod"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={handleInputChange}
                                    Value={earnLeaveInputs.servicePeriod}
                                    width={100}
                                    type='number'
                                    Error={error.servicePeriod}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <TextField
                                    Lable="Credit Earn Leave"
                                    Name="creditEarnLeave"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={handleInputChange}
                                    Value={earnLeaveInputs.creditEarnLeave}
                                    width={100}
                                    type='number'
                                    Error={error.creditEarnLeave}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <TextField
                                    Lable="Max Carry Forward"
                                    Name="maxCarryForward"
                                    variant="outlined"
                                    ClassName="form-field-design "
                                    Require={true}
                                    HandleInputChange={handleInputChange}
                                    Value={earnLeaveInputs.maxCarryForward}
                                    width={100}
                                    type='number'
                                    Error={error.maxCarryForward}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                <SelectInput
                                    Lable={'Shift'}
                                    disabled={true}
                                    Options={shiftList}
                                    keyName={'name'}
                                    className="form-field-design"
                                    Name="shift"
                                    SelectValue={earnLeaveInputs.shift}
                                    HandleChange={(e) => { handleInputChange(e) }}
                                    Error={error.shift}
                                    variant={'outlined'}
                                    isRequired={true}
                                />
                            </div>
                            <div className="col-lg-12 my-2">
                                Active/In-Active
                                <Switch checked={earnLeaveInputs?.isActive} onChange={(e) => setEarnLeaveInputs((pre) => ({ ...pre, isActive: e.target.checked }))} />
                            </div>


                            <div className='d-flex justify-content-end mt-5'>
                                <Button variant="outlined" onClick={() => { setEarnLeaveModal((prev) => ({ ...prev, open: false })) }}>Cancel</Button>
                                {
                                    earnLeaveModal?.data?._id ?
                                        <Button variant="contained" className='ms-2' onClick={handleSubmit}>Update</Button>
                                        :
                                        <Button variant="contained" className='ms-2' onClick={handleSubmit}>Submit</Button>
                                }
                            </div>

                        </div>
                    }
                />
            </div>
        </>
    )
}

export default EarnLeave

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { CKEditorCustomToolbar } from '../../constants'
import TextField from '../../components/TextField'
import { addRole, getRoleDetails, updateRole } from '../../repository/auth'
import { Spinner } from '../../components/Spinner/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import { requiredMessage, scrollIntoViewHelper } from '../../utils'
import { useNavigate, useLocation } from 'react-router-dom'
import useReduxStore from '../../hooks/useReduxStore'

const NewRoleForm = () => {
  const { selector: userPermission } = useReduxStore('auth')
  const { add, edit } = userPermission?.userInfo?.permission?.rolesAndPermission
  const navigate = useNavigate()
  const { state } = useLocation()
  // const [permissions, setPermissions] = useState(initialPermissions)
  const [permissionInputs, setPermissionInputs] = useState({
    roleTitle: '',
    description: '',
  })
  const [error, setError] = useState({})
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    if (state) {
      getPermissions()
    }
  }, [])

  // const handlePermissionChange = (category, key) => {
  //   setPermissions((prevPermissions) => ({
  //     ...prevPermissions,
  //     [category]: {
  //       ...prevPermissions[category],
  //       [key]: {
  //         ...prevPermissions[category][key],
  //         value: !prevPermissions[category][key].value,
  //       },
  //     },
  //   }))
  // }

  // const handlePermissionChange = (category, key) => {
  //   setPermissions((prevPermissions) => {
  //     const updatedPermissions = { ...prevPermissions };

  //     if (key === 'viewSelf') {
  //       updatedPermissions[category] = {
  //         ...updatedPermissions[category],
  //         viewSelf: {
  //           ...updatedPermissions[category].viewSelf,
  //           value: true,
  //         },
  //         viewAll: {
  //           ...updatedPermissions[category].viewAll,
  //           value: false,
  //         },
  //       };
  //     } else if (key === 'viewAll') {
  //       updatedPermissions[category] = {
  //         ...updatedPermissions[category],
  //         viewSelf: {
  //           ...updatedPermissions[category].viewSelf,
  //           value: false,
  //         },
  //         viewAll: {
  //           ...updatedPermissions[category].viewAll,
  //           value: true,
  //         },
  //       };
  //     } else {
  //       updatedPermissions[category] = {
  //         ...updatedPermissions[category],
  //         [key]: {
  //           ...updatedPermissions[category][key],
  //           value: !prevPermissions[category][key].value,
  //         },
  //       };
  //     }

  //     return updatedPermissions;
  //   });
  // };


  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setPermissionInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length &&
      setError((pre) => {
        delete pre[name]
        return { ...pre }
      })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    roleTitle: requiredMessage('role title'),
    description: requiredMessage('description'),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!permissionInputs.roleTitle) {
      errorObj['roleTitle'] = errorMessage['roleTitle']
    }
    if (!permissionInputs.description?.trim()?.length) {
      errorObj['description'] = errorMessage['description']
    }
    scrollIntoViewHelper(error)
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Add New Role
  const addNewRole = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    // let newObj = {};
    // for (let category in permissions) {
    //   newObj[category] = {};
    //   for (let action in permissions[category]) {
    //     newObj[category][action] = {
    //       value: permissions[category][action].value
    //     };
    //   }
    // }
    setSpinner(true)
    let payload = {
      roleTitle: permissionInputs.roleTitle,
      description: permissionInputs.description,
      // permission: permissions,
    }
    // payload.permission.task["add"] = { title: 'Add task', value: false }
    // payload.permission.task["approval"] = { title: 'Approve task', value: false }

    try {
      let response = await addRole(payload)
      navigate('/roles-&-permissions')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) { }
    setSpinner(false)
  }

  // Get user Role Permissions details
  const getPermissions = async () => {
    setSpinner(true)
    try {
      let response = await getRoleDetails(state?.userId)
      // setPermissions(response?.data?.permission)
      setPermissionInputs({
        roleTitle: response?.data?.roleTitle,
        description: response?.data?.description,
      })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Update User Role And Permission
  const updatePermissions = async (event) => {
    event.preventDefault()
    if (!isValid()) return
    // let newObj = {};
    // for (let category in permissions) {
    //   newObj[category] = {};
    //   for (let action in permissions[category]) {
    //     newObj[category][action] = {
    //       value: permissions[category][action].value
    //     };
    //   }
    // }
    setSpinner(true)
    let payload = {
      _id: state.userId,
      roleTitle: permissionInputs.roleTitle,
      description: permissionInputs.description,
      // permission: permissions,
    }
    try {
      let response = await updateRole(payload)
      navigate('/roles-&-permissions')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // function capitalizeFirstLetter(str) {
  //   return str.charAt(0).toUpperCase() + str.slice(1)
  // }

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              <div className="text-center pt-3">
                <h4>{state?.userId ? 'Update Role' : 'Add New Role'}</h4>
              </div>
              {/* <div className="row mt-4"> */}
              <div className="col-lg-12 mt-4">
                <TextField
                  Lable="Role Title"
                  Name="roleTitle"
                  variant="outlined"
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={permissionInputs.roleTitle}
                  width={100}
                  Error={error.roleTitle}
                />
              </div>
              {/* </div> */}

              <div className="col-lg-12  pt-2">
                <div className="App mt-2">
                  <p className="m-0 mb-3">
                    Description <span className="text-secondery">*</span>
                  </p>
                  <CKEditor
                    editor={ClassicEditor}
                    data={permissionInputs.description} // Set CKEditor data to teamInputs.description
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      // Update teamInputs.description when CKEditor content changes
                      setPermissionInputs((prevProps) => ({ ...prevProps, description: data }))
                      setError((prev) => ({ ...prev, description: '' }))
                    }}
                    onBlur={(event, editor) => {
                      !permissionInputs.description && setError((prev) => ({ ...prev, description: 'Please enter description' }))
                    }}
                  />
                  {error.description && <p className="text-danger">{error.description}</p>}
                </div>
              </div>

              {/* <div className="col-lg-12 row mt-4">
                {Object.keys(permissions)?.map((category) => {
                  return (
                    <span className={'col-3 mt-2'} key={category}>
                      <h5>{category === 'rolesAndPermission' ? 'Roles And Permission' : capitalizeFirstLetter(category)}</h5>
                      <FormGroup>
                        {Object.keys(permissions[category]).map((key) => (
                          <>
                            <FormControlLabel
                              key={key}
                              control={<Checkbox checked={permissions[category][key].value} onChange={() => handlePermissionChange(category, key)} />}
                              label={permissions[category][key].title}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </span>
                  )
                }
                )}
              </div> */}

              <div className="col-lg-12 my-2 pe-4">
                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    <Button variant="outlined" onClick={() => navigate('/roles-&-permissions')}>
                      Cancel
                    </Button>
                  </div>
                  {state?.userId ? (
                    <div className="m-2">
                      {edit?.value && <Button variant="contained" onClick={updatePermissions}>
                        Update
                      </Button>}
                    </div>
                  ) : (
                    <div className="m-2">
                      {add?.value && <Button variant="contained" onClick={addNewRole}>
                        Create
                      </Button>}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewRoleForm

import React from 'react'
import TextInput from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear'
import { RelationList } from '../../../constants';
const PersonalDetails = ({ data, methods }) => {
    const { error, teamMemberInputs, personalDoc, countryOrState } = data
    const { handleInputChange, handleBlur, addMultiplePreviousExperience, removePreviousExperience, handleFileChange, getAllStateList, setTeamMemberInputs,handleRemoveFile } = methods

    const sameAddress = (type) => {
        if (type) {
            setTeamMemberInputs((pre) => ({
                ...pre,
                currAddress1: teamMemberInputs?.perAddress1,
                currAddress2: teamMemberInputs?.perAddress2,
                currCountry: teamMemberInputs?.perCountry,
                currState: teamMemberInputs?.perState,
                currCity: teamMemberInputs?.perCity,
                currPincode: teamMemberInputs?.perPincode,
            }))
        }
        else {
            setTeamMemberInputs((pre) => ({
                ...pre,
                currAddress1: '',
                currAddress2: '',
                currCountry: '',
                currState: '',
                currCity: '',
                currPincode: '',
            }))

        }
    }
    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h3>Document</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.document?.map((item, index) =>
                        <>
                            <div className="col-lg-6 mt-4">
                                <TextInput
                                    Lable="Document"
                                    Name="docType"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    // Require={true}
                                    HandleInputChange={(e) => handleInputChange(e, index, 'document')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.document?.[index]?.docType}
                                    width={100}
                                    Error={error?.document?.[index]?.docType}
                                />
                            </div>
                            <div className="col-lg-6 mt-4">
                                <TextInput
                                    Lable="Document Number"
                                    Name="number"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'document')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.document?.[index]?.number}
                                    width={100}
                                    Error={error?.document?.[index]?.number}
                                    // Require={true}

                                />
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor={`inputTag-eduQual-${index}`}>
                                    <input id={`inputTag-eduQual-${index}`} type="file" multiple style={{ display: 'none' }} onChange={(event) => handleFileChange(event, index, 'document')} />
                                    <span id="imageName"></span>
                                    <h6 className='navi_clr pointer mt-3' style={{ color: '#297eb3' }}>+Attach a file</h6>
                                </label>
                            </div>
                            <div className='col-lg-12 Ticket_Attachment'>
                                <div className="row">
                                    {item.file?.map((cur, fileIndex) => {
                                        const filetype = cur?.split('.').pop();
                                        if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <img src={`${personalDoc}${cur}`} alt="img" onClick={() => window.open(`${personalDoc}${cur}`)}/>
                                                    <span onClick={() => handleRemoveFile(index, fileIndex, 'document')}>X</span>
                                                </div>
                                            );
                                        }
                                        if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <video src={`${personalDoc}${cur}`} controls onClick={() => window.open(`${personalDoc}${cur}`)}> </video>
                                                    <span onClick={() => handleRemoveFile(index, fileIndex, 'document')}>X</span>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                                                <span onClick={() => handleRemoveFile(index, fileIndex, 'document')}>X</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {
                                index === 0 ? null :
                                    <div className='col-lg-12 d-flex justify-content-end mt-4'>
                                        <Button variant="outlined" color='error' onClick={() => removePreviousExperience(index, 'document')}>
                                            <ClearIcon />
                                        </Button>
                                    </div>
                            }
                            <hr className='mt-5' />
                        </>)
                }

            </div>
            <div className='d-flex justify-content-end mt-3'>
                <Button variant="outlined" onClick={() => addMultiplePreviousExperience('document')}>
                    <AddIcon />
                </Button>
            </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h3>Permanent Address</h3>
                    <hr />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Address Line 1"
                        Name="perAddress1"
                        variant="outlined"
                        ClassName="form-field-design"
                        // Require={true}
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.perAddress1}
                        width={100}
                        Error={error?.perAddress1}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Address Line 2"
                        Name="perAddress2"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.perAddress2}
                        width={100}
                        // Require={true}
                        Error={error?.perAddress2}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Country'}
                        width={'310px'}
                        Options={countryOrState?.country}
                        keyName={'name'}
                        className="form-field-design"
                        Name="perCountry"
                        SelectValue={teamMemberInputs?.perCountry}
                        HandleChange={(e) => { handleInputChange(e); getAllStateList(e.target.value) }}
                        onBlur={handleBlur}
                        Error={error?.perCountry}
                        variant={'outlined'}
                        // isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'State'}
                        Options={countryOrState?.state}
                        keyName={'roleTitle'}
                        className="form-field-design"
                        Name="perState"
                        SelectValue={teamMemberInputs?.perState}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.perState}
                        variant={'outlined'}
                        // isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="City"
                        Name="perCity"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.perCity}
                        width={100}
                        Error={error?.perCity}
                        // Require={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Pin Code"
                        Name="perPincode"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.perPincode}
                        width={100}
                        Error={error?.perPincode}
                        // Require={true}
                    />
                </div>

            </div>

            <div className="row mt-5">

                <div className="col-lg-12">
                    <h3>Current Address</h3>
                    <hr />
                    <div className='d-flex align-items-center'>
                        <Checkbox onChange={(e) => { setTeamMemberInputs((pre) => ({ ...pre, sameAddress: e.target.checked })); sameAddress(e.target.checked) }} />
                        <h5 className='mt-2'>Same as Permanent Address</h5>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Address Line 1"
                        Name="currAddress1"
                        variant="outlined"
                        ClassName="form-field-design"
                        // Require={true}
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.currAddress1}
                        width={100}
                        Error={error?.currAddress1}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Address Line 1"
                        Name="currAddress2"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.currAddress2}
                        width={100}
                        // Require={true}
                        Error={error?.currAddress2}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'Country'}
                        width={'310px'}
                        Options={countryOrState?.country}
                        keyName={'name'}
                        className="form-field-design"
                        Name="currCountry"
                        SelectValue={teamMemberInputs?.currCountry}
                        HandleChange={(e) => { handleInputChange(e); getAllStateList(e.target.value) }}
                        onBlur={handleBlur}
                        Error={error?.currCountry}
                        variant={'outlined'}
                        // isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <SelectInput
                        Lable={'State'}
                        Options={countryOrState?.state}
                        keyName={'roleTitle'}
                        className="form-field-design"
                        Name="currState"
                        SelectValue={teamMemberInputs?.currState}
                        HandleChange={handleInputChange}
                        onBlur={handleBlur}
                        Error={error?.currState}
                        variant={'outlined'}
                        // isRequired={true}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="City"
                        Name="currCity"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.currCity}
                        width={100}
                        // Require={true}
                        Error={error?.currCity}
                    />
                </div>
                <div className="col-lg-4 mt-4">
                    <TextInput
                        Lable="Pin Code"
                        Name="currPincode"
                        variant="outlined"
                        ClassName="form-field-design"
                        HandleInputChange={handleInputChange}
                        onBlur={handleBlur}
                        Value={teamMemberInputs?.currPincode}
                        width={100}
                        // Require={true}
                        Error={error?.currPincode}
                    />
                </div>

            </div>

            <div className="row mt-5 mb-5">
                <div className="col-lg-12">
                    <h3>Family Details</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.familyDetails?.map((item, index) =>
                        <>
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Name"
                                    Name="name"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    // Require={true}
                                    HandleInputChange={(e) => handleInputChange(e, index, 'familyDetails')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.familyDetails?.[index]?.name}
                                    width={100}
                                    Error={error?.familyDetails?.[index]?.name}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <SelectInput
                                    Lable={'Relation'}
                                    width={'310px'}
                                    Options={RelationList}
                                    keyName={'title'}
                                    className="form-field-design"
                                    Name="relation"
                                    SelectValue={teamMemberInputs?.familyDetails?.[index]?.relation}
                                    HandleChange={(e) => { handleInputChange(e, index, 'familyDetails')}}
                                    onBlur={handleBlur}
                                    Error={error?.familyDetails?.[index]?.relation}
                                    variant={'outlined'}
                                    // isRequired={true}
                                />
                            </div>
                            {/* <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Relation"
                                    Name="relation"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'familyDetails')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.familyDetails?.[index]?.relation}
                                    width={100}
                                    Error={error?.familyDetails?.[index]?.relation}
                                    Require={true}
                                />
                            </div> */}
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Contact Number"
                                    Name="contactNo"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'familyDetails')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.familyDetails?.[index]?.contactNo}
                                    width={100}
                                    Error={error?.familyDetails?.[index]?.contactNo}
                                    // Require={true}
                                    max={10}
                                />
                            </div>

                            {
                                index === 0 ? null :
                                    <div className='col-lg-12 d-flex justify-content-end mt-4'>
                                        <Button variant="outlined" color='error' onClick={() => removePreviousExperience(index, 'familyDetails')}>
                                            <ClearIcon />
                                        </Button>
                                    </div>
                            }
                            <hr className='mt-5' />
                        </>
                    )
                }
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <Button variant="outlined" onClick={() => addMultiplePreviousExperience('familyDetails')}>
                    <AddIcon />
                </Button>
            </div>
        </>
    )
}

export default PersonalDetails

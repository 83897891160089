import React from 'react'
import TextInput from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import DateInput from '../../../components/DateInput'
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear'

const OtherDetails = ({ data, methods }) => {
    const { error, teamMemberInputs, personalDoc } = data
    const { handleInputChange, handleBlur, addMultiplePreviousExperience, removePreviousExperience, handleDateChange, handleFileChange, handleRemoveFile } = methods

    return (
        <>

            <div className="row mt-4">
                <div className="col-lg-12">
                    <h3>Previous Experience</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.previousExperience?.map((item, index) => (
                        <React.Fragment key={`previousExperience-${index}`}>
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Company"
                                    Name="company"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    // Require={true}
                                    HandleInputChange={(e) => handleInputChange(e, index, 'previousExperience')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.previousExperience?.[index]?.company}
                                    width={100}
                                    Error={error?.previousExperience?.[index]?.company}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Designation"
                                    Name="designation"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'previousExperience')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.previousExperience?.[index]?.designation}
                                    width={100}
                                    // Require={true}
                                    Error={error?.previousExperience?.[index]?.designation}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <DateInput
                                    className="w-100 form-field-design"
                                    // isRequired={true}
                                    Label={'Start Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'startDate', index, 'previousExperience')}
                                    SelectedDate={teamMemberInputs?.previousExperience?.[index]?.startDate ? new Date(teamMemberInputs?.previousExperience?.[index]?.startDate) : null}
                                />
                                {error?.previousExperience?.[index]?.startDate && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.previousExperience?.[index]?.startDate}</span>}
                            </div>
                            <div className="col-lg-4 mt-4">
                                <DateInput
                                    className="w-100 form-field-design"
                                    // isRequired={true}
                                    Label={'End Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'endDate', index, 'previousExperience')}
                                    SelectedDate={teamMemberInputs?.previousExperience?.[index]?.endDate ? new Date(teamMemberInputs?.previousExperience?.[index]?.endDate) : null}
                                />
                                {error?.previousExperience?.[index]?.endDate && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.previousExperience?.[index]?.endDate}</span>}
                            </div>
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Duration"
                                    Name="duration"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'previousExperience')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.previousExperience?.[index]?.duration}
                                    width={100}
                                    // Require={true}
                                    Error={error?.previousExperience?.[index]?.duration}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <TextInput
                                    Lable="Reason To Resign"
                                    Name="reasonToResign"
                                    variant="outlined"
                                    ClassName="form-field-design"
                                    HandleInputChange={(e) => handleInputChange(e, index, 'previousExperience')}
                                    onBlur={handleBlur}
                                    Value={teamMemberInputs?.previousExperience?.[index]?.reasonToResign}
                                    width={100}
                                    // Require={true}
                                    Error={error?.previousExperience?.[index]?.reasonToResign}
                                />
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor={`inputTag-prevExp-${index}`}>
                                    <input id={`inputTag-prevExp-${index}`} type="file" multiple style={{ display: 'none' }} onChange={(event) => handleFileChange(event, index, 'previousExperience')} />
                                    <span id="imageName"></span>
                                    <h6 className='navi_clr pointer mt-3' style={{ color: '#297eb3' }}>+Attach a file</h6>
                                </label>
                            </div>
                            <div className='col-lg-12 Ticket_Attachment'>
                                <div className="row">
                                    {item.file?.map((cur, fileIndex) => {
                                        const filetype = cur?.split('.').pop();
                                        if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <img className='pointer' src={`${personalDoc}${cur}`} alt="" onClick={() => window.open(`${personalDoc}${cur}`)} />
                                                    <span onClick={() => handleRemoveFile(index, fileIndex, 'previousExperience')}>X</span>
                                                </div>
                                            );
                                        }
                                        if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <video className='pointer' src={`${personalDoc}${cur}`} controls onClick={() => window.open(`${personalDoc}${cur}`)}> </video>
                                                    <span onClick={() => handleRemoveFile(index, fileIndex, 'previousExperience')}>X</span>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                                                <span onClick={() => handleRemoveFile(index, fileIndex, 'previousExperience')}>X</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {
                                index === 0 ? null :
                                    <div className='col-lg-12 d-flex justify-content-end mt-2'>
                                        <Button variant="outlined" color='error' onClick={() => removePreviousExperience(index, 'previousExperience')}>
                                            <ClearIcon />
                                        </Button>
                                    </div>
                            }
                            <hr className='mt-5' />
                        </React.Fragment>
                    ))
                }
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <Button variant="outlined" onClick={() => addMultiplePreviousExperience('previousExperience')}>
                    <AddIcon />
                </Button>
            </div>

            <div className="row mt-5">
                <div className="col-lg-12">
                    <h3>Education Qualification</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.educationQualification?.map((item, index) => (
                        <React.Fragment key={`educationQualification-${index}`}>
                            <div className='row'>
                                <div className="col-lg-4 mt-4">
                                    <TextInput
                                        Lable="Course"
                                        Name="course"
                                        variant="outlined"
                                        ClassName="form-field-design"
                                        // Require={true}
                                        HandleInputChange={(e) => handleInputChange(e, index, 'educationQualification')}
                                        onBlur={handleBlur}
                                        Value={teamMemberInputs?.educationQualification?.[index]?.course}
                                        width={100}
                                        Error={error?.educationQualification?.[index]?.course}
                                    />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <TextInput
                                        Lable="Specialization"
                                        Name="specialization"
                                        variant="outlined"
                                        ClassName="form-field-design"
                                        HandleInputChange={(e) => handleInputChange(e, index, 'educationQualification')}
                                        onBlur={handleBlur}
                                        Value={teamMemberInputs?.educationQualification?.[index]?.specialization}
                                        width={100}
                                        // Require={true}
                                        Error={error?.educationQualification?.[index]?.specialization}
                                    />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <TextInput
                                        Lable="School/University"
                                        Name="school_University"
                                        variant="outlined"
                                        ClassName="form-field-design"
                                        HandleInputChange={(e) => handleInputChange(e, index, 'educationQualification')}
                                        onBlur={handleBlur}
                                        Value={teamMemberInputs?.educationQualification?.[index]?.school_University}
                                        width={100}
                                        Error={error?.educationQualification?.[index]?.school_University}
                                    // Require={true}
                                    />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <DateInput
                                        className="w-100 form-field-design"
                                        // isRequired={true}
                                        Label={'Year'}
                                        handleDateChange={(date) => handleDateChange(date, 'year', index, 'educationQualification')}
                                        SelectedDate={teamMemberInputs?.educationQualification?.[index]?.year?new Date(teamMemberInputs?.educationQualification?.[index]?.year):null}
                                    />
                                    {error?.educationQualification?.[index]?.year && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.educationQualification?.[index]?.year}</span>}
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor={`inputTag-eduQual-${index}`}>
                                        <input id={`inputTag-eduQual-${index}`} type="file" multiple style={{ display: 'none' }} onChange={(event) => handleFileChange(event, index, 'educationQualification')} />
                                        <span id="imageName"></span>
                                        <h6 className='navi_clr pointer mt-3' style={{ color: '#297eb3' }}>+Attach a file</h6>
                                    </label>
                                </div>
                                <div className='col-lg-12 Ticket_Attachment'>
                                    <div className="row">
                                        {item.file?.map((cur, fileIndex) => {
                                            const filetype = cur?.split('.').pop();
                                            if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                                return (
                                                    <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                        <img className='pointer' src={`${personalDoc}${cur}`} alt="" onClick={() => window.open(`${personalDoc}${cur}`)} />
                                                        <span onClick={() => handleRemoveFile(index, fileIndex, 'educationQualification')}>X</span>
                                                    </div>
                                                );
                                            }
                                            if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                                return (
                                                    <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                        <video className='pointer' src={`${personalDoc}${cur}`} controls onClick={() => window.open(`${personalDoc}${cur}`)}> </video>
                                                        <span onClick={() => handleRemoveFile(index, fileIndex, 'educationQualification')}>X</span>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                                                    <span onClick={() => handleRemoveFile(index, fileIndex, 'educationQualification')}>X</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    {index === 0 ? null : (
                                        <div className='col-lg-12 d-flex justify-content-end mt-2'>
                                            <Button variant="outlined" color='error' onClick={() => removePreviousExperience(index, 'educationQualification')}>
                                                <ClearIcon />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className='mt-5' />
                        </React.Fragment>
                    ))
                }
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <Button variant="outlined" onClick={() => addMultiplePreviousExperience('educationQualification')}>
                    <AddIcon />
                </Button>
            </div>


        </>
    )
}

export default OtherDetails

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { requiredMessage, requiredSelect } from "../../../../utils"
import { toast } from "react-toastify"
import useReduxStore from "../../../../hooks/useReduxStore"
import { ADD_ANNOUNCEMENT, LIST_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from "../../../../repository/announcementRepo"
import { getShiftList } from "../../../../repository/shiftRepo"


const UseAnnouncement = () => {
    const [announcementModal, setAnnouncementModal] = useState({ data: {}, open: false })
    const [announcementInputs, setAnnouncementInputs] = useState({
        title: '',
        description: '',
        shift: '',
        startDateTime: null,
        endDateTime: null,
    })
    const [listData, setListData] = useState([])
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: 1 })
    const [error, setError] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [shiftList, setShiftList] = useState([])

    const { selector: user } = useReduxStore('auth')
    // console.log(user,'user')
    // console.log(listData, 'listData')

    useEffect(() => {
        list()
    }, [controller])

    useEffect(() => {
        shift()
    }, [])

    const shift = async () => {
        const payLoad = {
            isDeleted: false,
            page: 1,
            count: 100
        }
        setSpinner(true)
        try {
            let response = await getShiftList(payLoad)
            setShiftList(response.data)
            setSpinner(false)

        } catch (error) {
            setSpinner(false)
        }
    }

    const list = async () => {
        try {
            const payload = {
                count: controller.rowsPerPage,
                page: controller.page,
                shiftId: user?.userInfo?.shift_id
            }
            setSpinner(true)
            let response = await LIST_ANNOUNCEMENT(payload)
            settotalCount(response.totalCount);
            setListData(response?.data)
            setSpinner(false)

        }
        catch {
            setSpinner(false)
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        setAnnouncementInputs((prevProps) => ({ ...prevProps, [name]: value }));
        // trimmedValue.length &&
        setError((pre) => {
            delete pre[name];
            return { ...pre };
        });
    }
    // Error Message
    const errorMessage = {
        title: requiredMessage('title'),
        description: requiredMessage('description'),
        startDateTime: requiredSelect('start date time'),
        endDateTime: requiredSelect('end date time'),
    }
    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!announcementInputs?.title) {
            errorObj['title'] = errorMessage['title']
        }
        if (!announcementInputs?.description) {
            errorObj['description'] = errorMessage['description']
        }
        if (!announcementInputs?.startDateTime) {
            errorObj['startDateTime'] = errorMessage['startDateTime']
        }
        if (!announcementInputs?.endDateTime) {
            errorObj['endDateTime'] = errorMessage['endDateTime']
        }

        setError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const clearForm = () => {
        setAnnouncementInputs({
            title: '',
            description: '',
            startDateTime: null,
            endDateTime: null,
        })
    }
    // Handle add submit data..!
    const handleAddSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        let payload = {
            title: announcementInputs?.title,
            description: announcementInputs?.description,
            createdby_id: user?.userInfo?._id,
            endDate: announcementInputs?.endDateTime,
            startDate: announcementInputs?.startDateTime,
            shiftId: announcementInputs?.shift
        }
        if (announcementModal?.data?._id) {
            payload['id'] = announcementModal?.data?._id
        }
        if (announcementInputs?.startDateTime > announcementInputs?.endDateTime) return toast.warn('End date time must be greather than start date time')
        // console.log(payload)
        // return
        setSpinner(true)
        try {
            let response
            if (announcementModal?.data?._id) {
                response = await UPDATE_ANNOUNCEMENT(payload)
            }
            else {
                response = await ADD_ANNOUNCEMENT(payload)
            }
            list()
            setAnnouncementModal({ data: "", open: false })
            toast.success(response?.message)
            clearForm()
            setSpinner(false)
        } catch (err) {
            toast.error(err?.message)
            setSpinner(false)
        }
    }
    const handlePagination = (obj) => {
        setController(obj)
    }

    return {
        methods: {
            setAnnouncementModal,
            handleInputChange,
            clearForm,
            handleAddSubmit,
            setError,
            setAnnouncementInputs,
            setController,
            handlePagination
        },
        data: {
            announcementModal,
            spinner,
            error,
            announcementInputs,
            totalCount,
            controller,
            listData,
            shiftList
        }
    }
}

export default UseAnnouncement

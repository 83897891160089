import React from 'react'
// import Select from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import Select from 'react-dropdown-select';
const index = ({ className, label, error, isRequired, value, onChange, options, name, keyName, multiple, disabled, searchBy, selectedValues,placeholder }) => {
    return (
        <div className={""}>
            <InputLabel id="custom-select-label" sx={{ fontSize: "13px" }}>{label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>

            <Select
                required={isRequired}
                searchable={true}
                multi={multiple}
                options={options}
                onChange={onChange}
                searchBy={searchBy}
                labelField={keyName}
                valueField={value}
                name={name}
                disabled={disabled}
                values={Array.isArray(selectedValues) ? selectedValues : []}
                placeholder={placeholder}
            />
            {error && <p className="text-danger">{error}</p>}
        </div>
    )
}

export default index
import React from 'react'
import { Button } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import SelectInput from '../../components/SelectInput'
import DateInput from '../../components/DateInput'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { useNavigate } from 'react-router-dom'
import { attendanceStatus } from '../../constants'
import UseAddAttendance from './Hooks/useAddAttendance'


const AddAttendance = () => {
  const { data, methods } = UseAddAttendance()
  const { spinner, agentList, attendError, addAttendanceInput } = data
  const navigate = useNavigate()

  // console.log(addAttendanceInput, 'addAttendanceInput')
  const { setAttendError, setAddAttendanceInput, handleupdateSubmit, handleInputChange } = methods
  // console.log(attendError, 'attendError')
  return (
    <div>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              <div className="text-center pt-3">
                <h4>{'Add Attendance'}</h4>
              </div>
              <div className="row mt-4">
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    Lable={'Emp Name'}
                    // multiple={true}
                    width={'310px'}
                    Options={agentList}
                    keyName={'firstName'}
                    className="form-field-design"
                    Name="empName"
                    SelectValue={addAttendanceInput.empName}
                    HandleChange={handleInputChange}
                    // onBlur={handleBlur}
                    Error={attendError.empName}
                    variant={'outlined'}
                    isRequired={true}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <SelectInput
                    LabelButton
                    Lable={'Status'}
                    Options={attendanceStatus}
                    keyName={'title'}
                    Name="status:''"
                    SelectValue={addAttendanceInput?.status}
                    HandleChange={(e) => {
                      setAddAttendanceInput((pre) => ({ ...pre, status: e.target.value }))
                      if (e.target.value === 'A') {
                        setAddAttendanceInput((prevProps) => ({ ...prevProps, inTime: null, outTime: null }))
                      }
                    }}
                    variant={'outlined'}
                    isRequired={true}
                  />
                  {attendError?.status && <p className='text-danger'>{attendError?.status}</p>}
                </div>
                {addAttendanceInput?.status === 'A' ? <div>
                  <div className="col-lg-4 mt-4">
                    <DateInput
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Date'}
                      // handleDateChange={(date) => handleDateChange(date, 'appliedStartDate')}
                      handleDateChange={(e) => {
                        setAddAttendanceInput((prevProps) => ({ ...prevProps, date: e }))
                        setAttendError((prev) => ({ ...prev, date: '' }))
                      }}
                      SelectedDate={addAttendanceInput?.date || null}
                    />
                  </div>
                </div> :
                  <> <div className="col-lg-4 mt-4">

                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'In Time'}
                      handleDateChange={(e) => {
                        setAddAttendanceInput((prevProps) => ({ ...prevProps, inTime: e }))
                        setAttendError((prev) => ({ ...prev, inTime: '' }))
                      }}
                      SelectedDate={addAttendanceInput?.inTime || null}
                      Error={attendError?.inTime}
                      disabled={addAttendanceInput?.status === 'A' ? true : false}
                    />
                  </div>
                    <div className="col-lg-4 my-2">

                      <DesktopDateTimePicker
                        className="w-100 form-field-design"
                        isRequired={true}
                        Label={'Out Time'}
                        handleDateChange={(e) => {
                          setAddAttendanceInput((prevProps) => ({ ...prevProps, outTime: e }))
                          setAttendError((prev) => ({ ...prev, outTime: '' }))
                        }}
                        SelectedDate={addAttendanceInput?.outTime || null}
                        Error={attendError?.outTime}
                        disabled={addAttendanceInput?.status === 'A' ? true : false}
                      />
                    </div></>

                }

              </div>
              <div className="col-lg-12 my-2 pe-4">
                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    {/* <Button variant="outlined" onClick={() => navigate('/attendance')}> */}
                    <Button variant="outlined" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                  <div className="m-2">
                    {
                      <Button variant="contained" onClick={handleupdateSubmit}>
                        Submit
                      </Button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAttendance

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useToGetSkillList, deleteSkill } from '../../../../repository/skillsRepo';
import { toast } from "react-toastify";
import useReduxStore from '../../../../hooks/useReduxStore';
import { useDispatch } from 'react-redux';
import { addFilter } from '../../../../Slices/FiltersSlice';
const useSkillList = () => {
    const { selector: SkillsFilter } = useReduxStore('filters')
    const dispatch = useDispatch()
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [filterAction, setFilterAction] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [search, setSearchText] = useState(SkillsFilter?.Skills?.filters?.search || "");
    const [statusType, setStatusType] = useState(SkillsFilter?.Skills?.filters?.search || "false");
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: [],
    });
    const cacheTime = { cacheTime: 10000 };

    // Listing payLoad
    let payload = {
        count: controller.rowsPerPage,
        page: controller.page,
        isDeleted:  statusType==='true',
        search: search
    }
    // if (typeof statusType == "boolean") payload.isActive = statusType

    useEffect(() => {
        // Dispatch the addFilter action with the current 'Skills' page and the filter
        dispatch(addFilter({
            page: 'Skills', filter: {
                search: search,
                statusType: statusType,
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, statusType]);
    // Fetch api
    const {
        data: skillList,
        error: skillError,
        isFetching: projectIsFetching,
        refetch,
    } = useToGetSkillList(payload,
        {
            enabled: false,
            ...cacheTime,
        }
    );

    useEffect(() => {
        refetch();
    }, [controller, filterAction]);
    // Error Toast Message
    useEffect(() => {
        if (skillError) {
            // toast.error(skillError?.message);
        }
    }, [skillError]);

    const resetFilter = () => {
        setFilterAction((prev) => !prev)
        setStatusType()
        setSearchText("")
    }

    // Apply Search Filter
    const applayFilter = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            refetch()
        });
    };

    // Handle Search
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        // Perform any other actions you need here
    };

    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj);
    };

    // handle Delete
    const handleDelete = async () => {
        try {
            setSpinner(true);
            let response = await deleteSkill({
                _id: deleteModal?.data,
                isDeleted: true,
            });
            toast.success(response.message);
            setDeleteModal({ data: "", open: false });
            refetch();
        } catch (error) {
            toast.error(error.message);
        }
        setSpinner(false);
    };

    return {
        data: {
            controller,
            isLoading: spinner || projectIsFetching,
            totalCount: skillError?.message === "No record found" ? 0 : skillList?.totalCount || 0,
            skillList: skillError?.message === "No record found" ? [] : skillList?.data || [],
            search,
            deleteModal,
            statusType,
        },
        methods: {
            handlePagination,
            resetFilter,
            applayFilter,
            handleSearchChange,
            setStatusType,
            handleDelete,
            setDeleteModal,
            refetch
        }
    }

}

export default useSkillList
import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import SearchIcon from '@mui/icons-material/Search'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Checkbox from '@mui/material/Checkbox'
import Table from '../../../components/Table/Table'
import {MenuItem, Switch } from '@mui/material'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
const currencies = [
  {
    value: 'USD',
    label: 'Collaborator',
  },
  {
    value: 'EUR',
    label: 'Support Agent',
  },
]

const Accounts = () => {
  const Navigate = useNavigate()
  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  const [openmodal, setmodalOpen] = React.useState(false)
  const handlemodalOpen = () => setmodalOpen(true)
  const handlemodalClose = () => setmodalOpen(false)

  const TableHead = [
    { label: <Checkbox {...label} className="m-0 p-0 ms-2 ps-1" />, id: '' },
    { label: 'Initiated by', id: '' },
    { label: 'Initiated at', id: '' },
    { label: 'Type', id: '' },
    { label: 'Status', id: '' },
    { label: 'Progress', id: '' },
    { label: 'Export file', id: '' },
    { label: 'Details', id: '' },
  ]
  const TableRow = [
    {
      by: 'Sachin',
      at: '18 Jul at 12:05 PM',
      type: 'Company',
      status: 'Phone',
      progress: '100%',
      file: 'Download',
      details: 'Details',
    },
    {
      by: 'Rana',
      at: '18 Jul at 12:05 PM',
      type: 'Company',
      status: 'Phone',
      progress: '100%',
      file: 'Download',
      details: 'Details',
    },
  ]
  const TableContactHead = [
    { label: 'Export Contact', id: '' },
    { label: 'Permission', id: '' },
  ]
  const TableContactRow = [
    {
      contact: 'Admin',
    },
    {
      contact: 'Agent',
    },
    {
      contact: 'Team',
    },
  ]

  return (
    <>
      <div className="container-fluid py-3">
        <div className="row my-4">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Account Export" value="1" />
                  <Tab label="Contact Export" value="2" />
                  <Tab label="Security" value="3" />
                  <Tab label="Helpdesk Settings" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <Form.Control type="search" placeholder="Search" className="me-2 w-100" aria-label="Search" />
                      </div>
                      <div>
                        <Button variant="primary4" className="px-4 ms-3">
                          <SearchIcon />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex me-5 pe-1">
                      <div>
                        <Button variant="primary1">
                          <ExitToAppIcon /> Export
                        </Button>{' '}
                      </div>
                      {/* <div className="ms-2">
                <Button className="dropDownButtons11 ms-4" onClick={() => Navigate(``)}>
                  <AddIcon/> Add New
                </Button>
              </div> */}
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-lg-12 ticet">
                    <div className="bg shadow raduus pt-2">
                      <div className="">
                        <div className="mt-2">
                          <table className="table">
                            <Table TableHeadData={TableHead} />
                            <tbody>
                              {TableRow?.map((item) => {
                                return (
                                  <tr
                                    className="text-start rounded-row "
                                    style={{
                                      borderRadius: '50px',
                                      backgroundColor: '#fff',
                                    }}
                                  >
                                    <td>
                                      <Checkbox {...label} />
                                    </td>
                                    <td className="table-link" onClick={() => Navigate(``)}>
                                      {item.by}
                                    </td>
                                    <td className="">{item.at}</td>
                                    <td className="">{item.type}</td>
                                    <td>{item.status}</td>
                                    <td>{item.progress}</td>
                                    <td>
                                      <span className="border border-primary p-2 rounded table-link">{item.file}</span>
                                    </td>
                                    <td>
                                      <span className="border border-light p-2 rounded border-2" onClick={handlemodalOpen}>
                                        {item.details}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                {/* <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="me-2 w-100"
                          aria-label="Search"
                        />
                      </div>
                      <div>
                        <Button variant="primary4" className="px-4 ms-3">
                          <SearchIcon />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex me-5 pe-1">
                      <div>
                        <Button variant="primary1">
                          <ExitToAppIcon /> Export
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row py-3">
                  <div className="col-lg-12 ticet">
                    <div className="bg shadow raduus pt-2">
                      <div className="">
                        <div className="mt-2">
                          <table className="table">
                            <Table TableHeadData={TableContactHead} />
                            <tbody>
                              {TableContactRow?.map((item) => {
                                return (
                                  <tr
                                    className="text-start rounded-row "
                                    style={{
                                      borderRadius: '50px',
                                      backgroundColor: '#fff',
                                    }}
                                  >
                                    <td className="table-link" onClick={() => Navigate(``)}>
                                      {item.contact}
                                    </td>
                                    <td>
                                      <Switch {...label} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div style={{ height: '73.5vh', overflowY: 'auto', overflowX: 'hidden' }}>
                  <div className="col-lg-12 ticet">
                    <div className="bg shadow raduus">
                      <div className="row p-3">
                        <h5>Login settings</h5>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>Google Login</b>
                              </p>
                              <p className="m-0">Users can login into their Freshworks account via their Google credentials.</p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} defaultChecked />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>SSO Login</b>
                              </p>
                              <p className="m-0">Configure SAML/ OAuth/OIDC/JWT SSO with the identity provider of your choice.</p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>Passwordless Login</b>
                              </p>
                              <p className="m-0">
                                Users can login to their Freshworks accounts with a magic link that is sent to their email ID. Please evaluate your compliance requirements before opting for
                                passwordless authentication.
                              </p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 ticet my-4">
                    <div className="bg shadow raduus">
                      <div className="row p-3">
                        <h5>Portal Security Settings</h5>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>Allow Portals to be embedded as iframes</b>
                              </p>
                              <p className="m-0">Authorize access for third-party sites to embed portal pages as iframes</p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} defaultChecked />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>Access setting for attachments</b>
                              </p>
                              <p className="m-0">Allow only logged in users to access attachments Note: Secured attachment links are sent in the email notifications instead of the actual files</p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="col-lg-11">
                              <p className="m-0">
                                <b>Cookie Policy</b>
                              </p>
                              <p className="m-0">Enable default Freshdesk cookie policy on all customer portals.</p>
                            </div>
                            <div className="col-lg-1 text-center pt-1">
                              <Switch {...label} />
                            </div>
                            <div className="col-lg-12 mt-3">
                              <TextField id="outlined-basic" label="URL" variant="outlined" placeholder="Example: https://www.acmesupport.com/privacy" className="w-100" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-12 ticet my-4">
                  <div className="bg shadow raduus">
                    <div className="row p-3">
                      <h5>Redaction</h5>
                      <div className="col-lg-6 p-4">
                        <div className="border border-light rounded border-3 row p-2">
                          <div className="col-lg-11">
                            <p className="m-0">
                              <b>Enable redaction of critical information</b>
                            </p>
                            <p className="m-0">
                            Note: Any string that matches the selected pattern might get redacted and cannot be retrieved later
                            </p>
                          </div>
                          <div className="col-lg-1 text-center pt-1">
                            <Switch {...label}  />
                          </div>
                          <div className="col-lg-12 mt-3 d-flex">
                          <Checkbox {...label} /> <p className="mt-3">Credit Card number</p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div> */}
                  <div className="col-lg-12 ticet my-4">
                    <div className="bg shadow raduus">
                      <div className="row p-3">
                        <h5>Password policy</h5>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="row">
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} defaultChecked /> <p className="mt-3">Minimum of 8 characters</p>
                              </div>
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} defaultChecked /> <p className="mt-3">Cannot contain username</p>
                              </div>
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} defaultChecked /> <p className="mt-3">Password expires never</p>
                              </div>
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} /> <p className="mt-3">Cannot be same as last 3 Password</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-4">
                          <div className="border border-light rounded border-3 row p-2">
                            <div className="row">
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} /> <p className="mt-3">Have an alphabet and a number</p>
                              </div>
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} /> <p className="mt-3">Have a mixed case</p>
                              </div>
                              <div className="col-lg-6 mt-3 d-flex">
                                <Checkbox {...label} /> <p className="mt-3">Have special character</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="4">
                <div className="contact">
                  <div className="container bg">
                    <div className="row">
                      <div className="row my-3">
                        <div className="col-lg-4 mt-4">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Helpdesk Name"
                            // multiline
                            className="w-75"
                            maxRows={4}
                            variant="standard"
                            defaultValue="zimo"
                          />
                        </div>
                        <div className="col-lg-4 mt-4">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Helpdesk URL"
                            // multiline
                            className="w-75"
                            maxRows={4}
                            variant="standard"
                            defaultValue="zimo-assist.freshdesk.com"
                          />
                        </div>
                        <div className="col-lg-4 mt-4">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Helpdesk Email"
                            // multiline
                            className="w-75"
                            maxRows={4}
                            variant="standard"
                            defaultValue="zimo-assist.freshdesk.com"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <TextField id="standard-select-currency" select label="Primary Language" defaultValue="" className="w-75" variant="standard">
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="col-lg-4 mt-5">
                          <TextField id="standard-select-currency" select label="Date Format" defaultValue="dd/mm/yyyy" className="w-75" variant="standard">
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="col-lg-4 mt-5">
                          <TextField id="standard-select-currency" select label="Time zone" defaultValue="(GMT-04:00) Eastern Time (US & Canada)" className="w-75" variant="standard">
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="col-lg-4 mt-5">
                          <TextField
                            id="standard-multiline-flexible"
                            label="Next Ticket ID #"
                            // multiline
                            className="w-75"
                            maxRows={4}
                            variant="standard"
                            defaultValue=""
                          />
                        </div>
                      </div>

                      <div className="col-lg-11 mb-5">
                        <div className="row">
                          <div className="d-flex justify-content-between">
                            <div className="mt-4"></div>
                            <div className="row">
                              <div className="col-lg-6 mt-4 text-center">
                                <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                              </div>
                              <div className="col-lg-6 mt-4 text-center">
                                <Button className="px-4 add-cc-btn mt-3">Save</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Modal open={openmodal} onClose={handlemodalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title">
            <h3>Export details</h3>
            <h5 className="mt-4">Details</h5>
          </Typography>
          <Typography id="modal-modal-description" className="w-100">
            <div className="row py-4">
              <div className="col-lg-6">
                <div className="mt-3">
                  <p className="m-0 p-0">TYPE</p>
                  <p className="m-0 p-0">
                    <b>Company</b>
                  </p>
                </div>
                <div className="mt-3">
                  <p className="m-0 p-0">PROGRESS</p>
                  <p className="m-0 p-0">
                    <b>100</b>
                  </p>
                </div>
                <div className="mt-3">
                  <p className="m-0 p-0">FORMAT</p>
                  <p className="m-0 p-0">
                    <b>CSV</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-3">
                  <p className="m-0 p-0">STATUS</p>
                  <p className="m-0 p-0">
                    <b>Completed</b>
                  </p>
                </div>
                <div className="mt-3">
                  <p className="m-0 p-0">CREATED AT</p>
                  <p className="m-0 p-0">
                    <b>18 Jul at 12:05 PM</b>
                  </p>
                </div>
                <div className="mt-3">
                  <p className="m-0 p-0">UPDATED AT</p>
                  <p className="m-0 p-0">
                    <b>18 Jul at 12:05 PM</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="">Configuration</h5>
              <div style={{ backgroundColor: '#dbdbdb' }} className="py-2 ps-2">
                {' '}
                <h6>Company</h6>
                <p className="m-0 mt-3 p-0">
                  <b>COMPANY FIELDS</b>
                </p>
                <p>Company Name,Description,Notes,Company Domains,Health score,Account tier,Renewal date,Industry</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className="">{/* <FormControlLabel  control={<Checkbox />} label="Create another" /> */}</div>
                  <div className="row">
                    {/* <div className="col-lg-5 mt-2 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Cancel</Button>
                    </div> */}
                    <div className="col-lg-7 mt-2 text-center">
                      <Button className="px-4 add-cc-btn mt-3">Close</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default Accounts

import React from 'react'
import Table from '../../components/Table/Table'
import { Pagination } from '../../components/pagination/Pagination'
import useProjectNote from './Hooks/useProjectNote'
import SearchAndStatusFilter from '../../components/SearchAndStatusFilter'
import { Spinner } from '../../components/Spinner/Spinner'
import { ToastContainer } from 'react-toastify'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../../components/Modal'
import { CKEditorCustomToolbar } from '../../constants'

const ProjectNotes = () => {
  const { data, methods } = useProjectNote()

  const {
    noteList,
    search,
    statusType,
    totalCount,
    controller,
    isLoading,
    deleteModal,
    // =============
    noteFormModel,
    noteInputs,
    error,
  } = data
  const {
    handleSearchChange,
    setStatusType,
    resetFilter,
    applayFilter,
    handlePagination,
    setDeleteModal,
    handleDelete,
    handleAddSubmit,
    handleupdateSubmit,
    setNoteInputs,
    setError,
    setNoteFormModel,
  } = methods

  const TableHead = [
    { label: '#', id: '' },
    { label: 'Note', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} />
            </div>
            <div className="d-flex align-items-end me-5 pe-1">
              <div className="ms-2">
                <Button className="dropDownButtons11 ms-4" onClick={() => setNoteFormModel((prev) => ({ ...prev, open: true }))}>
                  <AddIcon /> Add New
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {noteList?.map((item, index) => {
                        return (
                          <tr
                            key={item._id}
                            className="text-start rounded-row "
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td className="table-link">{controller.rowsPerPage * (controller.page - 1) + index + 1} </td>
                            <td className="" dangerouslySetInnerHTML={{ __html: item?.note }}></td>
                            <td>
                              <div className="dropdown p-0 align-items-center justify-content-center">
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <li style={{ cursor: 'pointer' }} onClick={() => setNoteFormModel({ data: item._id, open: true })} className="dropdown-item">
                                    Edit
                                  </li>
                                  <li className="dropdown-item" onClick={() => setDeleteModal({ data: item._id, open: true })}>
                                    Delete
                                  </li>
                                  {/* <a className="dropdown-item">
                                        View History
                                      </a> */}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!noteList.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Modal */}
      <Modal
        size="lg"
        show={noteFormModel?.open}
        onHide={() => {
          setNoteFormModel({ data: '', open: false })
          setNoteInputs({ note: '' })
        }}
        headerContent={<h5>Notes</h5>}
        bodyContent={
          <>
            <div className="col-lg-12">
              <div className="App m-3">
                <p className="m-0 mb-3">
                  Notes <span className="text-secondery">*</span>{' '}
                </p>
                <CKEditor
                  editor={ClassicEditor}
                  data={noteInputs.note} // Set CKEditor data to noteInputs.note
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    // Update noteInputs.note when CKEditor content changes
                    setNoteInputs((prevProps) => ({ ...prevProps, note: data }))
                    setError((prev) => ({ ...prev, note: '' }))
                  }}
                  onBlur={(event, editor) => {
                    !noteInputs.note && setError((prev) => ({ ...prev, note: 'Please enter note' }))
                  }}
                />
                {error.note && <p className="text-danger">{error.note}</p>}
              </div>
            </div>

            <div className="col-lg-11 mb-5">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className=" d-flex justify-content-end ">
                    {noteFormModel?.data ? (
                      <div className="m-2">
                        <Button variant="contained" onClick={handleupdateSubmit}>
                          Update
                        </Button>
                      </div>
                    ) : (
                      <div className="m-2">
                        <Button variant="contained" onClick={handleAddSubmit}>
                          Create
                        </Button>
                      </div>
                    )}
                    <div className="m-2">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setNoteFormModel({ data: '', open: false })
                          setNoteInputs({ note: '' })
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default ProjectNotes

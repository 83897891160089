import { useDispatch, useSelector } from "react-redux";
const useReduxStore = ( key ) => {
    const Dispatch = useDispatch()
    const selector = useSelector((state) => state[key])
    return {
        Dispatch,
        selector
    }
}

export default useReduxStore
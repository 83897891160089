import React from 'react'
import { Button} from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useNavigate } from 'react-router-dom'
// import Box from '@mui/material/Box'
// import MenuItem from '@mui/material/MenuItem'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import FormGroup from '@mui/material/FormGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
// import Form from 'react-bootstrap/Form'
// import SearchIcon from '@mui/icons-material/Search'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import AddIcon from '@mui/icons-material/Add'
// import Tab from '@mui/material/Tab'
// import TabContext from '@mui/lab/TabContext'
// import TabList from '@mui/lab/TabList'
// import TabPanel from '@mui/lab/TabPanel'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
// import Menu from '@mui/material/Menu'
// import Fade from '@mui/material/Fade'
// import { FormLabel, Select, Switch, Autocomplete, TextField, InputLabel } from '@mui/material'
// import Table from '../../../components/Table/Table'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import FormControl from '@mui/material/FormControl'
// import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useTeamAddUpdate from './Hooks/useTeamAddUpdate'
import TextFields from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'
import { CKEditorCustomToolbar } from '../../../constants'
// import { Prev } from 'react-bootstrap/esm/PageItem'

const AddBusinessHours = () => {
  const navigate = useNavigate()
  const { data, methods } = useTeamAddUpdate()
  const { spinner, error, teamInputs, state, users } = data
  const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, setTeamInputs, setError } = methods



  // const TableHead = [
  //   { label: 'Dates', id: '' },
  //   { label: 'Name', id: '' },
  //   { label: 'Action', id: '' },
  // ]
  // const TableRow = [
  //   {
  //     date: 'Jan 01',
  //     name: 'New Year’s Day',
  //   },
  //   {
  //     date: 'Jan 16',
  //     name: 'Birthday of Martin Luther King Jr',
  //   },
  //   {
  //     date: 'Feb 20',
  //     name: 'Washington’s Birthday',
  //   },
  //   {
  //     date: 'May 28',
  //     name: 'Memorial Day',
  //   },
  //   {
  //     date: 'Jul 04',
  //     name: 'Independence Day',
  //   },
  //   {
  //     date: 'Sep 03',
  //     name: 'Labor Day',
  //   },
  //   {
  //     date: 'Oct 08',
  //     name: 'Columbus Day',
  //   },
  //   {
  //     date: 'Nov 11',
  //     name: 'Veterans Day',
  //   },
  //   {
  //     date: 'Nov 22',
  //     name: 'Thanksgiving Day',
  //   },
  //   {
  //     date: 'Dec 25',
  //     name: 'Christmas Day',
  //   },
  // ]
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  // const [value, setValue] = React.useState('1')

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }
  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              <div className="text-center pt-3">
                <h4>{state?.teamId ? 'Update group' : 'Add new group'}</h4>
              </div>
              <div className="col-lg-12 mt-4">
                <TextFields
                  Lable="Name"
                  Name="name"
                  variant="outlined"
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={teamInputs.name}
                  width={100}
                  Error={error.name}
                />
              </div>

              <div className="col-lg-12 mt-4">
                <SelectInput
                  Lable={'TL'}
                  // multiple={true}
                  width={'310px'}
                  Options={users}
                  keyName={'firstName'}
                  className="form-field-design"
                  Name="tl"
                  SelectValue={teamInputs.tl}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  // Error={error.tl}
                  variant={'outlined'}
                // isRequired={true}
                />
                {teamInputs?.tl?.length ? <p className='float-end text-danger pointer mt-1' onClick={() => setTeamInputs((pre) => ({ ...pre, tl: null }))}>Remove TL</p> : null}
              </div>

              {/* <div className="col-lg-11 mx-auto mb-2"> */}
              {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
                Time zone<span className="secondery"> *</span>
              </InputLabel>
              <Autocomplete
                onChange={(e, newValue) => {
                  setTeamInputs((prevProps) => ({ ...prevProps, timezone: newValue }))
                }}
                disableClearable={true}
                value={teamInputs.timezone || null}
                size="small"
                options={timeZonelist}
                getOptionLabel={(option) => `${option.text}`}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                renderOption={(props, option) => {
                  return <li {...props}>{`${option?.text}`}</li>
                }}
              />
              {error.timezone && <p className="text-danger">{error.timezone}</p>} */}
              {/* </div> */}

              {/* <SelectInput
                  Lable={'Time zone'}
                  width={"200px"}
                  Options={timeZonelist}
                  keyName={"text"}
                  Name="timezone"
                  SelectValue={teamInputs.timezone}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.timezone}
                  variant={"outlined"}
                  isRequired={true}
                  valueKey={"timezone"}
                /> */}

              {/* <div className="row my-4">
              <Box sx={{ width: "100%", }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Business Hours" value="1" />
                      <Tab label="Holidays" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div>
                      <h5>Set business hours</h5>
                      <div className="mt-4 pt-2">
                        <div>
                          <h6 className="m-0 p-0">Round the clock support</h6>
                        </div>
                        <FormControlLabel
                          value="best"
                          control={<Radio />}
                          label="24 hrs x 7 days"
                        />
                        <br />
                      </div>
                      <div className="pt-2">
                        <div>
                          <h6 className="m-0 p-0">
                            Setup custom working hours for your team
                          </h6>
                        </div>
                        <FormControlLabel
                          value="worst"
                          control={<Radio />}
                          label="Custom business hours"
                        />
                      </div>
                    </div>
                    <div>
                      <h6>Select the working days</h6>
                      <div className="d-flex">
                        <div className="my-3">
                          <span className="diseblesun sun">Sun</span>
                          <span className="diseblemon mon">Mon</span>
                          <span className="diseblemon tue">Tue</span>
                          <span className="diseblemon wed">Wed</span>
                          <span className="diseblemon thu">Thu</span>
                          <span className="diseblemon fri">Fri</span>
                          <span className="diseblesun sat">Sat</span>
                        </div>
                        <div className="my-3">
                          <p>(5 days selected)</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h6>Enter the working hours</h6>
                      <div className="d-flex">
                        <div className="my-3">
                          <div className="d-flex">
                            <div className="col-lg-4">
                              <p>Monday</p>
                            </div>
                            <div className="col-lg-8 d-flex">
                              <input type="time" className="w-100 h-75" />{" "}
                              &nbsp; &nbsp; <span className="mt-2">to</span>{" "}
                              &nbsp; &nbsp;
                              <input type="time" className="w-100 h-75" />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-lg-4">
                              <p>Tuesday</p>
                            </div>
                            <div className="col-lg-8 d-flex">
                              <input type="time" className="w-100 h-75" />{" "}
                              &nbsp; &nbsp; <span className="mt-2">to</span>{" "}
                              &nbsp; &nbsp;
                              <input type="time" className="w-100 h-75" />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-lg-4">
                              <p>Wednesday</p>
                            </div>
                            <div className="col-lg-8 d-flex">
                              <input type="time" className="w-100 h-75" />{" "}
                              &nbsp; &nbsp; <span className="mt-2">to</span>{" "}
                              &nbsp; &nbsp;
                              <input type="time" className="w-100 h-75" />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-lg-4">
                              <p>Thursday</p>
                            </div>
                            <div className="col-lg-8 d-flex">
                              <input type="time" className="w-100 h-75" />{" "}
                              &nbsp; &nbsp; <span className="mt-2">to</span>{" "}
                              &nbsp; &nbsp;
                              <input type="time" className="w-100 h-75" />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-lg-4">
                              <p>Friday</p>
                            </div>
                            <div className="col-lg-8 d-flex">
                              <input type="time" className="w-100 h-75" />{" "}
                              &nbsp; &nbsp; <span className="mt-2">to</span>{" "}
                              &nbsp; &nbsp;
                              <input type="time" className="w-100 h-75" />
                            </div>
                          </div>
                        </div>
                        <div className="ps-5 pt-4 table-link"><sup><ContentCopyIcon style={{ width: "16px", height: "16px" }} />&nbsp;Copy to all</sup></div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="row">
                      <div className="d-flex justify-content-between my-2">
                        <div className="d-flex">
                          <p className="text-muted">
                            Holidays will be ignored when calculating SLA for a
                            ticket. Add Holidays
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="ms-2">
                            <Button
                              className="dropDownButtons11 ms-4"
                              onClick={() => navigate(`/business-hours-form`)}
                            >
                              <AddIcon /> Add Holidays
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 ticet">
                        <div className="bg shadow raduus">
                          <div className="">
                            <div className="mt-2">
                              <table className="table">
                                <Table TableHeadData={TableHead} />
                                <tbody>
                                  {TableRow?.map((item) => {
                                    return (
                                      <>
                                        <div style={{ height: "10px" }}></div>
                                        <tr
                                          className="text-start rounded-row "
                                          style={{
                                            borderRadius: "50px",
                                            backgroundColor: "#fff",
                                          }}
                                        >
                                          <td className="">{item.date}</td>
                                          <td>{item.name}</td>
                                          <td>
                                            <div>
                                              <a
                                                id="fade-button"
                                                aria-controls={
                                                  open ? "fade-menu" : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                onClick={handleClick}
                                              >
                                                <MoreVertIcon />
                                              </a>
                                              <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                  "aria-labelledby":
                                                    "fade-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                              >
                                                <MenuItem onClick={handleClose}>
                                                  Edit
                                                </MenuItem>
                                                <MenuItem onClick={handleClose}>
                                                  Delete
                                                </MenuItem>
                                              </Menu>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div> */}

              <div className="col-lg-12 mt-4">
                <div className="App">
                  <p className="m-0">
                    Description <span className="text-secondery">*</span>{' '}
                  </p>
                  <CKEditor
                    editor={ClassicEditor}
                    data={teamInputs.description} // Set CKEditor data to teamInputs.description
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    onReady={(editor) => {
                      // You can store the "editor" and use it when needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      // Update teamInputs.description when CKEditor content changes
                      setTeamInputs((prevProps) => ({ ...prevProps, description: data }))
                      setError((prev) => ({ ...prev, description: '' }))
                    }}
                    onBlur={(event, editor) => {
                      !teamInputs.description && setError((prev) => ({ ...prev, description: 'Please enter description' }))
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                  {error.description && <p className="text-danger">{error.description}</p>}
                </div>
              </div>

              <div className="col-lg-12 my-2 pe-4">
                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    <Button variant="outlined" onClick={() => navigate('/setting/group')}>
                      Cancel
                    </Button>
                  </div>
                  {state?.teamId ? (
                    <div className="m-2">
                      <Button variant="contained" onClick={handleupdateSubmit}>
                        Update
                      </Button>
                    </div>
                  ) : (
                    <div className="m-2">
                      <Button variant="contained" onClick={handleAddSubmit}>
                        Create
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBusinessHours

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useToGetUserTimeLogList, getTimeLogDetails, ExportTimelogtList } from '../../../repository/TimeLog'
import { toast } from 'react-toastify'
import { userFullList } from '../../../repository/teamManagementRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../Slices/FiltersSlice'
const useUsersTimeLog = () => {
  const { selector: user } = useReduxStore('auth')
  const { selector: TimeLogFilter } = useReduxStore('filters')
  const dispatch = useDispatch()
  const [controller, setController] = useState({ page:TimeLogFilter?.TimeLog?.filters?.page|| 1, rowsPerPage:TimeLogFilter?.TimeLog?.filters?.rowsPerPage|| 10 })
  const [spinner, setSpinner] = useState(false)
  const [filterAction, setFilterAction] = useState(false)

  const [timeLogDetails, setTimeLogDetailsModel] = useState({
    open: false,
    timeLogId: '',
    data: {},
  })
  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')
  const [isReset, setIsReset] = useState(TimeLogFilter?.TimeLog?.filters?.isReset)
  const [agentList, setAgentList] = useState([])
  const [timeLogListInput, setTimeLogListInput] = useState({
    approvalstatus: TimeLogFilter?.TimeLog?.filters?.approvalstatus || [],
    agent: TimeLogFilter?.TimeLog?.filters?.agent || [],
    // startDate: TimeLogFilter?.TimeLog?.filters?.startDate || new Date(),
    startDate: getInitialStartDate(),
    endDate: TimeLogFilter?.TimeLog?.filters?.endDate || null,
  })
  function getInitialStartDate() {
    if (isReset && TimeLogFilter?.TimeLog?.filters?.startDate == null) {
      return null;
    }
    if (!isReset && TimeLogFilter?.TimeLog?.filters?.startDate) {
      return TimeLogFilter.TimeLog.filters.startDate;
    }
    return new Date();
  }

  const cacheTime = { cacheTime: 10000 }

  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    isDeleted: false,
    //   search: search,
  }
  if (timeLogListInput?.approvalstatus?.length > 0) {
    payload.status = timeLogListInput.approvalstatus.map((item) => item.value)
  }
  if (timeLogListInput.startDate) {
    payload.startDate = moment(timeLogListInput.startDate).format("YYYY-MM-DD")
  }
  if (timeLogListInput.endDate) {
    payload.endDate = moment(timeLogListInput.endDate).format("YYYY-MM-DD")
  }
  if (timeLogListInput?.agent?.length > 0) {
    payload.user_ids = timeLogListInput.agent.map((item) => item._id)
  }

  useEffect(() => {
    // Dispatch the addFilter action with the current 'TimeLog' page and the filter
    if (timeLogListInput.startDate) {
      setIsReset(false)
    }
    dispatch(addFilter({
      page: 'TimeLog', filter: {
        approvalstatus: timeLogListInput.approvalstatus,
        agent: timeLogListInput.agent,
        // startDate: timeLogListInput.startDate,
        startDate: isReset === true ? null : timeLogListInput.startDate,
        endDate: timeLogListInput.endDate,
        isReset: isReset,
        page:controller?.page,
        rowsPerPage:controller?.rowsPerPage
                
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset, timeLogListInput,controller?.page,controller?.rowsPerPage]);

  const {
    data: TimeLogList,
    error: TimeLogError,
    isFetching: TimeLogIsFetching,
    refetch: refetchTimeLog,
  } = useToGetUserTimeLogList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTimeLog()
  }, [controller, filterAction])

  // Export TimeLog Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportTimelogtList(payload)
      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Filter's Api's
  useEffect(() => {
    getAgentList()
  }, [])

  // Get All Agent  List
  const getAgentList = async (groupId) => {
    // const teamIds = groupId?.map(item => item._id);
    // let payload = {
    //     team_id: teamIds,
    // };
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response.data)
      // response?.data?.forEach((item) => {
      //   if (item?._id === user?.userInfo?._id) {
      //     setTimeLogListInput((prevProps) => ({ ...prevProps, agent: TimeLogFilter?.TimeLog?.filters?.agent || [item] }));
      //   }
      // });
    } catch (error) {
      setAgentList([])
      setTimeLogListInput((prevProps) => ({ ...prevProps, agent: '' }))
    }
    setSpinner(false)
  }

  const getLogDetails = async (timeLogId) => {
    try {
      setSpinner(true)
      let response = await getTimeLogDetails(timeLogId)
      setTimeLogDetailsModel((prev) => ({ ...prev, data: response.data }))
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchTimeLog()
    });
  }
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setSearchText('')
    setIsReset(true);
    setTimeLogListInput({
      approvalstatus: [],
      agent: [],
      startDate: null,
      endDate: null,
    })
    setController({ page: 1, rowsPerPage: 10 })
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }
  return {
    data: {
      controller,
      isLoading: spinner || TimeLogIsFetching,
      totalCount: TimeLogError?.message === 'No record found' ? 0 : TimeLogList?.totalCount || 0,
      TimeLogList: TimeLogError?.message === 'No record found' ? [] : TimeLogList?.data || [],
      agentList,
      search,
      statusType,
      timeLogListInput,
      timeLogDetails,
      timeLogPermission: user
    },
    methods: {
      setTimeLogDetailsModel,
      getLogDetails,
      setController,
      setTimeLogListInput,
      handlePagination,
      resetFilter,
      applayFilter,
      setSearchText,
      setStatusType,
      getAgentList,
      Export
    },
  }
}

export default useUsersTimeLog
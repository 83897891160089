import React from 'react'
import { Button } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useNavigate } from 'react-router-dom'
import useProjectAddUpdate from './Hooks/useProjectAddUpdate'
import TextField from '../../components/TextField'
import { CKEditorCustomToolbar } from '../../constants'
// import SelectInput from '../../components/SelectInput'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import MultipleSelect from "../../components/MultipleSelect"

const NewProjectForm = () => {
  const navigate = useNavigate()
  const { data, methods } = useProjectAddUpdate()
  const { spinner, error, projectInputs, state, ProjectPermission, agentList } = data
  const { handleInputChange, handleBlur, handleAddSubmit, handleupdateSubmit, setProjectInputs } = methods
  const { add, edit } = ProjectPermission?.userInfo?.permission?.project

  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              <div className="text-center pt-3">
                <h4>{state?.projectId ? 'Update project' : 'Add new project'}</h4>
              </div>
              {/* <div className="row mt-4"> */}
              <div className="col-lg-6 mt-4">
                <TextField
                  Lable="Project ID"
                  Name="project_id"
                  variant="outlined"
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={projectInputs.project_id}
                  width={100}
                  Error={error.project_id}
                />
              </div>
              <div className="col-lg-6 mt-4">
                <TextField
                  Lable="Project Name"
                  Name="name"
                  variant="outlined"
                  ClassName="form-field-design"
                  Require={true}
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={projectInputs.name}
                  width={100}
                  Error={error.name}
                />
              </div>
              <div className="col-lg-6 my-2">
                {/* <MultipleSelect
                  isRequired="true"
                  label={'Coordinator'}
                  options={agentList}
                  keyName={'name'}
                  name="coordinator"
                  value={projectInputs.coordinator}
                  onChange={(e) => handleInputChange({ target: { name: "coordinator", value: e } })}
                  onBlur={handleBlur}
                  multiple={true}
                /> */}
                <MultipleSelect
                  label={"Coordinator"}
                  // multiple={true}
                  options={agentList}
                  searchBy={"name"}
                  keyName={"name"}
                  name={"Projects"}
                  onChange={(e) => handleInputChange({ target: { name: "coordinator", value: e } })}
                  value={"_id"}
                  selectedValues={projectInputs?.coordinator}
                />
                {error?.coordinator && <span className='text-danger'>{error?.coordinator}</span>}
                <div className='mt-2'>
              <Button variant='contained' onClick={()=>handleInputChange({ target: { name: "coordinator", value: "" } })}>Remove Coordinator</Button>
                </div>
              </div>

              <div className="col-lg-12  pt-2">
                <div className="App mt-2">
                  <p className="m-0 mb-3">
                    Notes <span className="text-secondery">*</span>
                  </p>
                  <CKEditor
                    editor={ClassicEditor}
                    data={projectInputs.notes}
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    onReady={(editor) => {
                      // You can store the "editor" and use it when needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      // Update projectInputs.notes when CKEditor content changes
                      setProjectInputs((prevProps) => ({ ...prevProps, notes: data }))
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                  {error.notes && <p className="text-danger">{error.notes}</p>}
                </div>
              </div>
              <div className="col-lg-12 my-2 pe-4">
                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    <Button variant="outlined" onClick={() => navigate('/projects')}>
                      Cancel
                    </Button>
                  </div>
                  {state?.projectId ? (
                    <div className="m-2">
                      {edit?.value && <Button variant="contained" onClick={handleupdateSubmit}>
                        Update
                      </Button>}
                    </div>
                  ) : (
                    <div className="m-2">
                      {add?.value && <Button variant="contained" onClick={handleAddSubmit}>
                        Create
                      </Button>}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewProjectForm

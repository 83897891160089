/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../credentials/credentials.css";
import TextField from "../../components/TextField"

import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "../../components/Spinner/Spinner";
import { requiredMessage } from "../../utils";
import { AcceptInvetation, ChecqResetToken } from "../../repository/auth";
const CreateNewPassword = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(false)
  const [createPasswordInputs, setCreatePasswordInputs] = useState({
    password: "",
    confirm_password: ""
  })
  const [error, setError] = useState({})


  useEffect(() => {
    checqResetTokenApi()
  }, [])

  // Checq Token Error
  const checqResetTokenApi = async () => {
    try {
     await ChecqResetToken({ token: id })
    } catch (error) {
      toast.error(error.message)
    }
  }


  //Handle Input value
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCreatePasswordInputs((prevProps) => ({ ...prevProps, [name]: value }))
    value?.trim().length && setError(pre => {
      delete pre[name]
      return ({ ...pre })
    })
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    !value?.trim()?.length && setError(pre => ({ ...pre, [name]: errorMessage[name] }))
  }
  // -***--------------------***-
  // Error Message
  const errorMessage = {
    password: requiredMessage("password"),
    confirm_password: requiredMessage("confirm password"),
  }

  // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!createPasswordInputs.password?.trim()?.length) {
      errorObj['password'] = errorMessage['password']
    }
    if (!createPasswordInputs.confirm_password) {
      errorObj['confirm_password'] = errorMessage['confirm_password'];
    } else if (createPasswordInputs.password !== createPasswordInputs.confirm_password) {
      errorObj['confirm_password'] = "Passwords do not match.";
    }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // Handle submit data..!
  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!isValid()) return
    setSpinner(true)
    try {
      let payload = {
        password: createPasswordInputs.password,
        confirmPassword: createPasswordInputs.confirm_password,
        token: id
      }
      let response = await AcceptInvetation(payload)
      navigate('/')
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }
  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className=" pt-5">
        <div className="container  mt-5 ">
          <div className="contact" style={{ height: "80vh" }}>
            <div className="col-lg-5 mx-auto bg2 shadow2 p-4">
              <div className="text-center">
                <h4><b>Create New Password </b></h4>
                <p className="login-font-waight" >Your new password must be different from <br /> previous used Password</p>
              </div>
              <div className="">
                <TextField
                  Lable="Password"
                  Name="password"
                  variant="standard"
                  className="col-6 mt-4"
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={createPasswordInputs.password}
                  Require={true}
                  width={100}
                  Error={error.password}
                  type={"password"}
                />
                <TextField
                  Lable="Confirm Password"
                  Name="confirm_password"
                  variant="standard"
                  className="col-6 mt-4"
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={createPasswordInputs.confirm_password}
                  Require={true}
                  width={100}
                  Error={error.confirm_password}
                  type={"password"}
                />
                {/* <div className="text-end"><span className="table-link loginfont" onClick={() => navigate("/forgotpassword")}>Forgot your Password</span></div> */}
                <div className="pt-3">
                  <Button variant="primary1" className="py-2 w-100" onClick={(event) => handleSubmit(event)}>Submit</Button></div>
              </div>

              {/* <div className="text-center my-4">
              <p className="fw-1 text-muted">---- Or, Sign in With ----</p>
              </div>
              <div className="">
                  <div className="text-center">
                    <p className="loginfont google"><img src={Google} className="login-icon" alt="" /> &nbsp; Continue With Google</p>   
                  </div>
              </div>
              <div className="my-3">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Apple} className="login-icon" alt="" /> &nbsp; &nbsp; &nbsp; Continue With Apple</p>   
                  </div>
              </div>
              <div className="">
                    <div className="text-center">
                    <p className="loginfont google"><img src={Facebook} className="login-icon" alt="" /> Continue With Facebook</p>   
                  </div>
              </div>
              <p className="loginfont text-center mt-4 pt-2">Don't have an account? <span className="table-link">Register Now</span></p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateNewPassword

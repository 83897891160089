import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert';
import CakeIcon from '@mui/icons-material/Cake';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CelebrationIcon from '@mui/icons-material/Celebration';

export default function Announcement() {
    const [announcements, setAnnouncements] = useState([])
    // const BIRTHDAY = <CakeIcon fontSize="inherit" />
    // const ANNIVERSARY = <CakeIcon fontSize="inherit" />
    // const ALERT = <WarningAmberIcon fontSize="inherit" />
    // const ANNOUNCEMENT = <NotificationsActiveIcon fontSize="inherit" />

    useEffect(() => {
        let announcementArr = [
            { type: "BIRTHDAY", description: "Happy Birhtday Aashish" },
            { type: "ANNIVERSARY", description: "Happy Work Anniversary Gurbhej" },
            // {type: "ALERT", description: "Please verify your attendance for the past month"},
            // {type: "ANNOUNCEMENT", description: "This Week's saturday is working"},
        ]
        setAnnouncements(announcementArr)
    }, [])

    const getIcon = (type) => {
        switch (type) {
            case "BIRTHDAY":
            default:
                return <CakeIcon fontSize="inherit" />
            case "ANNIVERSARY":
                return <CelebrationIcon fontSize="inherit" />
            case "ALERT":
                return <WarningAmberIcon fontSize="inherit" />
            case "ANNOUNCEMENT":
                return <NotificationsActiveIcon fontSize="inherit" />

        }
    }



    return (
        announcements.length ?
            // <div className='announcement-bar'>
            //             {announcements.map((alert, index) =>
            //                 <Alert  style={{marginBottom: "5px"}} icon={<CakeIcon fontSize="inherit" />}>
            //                 {alert.description}
            //                 </Alert>
            //             )}
            //     </div>

            // : ''
        <div className='announcement-bar'>
            {/* <marquee>
                {announcements.map((alert, index) => <span key={index} className='me-2 announcemnet-bar-text-span'>
                    <span className='me-2 announcemnet-bar-icon-span'>{getIcon(alert.type)}</span> {alert.description}
                </span>

                    // <Alert  severity="warning" style={{marginBottom: "5px"}} icon={<CakeIcon fontSize="inherit" />}>
                    // {alert.description}
                    // </Alert>
                )}
            </marquee> */}
        </div> 
        : ''
    )
}
